<template>
  <div class="tab">
    <b-button-group class="mr-2">
      <b-button
        block
        size="sm"
        :class="{
          'w-auto': true,
          'button-secondary': true,
          'fade-in': true,
        }"
        variant="secondary"
        style="background-color: #e5e5e5; border: 1px solid #e5e5e5"
        :key="tabShortcut.MenuID"
        :data-menu-id="tabShortcut.MenuID"
        data-window-target="active"
        ><i class="fas fa-external-link"></i> {{ tabShortcut.Text }}</b-button
      >

      <!-- <b-button
        variant="dark"
        size="sm"
        @click="$store.dispatch('removeTabByID', tab.id)"
        ><i class="fas fa-times"></i
      ></b-button> -->
    </b-button-group>
  </div>
</template>

<script>
export default {
  props: {
    tabShortcut: {
      required: true,
      type: Object,
    },
  },
};
</script>
