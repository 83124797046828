
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        
        
        const __nunjucks_template_import__dep_0 = require("../elements/combobox.html");
        
        const __nunjucks_template_import__dep_1 = require("./table.html");
        
        const __nunjucks_template_import__dep_2 = require("./form.html");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.assets};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/dimensionobjects.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("views/elements/combobox.html", false, "views/content/dimensionobjects.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("combo", t_1);
output += "\n";
env.getTemplate("views/content/table.html", false, "views/content/dimensionobjects.html", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("table", t_4);
output += "\n";
env.getTemplate("views/content/form.html", false, "views/content/dimensionobjects.html", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
context.setVariable("form", t_7);
output += "\n\n<div class=\"grid-fill\">\n    <div class=\"col col-xs-12 col-sm-6 col-md-4\">\n        <!--Not very clean, fix setupCustomEnv with renderOptions.customButtons-->\n        <div class=\"action-bar sticky-div\">\n            <div class=\"buttons\">\n                <button class=\"button button-default\" data-window-event=\"cancelTreeView\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Save"), env.opts.autoescape);
output += "</button>\n                <button class=\"button button-default\" data-window-event=\"cancelTreeView\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Cancel"), env.opts.autoescape);
output += "</button>\n            </div>\n        </div>\n        <div class=\"content\">\n            <label class=\"table-cell field checkbox\">\n                <input name=\"3\" type=\"checkbox\" >\n                <span>Verplicht om in te vullen</span>\n            </label>\n\n            <div class=\"tree-view\">\n\n                <form data-form-id=\"FormPermissions\">\n                    <div class=\"branch\">\n                        <div class=\"branch-name\">Lopende projecten</div>\n                        <div class=\"branches\">\n                            <div class=\"stick\" </div>\n                            <label class=\"table-cell field checkbox\">\n                                <input name=\"3\" type=\"checkbox\" >\n                                <span data-window-event=\"selectPermissions:\">Dow</span></label>\n                        </div>\n                        <div class=\"stick\" </div>\n                            <label class=\"table-cell field checkbox\">\n                                <input name=\"4\" type=\"checkbox\" >\n                                <span data-window-event=\"selectPermissions:\">Schip</span></label>\n                    </div>\n                </div>\n            </div>\n        </form>\n\n    </div>\n</div>\n</div>\n<div class=\"col col-xs-12 col-md-8 col-sm-6 panel left-border\">\n<div class=\"breadcrumbs\">\n    <div class=\"crumb\">Lopende projecten</div>\n    <div class=\"crumb\">Dow</div>\n</div>\n<div class=\"content\">\n    <h3>Objectvelden</h3>\n    <div class=\"row\">\n        <div class=\"col col-xs-12 col-sm-6 col-md-4 col-lg-3\">\n            <label class=\"form-field\">\n                <div class=\"form-label\" title=\"Type\">\n                            Type\n                            <span class=\"required-indicator\" title=\"Verplicht veld\">*</span>\n                </div>\n                <div class=\"form-input\">\n                    ";
output += runtime.suppressValue((lineno = 57, colno = 44, runtime.callWrap(runtime.memberLookup((t_7),"createInputField"), "form[\"createInputField\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),{},"string","Netwerken",true,0])), env.opts.autoescape);
output += "\n                </div>\n            </label>\n        </div>\n        <div class=\"col col-xs-12 col-sm-6 col-md-3 col-lg-2\">\n            <label class=\"form-field\">\n                <div class=\"form-label\" title=\"Omschrijving\">\n                            Omschrijving\n                            <span class=\"required-indicator\" title=\"Verplicht veld\">*</span>\n                </div>\n                <div class=\"form-input\">\n                    ";
output += runtime.suppressValue((lineno = 68, colno = 44, runtime.callWrap(runtime.memberLookup((t_7),"createInputField"), "form[\"createInputField\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),{},"string","Project implementatie 2016",true,1])), env.opts.autoescape);
output += "\n                </div>\n            </label>\n        </div>\n        <div class=\"col col-xs-12 col-sm-6 col-md-3 col-lg-2\">\n            <label class=\"form-field\">\n                <div class=\"form-label\" title=\"Afdeling\">\n                            Afdeling\n                            <span class=\"required-indicator\" title=\"Verplicht veld\">*</span>\n                </div>\n                <div class=\"form-input\">\n                    ";
output += runtime.suppressValue((lineno = 79, colno = 44, runtime.callWrap(runtime.memberLookup((t_7),"createInputField"), "form[\"createInputField\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),{},"string","Software",true,2])), env.opts.autoescape);
output += "\n                </div>\n            </label>\n        </div>\n        <div class=\"col col-xs-12 col-sm-6 col-md-3 col-lg-2\">\n            <label class=\"form-field\">\n                <div class=\"form-label\" title=\"Status\">\n                            Status\n                            <span class=\"required-indicator\" title=\"Verplicht veld\">*</span>\n                </div>\n                <div class=\"form-input\">\n                    ";
output += runtime.suppressValue((lineno = 90, colno = 44, runtime.callWrap(runtime.memberLookup((t_7),"createInputField"), "form[\"createInputField\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),{},"string","Open",true,3])), env.opts.autoescape);
output += "\n                </div>\n            </label>\n        </div>\n    </div>\n\n</div>\n</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/dimensionobjects.html", ctx);
          }
        
          return nunjucks.render("views/content/dimensionobjects.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/dimensionobjects.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        