<template>
  <div>
    <form class="table-form sticky-div grid upload-submit">
      <div class="mb-5">
        <p>{{ translations.AdminDesktop_AlertNoFileSelected }}</p>

        <label
          v-if="window.output.Data.Subject == 'Rental.ItemImage'"
          class="button btn-secondary"
        >
          <i class="fas fa-upload"></i>
          <span v-if="file === null">{{
            translations.UploadFileSelectButtonText
          }}</span>
          <span v-else>{{ file.name }}</span>
          <input
            name="FileUpload"
            accept="image/*"
            type="file"
            class="ignore-change"
            @change="handleFileChange"
            hidden
          />
        </label>

        <label v-else class="button btn-secondary">
          <i class="fas fa-upload"></i>
          <span v-if="file === null">{{
            translations.UploadFileSelectButtonText
          }}</span>
          <span v-else>{{ file.name }}</span>
          <input
            @change="handleFileChange"
            name="FileUpload"
            type="file"
            class="ignore-change"
            hidden
          />
        </label>

        <button
          class="button button-default"
          @click="file = null"
          data-window-event="actionUploadFile"
        >
          {{ translations.UploadFile }}
        </button>
        <hr />
        <p>{{ translations.DropFile }}</p>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "Upload",

  data() {
    return {
      file: null,
    };
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
    window() {
      return this.$store.getters.selectedWindow;
    },
    files() {
      return this.window.output.Data.Rows;
    },
  },
  methods: {
    handleFileChange(e) {
      // Whenever the file changes, emit the 'input' event with the file data.
      this.file = e.target.files[0];
    },
  },
};
</script>

<style scoped></style>
