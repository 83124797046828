import $ from "jquery";
import entities from "entities";

/**
 * Serialize Form
 * @param {element} formElement - Form base element
 * @param {element} extraValues - Extra provided values
 * @returns {Object} Serialized form data, syntax {column: value}
 */
export default function serializeForm(formElement, extraValues) {
  let form = {};

  $(formElement)
    .find("[name]")
    .each(function forEachEl() {
      let name = entities.decodeHTML($(this).attr("name"));
      let type = $(this).attr("type");
      let val = type === "checkbox" ? $(this).prop("checked") : $(this).val();

      if ($(this).is("[contenteditable]")) {
        val = $(this).is("[data-html-editor]")
          ? $(this).html()
          : $(this).text();
      }

      form[name] = val;
    });

  // Set properties for extra values passed with the serializeForm function
  if (extraValues) {
    form["extraValues"] = extraValues;
  }

  return form;
}
