<template>
  <div
    :class="{
      'col-md-3': true,
      'col-lg-2': this.simpleInterface,
      'col-sm-12': true,
      'd-none': hideActionBar,
    }"
  >
    <div class="card mb-3 mr-md-3 card-actionbar">
      <div class="card-body">
        <div class="actionbar">
          <action-bar-actions
            :window="window"
            :subWindowid="subWindow.id || null"
            :buttons="buttons"
            :subWindow="subWindow"
          />

          <div class="window-description" v-if="windowDescription">
            <p class="title">
              <i class="fas fa-info-circle float-right"></i>
              {{ $store.state.translations.Information }}
            </p>
            <hr />
            <div class="info" v-html="windowDescription" />
          </div>
          <div class="actionbar-manual" v-if="manualURL">
            <hr />

            <manual :manualURL="manualURL" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionBarActionsVue from "./actionBar/actionBarActions.vue";
import Manual from "./manual.vue";

export default {
  name: "ActionBar",
  components: {
    "action-bar-actions": ActionBarActionsVue,
    Manual,
  },
  computed: {
    window: function () {
      return this.$store.getters.window;
    },
    subWindow() {
      return this.$store.getters.subWindow;
    },
    windowOutput() {
      if (!this.window.output) return {};
      return this.window.output;
    },
    subWindowOutput() {
      if (!this.subWindow.output) return {};
      return this.subWindow.output;
    },
    windowButtons() {
      if (!this.windowOutput) return [];
      if (!this.windowOutput.Buttons) return [];
      return this.windowOutput.Buttons;
    },
    subWindowButtons() {
      if (!this.subWindowOutput) return [];
      if (!this.subWindowOutput.Buttons) return [];
      return this.subWindowOutput.Buttons;
    },
    buttons() {
      if (this.subWindowButtons.length > 0) return this.subWindowButtons;
      if (this.windowButtons.length > 0) return this.windowButtons;
      return [];
    },
    windowDescription() {
      if (!this.windowOutput) return "";
      if (this.subWindowOutput.WindowDescription)
        return this.subWindowOutput.WindowDescription;
      return this.windowOutput.WindowDescription;
    },
    manualURL() {
      if (this.subWindow.output?.Options?.HelpUrl)
        return this.subWindow.output.Options.HelpUrl;
      if (this.window.output?.Options?.HelpUrl)
        return this.window.output.Options.HelpUrl;
      return "";
    },
    hideActionBar() {
      const hideActionBar =
        (this.buttons.length < 1 &&
          (this.windowDescription === "" || this.windowDescription === null)) ||
        this.windowDescription === undefined;
      this.$store.commit("setActionBarStatus", hideActionBar);

      return hideActionBar;
    },
    simpleInterface() {
      return this.$store.getters.simpleInterface;
    },
  },
  async created() {
    this.setCorrectActionBarClasses(this.buttons, this.windowDescription);
  },
  watch: {
    window() {
      if (global.session.activeWindow) {
        global.session.canvas.correctTableHeaders(
          global.session.activeWindow.element,
        );
      }
    },
    buttons(buttons) {
      this.setCorrectActionBarClasses(buttons);
      this.$store.commit("setActiveWindowButtons", buttons);
    },
  },
  methods: {
    setCorrectActionBarClasses(buttons, windowDescription) {
      if (buttons.length < 1 && typeof windowDescription !== undefined) {
        if (global.session.activeWindow) global.session.activeWindow.resize();
      } else {
        if (global.session.activeWindow) global.session.activeWindow.resize();
      }
      this.$store.commit("refreshTabs");
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: 600;
  font-size: 14px;
}
.window-description {
  font-size: 12px;
  margin-top: auto;
}
.fa-spin-hover:hover {
  svg {
    animation: fa-spin 2s linear;
  }
}
.fa-spin {
  -webkit-animation: fa-spin 2s linear;
  animation: fa-spin 2s linear;
}
.actionbar {
  display: flex;
  flex-flow: column nowrap;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
