
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        
        
        const __nunjucks_template_import__dep_0 = require("../elements/combobox.html");
        
        const __nunjucks_template_import__dep_1 = require("../elements/plusminusinput.html");
        
        const __nunjucks_template_import__dep_2 = require("./table.html");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.assets};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/return.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("views/elements/combobox.html", false, "views/content/return.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("combo", t_1);
output += "\n";
env.getTemplate("views/elements/plusminusinput.html", false, "views/content/return.html", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("plusminusinput", t_4);
output += "\n";
env.getTemplate("views/content/table.html", false, "views/content/return.html", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
context.setVariable("table", t_7);
output += "\n\n<div class=\"content view-return\">\n\t<form class=\"form-view grid row\">\n\t\t";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"OnlyShowScan")) {
output += "\n\t\t\t<div class=\"col col-xs-12 col-sm-6 col-md-3\">\n\t\t\t\t<label class=\"form-field\">\n\t\t\t\t\t<div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Type"), env.opts.autoescape);
output += ":</div>\n\t\t\t\t\t<div class=\"form-input required-field\">\n\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 11, colno = 32, runtime.callWrap(runtime.memberLookup((t_1),"constructCombobox"), "combo[\"constructCombobox\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"combobox")),"type")])), env.opts.autoescape);
output += "\n\t\t\t\t\t</div>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"col col-xs-12 col-sm-6 col-md-3\">\n\t\t\t\t<label class=\"form-field\">\n\t\t\t\t\t<div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Value"), env.opts.autoescape);
output += ":</div>\n\t\t\t\t\t<div class=\"form-input required-field\">\n\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 20, colno = 32, runtime.callWrap(runtime.memberLookup((t_1),"constructCombobox"), "combo[\"constructCombobox\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"combobox")),"value")])), env.opts.autoescape);
output += "\n\t\t\t\t\t</div>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t";
;
}
output += "\n\n\t\t<div class=\"col col-xs-12 col-sm-6 col-md-3\">\n\t\t\t<label class=\"form-field\">\n\t\t\t\t<div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Scan"), env.opts.autoescape);
output += ":</div>\n\t\t\t\t<div class=\"form-input required-field\">\n\t\t\t\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"isSearch")) {
output += "\n\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 31, colno = 32, runtime.callWrap(runtime.memberLookup((t_1),"constructCombobox"), "combo[\"constructCombobox\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"combobox")),"scan")])), env.opts.autoescape);
output += "\n\t\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t\t<input name=\"scan\" class=\"editable-text ignore-next\" type=\"text\">\n\t\t\t\t\t";
;
}
output += "\n\t\t\t\t</div>\n\t\t\t</label>\n\t\t</div>\n\n\t\t<div class=\"col col-xs-12 col-sm-6 col-md-3\">\n\t\t\t<label class=\"form-field\">\n\t\t\t\t<div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Notes"), env.opts.autoescape);
output += ":</div>\n\t\t\t\t<div class=\"form-input required-field\">\n\t\t\t\t\t<input name=\"notes\" class=\"editable-text ignore-next\" type=\"text\">\n\t\t\t\t</div>\n\t\t\t</label>\n\t\t</div>\n\t</form>\n\n\t";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"rows")),"length") > 0) {
output += "\n\t\t<div class=\"table-view unsortable\">\n\t\t\t<form class=\"table-container\">\n\t\t\t\t<div class=\"table-index sticky-div\">\n\t\t\t\t\t<div class=\"table-row\">\n\t\t\t\t\t\t<!-- <div class=\"field checkbox selection-box\"><div class=\"ellipsis\"><input type=\"checkbox\" title=\"Select all\"></div></div> -->\n\t\t\t\t\t\t<label class=\"table-cell checkbox selection-box\">\n\t\t\t\t\t\t\t<div class=\"ellipsis\">\n\t\t\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"EnableSelectAll")) {
output += "\n\t\t\t\t\t\t\t\t\t<input type=\"checkbox\" title=\"Select all\">\n\t\t\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t\t";
frame = frame.push();
var t_12 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"columns");
if(t_12) {t_12 = runtime.fromIterator(t_12);
var t_11 = t_12.length;
for(var t_10=0; t_10 < t_12.length; t_10++) {
var t_13 = t_12[t_10];
frame.set("col", t_13);
frame.set("loop.index", t_10 + 1);
frame.set("loop.index0", t_10);
frame.set("loop.revindex", t_11 - t_10);
frame.set("loop.revindex0", t_11 - t_10 - 1);
frame.set("loop.first", t_10 === 0);
frame.set("loop.last", t_10 === t_11 - 1);
frame.set("loop.length", t_11);
output += "\n\t\t\t\t\t\t\t<div class=\"table-cell ";
output += runtime.suppressValue((runtime.memberLookup((t_13),"Type") == "Money" || runtime.memberLookup((t_13),"Type") == "Decimal" || runtime.memberLookup((t_13),"Type") == "Int"?"number " + runtime.memberLookup((t_13),"Type"):""), env.opts.autoescape);
output += " ";
output += runtime.suppressValue((runtime.memberLookup((t_13),"CustomClass") && (lineno = 63, colno = 184, runtime.callWrap(runtime.memberLookup((runtime.memberLookup((t_13),"CustomClass")),"includes"), "col[\"CustomClass\"][\"includes\"]", context, ["d-none"]))?"d-none":""), env.opts.autoescape);
output += "\"\n\t\t\t\t\t\t\t\t data-column=\"";
output += runtime.suppressValue(runtime.memberLookup((t_13),"Name"), env.opts.autoescape);
output += "\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((t_13),"Title"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t<div class=\"ellipsis\">\n\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((t_13),"Title"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<!-- <div class=\"field\" title=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "i"), env.opts.autoescape);
output += "\"><div class=\"ellipsis\"><input type=\"checkbox\"></div></div> -->";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"table-body bulkedit\">\n\t\t\t\t\t<div class=\"table-rows\">\n\t\t\t\t\t\t";
frame = frame.push();
var t_16 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"rows");
if(t_16) {t_16 = runtime.fromIterator(t_16);
var t_15 = t_16.length;
for(var t_14=0; t_14 < t_16.length; t_14++) {
var t_17 = t_16[t_14];
frame.set("row", t_17);
frame.set("loop.index", t_14 + 1);
frame.set("loop.index0", t_14);
frame.set("loop.revindex", t_15 - t_14);
frame.set("loop.revindex0", t_15 - t_14 - 1);
frame.set("loop.first", t_14 === 0);
frame.set("loop.last", t_14 === t_15 - 1);
frame.set("loop.length", t_15);
var t_18;
t_18 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0");
frame.set("index", t_18, true);
if(frame.topLevel) {
context.setVariable("index", t_18);
}
if(frame.topLevel) {
context.addExport("index", t_18);
}
output += "\n\t\t\t\t\t\t\t<div class=\"table-row\" data-id=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_17),"used")),"ItemID"), env.opts.autoescape);
output += "\" data-rowid=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "index"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t<label class=\"table-cell checkbox selection-box\"><input type=\"checkbox\" data-commit-checkbox=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
output += "\" title=\"Select\" ";
output += runtime.suppressValue((runtime.memberLookup((t_17),"selected")?"checked":""), env.opts.autoescape);
output += "></label>\n\t\t\t\t\t\t\t\t<!--<div class=\"table-cell delete-row material-icons\" title=\"Verwijderen\"><div class=\"ellipsis\">&#xe5cd;</div></div>-->\n\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_21 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"columns");
if(t_21) {t_21 = runtime.fromIterator(t_21);
var t_20 = t_21.length;
for(var t_19=0; t_19 < t_21.length; t_19++) {
var t_22 = t_21[t_19];
frame.set("col", t_22);
frame.set("loop.index", t_19 + 1);
frame.set("loop.index0", t_19);
frame.set("loop.revindex", t_20 - t_19);
frame.set("loop.revindex0", t_20 - t_19 - 1);
frame.set("loop.first", t_19 === 0);
frame.set("loop.last", t_19 === t_20 - 1);
frame.set("loop.length", t_20);
if(runtime.memberLookup((t_22),"Name") == "Amount" && !runtime.memberLookup((runtime.memberLookup((t_17),"old")),"IsSerial") && (runtime.memberLookup((runtime.memberLookup((t_17),"used")),"AmountToReturn") > runtime.memberLookup((runtime.memberLookup((t_17),"old")),"Amount") || runtime.memberLookup((runtime.memberLookup((t_17),"used")),"AmountToReturn") > 1)) {
output += "\n\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 83, colno = 51, runtime.callWrap(runtime.memberLookup((t_4),"constructPlusMinusInput"), "plusminusinput[\"constructPlusMinusInput\"]", context, [t_22,"",runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"),runtime.memberLookup((runtime.memberLookup((t_17),"used")),runtime.memberLookup((t_22),"Name")),0,runtime.memberLookup((runtime.memberLookup((t_17),"used")),"AmountToReturn"),"table-cell field"])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 85, colno = 32, runtime.callWrap(runtime.memberLookup((t_7),"setColumnCell"), "table[\"setColumnCell\"]", context, [t_22,"",runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"),runtime.memberLookup((runtime.memberLookup((t_17),"used")),runtime.memberLookup((t_22),"Name")),runtime.contextOrFrameLookup(context, frame, "window"),runtime.memberLookup((runtime.memberLookup((t_17),"cells")),runtime.memberLookup((t_22),"Name")),runtime.memberLookup((runtime.memberLookup((t_17),"editable")),runtime.memberLookup((t_22),"Name"))])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</form>\n\t\t</div>\n\t";
;
}
output += "\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/return.html", ctx);
          }
        
          return nunjucks.render("views/content/return.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/return.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        