<template>
  <div class="interface" :style="styleOverrides">
    <content>
      <side-menu v-if="session.moduleMenu" :menuItems="session.moduleMenu" />

      <div :class="{canvas: true}" id="scrollContainer" v-show="token !== null">
        <div class="container-fluid">
          <div class="row tab-wrapper">
            <div class="col-xs-12 col-md-9">
              <tabs :tabs="session.tabs" />
            </div>
            <div class="col-xs-12 col-md-3">
              <div class="row">
                <div class="col-xs-12 col-md-6">
                  <div class="form-group">
                    <warehouse-selector class="mt-3" />
                  </div>
                </div>
                <div class="col-xs-12 col-md-6">
                  <div class="form-group">
                    <quick-action-search class="mt-3" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row no-gutters">
            <!-- Actionbar -->
            <action-bar v-if="simpleInterface === true" />
            <!-- End Actionbar -->

            <!-- START Canvas -->
            <div v-show="window.id" id="window" :class="windowClasses">
              <div id="cardCanvas" class="card h-100 mb-3 card-canvas">
                <div class="card-body">
                  <canvas-template :window="window" />
                </div>
              </div>
            </div>
            <!-- END Canvas -->
          </div>
          <back-to-top />
        </div>
      </div>
    </content>
    <webinar-hint v-if="webinar !== false" />
    <interface-environment-title />
    <Dialog />
  </div>
</template>

<script>
import BackToTop from "./interface/canvas/BackToTop.vue";
import combobox from "./elements/comboboxView.html";
import WebinarHint from "./interface/canvas/WebinarHint.vue";
import Dialog from "./interface/dialog/Dialog.vue";

import Menu from "./interface/menu.vue";
import Tabs from "./interface/tabs.vue";
import ActionBar from "./interface/actionBar.vue";
import Canvas from "./interface/canvas.vue";
import QuickActionSearch from "./interface/QuickActionSearch.vue";
import WarehouseSelector from "./interface/warehouse/WarehouseSelector.vue";

import InterfaceEnvironmentTitle from "./interface/InterfaceEnvironmentTitle.vue";

export default {
  components: {
    "side-menu": Menu,
    tabs: Tabs,
    "action-bar": ActionBar,
    "canvas-template": Canvas,
    BackToTop,
    WarehouseSelector,
    WebinarHint,
    "quick-action-search": QuickActionSearch,
    InterfaceEnvironmentTitle,
    Dialog,
  },
  data: () => {
    return {
      session: [],
      scanboxRendered: false,
      warehouseSelectorHTML: "",
      scanboxHTML: "",
    };
  },
  computed: {
    token: function () {
      return this.$store.state.accessToken;
    },
    styleOverrides() {
      if (this.$store.state.loading) {
        return "pointer-events none !important;";
      }
      return null;
    },
    selectedWindow() {
      return this.$store.getters.selectedWindow;
    },
    webinarURL() {
      return this.selectedWindow.output?.Options?.WebinarURL ?? null;
    },
    window() {
      return this.$store.state.window;
    },
    webinar() {
      return this.selectedWindow.output?.Options?.Webinar ?? false;
    },
    activeWindowButtons() {
      return this.$store.state.activeWindowButtons;
    },
    headerComboboxes() {
      return this.$store.state.headerComboboxes;
    },
    scanbox() {
      return this.$store.state.scanbox;
    },
    hideActionBar() {
      if (this.simpleInterface === false) return true;
      return this.$store.getters.hideSidebar;
    },
    simpleInterface() {
      return this.$store.getters.simpleInterface;
    },
    windowClasses() {
      let classes = {"mb-4": true};
      if (!this.hideActionBar) {
        classes = Object.assign(
          {
            "col-md-9": true,
            "col-lg-10": true,
            "col-sm-12": true,
            "active-sidebar": true,
          },
          classes,
        );
      } else {
        classes = Object.assign(
          {
            "col-12": true,
          },
          classes,
        );
      }

      return classes;
    },
  },
  watch: {
    activeWindowButtons: function () {
      if (this.scanbox && this.scanbox.id) {
        if (this.scanboxRendered === false) {
          this.scanboxHTML = combobox({
            session: this.session,
            combo: this.scanbox,
            width: null,
            placeholder: null,
          });
        } else {
          this.scanboxRendered = true;
        }
      }
    },
  },
  created: async function () {
    this.session = global.session;
  },
};
</script>

<style lang="scss">
.avoid-clicks {
  pointer-events: none !important;
}
content {
  display: flex;

  height: 100%;
}
.active-sidebar {
  .card {
    border-top-left-radius: 0px !important;
    margin-left: -14px;
  }
}

.card-canvas {
  z-index: 0;
}

/* CSS Simple Pre Code */
pre {
  background: #333;
  white-space: pre;
  word-wrap: break-word;
  overflow: auto;
}

pre.code {
  margin: 20px 25px;
  border-radius: 4px;
  border: 1px solid #292929;
  position: relative;
}

pre.code label {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 13px;
  color: #ddd;
  position: absolute;
  left: 1px;
  top: 15px;
  text-align: center;
  width: 60px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
}

pre.code code {
  font-family: "Inconsolata", "Monaco", "Consolas", "Andale Mono",
    "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
  display: block;
  margin: 0 0 0 60px;
  padding: 15px 16px 14px;
  border-left: 1px solid #555;
  overflow-x: auto;
  font-size: 13px;
  line-height: 19px;
  color: #ddd;
}

pre::after {
  content: "double click to selection";
  padding: 0;
  width: auto;
  height: auto;
  position: absolute;
  right: 18px;
  top: 14px;
  font-size: 12px;
  color: #ddd;
  line-height: 20px;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  transition: all 0.3s ease;
}

pre:hover::after {
  opacity: 0;
  visibility: visible;
}

pre.code-css code {
  color: #91a7ff;
}

pre.code-html code {
  color: #aed581;
}

pre.code-javascript code {
  color: #ffa726;
}

pre.code-jquery code {
  color: #4dd0e1;
}
</style>
