<template>
  <div class="w-100">
    <b-button
      variant="primary"
      size="md"
      class="float-right"
      @click="hideDialog"
    >
      Close
    </b-button>
  </div>
</template>

<script>
export default {
  name: "DialogCloseButton",
  methods: {
    hideDialog() {
      this.$store.commit("dialog/hideDialog");
    },
  },
};
</script>

<style scoped></style>
