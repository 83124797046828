import {Timeline} from "vis-timeline";
import {DataSet} from "vis-data";
import $ from "jquery";
import availabilityList from "../../views/elements/availabilityList.html";
import Formatter from "../model/formatter";
import Hook from "../hook";
import customParseFormat from "dayjs/plugin/customParseFormat";

import dayjs from "dayjs";

dayjs.extend(customParseFormat);

/**
 * Parses a date to date or datetime format.
 * @param {String} date The date to parse.
 * @returns {String} The parsed date.
 */
function parseDate(date) {
  let parsedDate = null;
  let useDateTime = window.session.settings.UseDateTime ? true : false;

  if (useDateTime) {
    parsedDate = Formatter.parsers.DateTime(date);
  } else {
    parsedDate = Formatter.parsers.Date(date);
  }
  return parsedDate;
}

/**
 * Construct the title for the given segment.
 * @param {object} segment The segment object to get data from.
 * @returns {string} The title for the given segment as s String. Contains HTML generated from the availabilityList.html (Nunjucks)
 */
function constructTitle(segment) {
  if (segment.type != null && segment.type != "box") return "";

  let itemID = segment.criteria.itemID;
  let startDate = parseDate(segment.start);
  let endDate = parseDate(segment.end);
  let useDateTime = window.session.settings.UseDateTime ? true : false;

  if (!useDateTime) {
    endDate = dayjs
      .unix(Formatter.unixMilisecondsToUnix(segment.end))
      .subtract(1, "days")
      .format("DD-MM-YYYY");
  }

  let html = availabilityList({
    availabilityList: segment.details,
    itemID,
    startDate,
    endDate,
    translations: window.session.translations,
  });
  return html;
}

/**
 * Serializes a date to date or datetime format.
 * @param {String} date The date to serialize.
 * @returns {String} The serialized date.
 */
function serializeDate(date) {
  let serializedDate = null;
  let useDateTime = window.session.settings.UseDateTime ? true : false;

  if (useDateTime) {
    serializedDate = Formatter.serializers.DateTime(date);
  } else {
    serializedDate = Formatter.serializers.Date(date);
  }
  return serializedDate;
}

/**
 * Get the ID of a group.
 * @param {Array} filteredGroups The array to search in.
 * @param {String} category The category to get the ID from.
 */
function getGroupID(filteredGroups, category) {
  let groupRow = filteredGroups.find(
    (filteredElement) => filteredElement.content === category,
  );
  return groupRow.id;
}

/**
 * If the amount is smaller than 1, set className to red so a red background color is applied. Else, a green class is returned.
 * @param {int} amount The amount to base the className on.
 * @returns {string} The classname to apply.
 */
function setClassName(segment) {
  let className = segment.id + "id";

  if (Number(segment.amount) < 1) {
    className = className + " red";
  } else {
    className = className + " green";
  }

  if (segment.fixedLength) {
    className = className + " fixedLength";
  }

  return className;
}

/**
 * Format the string amount (which is a decimal) to an int when type is a box, so we get no decimals added.
 * Then, return the int casted to a string because this is what vis.js expects as content.
 * If type is not a box, return empty string
 * @param {string} type The type to check.
 * @param {int} amount The amount to parse.
 * @return {string} The amount formatted as a String.
 */
function setContent(type, amount) {
  if (type != null && type != "box") return "";

  let intAmount = Formatter.parsers.Int(amount);
  return Formatter.parsers.String(intAmount);
}

/**
 * Async method to get timeline data for 1 year from the server.
 * @param {object} window The current window.
 * @returns {object} An object with the timeline data.
 */
async function getTimelineData(window) {
  // setup the criteria so we can request data for 1 year

  window.input.Criteria[0].startDate = serializeDate(dayjs());
  window.input.Criteria[0].endDate = serializeDate(dayjs().add(1, "year"));

  let timelineData = await window.session.request(
    "/Admin/WebServices/CoreWebServices.asmx/OpenWindow",
    {request: window.input},
  );
  delete window.input.Criteria[0].startDate; // delete the criteria, so when the user clicks reload we don't fetch data for 1 year anymore
  delete window.input.Criteria[0].endDate;

  return timelineData;
}

function isOverlap(foregroundItem, backgroundItem) {
  if (
    foregroundItem.group == backgroundItem.group &&
    ((foregroundItem.start < backgroundItem.end &&
      foregroundItem.end > backgroundItem.start) ||
      (foregroundItem.end > backgroundItem.start &&
        foregroundItem.start < backgroundItem.end))
  ) {
    return true;
  }
  return false;
}

/** Timeline hook */
class TimelineHook extends Hook {
  /**
   * Setup variables
   * @param {Window} window - Window
   * @returns {Promise} Promise
   */
  async afterProcess(window) {
    if (window.output.Data == null || window.output.Data.type != "timeline") {
      return;
    }

    let todayDate = dayjs();
    let startDate = parseDate(window.output.Data.startDate);
    let endDate = parseDate(window.output.Data.endDate);
    if (dayjs(window.output.Data.startDate).isBefore(todayDate)) {
      startDate = parseDate(dayjs());
    }

    if (dayjs(window.output.Data.endDate).isBefore(todayDate)) {
      endDate = parseDate(dayjs().add(3, "days"));
    }

    window.customData = window.output.Data;
    window.customData.startDate = startDate; // parseDate(window.output.Data.StartDate)
    window.customData.endDate = endDate; // parseDate(window.output.Data.EndDate)

    window.options.autoFocus = false;
    if (window.output.Data.searchAvailable) {
      window.customData.searchTerm = window.output.Data.searchTerm;
      window.searchItem = searchItem;
      window.output.Buttons.push({
        Event: "search-item",
        Title: window.session.translations.ModalDayPlannerSearchTitle,
      });
    }
  }

  /**
   *
   * @param {Window} window - Window
   * @returns {Promise} Promise
   */
  async afterRender(window) {
    if (
      window.output.Data == null ||
      window.output.Data.type != "timeline" ||
      window.customInit
    ) {
      return;
    }

    let givenData = new DataSet(window.output.Data.segments);

    // Distinct the Segments from the received data, so we can create rows for this.
    // Construct a DataSet with an id and the value of the group
    let filteredGroups = [];
    givenData
      .distinct("segmentGroup")
      .forEach(function fillGroupsArray(element, index) {
        filteredGroups.push({id: index, content: element});
      });

    let timelineData = await getTimelineData(window);

    // for each element in the Segments, add this to the items DataSet.
    let filteredItems = new DataSet();
    timelineData.Data.segments.forEach(function addItems(segment, index) {
      segment.id = index;
      filteredItems.add({
        id: segment.id,
        type: segment.type,
        title: constructTitle(segment),
        className: setClassName(segment),
        group: getGroupID(filteredGroups, segment.segmentGroup),
        criteria: segment.criteria,
        content: setContent(segment.type, segment.label),
        initialStart: dayjs
          .unix(Formatter.unixMilisecondsToUnix(segment.start))
          .toDate(),
        start: dayjs
          .unix(Formatter.unixMilisecondsToUnix(segment.start))
          .toDate(),
        initialEnd: dayjs
          .unix(Formatter.unixMilisecondsToUnix(segment.end))
          .toDate(),
        end: dayjs.unix(Formatter.unixMilisecondsToUnix(segment.end)).toDate(),
        editable: segment.editable,
      });
    });

    let timeline = new Timeline($(window.element).find(".chart").get(0));

    // Set timeline options
    let options = {
      zoomMin: window.session.settings.UseDateTime ? 86400000 : 604800000, // minimum zoom level is 1 day if useDateTime is true, 7 day's if useDateTime is false
      min: dayjs().toDate(), // The minimum visible date
      start: dayjs(window.customData.startDate, "DD-MM-YYYY").toDate(), // The initial visible startdate of the timeline
      end: dayjs(window.customData.startDate, "DD-MM-YYYY")
        .add(1, "month")
        .toDate(), // The initial visible enddate of the timeline
      max: dayjs().add(1, "year").toDate(), // The maximum visible date
      locale: window.session.language,
      stack: false, // do not stack the items, group them together on 1 row
      selectable: window.customData.selectable,
      multiselect: window.customData.multiselect,
      itemsAlwaysDraggable: window.customData.itemsAlwaysDraggable,
      editable: window.customData.editable || false,
      minHeight: "400px",
      tooltip: {
        overflowMethod: "cap",
      },
      onMoving: function (item, callback) {
        filteredItems.forEach(function (relatedItem) {
          if (!relatedItem.className.includes("green")) {
            relatedItem.className = relatedItem.className + " green";
          }

          if (relatedItem.className.includes("red")) {
            relatedItem.className = relatedItem.className
              .replace(" red", "")
              .replace("red", "");
          }

          filteredItems.forEach(function (backgroundItem) {
            if (
              relatedItem.id != backgroundItem.id &&
              (relatedItem.type == null || relatedItem.type == "box") &&
              backgroundItem.type == "background" &&
              isOverlap(relatedItem, backgroundItem)
            ) {
              relatedItem.className = relatedItem.className + " red";
            }
          });

          if (relatedItem.id == item.id) {
            // Update classname and dates in list of filteredItems
            item.className = relatedItem.className;
            relatedItem.start = item.start;
            relatedItem.end = item.end;
            relatedItem.title = constructTitle(relatedItem);
            timeline.itemsData.update(relatedItem);
          }

          if (
            (relatedItem.type == null || relatedItem.type == "box") &&
            relatedItem.id != item.id &&
            timeline.getSelection().indexOf(relatedItem.id) != -1
          ) {
            // Update related items with the same startdate
            if (
              relatedItem.initialStart.toString() ==
              item.initialStart.toString()
            ) {
              relatedItem.start = item.start;
              relatedItem.title = constructTitle(relatedItem);
              timeline.itemsData.update(relatedItem);
            }

            if (
              relatedItem.initialEnd.toString() == item.initialEnd.toString()
            ) {
              relatedItem.end = item.end;
              relatedItem.title = constructTitle(relatedItem);
              timeline.itemsData.update(relatedItem);
            }
          }
        });

        item.moving = true;
        item.title = constructTitle(item);
        callback(item); // send back the (possibly) changed item and redraw the item

        window.selection = filteredItems.get({
          filter: function (segment) {
            return segment.type == null || segment.type == "box";
          },
        });
      },
    };

    timeline.setData({groups: filteredGroups, items: filteredItems});
    timeline.setOptions(options);

    if (window.customData.forceSelectAll) {
      timeline.on("select", function () {
        // create empty array to hold ids of all box-items
        let allBoxIds = [];

        filteredItems.forEach(function (item) {
          allBoxIds.push(item.id);
        });

        timeline.setSelection(allBoxIds);

        $(".vis-drag-left", $(".vis-item.fixedLength")).hide();
        $(".vis-drag-right", $(".vis-item.fixedLength")).hide();
      });
    }

    timeline.on("doubleClick", async function (properties) {
      if (properties.what != "item") {
        return;
      }

      let clickedItem = filteredItems.get(properties.item);

      let criteria = [];
      criteria.push({
        SearchDate: serializeDate(properties.snappedTime),
        WarehouseID: clickedItem.criteria.warehouseID,
        ItemID: clickedItem.criteria.itemID,
      });

      let requestInput = {
        Criteria: criteria,
        Prefix: "Multi",
        Subject: "Rental.vw_AvailabilityDetail",
      };

      let data = await window.session.request(
        "/Admin/WebServices/CoreWebServices.asmx/OpenWindow",
        {request: requestInput},
      );

      await window.insertWindow(data);
    });

    // On change of the date input fields, change the window of the timeline accordingly.
    $(window.element).on(
      "value-change",
      "[name=EndDate], [name=StartDate], [name=SearchTerm]",
      function () {
        let startDate = $(window.element).find("[name=StartDate]").val();

        timeline.setWindow(serializeDate(startDate), null);

        let endDate = $(window.element).find("[name=EndDate]").val();

        let searchTerm = $(window.element).find("[name=SearchTerm]").val();

        if (dayjs(serializeDate(endDate)).isBefore(serializeDate(startDate))) {
          window.message(
            "error",
            window.session.translations.ErrorEndDateBeforeStart,
          );
          $(window.element).find("[name=EndDate]").val(startDate);
        } else if (endDate != window.customData.endDate) {
          timeline.setWindow(null, serializeDate(endDate));
        }
      },
    );
  }
}

async function searchItem() {
  let searchTerm = $(this.element).find("[name=SearchTerm]").val();
  let startDate = $(this.element).find("[name=StartDate]").val();
  startDate = dayjs(startDate, "DD-MM-YYYY").format("YYYY-MM-DD");
  let endDate = $(this.element).find("[name=EndDate]").val();
  endDate = dayjs(endDate, "DD-MM-YYYY").format("YYYY-MM-DD");

  let request = {
    Subject: "Rental.virtual_ItemAvailability",
    Prefix: "Multi",
    Criteria: [
      {
        startDate: startDate,
        endDate: endDate,
        searchCriteria: searchTerm,
      },
    ],
    Data: {
      PageNumber: 1,
      PageSize: 15,
    },
  };

  await this.session.openWindow(request);
}

/** @ignore */
export default new TimelineHook();
