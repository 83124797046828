<template>
  <b-button :block="block" variant="primary" class="mb-2" v-b-modal.modal-1>
    <b-modal id="modal-1" :title="title" size="md" @hide="clearMessage()">
      <b-alert :variant="messageType" :show="message.length > 0">{{
        message
      }}</b-alert>
      <div>
        <p>{{ translations.ExcelFormDescription }}</p>
        <a
          :href="templateDownloadLink"
          target="_BLANK"
          class="btn btn-primary"
          variant="primary"
          ><i class="fas fa-file-excel"></i>
          {{ translations.ExcelFormTemplateDownload }}</a
        >
        <hr />
        <b-form @submit="uploadExcel">
          <b-form-group
            id="options"
            :label="translations.ImportFromExcel_ImportOptions"
          >
            <b-form v-model="options" id="options-checkbox-group">
              <b-form-checkbox
                id="newRecords"
                name="newRecords"
                v-model="insert"
                :value="true"
                :unchecked-value="false"
              >
                {{ translations.ExcelFormOptionNewLines }}
              </b-form-checkbox>

              <b-form-checkbox
                name="updateRecords"
                :value="true"
                v-model="update"
                :unchecked-value="false"
              >
                {{ translations.ExcelFormOptionUpdatedLines }}
              </b-form-checkbox>
            </b-form>
          </b-form-group>

          <b-form-group id="options" :label="translations.ExcelFormFileLabel">
            <label></label>
            <b-form-file v-model="file"></b-form-file>
          </b-form-group>
        </b-form>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="secondary" @click="$bvModal.hide('modal-1')">
            {{ translations.Close }}
          </b-button>
          <b-button
            type="submit"
            variant="primary"
            @click="uploadExcel()"
            class="float-right"
            >{{ translations.ButtonExcelImport }}</b-button
          >
        </div>
      </template>
    </b-modal>
    <div v-if="icon" class="float-left" v-html="icon" />
    {{ title }}</b-button
  >
</template>

<script>
import {importExcelTemplate} from "@/services/excel.js";
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    fullTableName: {
      type: String,
      required: true,
    },
    block: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      options: {},
      file: null,
      insert: false,
      update: false,
      message: "",
      messageType: "",
    };
  },
  methods: {
    clearMessage() {
      this.message = "";
      this.messageType = "";
    },
    uploadExcel: async function () {
      this.message = "";
      this.messageType = "info";
      let formData = new FormData();

      formData.append(`file`, this.file);
      try {
        const result = await importExcelTemplate({
          formData,
          insert: this.insert,
          update: this.update,
          fullTableName: this.fullTableName,
        });

        if (result && result.Error) {
          this.message = result.Error.Message;
          this.messageType = "danger";
        } else {
          this.message = result.Succes?.Message ?? this.translations.Updated;
        }
      } catch (error) {
        console.log({error});
      }
    },
  },
  computed: {
    translations: function () {
      return this.$store.getters.translations;
    },
    templateDownloadLink: function () {
      return `/api/v2/excel/template?fullTableName=${this.fullTableName}`;
    },
  },
};
</script>

<style></style>
