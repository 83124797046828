import { render, staticRenderFns } from "./FormSidebar.vue?vue&type=template&id=08e35768&scoped=true&"
import script from "./FormSidebar.vue?vue&type=script&lang=js&"
export * from "./FormSidebar.vue?vue&type=script&lang=js&"
import style0 from "./FormSidebar.vue?vue&type=style&index=0&id=08e35768&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08e35768",
  null
  
)


    import installComponents from "!/Users/dhulsman/Projects/rentmagic.frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BRow} from 'bootstrap-vue'
    installComponents(component, {BCol, BRow})
    

export default component.exports