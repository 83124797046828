<template>
  <div>
    <ckeditor
      v-model="editorData"
      :readonly="readonly"
      @input="processValueChange()"
    ></ckeditor>
    <input
      v-model="editorData"
      :data-field-index="index"
      :name="name"
      data-html-editor
      type="hidden"
      vue-input
      @change="valueChange(event)"
    />
  </div>
</template>

<script>
import Ckeditor from "@/components/Ckeditor.vue";

export default {
  name: "RichTextEditor",
  components: {
    Ckeditor,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    index: {
      type: String,
      required: true,
    },
    readonly: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
  },
  data: () => {
    return {
      editorData: "",
    };
  },
  created: function () {
    this.editorData = this.value;
  },
  methods: {
    processValueChange() {
      if (this.editorData != this.value) {
        global.session.activeWindow.sub.window.dirty = true;
      }

      // eslint-disable-next-line no-undef
      $(document).find(`[data-html-editor]`).trigger("change");
    },
  },
};
</script>
<style lang="css"></style>
