import { render, staticRenderFns } from "./tabs.vue?vue&type=template&id=76ef9455&scoped=true&"
import script from "./tabs.vue?vue&type=script&lang=js&"
export * from "./tabs.vue?vue&type=script&lang=js&"
import style0 from "./tabs.vue?vue&type=style&index=0&id=76ef9455&scoped=true&style=scss&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76ef9455",
  null
  
)


    import installComponents from "!/Users/dhulsman/Projects/rentmagic.frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton} from 'bootstrap-vue'
    installComponents(component, {BButton})
    

export default component.exports