<template>
  <div>
    <timeline
      ref="timeline"
      :items="items"
      :groups="groups"
      :options="options"
      style="min-height: 600px; position: relative"
    />
  </div>
</template>
<script>
import {Timeline} from "vue2vis";
export default {
  name: "item-availability-planner",
  components: {
    Timeline,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
    },
    groups: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
    },
    options: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
  },
};
</script>
