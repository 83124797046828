<template>
  <v-select
    :options="warehouses"
    :append-to-body="true"
    v-model="value"
    :clearable="false"
    id="warehouseSelector"
    :closeOnSelect="true"
    @option:selected="selectOption"
  >
    <template v-slot:option="option">
      <span>{{ option.label }}</span>
    </template>
    <div slot="no-options">{{ $store.state.translations.NoRowsFound }}</div>
  </v-select>
</template>

<script>
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
  },
  data: function () {
    return {
      value: null,
    };
  },

  computed: {
    warehouses: function () {
      return this.$store.state.warehouses ?? [];
    },
    options: function () {
      return this.$store.state.warehouses;
    },
    activeWarehouse: function () {
      if (
        this.$store.state.activeWarehouse !== null &&
        this.$store.state.activeWarehouse !== ""
      ) {
        document.title = `${this.$store.state.activeWarehouse} | RentMagic`;
      }
      return this.$store.state.activeWarehouse;
    },
  },

  watch: {
    activeWarehouse: function () {
      this.value = this.activeWarehouse;
    },
  },
  methods: {
    selectOption: async function (value) {
      await this.$store.commit("setActiveWarehouse", {warehouse: value});
      await this.$store.dispatch("closeAllTabs");
      document.title = `${value} | RentMagic`;
    },
  },
};
</script>

<style></style>
