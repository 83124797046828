import Vue from "../../src/interface/vue";
import vueSlashSeperatedArray from "../../views/elements/slashSeperatedArrayField.vue";

const editors = {vueSlashSeperatedArray};

const initializeTableElements = function (window) {
  const $vueElements = $(window.element).find("div [vue-editor]");

  for (const vueElement of $vueElements) {
    const editor = editors[$(vueElement).attr("data-editor-type")];
    new Vue({
      el: `#${vueElement.id}`,
      render: (h) =>
        h(editor, {
          props: {
            name: $(vueElement).attr("name"),
            index: $(vueElement).attr("index"),
            value: $(vueElement).attr("value"),
            metadata: $(vueElement).attr("metadata"),
          },
        }),
    });
  }
};

export default initializeTableElements;
