var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.accessToken)?_c('nav',{on:{"mouseleave":function($event){return _vm.$store.commit('hideSubMenu')}}},[_c('div',{staticClass:"menu-main"},[_c('div',{staticClass:"menu-logo"}),_c('div',{staticClass:"menu-main-middle"},_vm._l((_vm.menuItems),function(menuItem,index){return _c('div',{key:menuItem.ID,class:{
          'menu-item-icon-wrapper': true,
          'w-100': true,
          active: _vm.selectedMenuItemId === index && _vm.subMenuOpen,
        },on:{"mouseover":function($event){return _vm.openSubMenu(index)}}},[(menuItem.Position === 'Top')?_c('div',{staticClass:"menu-item-icon"},[_c('i',{class:menuItem.Icon})]):_vm._e(),(menuItem.Position === 'Top' && _vm.showMenuTitles)?_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(menuItem.Text)+" ")]):_vm._e()])}),0),_c('div',{staticClass:"menu-main-bottom"},[_vm._m(0),_vm._l((_vm.menuItems),function(menuItem,index){return _c('div',{key:menuItem.ID,class:{
          'menu-item-icon-wrapper': true,
          'w-100': true,
          active: _vm.selectedMenuItemId === index && _vm.subMenuOpen,
        },on:{"mouseover":function($event){return _vm.openSubMenu(index)}}},[(menuItem.Position === 'Bottom')?_c('div',{staticClass:"menu-item-icon"},[_c('i',{class:menuItem.Icon})]):_vm._e()])}),_vm._m(1)],2)]),_c('transition',{attrs:{"name":"component-fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.subMenuOpen),expression:"subMenuOpen"}],staticClass:"menu-submenu"},[_c('div',{staticClass:"menu-submenu-header"},[_c('span',{staticClass:"float-right close-button",on:{"click":function($event){return _vm.$store.commit('toggleSubMenu')}}},[_c('i',{staticClass:"fas fa-times",on:{"click":function($event){return _vm.$store.commit('toggleSubMenu')}}})]),_c('div',{staticClass:"sub-menu-title w-100"},[_vm._v(" "+_vm._s(_vm.selectedMenuItem.Text)+" ")])]),_c('div',{staticClass:"sub-menu-items"},_vm._l((_vm.subMenuItems),function(menuItem){return _c('menu-sub-item',{key:menuItem.ID,attrs:{"menu-item":menuItem,"selected-menu-item":_vm.selectedMenuItem}})}),1)])])],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-item-icon-wrapper"},[_c('div',{staticClass:"menu-item-icon toggle-menu",attrs:{"data-canvas-event":"usersettings"}},[_c('i',{staticClass:"fas fa-user"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-item-icon-wrapper"},[_c('div',{staticClass:"menu-item-icon toggle-menu",attrs:{"data-canvas-event":"logoff"}},[_c('i',{staticClass:"fas fa-sign-out-alt text-danger"})])])}]

export { render, staticRenderFns }