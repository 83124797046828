import $ from "jquery";
// import formatter from "../model/formatter"
// import Window from "../model/window"

/**
 * Behaviour and positioning of elements inside the scroll container (window body)
 * @property {string} updateMatch - Update on ".scroll-container"
 * @property {Session} session - Session reference
 */
export default class scrollContainer {
  static updateMatch = "#scrollContainer";
  static session = null;

  /**
   * Position menu to fit in window
   * @param {element} el - Element
   * @returns {void}
   */
  static positionMenu(el) {
    if (!el) {
      return;
    }
    let pos = $(el).offset();
    let isCombo = $(el).is(".combobox");
    let heightEl = isCombo ? $(el).find("input.combobox-input") : $(el);

    if (heightEl.length !== 1) {
      return;
    }
    let height = heightEl.outerHeight();
    // TODO: combobox select rows upwards instead of downwards if overflowing viewport

    let $menu = $(el).find(".menu");
    if ($menu.is(".hide")) {
      return;
    }

    let csswidth = $menu.outerWidth();
    let cssheight = $menu.outerHeight();

    let css = {
      top: pos.top + (height - 2),
      left: pos.left,
    };

    if (isCombo) {
      css.width = $(el).outerWidth();
      csswidth = css.width;
      css.top += 2;
    }

    if (css.top + cssheight > window.innerHeight) {
      css.top = pos.top - cssheight;
    }

    if (css.left < 0) {
      css.left = 0;
    }

    if (css.left + csswidth > window.innerWidth) {
      css.left = window.innerWidth - csswidth;
    }

    if (css.right < 0) {
      css.right = 0;
    }

    $menu.css(css);
  }

  /**
   * A element was found that matches our selector
   * @param {element} el - Element
   * @returns {void}
   */
  static attach(el) {
    // TODO: this is a temp fix for mobile devices flickering
    if ($(window).width() < 1000) {
      return;
    }

    // let $windowScroll = $(window.element).find(".window-body .scroll-container")
    $(el).on("scroll scrollstart touchstart touchmove touchend", function (e) {
      let parentHeight = $(this).outerHeight(true);
      let overflow = 0;
      let childoverflow = 0;
      let childtop = 0;
      let hasChild = false;

      if ($(this).find(".window.child").length) {
        hasChild = true;
        childtop = +$(this).find(".window.child").position().top;
      }

      let viewportHeight = window.innerHeight;

      $(this)
        .find(".combobox, .date-picker.active")
        .each(function () {
          scrollContainer.positionMenu(this);
        });

      $(this)
        .find(".sticky-div")
        .each(function () {
          let itemPos = $(this).position();
          let itemTop = $(this).css("top");
          let itemHeight = $(this).outerHeight();
          let $window = $(this).closest(".window");
          let isInChild = $window.is(".child");

          itemTop = itemTop && itemTop !== "auto" ? parseInt(itemTop) : 0;

          if (isInChild) {
            itemPos.top += $window.position().top;
            if (!$(this).is(".tabs")) {
              itemPos.top += $window.find(".tabs").outerHeight(true);
            }
          } else if (hasChild && overflow + itemHeight > childtop) {
            itemTop -= overflow + itemHeight - childtop;
          }

          let newPos = itemTop - itemPos.top;

          if ($(this).hasClass("stick-to-bottom")) {
            newPos += parentHeight - itemHeight;
            $(this).css({top: newPos < 0 ? newPos : 0});
          } else {
            newPos += isInChild ? childoverflow : overflow;
            $(this).css({top: newPos > 0 ? newPos : 0});
            let d_overflow = newPos > 0 ? itemHeight : 0;
            isInChild
              ? (childoverflow += d_overflow)
              : (overflow += d_overflow);
          }
        });
    });
  }
}
