var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[_c('div',{staticClass:"select-scan-label"},[(_vm.option.type)?_c('i',{class:{
        'fas fa-tag pl-1': _vm.option.type === 'ItemSerial',
        'fas fa-cube': _vm.option.type === 'Item',
        'fas fa-cubes': _vm.option.type === 'Composition',
      }}):_vm._e(),_c('span',{class:{
        italic: _vm.option.type == 'Composition',
        'pl-1 serial': _vm.option.type == 'ItemSerial',
      },domProps:{"innerHTML":_vm._s(_vm.highlightSearchCharacters(_vm.option.label))}})])])}
var staticRenderFns = []

export { render, staticRenderFns }