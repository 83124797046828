import $ from "jquery";
import clone from "clone";
import Combobox from "../interface/combobox.class";
// import scroll from "./scrollContainer"
import serializeForm from "form-serialize";
import setupTemplate from "../../views/setup.html";
//import licenseTemplate from "../../views/license.html"
// import Window from "../model/window"

/**
 * Initial product setup logic
 * @property {Session} session - session reference
 * @property {Object} step - Current step
 * @property {Object} stepHistory - Step history
 * @property {number} currentStep - Current step index
 * @property {boolean} loading - If loading
 * @property {Object} translations - Translations reference
 * @property {string} subdomain - Current subdomain
 */
export default class Setup {
  session = null;
  step = {};
  stepHistory = {};
  currentStep = -1;
  loading = false;
  translations = null;
  showExpiredLicenseScreen = false;
  installKey = null;

  /**
   * @param {Session} session - Current session
   */
  constructor(session) {
    this.session = session;
  }

  /**
   * Initialise component
   * @param {document} doc - page document
   * @returns {void}
   */
  init(doc) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let _this = this;
    // Set event listener
    $(doc).on("click", "[data-select-language]", function selectLanguage() {
      let lang = $(this).attr("data-select-language");
      _this.session.switchLanguage(lang);
      $(this).closest("form").find("input").val(lang);
      _this.gotoStep(1);
    });

    $(doc).on(
      "keyup",
      "[name=PasswordConfirm], [name=Password]",
      function check() {
        let $form = $(this).closest("form");
        let $pw = $form.find("[name=Password]");
        let $pwc = $form.find("[name=PasswordConfirm]");
        let pw = $pw.val();
        let pwc = $pwc.val();
        let hasTyped = pw !== "";
        let hasValue = pw !== "" || pwc !== "";
        let isPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}/g.test(
          pw,
        );
        let isValid = pw === pwc;
        $pwc.toggleClass("correct", hasValue && isValid);
        $pwc.toggleClass("incorrect", hasValue && !isValid);

        $pw.toggleClass("correct", hasTyped && isPassword);
        $pw.toggleClass("incorrect", hasTyped && !isPassword);

        $form.find(".password-requires li").each(function setStripes() {
          let regex = new RegExp($(this).attr("data-regex"));
          $(this).toggleClass("done", regex.test(pw));
        });

        // Only enable Next button if password and password confirmation are valid and the same
        if (isPassword && isValid) {
          $("button[data-setup-step='rel:1']")
            .addClass("clickable")
            .attr("disabled", false);
        } else {
          $("button[data-setup-step='rel:1']")
            .removeClass("clickable")
            .attr("disabled", true);
        }
      },
    );

    $(doc).on("click", "[data-setup-step]", function stepHandler() {
      if (!$(this).hasClass("clickable")) {
        return;
      }

      // get index number
      let [type, v] = $(this).attr("data-setup-step").split(":");
      let value = Number(v) || 0;
      let newstep = Number(_this.currentStep);

      // perform corresponding action
      if (type === "set") {
        newstep = value;
      } else if (type === "rel") {
        // relative step
        newstep = _this.currentStep + value;
      } else {
        return;
      }

      // we ended back to were we were
      if (newstep === _this.currentStep) {
        return;
      }

      // go to step
      _this.gotoStep(newstep);
    });
  }

  /**
   * Show error
   * @param {string} text - Error text
   * @returns {void}
   */
  error(text) {
    let $err = $(".steps .error-box");
    $err.text(text);
    $err.stop().fadeIn(10).delay(4000).fadeOut(1000);
  }

  /**
   * Start setup
   * @returns {Promise} Promise
   */
  async start() {
    if (global.location.hash.slice(0, "#install=".length)) {
      this.installKey = global.location.hash.slice("#install=".length);
    }
    // request to InstallDB is obsolete, we now restore a backed up database, so BorrowMe / RentMagic does not need to install itself.
    // await Setup.requestMethod(this, "InstallDB")
    let isInstalled = await Setup.requestMethod(this, "IsInstalled");
    if (
      isInstalled.IsInstalled &&
      global.location.hash.slice(0, "#install".length) == "#install"
    ) {
      global.location.href = "/";
    }

    // Check can be removed in future because on startup of installer we already have an installed database.
    if (!window.translations) {
      window.location.reload();
    }
    this.translations = window.translations;

    this.gotoStep(0);
    this.init(document);
  }

  /**
   * Create combobox for step
   * @returns {void}
   */
  formatStep() {
    for (let columnname in this.step.Columns) {
      let c = this.step.Columns[columnname];
      // find where combobox is needed
      if (c.DropdownItems || c.Dropdown) {
        c.Combobox = Combobox.new({Column: c}, null);
      }
    }
  }

  /**
   * Go to step
   * @param {number} newstep - New step index
   * @returns {Promise} Promise
   */
  async gotoStep(newstep) {
    let data = null;
    let previousStep = this.currentStep;

    // If valid step
    if (this.currentStep > -1) {
      // Save step data
      let $form = $(document).find(`form[data-step=${this.currentStep}]`);
      data =
        serializeForm($form.get(0), {
          hash: true,
          empty: true,
        }) || {};

      if (data.LangID != null) {
        this.session.language = data.LangID;
      }

      // save state
      this.stepHistory[this.currentStep] = clone(data);
    }

    let allData = {};
    let error = null;

    // Get data from all steps
    for (let stepNo in this.stepHistory) {
      let stepData = this.stepHistory[stepNo];

      for (let key in stepData) {
        allData[key] = stepData[key];
      }
    }

    // render loading screen
    this.loading = true;
    $(".setup").html(setupTemplate(this));

    let nextstep = newstep;
    try {
      // Get new step data with or without installKey (used for live deployment, not for on premise deploy)
      let newstepdata = null;
      if (this.installKey != null) {
        newstepdata = await Setup.requestStep(
          this,
          this.installKey,
          newstep,
          allData,
        );
      } else {
        newstepdata = await Setup.requestStep(this, 123, newstep, allData);
      }

      this.step = newstepdata;
    } catch (_error) {
      error = _error;
      nextstep = previousStep;
    }

    if (this.step.Language) {
      this.session.switchLanguage(this.step.Language);
    }

    if (this.step.IsDone) {
      // Reload page if we are done
      global.location.href = "/";
    }

    if (this.step.IsLast) {
      let domainName = global.location - global.location.hash;
      this.step.Text = this.step.Text.replace(
        /\[domain\]/g,
        global.location.host,
      );
    }

    // go to step
    this.currentStep = Number(nextstep);
    this.formatStep();

    // Obsolete, use <url>/license.html to show the current InstanceKey.
    // let setupscreen
    // if(this.step.ShowExpiredLicenseScreen) {

    // 	this.showExpiredLicenseScreen = true
    // 	this.instanceKey = await this.session.request("/Public/WebServices/AppWebServices.asmx/InstanceKey")
    // 	this.loading = false
    // 	setupscreen = setupTemplate.render(this)
    // 	//setupscreen = licenseTemplate.render(instanceKey)

    // 	this.showExpiredLicenseScreen = false
    // 	this.instanceKey = null
    // } else {
    // 	this.loading = false
    // 	setupscreen = setupTemplate.render(this)
    // }

    //$(".setup").html(setupscreen)
    // render self
    this.loading = false;
    $(".setup").html(setupTemplate(this));
    $(".setup").removeClass("hide");

    // get data from history
    if (this.stepHistory[this.currentStep]) {
      let $form = $(document).find(`form[data-step=${this.currentStep}]`);
      let oldData = this.stepHistory[this.currentStep];

      for (let name in oldData) {
        let value = oldData[name];
        // set value in input
        let $field = $form.find(`[name=${name}]`);
        $field.val(value);

        // make sure value gets displayed in comboboxes
        if ($field.parent().is(".combobox")) {
          $field.trigger("change");
        }
      }
    }

    if (error) {
      this.error(error);
    }

    if (this.step.IsLoading) {
      setTimeout(() => this.gotoStep(nextstep), 1500);
    }
  }

  /**
   * Request step
   * @todo document
   * @param {Setup} setup - Setup object
   * @param {*} installKey - Install key
   * @param {number} step - Step index
   * @param {Object} data - Request data
   * @returns {Promise} Object with request
   */

  static async requestMethod(setup, method) {
    try {
      const SERVICEURL =
        "/Public/WebServices/InstallWebServices.asmx/" + method;
      return await setup.session.request(SERVICEURL);
    } catch (error) {
      console.log({error});
    }
  }

  static async requestStep(setup, installKey, step, data = {}) {
    try {
      const SERVICEURL =
        "/Public/WebServices/InstallWebServices.asmx/GetInstallSteps";
      return await setup.session.request(SERVICEURL, {
        installKey,
        step,
        data,
      });
    } catch (error) {
      console.log({error});
    }
  }
}
