import $ from "jquery";
import "bootstrap4-notify";
import Combobox from "../../interface/combobox.class";
import ComboboxUI from "../../interface/combobox";
import modalDimensionObjectSelectorHtml from "../../../views/content/modals/modalDimensionObjectSelector.html";
import modalRelatedDimensionObjectsContainerHtml from "../../../views/content/modals/modalRelatedDimensionObjectsContainer.html";
import customParseFormat from "dayjs/plugin/customParseFormat";

import dayjs from "dayjs";

dayjs.extend(customParseFormat);

/**
 * Create Click event to open this modal
 */
$(document).on("click", "#openDimensionObjectModal", async function (event) {
  let combobox = $(event.target.parentNode);
  let $window = combobox.closest(".window");

  let window = global.session.getClosestWindow($window);
  let rowData = {};

  if (window.customData.CustomerID) {
    rowData.CustomerID = window.customData.CustomerID;
  } else if ($(combobox).parents(".table-row").length > 0) {
    let rowindex = $(combobox).parents(".table-row").data("row-index");

    if (
      window.customData.rows &&
      window.customData.rows.length > rowindex &&
      window.customData.rows[rowindex].old.CustomerID
    ) {
      rowData.CustomerID = window.customData.rows[rowindex].old.CustomerID;
    }
  }

  rowData.DimensionID = 3;
  rowData.DimensionObjectID = $.merge(
    combobox.find("input[name=DimensionID-3]"),
    combobox.find("input[name=DimensionObjectID]"),
  ).get(0).value;
  let modalDimensionObjectSelectorDialogInstance = new ModalDimensionObjectSelectorDialog(
    window,
    rowData,
    combobox,
  );
  modalDimensionObjectSelectorDialogInstance.render();
});

/**
 * @property {Window} window
 * @property {object} model
 * @property {object} combobox
 */
export default class ModalDimensionObjectSelectorDialog {
  window = null;
  model = null;
  combobox = null;
  /**
   *
   * @param {Window} window the current window
   * @param {object} rowData Data from combobox in quickscan form before opening modal (We can relate from this data)
   * @param {object} combobox the combobox of the DimensionObjects
   */
  constructor(window, rowData, combobox) {
    this.window = window;
    this.model = {
      window: window,
      companyName: "",
      fields: [],
      rowData: rowData,
      relevantDimensionObjects: [],
      otherRelatedDimensionObjects: [],
      comboboxData: {
        OtherRelatedCustomerID: Combobox.new(null, {
          name: "OtherRelatedCustomerID",
          nullable: true,
          tableName: "Customer.Customer",
          columnName: "CustomerID",
          fetch: true,
          lazyLoading: true,
        }),
        ParentDimensionObjectID: Combobox.new(null, {
          name: "ParentDimensionObjectID",
          nullable: true,
          tableName: "Customer.DimensionObject",
          columnName: "DimensionObjectID",
          subject: "Sales.virtual_QuickScan",
          filter: {
            CustomerID: rowData.CustomerID,
            IsObjectNode: false,
            DimensionID: rowData.DimensionID,
          },
        }),
      },
    };
    this.combobox = combobox;
  }

  /**
   * Caches the dom.
   */
  cacheDom() {
    this.$modalBody = $("#modalSelectDimensionObject");
    this.$modalForm = this.$modalBody.find("form");
  }

  /**
   * Activates all related listeners
   */
  bindEvents() {
    // this.$modalBody.off()
    this.$modalBody.on("click", "#findRelevantDimensionObjects", async () => {
      await this.findRelevantDimensionObjects();

      // Re cache so our other listeners still know what's where
      this.cacheDom();
    });

    this.$modalBody.on("click", "#addDimensionObject", async () => {
      await this.addDimensionObject();
    });

    this.$modalBody.on("click", "#clearDimensionObjectForm", async () => {
      this.$modalForm[0].reset();
      this.$modalForm
        .find("input[type=text], textarea, input[type=checkbox]")
        .val("");
      this.$modalForm.find("input[type=checkbox]").prop("checked", false);
      this.$modalBody.find("h4")[0].innerText =
        window.session.translations.ModalDimensionObjectSelectorNewPerson;
      // this.model.comboboxData.OtherRelatedCustomerID.setInitialValues("", "")
      // this.model.comboboxData.ParentDimensionObjectID.setInitialValues("", "")
      // this.$modalBody.find("[name='OtherRelatedCustomerID']").val("")
      // this.$modalBody.find("[name='ParentDimensionObjectID']").val("")
      // for(let column in this.model.columns){
      // 	if(column.combobox){
      // 		column.combobox.setInitialValues("", "")
      // 	}
      // }
      this.model.columns.forEach((column) => {
        if (column.combobox) {
          column.combobox.setInitialValues("", "");
        }
      });
      this.model.dimensionObject = {};
      this.renderView();
    });

    this.$modalBody.on("click", "p[dimensionObjectID]", async (event) => {
      let dimensionObjectID = event.target.attributes.dimensionobjectid.value;
      await this.fetchDimensionObject(dimensionObjectID);
    });

    this.$modalBody.on(
      "value-accept",
      "[name='ParentDimensionObjectID'], [name='OtherRelatedCustomerID']",
      async (e) => {
        let value = e.currentTarget.value;
        if (value) {
          let description = $(e.target)
            .closest(".combobox")
            .find("input.combobox-input")[0].value;
          this.model.comboboxData[e.target.name].setInitialValues(
            description,
            value,
          );
        } else {
          // this.model.comboboxData[e.target.name].setInitialValues('', '')
        }
      },
    );
  }

  /**
   * Renders the modal on startup.
   */
  async render() {
    let selectionInfo = {
      Request: {
        Criteria: [
          {
            CustomerID: this.model.rowData.CustomerID,
            DimensionID: this.model.rowData.DimensionID,
            DimensionObjectID: this.model.rowData.DimensionObjectID,
            IsObjectNode: true,
          },
        ],
      },
    };
    let requestURL =
      "/Admin/WebServices/DimensionObjectWebServices.asmx/GetDimensionObjectContent";

    try {
      let result = await this.window.session.request(requestURL, {
        selectionInfo: selectionInfo,
      });
      this.addSceneDataToModel(result.SceneData);
      this.model.description = result.Description;
      this.model.companyName = result.CompanyName;
      this.model.sceneData = result.SceneData;
      this.renderView();
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Get the dimension object from the form.
   */
  getDimensionObjectFromForm(includeEmptyFields = true) {
    let dimensionObject = includeEmptyFields ? this.model.dimensionObject : {};
    let columns = this.model.columns;

    $.each(
      $("form[name='modalDimensionObjectSelectorDialog']").serializeArray(),
      (i, field) => {
        let column = columns.find((c) => c.Name == [field.name]);
        if (column) {
          //&& field.value.length > 0
          if (column.Type === "Date" && field.value) {
            dimensionObject[field.name] = dayjs(
              field.value,
              "DD-MM-YYYY",
            ).format("YYYY-MM-DD");
          } else if (column.Type === "Int" && field.value) {
            dimensionObject[field.name] = parseInt(field.value);
          } else if (column.Type === "Boolean" && field.value === "on") {
            // check if the this.model.fields[field.name].Type is an bool
            dimensionObject[field.name] = true;
          } else if (field.value) {
            dimensionObject[field.name] = field.value;
          }
        }
      },
    );

    // Checking all checkbox inputs again that are NOT checked and hardcoded forcing them to be false and part of the dimensionObject {object}
    $('form[name="modalDimensionObjectSelectorDialog"]')
      .find('input[type="checkbox"]:not(:checked)')
      .each(function () {
        if (includeEmptyFields) {
          dimensionObject[this.name] = false;
        }
      });

    dimensionObject.CustomerID = this.model.rowData.CustomerID;
    dimensionObject.DimensionID = this.model.rowData.DimensionID;

    return dimensionObject;
  }

  /**
   * Finds for relevant dimensionObjects
   */
  async findRelevantDimensionObjects() {
    this.model.dimensionObject = this.getDimensionObjectFromForm(false);
    let requestURL =
      "/Admin/WebServices/DimensionObjectWebServices.asmx/SelectRelatedDimensionObjects";

    try {
      let result = await window.session.request(requestURL, {
        dimensionObject: this.model.dimensionObject,
      });
      this.model.relevantDimensionObjects = result.RelatedDimensionObjects;
      for (let index in this.model.relevantDimensionObjects) {
        let relevantDimensionObject = this.model.relevantDimensionObjects[
          index
        ];
        if (relevantDimensionObject.Birthdate) {
          relevantDimensionObject.Birthdate = dayjs(
            relevantDimensionObject.Birthdate,
            "YYYY-MM-DD",
          ).format("DD-MM-YYYY");
        }
      }
      this.model.otherRelatedDimensionObjects =
        result.OtherRelateddimensionObjects;
      for (let index in this.model.otherRelatedDimensionObjects) {
        let relevantDimensionObject = this.model.otherRelatedDimensionObjects[
          index
        ];
        if (relevantDimensionObject.Birthdate) {
          relevantDimensionObject.Birthdate = dayjs(
            relevantDimensionObject.Birthdate,
            "YYYY-MM-DD",
          ).format("DD-MM-YYYY");
        }
      }
      this.renderView(false);
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Adds the sceneData to the model.
   * Sets the correct datetime format and converts the columns object to an list or columns sorted on ranking.
   * @param {*} sceneData the sceneData from the server
   */
  addSceneDataToModel(sceneData) {
    this.model.dimensionObject = sceneData.Rows[0];
    this.model.metaData = sceneData.Metadata[0];
    // Set model titles.
    this.model.description = this.model.dimensionObject.Description;
    this.model.customer = this.model.metaData.CustomerID
      ? this.model.metaData.CustomerID.Description
      : null;

    // Convert columns object to an list of columns.
    let columns = [];
    for (let propertyName in sceneData.Columns) {
      let column = sceneData.Columns[propertyName];
      let value = this.model.dimensionObject[column.Name];

      // Reformat date.
      if (column.Type === "Date" && value && value != "") {
        if (value.startsWith("/Date(")) {
          this.model.dimensionObject[column.Name] = dayjs(value).format(
            "DD-MM-YYYY",
          );
        } else {
          this.model.dimensionObject[column.Name] = dayjs(
            value,
            "YYYY-MM-DD",
          ).format("DD-MM-YYYY");
        }
      }

      // Add combobox to a column with a dropdown.
      if (column.Dropdown) {
        let combobox;
        if (this.model.comboboxData[column.Name]) {
          combobox = this.model.comboboxData[column.Name];
          if (value && value != "") {
            let text = this.model.metaData[propertyName]
              ? this.model.metaData[propertyName].Description
              : value;
            combobox.setInitialValues(text, value);
          }
        } else {
          combobox = Combobox.new(null, {
            name: propertyName,
            tableName: column.Dropdown.TableName,
            columnName: column.Dropdown.ColumnName,
          });
          if (value && value != "") {
            let text = this.model.metaData[propertyName]
              ? this.model.metaData[propertyName].Description
              : value;
            combobox.setInitialValues(text, value);
          }
        }
        if (column.Dropdown.Items) {
          combobox.populate(column.Dropdown.Items);
          if (value && value != "") {
            let text = column.Dropdown.Items.find((i) => i.Value == value).Text;
            combobox.setInitialValues(text, value);
          }
        }

        column.combobox = combobox;
      }
      columns.push(column);
    }

    this.model.columns = columns.sort(
      (a, b) => parseFloat(a.Ranking) - parseFloat(b.Ranking),
    );
  }

  /**
   * Fetch the dimensionObject from the server.
   * This will rerender the modal view.
   * @param {*} dimensionObjectID The dimensionObjectID of the dimensionObject.
   */
  async fetchDimensionObject(dimensionObjectID) {
    let requestURL =
      "/Admin/WebServices/DimensionObjectWebServices.asmx/GetDimensionObjectSceneData";
    try {
      let result = await window.session.request(requestURL, {
        dimensionObjectID: dimensionObjectID,
      });
      this.addSceneDataToModel(result);
      this.renderView();
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Add the dimensionObject to the order item.
   * This will create a new dimensionObject or updates the existing dimensionObject.
   */
  async addDimensionObject() {
    try {
      const comboboxObject = ComboboxUI.getClass($(this.combobox.get(0)));
      let requestURL =
        "/Admin/WebServices/DimensionObjectWebServices.asmx/SaveDimensionObject";
      let dimensionObject = this.getDimensionObjectFromForm();
      // let $hiddenComboboxInput = this.combobox.find("input[name=DimensionID-3]")
      let body = {dimensionObject: dimensionObject};
      let result;

      dimensionObject.CustomerID = this.model.rowData.CustomerID;
      dimensionObject.DimensionID = this.model.rowData.DimensionID;

      if (this.model.rowData.DimensionObjectID) {
        let id = parseInt(this.model.rowData.DimensionObjectID);
        body.dimensionObjectID = id;
        body.dimensionObject.DimensionObjectID = id;
      }

      result = await window.session.request(requestURL, body);

      this.model.rowData.DimensionObjectID = String(result.DimensionObjectID);
      this.model.rowData.DimensionObjectDescription = result.Description;
      // $($hiddenComboboxInput.get(0)).val(this.model.rowData.DimensionObjectID).change()

      this.combobox
        .find("input[data-field-index]")
        .get(0).value = this.model.rowData.DimensionObjectDescription;
      $.merge(
        this.combobox.find("input[name=DimensionID-3]"),
        this.combobox.find("input[name=DimensionObjectID]"),
      ).get(0).value = this.model.rowData.DimensionObjectID;

      comboboxObject.setInitialValues(
        this.model.rowData.DimensionObjectDescription,
        this.model.rowData.DimensionObjectID,
      );

      await ComboboxUI.fetch(comboboxObject);

      await ComboboxUI.change(comboboxObject);

      await ComboboxUI.select(
        comboboxObject,
        this.model.rowData.DimensionObjectDescription,
        this.model.rowData.DimensionObjectID,
      );

      $(this.combobox)
        .find(`input[value='${this.model.rowData.DimensionObjectID}']`)
        .trigger("change");

      this.window.extraLargeModalInstance.hide();
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Renders the modal view based on the model.
   */
  async renderView(fullRewrite = true) {
    let view = modalDimensionObjectSelectorHtml(this.model);
    let subView = modalRelatedDimensionObjectsContainerHtml(this.model);

    // Set Modal content
    if (fullRewrite) {
      this.window.extraLargeModalInstance.setContent(view);
      this.window.extraLargeModalInstance.backdrop = "static";
      $("#containerHolder").html(subView);

      this.form = await new Promise(() =>
        this.window.extraLargeModalInstance.show(),
      )
        .then(this.cacheDom())
        .then(this.bindEvents());
    } else {
      $("#containerHolder").html(subView);
    }
    // Register the listeners required within the modal for certain actions
  }
}
