// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////
function getKeyFromLocalStorage(key) {
  const value = localStorage.getItem(key);

  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
}

const state = {
  window: {},
  subWindow: {},
  activeWindowID: null,
  activeWindowHTML: "",
  activeWindowButtons: "",
  activeWarehouse: null,
  warehouses: null,
  translations: [],
  accessToken: null,
  subMenuOpen: false,
  subMenuItems: [],
  tabs: [],
  scrollPosition: 0,
  shortcuts: [],
  settings: {},
  headerComboboxes: {},
  scanbox: {},
  hideActionBar: false,
  closeActiveModal: false,
  loading: false,
  language: "nl",
  oldWindows: getKeyFromLocalStorage("oldWindows") ?? [],
};

export default state;
