
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        
        
        
        const __nunjucks_template_import__dep_0 = require("../elements/combobox.html");
        
        const __nunjucks_template_import__dep_1 = require("./table.html");
        
        const __nunjucks_template_import__dep_2 = require("./form.html");
        
        const __nunjucks_template_import__dep_3 = require("./form/inputField.html");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.assets};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/quickrent.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("views/elements/combobox.html", false, "views/content/quickrent.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("combo", t_1);
output += "\n";
env.getTemplate("views/content/table.html", false, "views/content/quickrent.html", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("table", t_4);
output += "\n";
env.getTemplate("views/content/form.html", false, "views/content/quickrent.html", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
context.setVariable("form", t_7);
output += "\n";
env.getTemplate("views/content/form/inputField.html", false, "views/content/quickrent.html", false, function(t_11,t_10) {
if(t_11) { cb(t_11); return; }
t_10.getExported(function(t_12,t_10) {
if(t_12) { cb(t_12); return; }
context.setVariable("inputField", t_10);
output += "\n\n";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"settings")),"AlwaysShowPrimarySideBarItems") == true) {
output += "\n\t<div class=\"content row\" style=\"\n\t\t\tmargin-left: 0px;\n\t\t\talign-items: baseline;\n\t\">\n\t<form class=\"form-view grid row col-12 quickrent\">\n";
;
}
else {
output += "\n\t<div class=\"content\">\n\t\t<form class=\"form-view grid row\">\n";
;
}
output += "\n\t\n\t\t<input type=\"hidden\" name=\"RentalType\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"formType"), env.opts.autoescape);
output += "\">\n\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"isNewObject")) {
output += "\n\n\t\t\t<div class=\"col col-xs-12 col-sm-6 col-md-3\">\n\t\t\t\t<label class=\"form-field\">\n\t\t\t\t\t<div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Customer"), env.opts.autoescape);
output += ": <span class=\"required-indicator\" title=\"\">*</span></div>\n\t\t\t\t\t<div class=\"form-input required-field\">\n\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 23, colno = 32, runtime.callWrap(runtime.memberLookup((t_1),"constructCombobox"), "combo[\"constructCombobox\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"Comboboxes")),"CustomerID")])), env.opts.autoescape);
output += "\n\t\t\t\t\t</div>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t";
frame = frame.push();
var t_15 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"QuickRentColumns");
if(t_15) {t_15 = runtime.fromIterator(t_15);
var t_14 = t_15.length;
for(var t_13=0; t_13 < t_15.length; t_13++) {
var t_16 = t_15[t_13];
frame.set("col", t_16);
frame.set("loop.index", t_13 + 1);
frame.set("loop.index0", t_13);
frame.set("loop.revindex", t_14 - t_13);
frame.set("loop.revindex0", t_14 - t_13 - 1);
frame.set("loop.first", t_13 === 0);
frame.set("loop.last", t_13 === t_14 - 1);
frame.set("loop.length", t_14);
output += "\n\t\t\t\t";
var t_17;
t_17 = runtime.memberLookup((t_16),"Value");
frame.set("value", t_17, true);
if(frame.topLevel) {
context.setVariable("value", t_17);
}
if(frame.topLevel) {
context.addExport("value", t_17);
}
output += "\n\n\t\t\t\t";
if(runtime.memberLookup((t_16),"Name") == "DateTimeBusinessStart" || runtime.memberLookup((t_16),"Name") == "DateTimeExpectedStart") {
output += "\n\t\t\t\t\t";
var t_18;
t_18 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"initialDate");
frame.set("value", t_18, true);
if(frame.topLevel) {
context.setVariable("value", t_18);
}
if(frame.topLevel) {
context.addExport("value", t_18);
}
output += "\n\t\t\t\t";
;
}
output += "\n\t\t\t\t<div class=\"col col-xs-12 col-sm-6 col-md-3\">\n\t\t\t\t\t<label class=\"form-field\">\n\t\t\t\t\t\t<div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((t_16),"Title"), env.opts.autoescape);
output += ":\n\t\t\t\t\t\t\t";
if(runtime.memberLookup((t_16),"IsRequired")) {
output += "\n\t\t\t\t\t\t\t\t<span class=\"required-indicator\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"RequiredField"), env.opts.autoescape);
output += "\">*</span>\n\t\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t<div class=\"form-input\">\n\t\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 43, colno = 37, runtime.callWrap(runtime.memberLookup((t_10),"createInputField"), "inputField[\"createInputField\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),t_16,runtime.memberLookup((t_16),"Type"),runtime.contextOrFrameLookup(context, frame, "value"),null,null,null,runtime.contextOrFrameLookup(context, frame, "window")])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t";
;
}
output += "\n\n\t\t<!-- Category Box-->\n\n\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"DisplayCategoryDropdown")) {
output += "\n\t\t\t<div class=\"col col-xs-12 col-sm-6 col-md-3\">\n\t\t\t\t<label class=\"form-field\">\n\t\t\t\t\t<div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Category"), env.opts.autoescape);
output += ":</div>\n\t\t\t\t\t<div class=\"form-input required-field\">\n\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 57, colno = 32, runtime.callWrap(runtime.memberLookup((t_1),"constructCombobox"), "combo[\"constructCombobox\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"Comboboxes")),"CategoryID")])), env.opts.autoescape);
output += "\n\t\t\t\t\t</div>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t";
;
}
output += "\n\n\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"CanAddNewItems") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"isNewObject")) {
output += "\n\t\t\t<!-- Scan box -->\n\t\t\t<div class=\"col col-xs-12 col-sm-6 col-md-3\">\n\t\t\t\t\t<div class=\"form-label form-label-select\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Scan"), env.opts.autoescape);
output += ":</div>\n\t\t\t\t\t<div id=\"selectScanOrderItem\"></div>\n\t\t\t</div>\n\n\t\t\t<!-- availability Button -->\n\t\t\t<div class=\"col col-xs-12 col-sm-6 col-md-3\" id=\"availabilityModalColumn\">\n\t\t\t\t<div id=\"availabilityModal\"></div>\n\t\t\t</div>\n\n\t\t";
;
}
output += "\n\t</form>\n\n\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"bulkedit")) {
output += "\n\t\t";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"settings")),"AlwaysShowPrimarySideBarItems") == true) {
output += "\n\t\t\t<div class=\"table-view pl-0 col-10 unsortable\">\n\t\t";
;
}
else {
output += "\n\t\t\t<div class=\"table-view unsortable \">\n\t\t";
;
}
output += "\n\t\t\t<form class=\"table-container quickrent-table\">\n\t\t\t\t<div class=\"table-index sticky-div\">\n\t\t\t\t\t<div class=\"table-row\">\n\t\t\t\t\t\t<!-- <div class=\"field checkbox selection-box\"><div class=\"ellipsis\"><input type=\"checkbox\" title=\"Select all\"></div></div> -->\n\t\t\t\t\t\t<div class=\"table-cell delete-row material-icons\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Delete"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t<div class=\"ellipsis\">&#xe5cd;</div>\n\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t";
frame = frame.push();
var t_21 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"Columns");
if(t_21) {t_21 = runtime.fromIterator(t_21);
var t_20 = t_21.length;
for(var t_19=0; t_19 < t_21.length; t_19++) {
var t_22 = t_21[t_19];
frame.set("col", t_22);
frame.set("loop.index", t_19 + 1);
frame.set("loop.index0", t_19);
frame.set("loop.revindex", t_20 - t_19);
frame.set("loop.revindex0", t_20 - t_19 - 1);
frame.set("loop.first", t_19 === 0);
frame.set("loop.last", t_19 === t_20 - 1);
frame.set("loop.length", t_20);
output += "\n\t\t\t\t\t\t\t";
var t_23;
t_23 = "table-cell";
frame.set("classes", t_23, true);
if(frame.topLevel) {
context.setVariable("classes", t_23);
}
if(frame.topLevel) {
context.addExport("classes", t_23);
}
output += "\n\n\t\t\t\t\t\t\t";
if(runtime.memberLookup((t_22),"Type") == "Money") {
output += "\n\t\t\t\t\t\t\t\t";
var t_24;
t_24 = runtime.contextOrFrameLookup(context, frame, "classes") + " number";
frame.set("classes", t_24, true);
if(frame.topLevel) {
context.setVariable("classes", t_24);
}
if(frame.topLevel) {
context.addExport("classes", t_24);
}
output += "\n\t\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t\t\t";
if(runtime.memberLookup((t_22),"Type") == "Decimal") {
output += "\n\t\t\t\t\t\t\t\t";
var t_25;
t_25 = runtime.contextOrFrameLookup(context, frame, "classes") + " number";
frame.set("classes", t_25, true);
if(frame.topLevel) {
context.setVariable("classes", t_25);
}
if(frame.topLevel) {
context.addExport("classes", t_25);
}
output += "\n\t\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t\t\t";
if(runtime.memberLookup((t_22),"Type") == "Int") {
output += "\n\t\t\t\t\t\t\t\t";
var t_26;
t_26 = runtime.contextOrFrameLookup(context, frame, "classes") + " number";
frame.set("classes", t_26, true);
if(frame.topLevel) {
context.setVariable("classes", t_26);
}
if(frame.topLevel) {
context.addExport("classes", t_26);
}
output += "\n\t\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t\t\t<div class=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "classes"), env.opts.autoescape);
output += "\" data-column=\"";
output += runtime.suppressValue(runtime.memberLookup((t_22),"Name"), env.opts.autoescape);
output += "\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((t_22),"Title"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t<div class=\"ellipsis\">\n\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((t_22),"Title"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<!-- <div class=\"field\" title=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "i"), env.opts.autoescape);
output += "\"><div class=\"ellipsis\"><input type=\"checkbox\"></div></div> -->";
;
}
}
frame = frame.pop();
output += "\n\n\t\t\t\t\t\t<div class=\"table-cell dropdown-info\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"UncollapseAll"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t<div class=\"ellipsis\" data-show-all-lower-fields>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"UncollapseAll"), env.opts.autoescape);
output += "</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"table-body bulkedit\">\n\t\t\t\t\t<div class=\"table-rows\">\n\t\t\t\t\t\t<div class=\"table-row-group\">\n\t\t\t\t\t\t\t<div class=\"table-row new-row\">\n\t\t\t\t\t\t\t\t<!-- <div class=\"field checkbox selection-box\"><input type=\"checkbox\" data-commit-checkbox=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
output += "\" title=\"Select\"></div> -->\n\t\t\t\t\t\t\t\t<div class=\"table-cell delete-row material-icons\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Delete"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t<div class=\"ellipsis\">&#xe5cd;</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_29 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"Columns");
if(t_29) {t_29 = runtime.fromIterator(t_29);
var t_28 = t_29.length;
for(var t_27=0; t_27 < t_29.length; t_27++) {
var t_30 = t_29[t_27];
frame.set("col", t_30);
frame.set("loop.index", t_27 + 1);
frame.set("loop.index0", t_27);
frame.set("loop.revindex", t_28 - t_27);
frame.set("loop.revindex0", t_28 - t_27 - 1);
frame.set("loop.first", t_27 === 0);
frame.set("loop.last", t_27 === t_28 - 1);
frame.set("loop.length", t_28);
output += "<!--{ % if col.Name === \"SerialID\" % }\n\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 131, colno = 32, runtime.callWrap(runtime.memberLookup((t_4),"setColumnCell"), "table[\"setColumnCell\"]", context, [t_30,"",runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"),"",runtime.contextOrFrameLookup(context, frame, "window"),null,null,runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Data")),"Metadata")),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"))])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t{ % elif col.Combobox % }-->\n\t\t\t\t\t\t\t\t\t";
if(runtime.memberLookup((t_30),"Combobox")) {
output += "\n\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 134, colno = 47, runtime.callWrap(runtime.memberLookup((t_1),"constructTableRecordCombobox"), "combo[\"constructTableRecordCombobox\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),runtime.memberLookup((t_30),"Combobox"),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0")])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 136, colno = 32, runtime.callWrap(runtime.memberLookup((t_4),"setColumnCell"), "table[\"setColumnCell\"]", context, [t_30,"",runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"),"",runtime.contextOrFrameLookup(context, frame, "window"),null,null,runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Data")),"Metadata")),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"))])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t\t\t<div class=\"table-cell dropdown-info material-icons\" data-toggle-lower-field>&#xe313;</div>\n\t\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t\t\t<td class=\"lower-field hide\" colspan=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"Columns")),"length") + 2, env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t\t<form class=\"form-view grid row\">\n\t\t\t\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_33 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"ColumnsExtra");
if(t_33) {t_33 = runtime.fromIterator(t_33);
var t_32 = t_33.length;
for(var t_31=0; t_31 < t_33.length; t_31++) {
var t_34 = t_33[t_31];
frame.set("col", t_34);
frame.set("loop.index", t_31 + 1);
frame.set("loop.index0", t_31);
frame.set("loop.revindex", t_32 - t_31);
frame.set("loop.revindex0", t_32 - t_31 - 1);
frame.set("loop.first", t_31 === 0);
frame.set("loop.last", t_31 === t_32 - 1);
frame.set("loop.length", t_32);
output += "<div class=\"col col-xs-12 col-sm-6 col-md-20\">\n\t\t\t\t\t\t\t\t\t\t\t\t\t<label class=\"form-field\">\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t<div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((t_34),"Title"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t";
if(runtime.memberLookup((t_34),"IsRequired")) {
output += "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t<span class=\"required-indicator\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"RequiredField"), env.opts.autoescape);
output += "\">*</span>\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t<div class=\"form-input required-field ";
output += runtime.suppressValue((runtime.memberLookup((t_34),"Type") == "Money"?"money":""), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 154, colno = 45, runtime.callWrap(runtime.memberLookup((t_10),"createInputField"), "inputField[\"createInputField\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),t_34,runtime.memberLookup((t_34),"Type"),null,runtime.memberLookup((t_34),"Initial"),null,null,runtime.contextOrFrameLookup(context, frame, "window")])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t";
output += "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t\t\t\t\t</form>\n\t\t\t\t\t\t\t\t\t\t</td>\n\t\t\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</form>\n\n\t\t\t\t\t<div class=\"table-footer clearfix sticky-div stick-to-bottom\">\n\t\t\t\t\t\t<div class=\"fake-button button button-menu no-hover no-action\">\n\t\t\t\t\t\t\t<span data-select-count title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"SelectCount"), env.opts.autoescape);
output += "\">0</span>\n\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"product") == "RentMagic") {
output += "\n\t\t\t\t\t\t<div class=\"pull-right\" style=\"max-width: 650px;\">\n\t\t\t\t\t\t\t <div class=\"pull-right\" style=\"margin-bottom: 10px;\">\n\t\t\t\t\t\t\t\t\t<div class=\"fake-button button button-menu no-hover no-action\">\n\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"PeriodPriceTotalExcVAT"), env.opts.autoescape);
output += ": <span data-period-price-total-exc-vat>0.00</span>\n\t\t\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t\t\t<div class=\"fake-button button button-menu no-hover no-action\">\n\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"PeriodPriceTotalVAT"), env.opts.autoescape);
output += ": <span data-period-price-total-vat>0.00</span>\n\t\t\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t\t\t<div class=\"fake-button button button-menu no-hover no-action\">\n\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"PeriodPriceTotalIncVAT"), env.opts.autoescape);
output += ": <span data-period-price-total-inc-vat>0.00</span>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div class=\"pull-right\">\n\t\t\t\t\t\t\t\t\t<div class=\"fake-button button button-menu no-hover no-action\">\n\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"TotalPriceExcVAT"), env.opts.autoescape);
output += ": <span data-total-price>0.00</span>\n\t\t\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t\t\t<div class=\"fake-button button button-menu no-hover no-action\">\n\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"TotalVAT"), env.opts.autoescape);
output += ": <span data-total-btw>0.00</span>\n\t\t\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t\t\t<div class=\"fake-button button button-menu no-hover no-action\">\n\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"TotalPriceIncVAT"), env.opts.autoescape);
output += ": <span data-total-btw-price>0.00</span>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"settings")),"AlwaysShowPrimarySideBarItems") == true && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"parent") !== null || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "windows")),"sub")) {
output += "\n\t\t\t\t\t<div sidebar></div>\n\t\t\t\t";
;
}
output += "\n\t\t\t";
;
}
output += "\n\t\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/quickrent.html", ctx);
          }
        
          return nunjucks.render("views/content/quickrent.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/quickrent.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        