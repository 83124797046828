import {notify} from "../../util/notify";
export async function _checkButton({webMethodName, window}) {
  if (webMethodName == "CUST_CheckButton") {
    const selectedSerials = window.selection.map((s) => s.SerialID);
    const selectedSerialsString = selectedSerials.join(", ");

    if (selectedSerials.length === 0) {
      notify({
        message: "U heeft geen serienummers geselecteerd",
        type: "warning",
      });
      return false;
    }

    if (
      !(await window.confirm(
        `Weet u zeker dat u de volgende serienummer(s) ${selectedSerialsString} wilt vrijgeven?`,
      ))
    ) {
      return false;
    }
  }
  return true;
}
