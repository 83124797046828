
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        
        
        
        
        const __nunjucks_template_import__dep_0 = require("../elements/combobox.html");
        
        const __nunjucks_template_import__dep_1 = require("../elements/fileIcon.html");
        
        const __nunjucks_template_import__dep_2 = require("./form.html");
        
        const __nunjucks_template_import__dep_3 = require("../elements/plusminusinput.html");
        
        const __nunjucks_template_import__dep_4 = require("../elements/vueEditor.html");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_4.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_4.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_4.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_4.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_4.__nunjucks_module_dependencies__.assets};
    
    
            const __nunjucks_template_import__global_stringStartsWith = require("../../src/util/stringStartsWith.js");
            __nunjucks_module_dependencies__.globals['stringStartsWith'] = {
                module: __nunjucks_template_import__global_stringStartsWith && __nunjucks_template_import__global_stringStartsWith.default || __nunjucks_template_import__global_stringStartsWith
            };
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/table.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("views/elements/combobox.html", false, "views/content/table.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("combo", t_1);
output += "\n";
env.getTemplate("views/elements/fileIcon.html", false, "views/content/table.html", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("fileIcon", t_4);
output += "\n";
env.getTemplate("views/content/form.html", false, "views/content/table.html", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
context.setVariable("form", t_7);
output += "\n";
env.getTemplate("views/elements/plusminusinput.html", false, "views/content/table.html", false, function(t_11,t_10) {
if(t_11) { cb(t_11); return; }
t_10.getExported(function(t_12,t_10) {
if(t_12) { cb(t_12); return; }
context.setVariable("plusminusinput", t_10);
output += "\n";
env.getTemplate("views/elements/vueEditor.html", false, "views/content/table.html", false, function(t_14,t_13) {
if(t_14) { cb(t_14); return; }
t_13.getExported(function(t_15,t_13) {
if(t_15) { cb(t_15); return; }
context.setVariable("vue", t_13);
output += "\n\n";
output += "\n\n";
var macro_t_16 = runtime.makeMacro(
["filter", "parent"], 
[], 
function (l_filter, l_parent, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("filter", l_filter);
frame.set("parent", l_parent);
var t_17 = "";t_17 += "\n\t";
frame = frame.push();
var t_20 = l_parent;
if(t_20) {t_20 = runtime.fromIterator(t_20);
var t_19 = t_20.length;
for(var t_18=0; t_18 < t_20.length; t_18++) {
var t_21 = t_20[t_18];
frame.set("branch", t_21);
frame.set("loop.index", t_18 + 1);
frame.set("loop.index0", t_18);
frame.set("loop.revindex", t_19 - t_18);
frame.set("loop.revindex0", t_19 - t_18 - 1);
frame.set("loop.first", t_18 === 0);
frame.set("loop.last", t_18 === t_19 - 1);
frame.set("loop.length", t_19);
t_17 += "\n\t\t";
if(runtime.memberLookup((t_21),"children")) {
t_17 += "\n\t\t\t<div class='branch collapsed'>\n\t\t\t\t<div class='branch-name'><span data-window-event=\"filterAction:";
t_17 += runtime.suppressValue(runtime.memberLookup((l_filter),"name"), env.opts.autoescape);
t_17 += ":";
t_17 += runtime.suppressValue(runtime.memberLookup((t_21),"Value"), env.opts.autoescape);
t_17 += "\">";
t_17 += runtime.suppressValue(runtime.memberLookup((t_21),"Text"), env.opts.autoescape);
t_17 += "</span></div>\n\t\t\t\t<div class=\"branches\">\n\t\t\t\t\t";
t_17 += runtime.suppressValue((lineno = 35, colno = 18, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "makeBranch"), "makeBranch", context, [l_filter,runtime.memberLookup((t_21),"children")])), env.opts.autoescape);
t_17 += "\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t";
;
}
else {
t_17 += "\n\t\t\t<div class=\"stick\" data-window-event=\"filterAction:";
t_17 += runtime.suppressValue(runtime.memberLookup((l_filter),"name"), env.opts.autoescape);
t_17 += ":";
t_17 += runtime.suppressValue(runtime.memberLookup((t_21),"Value"), env.opts.autoescape);
t_17 += "\"><span>";
t_17 += runtime.suppressValue(runtime.memberLookup((t_21),"Text"), env.opts.autoescape);
t_17 += "</span></div>\n\t\t";
;
}
t_17 += "\n\t";
;
}
}
frame = frame.pop();
t_17 += "\n";
;
frame = callerFrame;
return new runtime.SafeString(t_17);
});
context.addExport("makeBranch");
context.setVariable("makeBranch", macro_t_16);
output += "\n\n";
output += "\n";
var macro_t_22 = runtime.makeMacro(
["cellStatus"], 
[], 
function (l_cellStatus, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("cellStatus", l_cellStatus);
var t_23 = "";t_23 += "\n    ";
if(l_cellStatus == "success") {
t_23 += "\n        <i class=\"fas fa-check text-success\"></i>\n    ";
;
}
t_23 += "\n    ";
if(l_cellStatus == "danger") {
t_23 += "\n        <i class=\"fas fa-check text-danger\"></i>\n    ";
;
}
t_23 += "\n    ";
if(l_cellStatus == "warning") {
t_23 += "\n        <i class=\"fas fa-check text-warning\"></i>\n    ";
;
}
t_23 += "\n    ";
if(l_cellStatus == "info") {
t_23 += "\n        <i class=\"fas fa-info text-info\"></i>\n    ";
;
}
t_23 += "\n\n";
;
frame = callerFrame;
return new runtime.SafeString(t_23);
});
context.addExport("getCellStatus");
context.setVariable("getCellStatus", macro_t_22);
output += "\n\n";
var macro_t_24 = runtime.makeMacro(
["col", "columnindex", "index", "colval", "window", "cell", "allowEdit", "metadata"], 
[], 
function (l_col, l_columnindex, l_index, l_colval, l_window, l_cell, l_allowEdit, l_metadata, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("col", l_col);
frame.set("columnindex", l_columnindex);
frame.set("index", l_index);
frame.set("colval", l_colval);
frame.set("window", l_window);
frame.set("cell", l_cell);
frame.set("allowEdit", l_allowEdit);
frame.set("metadata", l_metadata);
var t_25 = "";if(runtime.memberLookup((runtime.memberLookup((l_metadata),runtime.memberLookup((l_col),"Name"))),"Status")) {
t_25 += "\n\t\t";
var t_26;
t_26 = (lineno = 63, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "getCellStatus"), "getCellStatus", context, [runtime.memberLookup((runtime.memberLookup((l_metadata),runtime.memberLookup((l_col),"Name"))),"Status")]));
frame.set("cellStatus", t_26, true);
if(frame.topLevel) {
context.setVariable("cellStatus", t_26);
}
if(frame.topLevel) {
context.addExport("cellStatus", t_26);
}
t_25 += "\n\t";
;
}
t_25 += "\n\t";
var t_27;
t_27 = (runtime.memberLookup((l_col),"Editor")?(lineno = 65, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "stringStartsWith"), "stringStartsWith", context, [runtime.memberLookup((l_col),"Editor"),"vue"])):"");
frame.set("vueEditor", t_27, true);
if(frame.topLevel) {
context.setVariable("vueEditor", t_27);
}
if(frame.topLevel) {
context.addExport("vueEditor", t_27);
}
t_25 += "\n\n\t";
if(runtime.memberLookup((l_col),"Editor") == "link" && runtime.memberLookup((runtime.memberLookup((l_metadata),runtime.memberLookup((l_col),"Name"))),"Description") != null && runtime.memberLookup((runtime.memberLookup((l_metadata),runtime.memberLookup((l_col),"Name"))),"Description") != "") {
t_25 += "\n\t\t<div class=\"case-0 table-cell field ";
t_25 += runtime.suppressValue((runtime.memberLookup((l_col),"CustomClass")?runtime.memberLookup((l_col),"CustomClass"):""), env.opts.autoescape);
t_25 += "\" data-field-index=\"";
t_25 += runtime.suppressValue(l_index, env.opts.autoescape);
t_25 += "\"\n\n\t\t\t\t";
if(runtime.memberLookup((l_col),"Width")) {
t_25 += " \n\t\t\t\t\tstyle=\"width: ";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"Width"), env.opts.autoescape);
t_25 += "\"\n\t\t\t\t";
;
}
t_25 += "\n\t\t\t\t\n\t\t\t>\n\t\t\t";
var t_28;
t_28 = /^http([s]?):\/\/.*/;
frame.set("regExp", t_28, true);
if(frame.topLevel) {
context.setVariable("regExp", t_28);
}
if(frame.topLevel) {
context.addExport("regExp", t_28);
}
t_25 += "\n\t\t\t";
if((lineno = 76, colno = 20, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "regExp")),"test"), "regExp[\"test\"]", context, [l_colval]))) {
t_25 += "\n\t\t\t\t<a href=\"";
t_25 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_25 += "\" target=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"Type"), env.opts.autoescape);
t_25 += "\">";
t_25 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_metadata),runtime.memberLookup((l_col),"Name"))),"Description"), env.opts.autoescape);
t_25 += "</a>\n\t\t\t";
;
}
else {
t_25 += "\n\t\t\t\t<a href=\"//";
t_25 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_25 += "\" target=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"Type"), env.opts.autoescape);
t_25 += "\">";
t_25 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_metadata),runtime.memberLookup((l_col),"Name"))),"Description"), env.opts.autoescape);
t_25 += "</a>\n\t\t\t";
;
}
t_25 += "\n\t\t</div>\n\t\t";
t_25 += "\n\t";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "vueEditor")) {
t_25 += "\n\t\t<div class=\"table-cell field\" data-name=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_25 += "\" col-type=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"Editor"), env.opts.autoescape);
t_25 += "\">\n\t\t    ";
t_25 += runtime.suppressValue((lineno = 85, colno = 28, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "vue")),"generateElement"), "vue[\"generateElement\"]", context, [l_colval,runtime.memberLookup((l_col),"Name"),l_index,runtime.memberLookup((l_col),"Editor")])), env.opts.autoescape);
t_25 += "\n\t\t</div>\n    ";
;
}
else {
if(runtime.memberLookup((l_col),"Editor") === "hidden") {
t_25 += "\n        <div class=\"table-cell field d-none\" col-type=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"Editor"), env.opts.autoescape);
t_25 += "\" data-name=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_25 += "\" data-field-index=\"";
t_25 += runtime.suppressValue(l_index, env.opts.autoescape);
t_25 += "\">\n            ";
t_25 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_25 += "\n        </div>\n\t";
;
}
else {
if(runtime.memberLookup((l_col),"Editor") === "ref") {
t_25 += "\n\t\t<div class=\"table-cell field\" data-name=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_25 += "\" data-field-index=\"";
t_25 += runtime.suppressValue(l_index, env.opts.autoescape);
t_25 += "\">\n\t\t\t<span class=\"col-ref text-primary cursor-pointer underline\" data-field-index=\"";
t_25 += runtime.suppressValue(l_index, env.opts.autoescape);
t_25 += "\">";
if(runtime.memberLookup((runtime.memberLookup((l_col),"Dropdown")),"Items")) {
t_25 += runtime.suppressValue((lineno = 95, colno = 27, runtime.callWrap(runtime.memberLookup((l_col),"getDropdownText"), "col[\"getDropdownText\"]", context, [l_colval])), env.opts.autoescape);
;
}
else {
if(l_cell && runtime.memberLookup((l_cell),"Initial") && runtime.memberLookup((runtime.memberLookup((l_cell),"Initial")),"Description")) {
t_25 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_cell),"Initial")),"Description"), env.opts.autoescape);
;
}
else {
if(runtime.memberLookup((l_col),"Html")) {
t_25 += runtime.suppressValue(env.getFilter("safe").call(context, l_colval), env.opts.autoescape);
;
}
else {
t_25 += "\n\t\t\t\t\t";
if((runtime.memberLookup((l_col),"Type") === "DateTime" || runtime.memberLookup((l_col),"Type") === "Date") && l_colval) {
t_25 += "\n\t\t\t\t\t\t<span>";
t_25 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_25 += "</span>\n\t\t\t\t\t";
;
}
else {
t_25 += "\n\t\t\t\t\t\t";
t_25 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_25 += "\n\t\t\t\t\t";
;
}
;
}
;
}
;
}
t_25 += "</span>\n\t\t</div>\n\t";
;
}
else {
if((runtime.memberLookup((l_metadata),"Label") && runtime.memberLookup((l_col),"Name") == "Label")) {
t_25 += "\n\t\t<div class=\"table-cell field\" data-name=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_25 += "\" col-type=\"label\">\n\t\t";
var t_29;
t_29 = runtime.memberLookup((runtime.memberLookup((l_metadata),"Label")),"JSONValue");
frame.set("labels", t_29, true);
if(frame.topLevel) {
context.setVariable("labels", t_29);
}
if(frame.topLevel) {
context.addExport("labels", t_29);
}
t_25 += "\n\n\t\t";
frame = frame.push();
var t_32 = runtime.contextOrFrameLookup(context, frame, "labels");
if(t_32) {t_32 = runtime.fromIterator(t_32);
var t_30;
if(runtime.isArray(t_32)) {
var t_31 = t_32.length;
for(t_30=0; t_30 < t_32.length; t_30++) {
var t_33 = t_32[t_30][0];
frame.set("[object Object]", t_32[t_30][0]);
var t_34 = t_32[t_30][1];
frame.set("[object Object]", t_32[t_30][1]);
frame.set("loop.index", t_30 + 1);
frame.set("loop.index0", t_30);
frame.set("loop.revindex", t_31 - t_30);
frame.set("loop.revindex0", t_31 - t_30 - 1);
frame.set("loop.first", t_30 === 0);
frame.set("loop.last", t_30 === t_31 - 1);
frame.set("loop.length", t_31);
t_25 += "\n\t\t\t<span label-id=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((t_34),"LabelID"), env.opts.autoescape);
t_25 += "\" class=\"badge badge-secondary\" title=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((t_34),"description"), env.opts.autoescape);
t_25 += "\" label=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((t_34),"description"), env.opts.autoescape);
t_25 += "\" style=\"background-color:";
t_25 += runtime.suppressValue(runtime.memberLookup((t_34),"Color"), env.opts.autoescape);
t_25 += "; color:";
t_25 += runtime.suppressValue((lineno = 114, colno = 202, runtime.callWrap(runtime.memberLookup((l_window),"getColorByBgColor"), "window[\"getColorByBgColor\"]", context, [runtime.memberLookup((t_34),"Color")])), env.opts.autoescape);
t_25 += "\">";
t_25 += runtime.suppressValue(runtime.memberLookup((t_34),"description"), env.opts.autoescape);
t_25 += "</span>\n\t\t";
;
}
} else {
t_30 = -1;
var t_31 = runtime.keys(t_32).length;
for(var t_35 in t_32) {
t_30++;
var t_36 = t_32[t_35];
frame.set("index", t_35);
frame.set("label", t_36);
frame.set("loop.index", t_30 + 1);
frame.set("loop.index0", t_30);
frame.set("loop.revindex", t_31 - t_30);
frame.set("loop.revindex0", t_31 - t_30 - 1);
frame.set("loop.first", t_30 === 0);
frame.set("loop.last", t_30 === t_31 - 1);
frame.set("loop.length", t_31);
t_25 += "\n\t\t\t<span label-id=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((t_36),"LabelID"), env.opts.autoescape);
t_25 += "\" class=\"badge badge-secondary\" title=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((t_36),"description"), env.opts.autoescape);
t_25 += "\" label=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((t_36),"description"), env.opts.autoescape);
t_25 += "\" style=\"background-color:";
t_25 += runtime.suppressValue(runtime.memberLookup((t_36),"Color"), env.opts.autoescape);
t_25 += "; color:";
t_25 += runtime.suppressValue((lineno = 114, colno = 202, runtime.callWrap(runtime.memberLookup((l_window),"getColorByBgColor"), "window[\"getColorByBgColor\"]", context, [runtime.memberLookup((t_36),"Color")])), env.opts.autoescape);
t_25 += "\">";
t_25 += runtime.suppressValue(runtime.memberLookup((t_36),"description"), env.opts.autoescape);
t_25 += "</span>\n\t\t";
;
}
}
}
frame = frame.pop();
t_25 += "\n\t\t</div>\n\t";
t_25 += "\n\t";
;
}
else {
if(runtime.memberLookup((l_col),"Name") == "ImageUrl") {
t_25 += "\n\t\t<div class=\"case-1 table-cell field ";
t_25 += runtime.suppressValue((runtime.memberLookup((l_col),"CustomClass")?runtime.memberLookup((l_col),"CustomClass"):""), env.opts.autoescape);
t_25 += "\" data-name=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_25 += "\" data-field-index=\"";
t_25 += runtime.suppressValue(l_index, env.opts.autoescape);
t_25 += "\" col-type=\"imageURL\">\n\t\t\t";
if(l_colval) {
t_25 += "\n\t\t\t\t<span img-url=\"";
t_25 += runtime.suppressValue(env.getFilter("safe").call(context, l_colval), env.opts.autoescape);
t_25 += "\" class=\"material-icons image\">&#xe3f4;</span>\n\t\t\t";
;
}
t_25 += "\n\t\t\t";
t_25 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "cellStatus"), env.opts.autoescape);
t_25 += "\n\t\t</div>\n\t";
t_25 += "\n\t";
;
}
else {
if((runtime.memberLookup((l_window),"bulkedit") || l_allowEdit) && runtime.memberLookup((l_col),"Type") == "Boolean") {
t_25 += "\n\t\t<div class=\"case-2 table-cell field checkbox";
t_25 += runtime.suppressValue((runtime.memberLookup((l_col),"CustomClass")?" " + runtime.memberLookup((l_col),"CustomClass"):""), env.opts.autoescape);
t_25 += "\" data-name=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_25 += "\" data-field-index=\"";
t_25 += runtime.suppressValue(l_index, env.opts.autoescape);
t_25 += "\">\n\t\t\t<input name=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_25 += "[";
t_25 += runtime.suppressValue(l_columnindex, env.opts.autoescape);
t_25 += "]\"\ttype=\"checkbox\" title=\"";
t_25 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_25 += "\" ";
t_25 += runtime.suppressValue((l_colval?"checked":""), env.opts.autoescape);
t_25 += " ";
if(runtime.memberLookup((l_col),"BaseType") != runtime.memberLookup((l_col),"Type")) {
t_25 += " class=\"pull-left\"";
;
}
t_25 += ">\n\t\t\t";
t_25 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "cellStatus"), env.opts.autoescape);
t_25 += "\n\t\t</div>\n\t";
t_25 += "\n\t";
;
}
else {
if(runtime.memberLookup((l_col),"Type") == "Boolean") {
t_25 += "\n\t\t<div class=\"case-3 table-cell field checkbox";
t_25 += runtime.suppressValue((runtime.memberLookup((l_col),"CustomClass")?" " + runtime.memberLookup((l_col),"CustomClass"):""), env.opts.autoescape);
t_25 += "\" data-name=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_25 += "\" data-field-index=\"";
t_25 += runtime.suppressValue(l_index, env.opts.autoescape);
t_25 += "\">\n\t\t\t<input \"disabled\" type=\"checkbox\" title=\"";
t_25 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_25 += "\" ";
t_25 += runtime.suppressValue((l_colval?"checked":""), env.opts.autoescape);
t_25 += " ";
if(runtime.memberLookup((l_col),"BaseType") != runtime.memberLookup((l_col),"Type")) {
t_25 += " class=\"pull-left\"";
;
}
t_25 += ">\n\t\t\t";
t_25 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "cellStatus"), env.opts.autoescape);
t_25 += "\n\t\t</div>\n\n\t";
;
}
else {
if(runtime.memberLookup((l_col),"Editor") == "plusminusinput") {
t_25 += "\n\t\t";
t_25 += runtime.suppressValue((lineno = 139, colno = 43, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "plusminusinput")),"constructPlusMinusInput"), "plusminusinput[\"constructPlusMinusInput\"]", context, [l_col,"",l_index,l_colval,runtime.memberLookup((runtime.memberLookup((l_metadata),runtime.memberLookup((l_col),"Name"))),"MinNumber"),runtime.memberLookup((runtime.memberLookup((l_metadata),runtime.memberLookup((l_col),"Name"))),"MaxNumber"),"table-cell field"])), env.opts.autoescape);
;
}
else {
if((runtime.memberLookup((l_window),"bulkedit") || l_allowEdit) && (runtime.memberLookup((l_col),"Type") == "Date" || runtime.memberLookup((l_col),"Type") == "DateTime" || runtime.memberLookup((l_col),"Type") == "Time")) {
t_25 += "\n\t\t<div class=\"case-3 table-cell date-picker\"  data-replace=\"";
t_25 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_25 += "\" data-name=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_25 += "\" data-type=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"Type"), env.opts.autoescape);
t_25 += "\">\n\t\t\t<span class=\"float-right\">";
t_25 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "cellStatus"), env.opts.autoescape);
t_25 += "</span>\n\t\t\t<div class=\"field date editable-text ";
t_25 += runtime.suppressValue((runtime.memberLookup((l_col),"Type") == "Money" || runtime.memberLookup((l_col),"Type") == "Decimal" || runtime.memberLookup((l_col),"Type") == "Int"?" number " + runtime.memberLookup((l_col),"Type"):""), env.opts.autoescape);
t_25 += runtime.suppressValue((runtime.memberLookup((l_col),"CustomClass")?" " + runtime.memberLookup((l_col),"CustomClass"):""), env.opts.autoescape);
t_25 += "\"\n\t\t\t\tdata-field-index=\"";
t_25 += runtime.suppressValue(l_index, env.opts.autoescape);
t_25 += "\" ";
t_25 += runtime.suppressValue((!runtime.memberLookup((l_col),"IsReadOnly")?"contenteditable=true":""), env.opts.autoescape);
t_25 += " name=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_25 += "[";
t_25 += runtime.suppressValue(l_columnindex, env.opts.autoescape);
t_25 += "]\">";
t_25 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_25 += "\n\t\t\t</div>\n\t\t</div>\n\t";
t_25 += "\n\t";
;
}
else {
if((runtime.memberLookup((l_window),"bulkedit") || l_allowEdit) && !(runtime.memberLookup((l_col),"IsRequired") && runtime.memberLookup((l_col),"IsPrimaryKey") && l_cell) && runtime.memberLookup((l_cell),"Combobox")) {
t_25 += "\n\t\t<div class=\"case-4 table-cell field";
t_25 += runtime.suppressValue((runtime.memberLookup((l_col),"CustomClass")?" " + runtime.memberLookup((l_col),"CustomClass"):""), env.opts.autoescape);
t_25 += "\" data-name=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_25 += "\" data-field-index=\"";
t_25 += runtime.suppressValue(l_index, env.opts.autoescape);
t_25 += "\"\n\t\t\t";
if(runtime.memberLookup((l_col),"Width")) {
t_25 += "\n\t\t\t\tstyle=\"min-width: ";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"Width"), env.opts.autoescape);
t_25 += "; width: ";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"Width"), env.opts.autoescape);
t_25 += "\"\n\t\t\t";
;
}
t_25 += "\n\t\t>\n\t\t\t\t";
if(runtime.memberLookup((l_col),"Name") == "DimensionID-3") {
t_25 += "\n\t\t\t\t ";
t_25 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "cellStatus"), env.opts.autoescape);
t_25 += " ";
t_25 += runtime.suppressValue((lineno = 155, colno = 59, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "combo")),"constructTableRecordCombobox"), "combo[\"constructTableRecordCombobox\"]", context, [runtime.memberLookup((l_window),"session"),runtime.memberLookup((l_cell),"Combobox"),l_index,runtime.memberLookup((l_col),"Name") == "DimensionID-3"])), env.opts.autoescape);
t_25 += "\n\t\t\t";
;
}
else {
t_25 += "\n\t\t\t\t";
t_25 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "cellStatus"), env.opts.autoescape);
t_25 += " ";
t_25 += runtime.suppressValue((lineno = 157, colno = 47, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "combo")),"constructCombobox"), "combo[\"constructCombobox\"]", context, [runtime.memberLookup((l_window),"session"),runtime.memberLookup((l_cell),"Combobox"),runtime.memberLookup((l_col),"Width")])), env.opts.autoescape);
t_25 += " \n\t\t\t";
;
}
t_25 += "\n\t\t</div>\n\t";
t_25 += "\n\t";
;
}
else {
if((runtime.memberLookup((l_window),"bulkedit") || l_allowEdit)) {
t_25 += "\n\t\t<div data-name=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_25 += "\" class=\"case-5 table-cell field\n\t\t\t";
t_25 += runtime.suppressValue((runtime.memberLookup((l_col),"IsReadOnly") == false?" editable-text ":""), env.opts.autoescape);
t_25 += "\n\t\t\t";
t_25 += runtime.suppressValue((runtime.memberLookup((l_col),"Type") == "Money" || runtime.memberLookup((l_col),"Type") == "Decimal" || runtime.memberLookup((l_col),"Type") == "Int" || runtime.memberLookup((l_col),"Editor") == "number"?" number " + runtime.memberLookup((l_col),"Type"):""), env.opts.autoescape);
t_25 += "\n\t\t\t";
t_25 += runtime.suppressValue((runtime.memberLookup((l_col),"CustomClass")?" " + runtime.memberLookup((l_col),"CustomClass"):""), env.opts.autoescape);
t_25 += "\"\n\t\t\t";
t_25 += "\n\t\t\t";
if(runtime.memberLookup((l_col),"IsReadOnly")) {
t_25 += "\n\t\t\t\tcontenteditable=\"false\"\n\t\t\t";
;
}
else {
t_25 += "\n\t\t\t\tcontenteditable=\"true\"\n\t\t\t";
;
}
t_25 += "\n\t\t\tname=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_25 += "[";
t_25 += runtime.suppressValue(l_columnindex, env.opts.autoescape);
t_25 += "]\"\n\t\t\tdata-field-index=\"";
t_25 += runtime.suppressValue(l_index, env.opts.autoescape);
t_25 += "\"\n\t\t\t";
if(runtime.memberLookup((l_col),"MinNumber")) {
t_25 += "\n\t\t\t\tmin=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"MinNumber"), env.opts.autoescape);
t_25 += "\"\n\t\t\t";
;
}
t_25 += "\n\t\t\t";
if(runtime.memberLookup((l_col),"MaxNumber")) {
t_25 += "\n\t\t\t\tmax=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"MaxNumber"), env.opts.autoescape);
t_25 += "\"\n\t\t\t";
;
}
t_25 += "\n\t\t\t";
if(runtime.memberLookup((l_col),"Width")) {
t_25 += "\n\t\t\t\tstyle=\"width: ";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"Width"), env.opts.autoescape);
t_25 += "\"\n\t\t\t";
;
}
t_25 += "\n\t\t>\n\t\t\t";
t_25 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_25 += "\n\t\t</div>";
;
}
else {
t_25 += "\n\t\t<div data-name=\"";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_25 += "\" class=\"case-6 table-cell field ";
t_25 += runtime.suppressValue("table-cell-" + runtime.memberLookup((l_col),"Type"), env.opts.autoescape);
t_25 += " ";
t_25 += runtime.suppressValue((runtime.memberLookup((l_col),"Type") == "Money" || runtime.memberLookup((l_col),"Type") == "Decimal"?"number " + runtime.memberLookup((l_col),"Type"):""), env.opts.autoescape);
t_25 += runtime.suppressValue((runtime.memberLookup((l_col),"CustomClass")?runtime.memberLookup((l_col),"CustomClass"):""), env.opts.autoescape);
t_25 += "\" data-field-index=\"";
t_25 += runtime.suppressValue(l_index, env.opts.autoescape);
t_25 += "\"\n\t\t\t";
if(runtime.memberLookup((l_col),"Width")) {
t_25 += "\n\t\t\t\tstyle=\"min-width: ";
t_25 += runtime.suppressValue(runtime.memberLookup((l_col),"Width"), env.opts.autoescape);
t_25 += "\"\n\t\t\t";
;
}
t_25 += ">\n\t\t\t";
t_25 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "cellStatus"), env.opts.autoescape);
if((runtime.memberLookup((l_col),"Editor") === "number")) {
t_25 += runtime.suppressValue(l_colval, env.opts.autoescape);
;
}
else {
if(runtime.memberLookup((runtime.memberLookup((l_col),"Dropdown")),"Items")) {
t_25 += runtime.suppressValue((lineno = 196, colno = 26, runtime.callWrap(runtime.memberLookup((l_col),"getDropdownText"), "col[\"getDropdownText\"]", context, [l_colval])), env.opts.autoescape);
;
}
else {
if(l_cell && runtime.memberLookup((l_cell),"Initial") && runtime.memberLookup((runtime.memberLookup((l_cell),"Initial")),"Description") !== null && runtime.memberLookup((runtime.memberLookup((l_cell),"Initial")),"Description") !== "") {
t_25 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_cell),"Initial")),"Description"), env.opts.autoescape);
;
}
else {
if(runtime.memberLookup((l_col),"Html")) {
t_25 += runtime.suppressValue(env.getFilter("safe").call(context, l_colval), env.opts.autoescape);
;
}
else {
t_25 += "\n\t\t\t\t";
if((runtime.memberLookup((l_col),"Type") === "DateTime" || runtime.memberLookup((l_col),"Type") === "Date") && l_colval) {
t_25 += "\n\t\t\t\t\t<span>";
t_25 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_25 += "</span>\n\t\t\t\t";
;
}
else {
t_25 += "\n\t\t\t\t\t";
t_25 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_25 += "\n\t\t\t\t";
;
}
;
}
;
}
;
}
;
}
t_25 += "</div>";
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
frame = callerFrame;
return new runtime.SafeString(t_25);
});
context.addExport("setColumnCell");
context.setVariable("setColumnCell", macro_t_24);
output += "\n\n\t";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"settings")),"AlwaysShowPrimarySideBarItems") == true && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"parent") !== null || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "windows")),"sub")) {
output += "\n\t<div class=\"content row\" style=\"\n\t\t\tmargin-left: 0px;\n\t\t\talign-items: baseline;\n\t\">\n";
;
}
else {
output += "\n\t<div class=\"content\">\n";
;
}
output += "\n\n  ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"settings")),"AlwaysShowPrimarySideBarItems") == true && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"parent") !== null) {
output += "\n\t  <div class=\"table-view pl-0 col-12 col-lg-10 ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"bulkedit")?"unsortable":""), env.opts.autoescape);
output += "\">\n  ";
;
}
else {
output += "\n    <div class=\"table-view ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"bulkedit")?"unsortable":""), env.opts.autoescape);
output += "\">\n  ";
;
}
output += "\n\t\t";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("header"))(env, context, frame, runtime, function(t_38,t_37) {
if(t_38) { cb(t_38); return; }
output += t_37;
output += "\n\n\t\t";
if((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Table")),"Rows")),"length") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"bulkedit")) && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Table")),"Columns")),"length")) {
output += "\n\t\t\t<form class=\"table-container\">\n\t\t\t\t<div class=\"table-index sticky-div\">\n\t\t\t\t\t<div class=\"table-row\">\n\t\t\t\t\t\t";
if(!runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Options")),"DisableSelection")) {
output += "\n\t\t\t\t\t\t\t<label style=\"padding-left: 5px;\" class=\"table-cell checkbox selection-box\"><div class=\"ellipsis\"><input type=\"checkbox\" title=\"Select all\"></div></label>\n\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t";
frame = frame.push();
var t_41 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Table")),"Columns");
if(t_41) {t_41 = runtime.fromIterator(t_41);
var t_40 = t_41.length;
for(var t_39=0; t_39 < t_41.length; t_39++) {
var t_42 = t_41[t_39];
frame.set("col", t_42);
frame.set("loop.index", t_39 + 1);
frame.set("loop.index0", t_39);
frame.set("loop.revindex", t_40 - t_39);
frame.set("loop.revindex0", t_40 - t_39 - 1);
frame.set("loop.first", t_39 === 0);
frame.set("loop.last", t_39 === t_40 - 1);
frame.set("loop.length", t_40);
output += "\n\t\t\t\t\t\t\t<div class=\"table-cell ";
output += runtime.suppressValue((runtime.memberLookup((t_42),"Type") == "Money" || runtime.memberLookup((t_42),"Type") == "Decimal"?"number " + runtime.memberLookup((t_42),"Type"):""), env.opts.autoescape);
output += "\" data-window-event=\"toggle-column-sorting:";
output += runtime.suppressValue(runtime.memberLookup((t_42),"Name"), env.opts.autoescape);
output += "\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((t_42),"Title"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t<div class=\"ellipsis\">";
if(runtime.memberLookup((t_42),"OrderType")) {
output += "<span class=\"material-icons\">";
output += runtime.suppressValue((runtime.memberLookup((t_42),"OrderType") == "DESC"?(env.getFilter("safe").call(context, "&#xe5c5;")):(env.getFilter("safe").call(context, "&#xe5c7;"))), env.opts.autoescape);
output += "</span>";
;
}
output += runtime.suppressValue(runtime.memberLookup((t_42),"Title"), env.opts.autoescape);
output += "</div>\n\t\t\t\t\t\t\t</div>";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"table-body ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"bulkedit")?"bulkedit":""), env.opts.autoescape);
output += "\">\n\t\t\t\t\t<div class=\"table-rows\">\n\n\t\t\t\t\t\t";
var t_43;
t_43 = 0;
frame.set("rowIndex", t_43, true);
if(frame.topLevel) {
context.setVariable("rowIndex", t_43);
}
if(frame.topLevel) {
context.addExport("rowIndex", t_43);
}
output += "\n\t\t\t\t\t\t";
frame = frame.push();
var t_46 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Table")),"Rows");
if(t_46) {t_46 = runtime.fromIterator(t_46);
var t_45 = t_46.length;
for(var t_44=0; t_44 < t_46.length; t_44++) {
var t_47 = t_46[t_44];
frame.set("row", t_47);
frame.set("loop.index", t_44 + 1);
frame.set("loop.index0", t_44);
frame.set("loop.revindex", t_45 - t_44);
frame.set("loop.revindex0", t_45 - t_44 - 1);
frame.set("loop.first", t_44 === 0);
frame.set("loop.last", t_44 === t_45 - 1);
frame.set("loop.length", t_45);
output += "<div class=\"table-row\" data-row-index=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
output += "\" style=\"";
output += runtime.suppressValue((lineno = 345, colno = 91, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"getCssInfo"), "window[\"getCssInfo\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0")])), env.opts.autoescape);
output += "\">";
if(!runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Options")),"DisableSelection")) {
output += "<label class=\"table-cell checkbox selection-box\"><input type=\"checkbox\" data-commit-checkbox=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
output += "\" title=\"Select\"></label>";
;
}
var t_48;
t_48 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0");
frame.set("loopIndex", t_48, true);
if(frame.topLevel) {
context.setVariable("loopIndex", t_48);
}
if(frame.topLevel) {
context.addExport("loopIndex", t_48);
}
frame = frame.push();
var t_51 = t_47;
if(t_51) {t_51 = runtime.fromIterator(t_51);
var t_50 = t_51.length;
for(var t_49=0; t_49 < t_51.length; t_49++) {
var t_52 = t_51[t_49];
frame.set("col", t_52);
frame.set("loop.index", t_49 + 1);
frame.set("loop.index0", t_49);
frame.set("loop.revindex", t_50 - t_49);
frame.set("loop.revindex0", t_50 - t_49 - 1);
frame.set("loop.first", t_49 === 0);
frame.set("loop.last", t_49 === t_50 - 1);
frame.set("loop.length", t_50);
output += runtime.suppressValue((lineno = 353, colno = 24, runtime.callWrap(macro_t_24, "setColumnCell", context, [runtime.memberLookup((t_52),"Column"),runtime.contextOrFrameLookup(context, frame, "loopIndex"),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"),runtime.memberLookup((t_52),"Value"),runtime.contextOrFrameLookup(context, frame, "window"),t_52,runtime.memberLookup((runtime.memberLookup((t_52),"Column")),"AllowEditOnTable"),runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Data")),"Metadata")),runtime.contextOrFrameLookup(context, frame, "rowIndex"))])), env.opts.autoescape);
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t";
var t_53;
t_53 = runtime.contextOrFrameLookup(context, frame, "rowIndex") + 1;
frame.set("rowIndex", t_53, true);
if(frame.topLevel) {
context.setVariable("rowIndex", t_53);
}
if(frame.topLevel) {
context.addExport("rowIndex", t_53);
}
;
}
}
frame = frame.pop();
output += "\n\n\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"bulkedit")) {
output += "\n\t\t\t\t\t\t\t<div class=\"table-row new-row\">\n\t\t\t\t\t\t\t\t<label class=\"table-cell checkbox selection-box\"><input type=\"checkbox\" data-commit-checkbox=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
output += "\" title=\"Select\"></label>\n\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_56 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Table")),"Columns");
if(t_56) {t_56 = runtime.fromIterator(t_56);
var t_55 = t_56.length;
for(var t_54=0; t_54 < t_56.length; t_54++) {
var t_57 = t_56[t_54];
frame.set("col", t_57);
frame.set("loop.index", t_54 + 1);
frame.set("loop.index0", t_54);
frame.set("loop.revindex", t_55 - t_54);
frame.set("loop.revindex0", t_55 - t_54 - 1);
frame.set("loop.first", t_54 === 0);
frame.set("loop.last", t_54 === t_55 - 1);
frame.set("loop.length", t_55);
output += runtime.suppressValue((lineno = 363, colno = 25, runtime.callWrap(macro_t_24, "setColumnCell", context, [t_57,"",runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"),"",runtime.contextOrFrameLookup(context, frame, "window"),null,null,runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Data")),"Metadata")),runtime.contextOrFrameLookup(context, frame, "rowIndex"))])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</form>\n\t\t";
;
}
else {
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Table")),"Columns")),"length") == 0) {
output += "\n\t\t\t<p>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"NoColumnsFound"), env.opts.autoescape);
output += "</p>\n\t\t";
;
}
else {
output += "\n\t\t\t<p>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"NoRowsFound"), env.opts.autoescape);
output += "</p>\n\t\t";
;
}
;
}
output += "\n\n\t\t";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"bulkedit") && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Data")),"Totals")),"RowCount")),"Value") > 0) {
output += "<div class=\"table-footer clearfix sticky-div stick-to-bottom\">\n\t\t\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination") && !runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"bulkedit")) {
output += "<ul class=\"pagination\">\n\t\t\t\t\t\t<li class=\"button button-menu material-icons\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"first") || "", env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"first")?"disabled":""), env.opts.autoescape);
output += ">&#xe045;</li>\n\t\t\t\t\t\t<li class=\"button button-menu material-icons\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"prev") || "", env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"prev")?"disabled":""), env.opts.autoescape);
output += ">&#xe314;</li>\n\t\t\t\t\t\t";
frame = frame.push();
var t_60 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"sequence");
if(t_60) {t_60 = runtime.fromIterator(t_60);
var t_59 = t_60.length;
for(var t_58=0; t_58 < t_60.length; t_58++) {
var t_61 = t_60[t_58];
frame.set("item", t_61);
frame.set("loop.index", t_58 + 1);
frame.set("loop.index0", t_58);
frame.set("loop.revindex", t_59 - t_58);
frame.set("loop.revindex0", t_59 - t_58 - 1);
frame.set("loop.first", t_58 === 0);
frame.set("loop.last", t_58 === t_59 - 1);
frame.set("loop.length", t_59);
output += "<li class=\"page ";
output += runtime.suppressValue((runtime.memberLookup((t_61),"current")?"current":""), env.opts.autoescape);
output += "\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((t_61),"page"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_61),"page"), env.opts.autoescape);
output += "</li>\n\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t<li class=\"button button-menu material-icons\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"next") || "", env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"next")?"disabled":""), env.opts.autoescape);
output += ">&#xe315;</li>\n\t\t\t\t\t\t<li class=\"button button-menu material-icons\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"last") || "", env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"last")?"disabled":""), env.opts.autoescape);
output += ">&#xe044;</li>\n\t\t\t\t\t</ul>\n\t\t\t\t";
;
}
output += "\n\n\t\t\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Totals") && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Totals")),"length") > 0) {
output += "\n\t\t\t\t\t<div class=\"pull-right\">\n\t\t\t\t\t";
frame = frame.push();
var t_64 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Totals");
if(t_64) {t_64 = runtime.fromIterator(t_64);
var t_63 = t_64.length;
for(var t_62=0; t_62 < t_64.length; t_62++) {
var t_65 = t_64[t_62];
frame.set("col", t_65);
frame.set("loop.index", t_62 + 1);
frame.set("loop.index0", t_62);
frame.set("loop.revindex", t_63 - t_62);
frame.set("loop.revindex0", t_63 - t_62 - 1);
frame.set("loop.first", t_62 === 0);
frame.set("loop.last", t_62 === t_63 - 1);
frame.set("loop.length", t_63);
output += "<div class=\"fake-button button button-menu no-hover no-action\">\n\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((t_65),"Title"), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue(env.getFilter("round").call(context, runtime.memberLookup((t_65),"Value"),2), env.opts.autoescape);
output += "\n\t\t\t\t\t\t</div>";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</div>\n\t\t\t\t";
;
}
output += "\n\n\t\t\t\t<div class=\"fake-button button button-menu no-hover no-action\">\n\t\t\t\t\t";
if(!runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Options")),"DisableSelection")) {
output += "\n\t\t\t\t\t\t<span data-select-count>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"selection")),"length"), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t/\n\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t<span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Data")),"Totals")),"RowCount")),"Value"), env.opts.autoescape);
output += "</span>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t";
;
}
output += "\n\t</div>\n\n\n\t";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"settings")),"AlwaysShowPrimarySideBarItems") == true && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"parent") !== null || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "windows")),"sub")) {
output += "\n\t\t<div sidebar></div>\n\t";
;
}
output += "\n\n\n</div>\n\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_header(env, context, frame, runtime, cb) {
var lineno = 227;
var colno = 5;
var output = "";
try {
var frame = frame.push(true);
output += "\n\t\t\t";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"bulkedit")) {
output += "\n\t\t\t\t<div class=\"table-form w-100 sticky-div\">\n\t\t\t\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination") && !runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"bulkedit")) {
output += "<div class=\"right\">\n\t\t\t\t\t\t\t<ul class=\"pagination  ml-3\">\n\t\t\t\t\t\t\t\t<li class=\"button button-menu material-icons\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"first") || "", env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"first")?"disabled":""), env.opts.autoescape);
output += ">&#xe045;</li>\n\t\t\t\t\t\t\t\t<li class=\"button button-menu material-icons\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"prev") || "", env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"prev")?"disabled":""), env.opts.autoescape);
output += ">&#xe314;</li>\n\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_68 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"sequence");
if(t_68) {t_68 = runtime.fromIterator(t_68);
var t_67 = t_68.length;
for(var t_66=0; t_66 < t_68.length; t_66++) {
var t_69 = t_68[t_66];
frame.set("item", t_69);
frame.set("loop.index", t_66 + 1);
frame.set("loop.index0", t_66);
frame.set("loop.revindex", t_67 - t_66);
frame.set("loop.revindex0", t_67 - t_66 - 1);
frame.set("loop.first", t_66 === 0);
frame.set("loop.last", t_66 === t_67 - 1);
frame.set("loop.length", t_67);
output += "<li class=\"page ";
output += runtime.suppressValue((runtime.memberLookup((t_69),"current")?"current":""), env.opts.autoescape);
output += "\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((t_69),"page"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_69),"page"), env.opts.autoescape);
output += "</li>\n\t\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t\t<li class=\"button button-menu material-icons\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"next") || "", env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"next")?"disabled":""), env.opts.autoescape);
output += ">&#xe315;</li>\n\t\t\t\t\t\t\t\t<li class=\"button button-menu material-icons\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"last") || "", env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"last")?"disabled":""), env.opts.autoescape);
output += ">&#xe044;</li>\n\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t<select class=\"number-of\">\n\t\t\t\t\t\t";
frame = frame.push();
var t_72 = [15,25,50,100,200,500];
if(t_72) {t_72 = runtime.fromIterator(t_72);
var t_71 = t_72.length;
for(var t_70=0; t_70 < t_72.length; t_70++) {
var t_73 = t_72[t_70];
frame.set("i", t_73);
frame.set("loop.index", t_70 + 1);
frame.set("loop.index0", t_70);
frame.set("loop.revindex", t_71 - t_70);
frame.set("loop.revindex0", t_71 - t_70 - 1);
frame.set("loop.first", t_70 === 0);
frame.set("loop.last", t_70 === t_71 - 1);
frame.set("loop.length", t_71);
output += "\n\t\t\t\t\t\t\t<option ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output") && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Request")),"Data") && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Request")),"Data")),"PageSize") == t_73?"selected":""), env.opts.autoescape);
output += ">";
output += runtime.suppressValue(t_73, env.opts.autoescape);
output += "</option>\n\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</select>\n\n\t\t\t\t\t<form class=\"search-bar mb-2\">\n\t\t\t\t\t\t<div class=\"search-box\">\n\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_76 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"filters")),"date");
if(t_76) {t_76 = runtime.fromIterator(t_76);
var t_75 = t_76.length;
for(var t_74=0; t_74 < t_76.length; t_74++) {
var t_77 = t_76[t_74];
frame.set("filter", t_77);
frame.set("loop.index", t_74 + 1);
frame.set("loop.index0", t_74);
frame.set("loop.revindex", t_75 - t_74);
frame.set("loop.revindex0", t_75 - t_74 - 1);
frame.set("loop.first", t_74 === 0);
frame.set("loop.last", t_74 === t_75 - 1);
frame.set("loop.length", t_75);
if(runtime.memberLookup((t_77),"value")) {
output += "<span class=\"filter\" data-window-filter-id=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
output += "\" data-window-event=\"remove-date-query:";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t\t\t<span class=\"close material-icons\" style=\"font-size:inherit;\">&#xe5cd;</span>\n\t\t\t\t\t\t\t\t\t\t\t<span>";
output += runtime.suppressValue(runtime.memberLookup((t_77),"text"), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue(runtime.memberLookup((t_77),"value"), env.opts.autoescape);
output += "</span>\n\n\t\t\t\t\t\t\t\t\t\t</span>\n\t\t\t\t\t\t\t\t\t\t";
;
}
else {
output += "<div class=\"dropdown filter-option date-picker\" data-type=\"Date\" data-replace>\n\t\t\t\t\t\t\t\t\t\t\t<button class=\"toggle-menu button button-menu\">\n\t\t\t\t\t\t\t\t\t\t\t\t<span>";
output += runtime.suppressValue(runtime.memberLookup((t_77),"text"), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t\t\t\t\t\t\t<span class=\"material-icons\" style=\"font-size:inherit;\">&#xe5c5;</span>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t\t<input type=\"hidden\" class=\"date\" data-index=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t\t</div>";
;
}
;
}
}
frame = frame.pop();
output += "\n\n\t\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Filters")) {
output += "\n\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_80 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Filters");
if(t_80) {t_80 = runtime.fromIterator(t_80);
var t_79 = t_80.length;
for(var t_78=0; t_78 < t_80.length; t_78++) {
var t_81 = t_80[t_78];
frame.set("filter", t_81);
frame.set("loop.index", t_78 + 1);
frame.set("loop.index0", t_78);
frame.set("loop.revindex", t_79 - t_78);
frame.set("loop.revindex0", t_79 - t_78 - 1);
frame.set("loop.first", t_78 === 0);
frame.set("loop.last", t_78 === t_79 - 1);
frame.set("loop.length", t_79);
if(runtime.memberLookup((t_81),"Type") == "List") {
output += "\n\t\t\t\t\t\t\t\t\t<div class=\"filter-option right\">\n\t\t\t\t\t\t\t\t\t\t<select data-filter-target=\"";
output += runtime.suppressValue(runtime.memberLookup((t_81),"Target"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((t_81),"Options")),"length") > 0) {
output += "\n\t\t\t\t\t\t\t\t\t\t\t\t<option disabled ";
output += runtime.suppressValue((runtime.memberLookup((t_81),"Selected") < 0?"selected":""), env.opts.autoescape);
output += " value=\"\">Filter</option>\n\t\t\t\t\t\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_84 = runtime.memberLookup((t_81),"Options");
if(t_84) {t_84 = runtime.fromIterator(t_84);
var t_83 = t_84.length;
for(var t_82=0; t_82 < t_84.length; t_82++) {
var t_85 = t_84[t_82];
frame.set("item", t_85);
frame.set("loop.index", t_82 + 1);
frame.set("loop.index0", t_82);
frame.set("loop.revindex", t_83 - t_82);
frame.set("loop.revindex0", t_83 - t_82 - 1);
frame.set("loop.first", t_82 === 0);
frame.set("loop.last", t_82 === t_83 - 1);
frame.set("loop.length", t_83);
output += "\n\t\t\t\t\t\t\t\t\t\t\t<option ";
output += runtime.suppressValue((runtime.memberLookup((t_81),"Selected") == runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0")?"selected":""), env.opts.autoescape);
output += " value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_85),"Key"), env.opts.autoescape);
output += "</option>\n\t\t\t\t\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t\t\t\t</select>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t";
;
}
if(runtime.memberLookup((t_81),"Type") == "Treeview") {
output += "\n\t\t\t\t\t\t\t\t\t<div class=\"dropdown right filter-option\">\n\t\t\t\t\t\t\t\t\t\t<div class=\"button button-menu fake-button toggle-menu\">\n\t\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((t_81),"text"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div class=\"menu hide tree-view white\">\n\t\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 289, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "makeBranch"), "makeBranch", context, [t_81,runtime.memberLookup((t_81),"list")])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t";
;
}
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t";
;
}
output += "\n\n\n\n\n\t\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Request")),"Data") && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Request")),"Data")),"Search") && runtime.memberLookup(((lineno = 299, colno = 87, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"getSearchColumns"), "window[\"getSearchColumns\"]", context, []))),"length") > 0) {
output += "\n\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_88 = runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Request")),"Data")),"Search");
if(t_88) {t_88 = runtime.fromIterator(t_88);
var t_87 = t_88.length;
for(var t_86=0; t_86 < t_88.length; t_86++) {
var t_89 = t_88[t_86];
frame.set("searchObj", t_89);
frame.set("loop.index", t_86 + 1);
frame.set("loop.index0", t_86);
frame.set("loop.revindex", t_87 - t_86);
frame.set("loop.revindex0", t_87 - t_86 - 1);
frame.set("loop.first", t_86 === 0);
frame.set("loop.last", t_86 === t_87 - 1);
frame.set("loop.length", t_87);
output += "\n\t\t\t\t\t\t\t\t\t";
var t_90;
t_90 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0");
frame.set("offset", t_90, true);
if(frame.topLevel) {
context.setVariable("offset", t_90);
}
if(frame.topLevel) {
context.addExport("offset", t_90);
}
output += "\n\t\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_93 = t_89;
if(t_93) {t_93 = runtime.fromIterator(t_93);
var t_91;
if(runtime.isArray(t_93)) {
var t_92 = t_93.length;
for(t_91=0; t_91 < t_93.length; t_91++) {
var t_94 = t_93[t_91][0];
frame.set("[object Object]", t_93[t_91][0]);
var t_95 = t_93[t_91][1];
frame.set("[object Object]", t_93[t_91][1]);
frame.set("loop.index", t_91 + 1);
frame.set("loop.index0", t_91);
frame.set("loop.revindex", t_92 - t_91);
frame.set("loop.revindex0", t_92 - t_91 - 1);
frame.set("loop.first", t_91 === 0);
frame.set("loop.last", t_91 === t_92 - 1);
frame.set("loop.length", t_92);
output += "\n\t\t\t\t\t\t\t\t\t\t<div class=\"filter\" data-window-filter-id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "offset"), env.opts.autoescape);
output += "\" data-window-event=\"remove-text-query:";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "offset"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t\t\t<span class=\"close material-icons\" style=\"font-size:inherit;\">&#xe5cd;</span>\n\t\t\t\t\t\t\t\t\t\t\t<span>";
output += runtime.suppressValue(t_95, env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t";
;
}
} else {
t_91 = -1;
var t_92 = runtime.keys(t_93).length;
for(var t_96 in t_93) {
t_91++;
var t_97 = t_93[t_96];
frame.set("columnSelection", t_96);
frame.set("searchTerm", t_97);
frame.set("loop.index", t_91 + 1);
frame.set("loop.index0", t_91);
frame.set("loop.revindex", t_92 - t_91);
frame.set("loop.revindex0", t_92 - t_91 - 1);
frame.set("loop.first", t_91 === 0);
frame.set("loop.last", t_91 === t_92 - 1);
frame.set("loop.length", t_92);
output += "\n\t\t\t\t\t\t\t\t\t\t<div class=\"filter\" data-window-filter-id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "offset"), env.opts.autoescape);
output += "\" data-window-event=\"remove-text-query:";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "offset"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t\t\t<span class=\"close material-icons\" style=\"font-size:inherit;\">&#xe5cd;</span>\n\t\t\t\t\t\t\t\t\t\t\t<span>";
output += runtime.suppressValue(t_97, env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t";
;
}
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\n\t\t\t\t\t\t\t\t<div class=\"search-input-container\">\n\t\t\t\t\t\t\t\t\t<input class=\"input ignore-next ignore-reset\" placeholder=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"PlaceholderSearch"), env.opts.autoescape);
output += "\" >\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</form>\n\n\n\t\t\t\t</div>\n\n\t\t\t";
;
}
output += "\n\n\t\t";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_header: b_header,
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/table.html", ctx);
          }
        
          return nunjucks.render("views/content/table.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/table.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        