import { render, staticRenderFns } from "./tab.vue?vue&type=template&id=152f0bb8&scoped=true&"
import script from "./tab.vue?vue&type=script&lang=js&"
export * from "./tab.vue?vue&type=script&lang=js&"
import style0 from "./tab.vue?vue&type=style&index=0&id=152f0bb8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "152f0bb8",
  null
  
)


    import installComponents from "!/Users/dhulsman/Projects/rentmagic.frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton, BButtonGroup} from 'bootstrap-vue'
    installComponents(component, {BButton, BButtonGroup})
    


    import installDirectives from "!/Users/dhulsman/Projects/rentmagic.frontend/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBTooltip} from 'bootstrap-vue'
    installDirectives(component, {'b-tooltip': VBTooltip})
    

export default component.exports