import {api} from "./api";
import store from "../../state/store";

export async function getExcelTemplate({fullTableName}) {
  const result = await api.get(`/v2/excel/template`, {
    params: {fullTableName},
  });

  return result.data;
}

export async function importExcelTemplate({
  formData,
  insert,
  update,
  fullTableName,
}) {
  const result = await api.post(`/v2/excel/import`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${store.state.accessToken}`,
    },
    params: {
      insert,
      update,
      fullTableName,
    },
  });

  return result.data;
}
