import "lodash/forEach";

const mutations = {
  updateWindow(state) {
    const activeWindowInstance = global.session.activeWindow || {};

    if (activeWindowInstance.getSearchColumns) {
      state.window = {
        id: activeWindowInstance.id || null,
        templateName: activeWindowInstance.customTemplateName || null,
        output: activeWindowInstance.output || null,
        searchColumns: activeWindowInstance.getSearchColumns() || null,
        tabs: activeWindowInstance.output.Tabs,
        options: activeWindowInstance.options,
        customTitle: activeWindowInstance.customTitle,
        bulkedit: activeWindowInstance.bulkedit || null,
        changedRows: [],
      };
    } else {
      state.window = {};
    }

    if (activeWindowInstance.output) {
      state.window.subTitle = activeWindowInstance.output.SubTitle || null;
    }

    if (activeWindowInstance.sub) {
      const activeSubWindowInstance = activeWindowInstance.sub.window;
      state.subWindow = {
        id: activeSubWindowInstance.id || null,
        templateName: activeSubWindowInstance.customTemplateName || null,
        output: activeSubWindowInstance.output || null,
        bulkedit: activeSubWindowInstance.bulkedit || null,
        changedRows: [],
      };
    } else {
      state.subWindow = {};
    }
  },
  setReservations(state, {reservations}) {
    state.reservations = reservations;
  },
  setTranslations(state, {translations}) {
    state.translations = translations;
  },
  setToken(state, {token}) {
    if (!token) return;
    state.accessToken = token.Access_token;
  },
  setActiveWarehouse(state, {warehouse}) {
    if (!warehouse) return;
    state.activeWarehouse = warehouse;
  },
  setWarehouses(state, {warehouses}) {
    if (!warehouses) return;
    state.warehouses = warehouses;
  },
  setShortcuts(state, {shortcuts}) {
    state.shortcuts = shortcuts;
  },
  setSettings(state, {settings}) {
    state.settings = settings;
  },
  logout(state) {
    state.accessToken = null;
    state.settings = {};
    state.shortcuts = [];
  },
  setSubMenuItems(state, {subMenuItems}) {
    state.subMenuItems = subMenuItems;
  },

  closeActiveModal(state, close) {
    state.closeActiveModal = close;
  },

  setHeaderComboboxes(state, comboboxes) {
    state.headerComboboxes = comboboxes;
  },
  setScanbox(state, scanbox) {
    state.scanbox = scanbox;
  },
  setActionBarStatus(state, actionBarStatus) {
    state.hideActionBar = actionBarStatus;
  },
  toggleSubMenu(state) {
    if (state.subMenuOpen) state.subMenuOpen = false;
    else state.subMenuOpen = true;
  },
  showSubMenu(state) {
    state.subMenuOpen = true;
  },
  hideSubMenu(state) {
    state.subMenuOpen = false;
  },
  removeAllTabs(state) {
    state.tabs = [];
  },
  setActiveWindowID(state, windowID) {
    state.activeWindowID = windowID;
  },
  updateScrollPosition(state, scrollPosition) {
    state.scrollPosition = scrollPosition;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  refreshTabs(state) {
    const tabs = [];

    for (const tab of global.session.tabs) {
      if (tab !== undefined) {
        tabs.push({
          id: tab.id,
          title: tab.title(),
          templateName: tab.customTemplateName,
          index: tab.counter,
        });
      }
    }

    state.tabs = tabs;
  },

  setActiveWindowHTML(state, windowHTML) {
    state.activeWindowHTML = windowHTML;
  },
  setActiveWindowButtons(state, buttons) {
    state.activeWindowButtons = buttons;
  },
  setWindowHistory(state, oldWindows) {
    localStorage.setItem("oldWindows", JSON.stringify(oldWindows));

    state.oldWindows = oldWindows;
  },
};

export default mutations;
