<template>
  <ckeditor
    :editor="editor"
    v-model="editorData"
    :readonly="readonly"
    :config="editorConfig"
  ></ckeditor>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import FontColor from "@ckeditor/ckeditor5-font/src/fontcolor";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import AutoLink from "@ckeditor/ckeditor5-link/src/autolink";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import ListStyle from "@ckeditor/ckeditor5-list/src/liststyle";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageBlock from "@ckeditor/ckeditor5-image/src/imageblock";
import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert";

export default {
  name: "CKeditor",
  props: {
    value: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    // Use the <ckeditor> component in this view.
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: "<p>Content of the editor.</p>",
      editorConfig: {
        ckfinder: {
          options: {
            resourceType: "Images",
          },
        },
        plugins: [
          AutoLink,
          Image,
          ImageToolbar,
          ImageCaption,
          ImageStyle,
          FontColor,
          ImageResize,
          ImageInsert,
          Heading,
          EssentialsPlugin,
          BoldPlugin,
          ListStyle,
          Underline,
          Strikethrough,
          ItalicPlugin,
          LinkPlugin,
          ParagraphPlugin,
          Base64UploadAdapter,
          Table,
          TableToolbar,
          ImageBlock,
        ],
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
            {
              model: "heading6",
              view: "h6",
              title: "Heading 6",
              class: "ck-heading_heading6",
            },
          ],
        },
        image: {
          toolbar: [
            "imageStyle:block",
            "imageStyle:side",
            "|",
            "toggleImageCaption",
            "imageTextAlternative",
          ],
        },
        fontColor: {
          colors: [
            {
              color: "hsl(0, 75%, 60%)",
              label: "Red",
            },
            {
              color: "hsl(30, 75%, 60%)",
              label: "Orange",
            },
            {
              color: "hsl(60, 75%, 60%)",
              label: "Yellow",
            },
            {
              color: "hsl(90, 75%, 60%)",
              label: "Light green",
            },
            {
              color: "hsl(120, 75%, 60%)",
              label: "Green",
            },
            {
              color: "hsl(240, 100%, 50%)",
              label: "Blue",
            },
            {
              color: "hsl(0, 0%, 0%)",
              label: "Black",
            },
            {
              color: "hsl(0, 0%, 30%)",
              label: "Dim grey",
            },
            {
              color: "hsl(0, 0%, 60%)",
              label: "Grey",
            },
            {
              color: "hsl(0, 0%, 90%)",
              label: "Light grey",
            },
            {
              color: "hsl(0, 0%, 100%)",
              label: "White",
              hasBorder: true,
            },

            // ...
          ],
        },
        fontBackgroundColor: {
          colors: [
            {
              color: "hsl(0, 75%, 60%)",
              label: "Red",
            },
            {
              color: "hsl(30, 75%, 60%)",
              label: "Orange",
            },
            {
              color: "hsl(60, 75%, 60%)",
              label: "Yellow",
            },
            {
              color: "hsl(90, 75%, 60%)",
              label: "Light green",
            },
            {
              color: "hsl(120, 75%, 60%)",
              label: "Green",
            },
            {
              color: "hsl(0, 75%, 60%)",
              label: "Red",
            },
            {
              color: "hsl(30, 75%, 60%)",
              label: "Orange",
            },
            {
              color: "hsl(60, 75%, 60%)",
              label: "Yellow",
            },
            {
              color: "hsl(90, 75%, 60%)",
              label: "Light green",
            },
            {
              color: "hsl(120, 75%, 60%)",
              label: "Green",
            },
            {
              color: "hsl(240, 100%, 50%)",
              label: "Blue",
            },

            // ...
          ],
        },
        link: {
          // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
          addTargetToExternalLinks: true,

          // Let the users control the "download" attribute of each link.
          decorators: [
            {
              mode: "manual",
              label: "Downloadable",
              attributes: {
                download: "download",
              },
            },
          ],
        },
        toolbar: {
          items: [
            "Heading",
            "|",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "fontColor",
            "|",
            "insertTable",
            "|",
            "undo",
            "redo",
          ],
        },
      },
    };
  },
  created() {
    this.editorData = this.value;
  },
  watch: {
    value() {
      this.editorData = this.value;
    },
    editorData(newValue) {
      this.$emit("input", newValue);
    },
  },
};
</script>

<style>
.ck.ck-sticky-panel {
  margin: 7px;
  margin-bottom: -1px;
}
</style>
