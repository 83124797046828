import $ from "jquery";
import serializeForm from "form-serialize";
import Formatter from "../model/formatter";
import Combobox from "../interface/combobox.class";
import ComboboxUI from "../interface/combobox";
import Hook from "../hook";
import customParseFormat from "dayjs/plugin/customParseFormat";

import dayjs from "dayjs";
dayjs.extend(customParseFormat);

/** Rent Order Item / Quickrent */
class PartialDeliveryHook extends Hook {
  /**
   * Setup values
   * @param {Window} window - Window
   * @returns {Promise} Promise
   */
  async afterProcess(window) {
    if (!window.output.Data || window.output.Data.Type != "partialdelivery") {
      return;
    }

    window.bulkedit =
      window.output.Request.Criteria != null &&
      window.output.Request.Criteria.length > 0 &&
      Object.keys(window.output.Request.Criteria[0]).length >= 2;
    window.customData.isNewObject = !window.bulkedit;
    window.options.customKeyEvents = false;
    window.renderOptions.customButtons = true;
    window.customData.EnableSelectAll = true;

    window.quickrentSubmit = quickrentSubmit;
    window.viewAvailability = viewAvailability;
    window.renumberRows = renumberRows;
    window.getRow = getRow;

    window.output.saveAction =
      "/Admin/WebServices/RentWebServices.asmx/DeliverOrderItems";
    let newSaveAction = window.output.Buttons.find(function (button) {
      return button.WebMethod == "DeliverOrderItems";
    });
    if (newSaveAction) {
      window.output.saveAction = `${newSaveAction.WebService}/${newSaveAction.WebMethod}`;
    }

    window.output.Buttons = [
      {
        Event: "quickrent-submit",
        Title: window.session.translations.Save,
      },
      {
        Event:
          "action:CloseRentForm:Rental.virtual_QuickRent:../../Admin/WebServices/RentWebServices.asmx:CloseRentForm",
        Title: window.session.translations.Cancel,
      },
    ];

    for (let column of window.output.Table.Columns) {
      if (column.Dropdown != null && column.Dropdown.TableName != null) {
        column.Combobox = Combobox.new(null, {
          name: column.Name,
          tableName: column.Dropdown.TableName,
          columnName: column.Dropdown.ColumnName,
          openRef: column.Dropdown.OpenRef,
        });
      }
    }

    let defaultCustomData = {
      Columns: window.output.Table.Columns.filter(
        (x) => window.output.Data.HiddenColumns.indexOf(x.Name) < 0,
      ),
      ColumnsExtra: window.output.Table.Columns.filter(
        (x) => window.output.Data.HiddenColumns.indexOf(x.Name) >= 0,
      ),
      initialDate: dayjs().format("DD-MM-YYYY"),
      CanAddNewItems: window.output.Data.CanAddNewItems,
    };

    for (let i in defaultCustomData) {
      window.customData[i] = defaultCustomData[i];
    }
  }

  /**
   * Set combobox functionality
   * @param {Window} window - Window
   * @returns {Promise} Promise
   */
  async afterRender(window) {
    if (
      !window.output.Data ||
      window.output.Data.Type != "partialdelivery" ||
      window.customInit
    ) {
      return;
    }
    window.customInit = true;

    window.toggleLoading(true);

    if (window.output.Data.Customer != null) {
      setCustomer(
        window,
        window.output.Data.Customer.Value,
        window.output.Data.Customer.Text,
      );
    }

    window.loading = false;

    await window.render();

    window.toggleLoading(false);

    if (window.output.Data.Rows != null && window.output.Data.Rows.length > 0) {
      addRows(
        window.element,
        window.output.Data.Columns,
        window.output.Data.Rows,
        window.output.Data.Metadata,
        null,
        window,
        true,
      );
      let $lastrow = $(window.element).find(".table-row-group:last-child");
      $lastrow.remove();

      if (window.output.Data.Type !== "partialdelivery") {
        fixRowNumbers(window);
      }
    }

    // Disable all previous Window events.
    $(window.element).off();

    $(window.element).on(
      "change",
      "[name='DeliveryAmount[]']",
      async function (e) {
        await new Promise((resolve) => setTimeout(resolve, 100));

        let name = $(this).attr("name").split(/\[|\]/g).shift();
        let $row = $(this).closest(".table-row-group");
        let data = getRow($row.get(0), window);

        if (!data || (!data.ItemID && !data.StaticItemID)) {
          return;
        }
        let deliveryAmount = parseInt(data.DeliveryAmount);
        let amount = parseInt(data.Amount);
        let selectRow = true;

        if (
          isNaN(data.DeliveryAmount) ||
          !data.DeliveryAmount ||
          deliveryAmount < 0
        ) {
          deliveryAmount = "";
          selectRow = false;
        } else if (deliveryAmount > amount) {
          deliveryAmount = $row.find("[name='Amount[]']")[0].textContent;
        }
        $row.find("input[title='Select']").prop("checked", selectRow);
        if (!selectRow) {
          $row.find("input[title='Select']").removeClass("dirty");
        }
        $row.find("[name='DeliveryAmount[]']").text(deliveryAmount.toString());
      },
    );

    $(window.element).on(
      "change",
      ".table-row .selection-box input",
      function (e, arg) {
        let $row = $(this).closest(".table-row");
        if (!$row.length) {
          return;
        }
        let index = $row.parent().attr("row-index");
        let $amountInput = $row.find("div[name='Amount[]']");
        let $deliverAmount = $row.find("div[name='DeliveryAmount[]']");
        if ($(this).prop("checked") == false) {
          $deliverAmount.text("");
        } else if ($deliverAmount.text() != "0") {
          $deliverAmount.text($amountInput.text());
        }
      },
    );

    // $(window.element).on("click", ".table-cell.delete-row", async function (e) {
    //     if ($(this).hasClass("disabled")) {
    //         return
    //     }
    //     let $row = $(this).closest(".table-row-group")
    //     let data = getRow($row.get(0), window)

    //     let isSucces = await deleteOrderItem(data.OrderItemID, window)
    //     if(isSucces){
    //         if(!$row.is(":last-child")) {
    //             $row.remove()
    //             calculateTotals(window)
    //             // updateSelectCount(window)
    //         }
    //     }
    // })
  }
}

function getHeader(window) {
  let form = serializeForm($(window.element).find("form.form-view").get(0), {
    hash: true,
    empty: true,
    disabled: true,
  });

  if (
    form.DateTimeStart == null &&
    window.parent != null &&
    window.parent.output.FullTable.Rows[0] != null
  ) {
    let cell = window.parent.output.FullTable.Rows[0]
      .filter(
        (x) =>
          x.Column.Name == "DateTimeExpectedStart" ||
          x.Column.Name == "DateInvoice",
      )
      .pop();
    form.DateTimeStart = (cell && cell.NewValue) || cell.Value;
  }

  if (
    form.DateTimeEnd == null &&
    window.parent != null &&
    window.parent.output.FullTable.Rows[0] != null
  ) {
    let cell = window.parent.output.FullTable.Rows[0]
      .filter((x) => x.Column.Name == "DateTimeRentalEnd")
      .pop();
    if (cell != null) {
      form.DateTimeEnd = (cell && cell.NewValue) || cell.Value;
    } else {
      form.DateTimeEnd = null;
    }
  }

  form.PrimaryKey = window.customData.primaryKey || null;
  form.DateTimeStart = form.DateTimeStart
    ? dayjs(form.DateTimeStart, "DD-MM-YYYY").format("YYYY-MM-DD")
    : null;
  form.DateTimeEnd = form.DateTimeEnd
    ? dayjs(form.DateTimeEnd, "DD-MM-YYYY").format("YYYY-MM-DD")
    : null;

  if (form.CustomerID == null && window.parent != null) {
    form.CustomerID = window.parent.output.FullTable.Rows[0].filter(
      (x) => x.Column.Name == "CustomerID",
    )[0].Value;
  }

  if (
    form.DefaultPeriodID == null &&
    window.parent != null &&
    window.parent.output.FullTable.Rows[0] != null
  ) {
    let cell = window.parent.output.FullTable.Rows[0]
      .filter((x) => x.Column.Name == "DefaultPeriodID")
      .pop();
    if (cell != null) {
      form.DefaultPeriodID = (cell && cell.NewValue) || cell.Value;
    } else {
      form.DefaultPeriodID = null;
    }
  }
  return form;
}

function getTypes(window) {
  let columnInfo = {};

  for (let info of window.output.Table.Columns) {
    columnInfo[info.Name] = info;
  }
  return columnInfo;
}

function fixRowNumbers(window) {
  let min = 0;

  $(window.element)
    .find(".table-body .table-row-group [name='Ranking[]']")
    .each(function (index) {
      let $row = $(this).closest(".table-row");
      if (!$row.is(".added-row")) {
        return;
      }
      let n = $(this).text();
      n = typeof n !== "undefined" ? n.trim() : n;
      n = typeof n === "undefined" || n === "" ? 0 : Number(n);
      if (n <= min) {
        min = Math.floor(min / 10) * 10 + 10;
        $(this).text(min);
      } else {
        min = n;
      }
    });

  calculateTotals(window);
}

function getRows(window) {
  let rows = [];

  $(window.element)
    .find(".table-body .table-row-group")
    .each(function () {
      if ($(this).find("input[title='Select']").prop("checked") == false) {
        return;
      }
      rows = rows.concat(getRow(this, window) || []);
    });
  return rows;
}

function getRow(el, window) {
  let data = {};
  let dirty = false;
  let dimensions = [];
  let columnInfo = getTypes(window);

  $(el)
    .find("[name]")
    .each(function () {
      let name = $(this).attr("name").split(/\[|\]/g).shift();

      if (!name) {
        return;
      }

      let value = !$(this).is("input") ? $(this).text() : $(this).val();

      if (typeof $(this).data("override-value") != "undefined") {
        value = $(this).data("override-value");
      }

      if (value) {
        dirty = true;
      }

      if (value.indexOf("OBJECTDEF:") != 0) {
        let info = columnInfo[name] || {};

        if (
          info.Type === "Decimal" ||
          info.Type === "Money" ||
          info.Type === "Money"
        ) {
          value = Number(value) || 0;
        } else if (info.Type === "DateTime" || info.Type === "Date") {
          value = value
            ? dayjs(value, "DD-MM-YYYY").format("YYYY-MM-DD")
            : null;
        } else {
          value =
            value || (value === false || value === "false" ? false : null);
          value = typeof value === "string" ? value.trim() : value;
        }
      } else {
        value = JSON.parse(value.slice("OBJECTDEF:".length));
      }

      data[name] = value;

      if (name.indexOf("DimensionID-") !== -1 && value != null) {
        let dimension = {DimensionObjectID: value};
        dimensions.push(dimension);
        delete data[name];
        data.Dimensions = dimensions;
      }
    });

  return dirty && data;
}

function fillInputs(el, row, columnInfo, window, initialData) {
  let fullReadOnly =
    Object.keys(columnInfo || {}).filter((x) => !columnInfo[x].IsReadOnly)
      .length == 0;
  $(el).find(".delete-row").toggleClass("disabled", fullReadOnly);
  $(el).find(".table-row").removeClass("new-row").addClass("added-row");

  let supportedNames = [];

  $(el)
    .find("[name]")
    .each(function () {
      let name = $(this).attr("name").replace(/\[\]$/, "");

      // if no name is found, or if the name is DimensionID AND it already has a value, do not overwrite.
      if (
        !name ||
        (name.indexOf("DimensionID-") != -1 && $(this).attr("value") != "")
      ) {
        return;
      }

      let column = columnInfo[name] || {};
      let value =
        (row[name] != null && typeof row[name] == "object"
          ? "OBJECTDEF:" + JSON.stringify(row[name])
          : Formatter.parseValue(column, row[name])) || String();

      supportedNames.push(name);

      if ($(this).parent().is(".combobox")) {
        $(this)
          .parent()
          .find(".combobox-input")
          .val(column.Description || "");
        $(this).parent().toggleClass("disabled", column.IsReadOnly);
        $(this)
          .parent()
          .find(".combobox-input")
          .prop("disabled", column.IsReadOnly);
      }

      if (column.Dropdown != null && column.Dropdown.Items != null) {
        $(this).data("override-value", value);
        let description = (
          column.Dropdown.Items.filter((x) => x.Value == value)[0] || {}
        ).Text;
        $(this).text(description);
      } else if ($(this).is("input")) {
        $(this).val(value);
        $(this).prop("disabled", Boolean(column.IsReadOnly));
      } else {
        $(this).text(value);
        $(this).prop("contenteditable", !column.IsReadOnly);
      }

      $(this).data("server-value", value);

      if (initialData) {
        $(this).data("initial-data", true);
      }
    });

  for (let name in row) {
    if (supportedNames.indexOf(name) === -1) {
      let value =
        (row[name] != null && typeof row[name] == "object"
          ? "OBJECTDEF:" + JSON.stringify(row[name])
          : Formatter.parseValue(columnInfo[name] || {}, row[name])) ||
        String();
      $(el).append(
        $("<input>").prop("type", "hidden").prop("name", name).val(value),
      );
    }
  }
}
function correctTableHeaders(window) {
  $(".table-view", window.element).each(function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let tableElement = this;
    let headerRow = $(
      ".table-index .table-row:first-child .table-cell",
      tableElement,
    );
    let firstContentRow = $(
      ".table-body .table-row:first-child .table-cell",
      tableElement,
    );

    headerRow.each(function () {
      let index = $(this).index();
      let cell = firstContentRow.eq(index);

      if (!cell || !cell.length) {
        return;
      }
      let width = cell.outerWidth();
      $(this).css({
        width: width,
        "min-width": width,
        "max-width": width,
      });
    });
  });
}

function addRows(
  container,
  columnInfo,
  rows,
  metadata,
  source,
  window,
  initialData,
) {
  for (let i = 0; i < rows.length; i++) {
    let row = rows[i];

    if (
      row.Amount &&
      String(row.Amount).indexOf(",") !== -1 &&
      String(row.Amount).indexOf(".") === -1
    ) {
      row.Amount = row.Amount.replace(/,/g, ".");
    }

    let $lastRow = $(container).find(".table-row-group:last-child");
    let $newRow = $lastRow.clone();
    $newRow.attr("row-index", i);

    let $placerow =
      source && $(source).attr("name") === "ItemID[]"
        ? $(source).closest(".table-row-group")
        : $lastRow;

    $newRow.find(".dropdown.combobox").each(function () {
      // Create new combobox object for every new row, every dropdown
      let previousCb = ComboboxUI.getClass($(this));

      let newCb = Combobox.new(null, {
        name: previousCb.specification.name,
        tableName: previousCb.specification.tableName,
        columnName: previousCb.specification.columnName,
        openRef: previousCb.specification.openRef,
      });

      $(this).attr("data-id", newCb.id);
    });

    fillInputs(
      $newRow.get(0),
      row,
      Formatter.applyMetadataRow(columnInfo, metadata && metadata[i]),
      window,
      initialData,
    );

    $newRow.insertBefore($placerow);
    correctTableHeaders(window);
  }
}

function addNewRow(el) {
  let $old = $(el).find(".table-row-group:last-child");

  if ($old.length < 0) {
    return;
  }

  let $trClone = $old.clone();
  $trClone.find("[contenteditable]").html("");
  $trClone.find("select").val("");
  $trClone.removeClass("dirty");
  $trClone.find("input").val(String());

  $trClone.find(".dropdown.combobox").each(function () {
    // Create new combobox object for every new row, every dropdown
    let previousCb = ComboboxUI.getClass($(this));

    let newCb = Combobox.new(null, {
      name: previousCb.specification.name,
      tableName: previousCb.specification.tableName,
      columnName: previousCb.specification.columnName,
      openRef: previousCb.specification.openRef,
    });

    $(this).attr("data-id", newCb.id);
  });
  $trClone.insertAfter($old);
  return $trClone;
}

function sumTotal(window, name) {
  let total = 0;

  $(window.element)
    .find("[name='" + name + "[]'], [name='" + name + "']")
    .each(function () {
      total +=
        Number($(this).is("input") ? $(this).val() : $(this).text()) || 0;
    });

  return total;
}

function calculateTotals(window) {
  $("[data-total-price]").text(sumTotal(window, "TotalExcVAT").toFixed(2));
  $("[data-total-btw]").text(
    Math.max(
      0,
      sumTotal(window, "TotalIncVAT") - sumTotal(window, "TotalExcVAT"),
    ).toFixed(2),
  );
  $("[data-total-btw-price]").text(sumTotal(window, "TotalIncVAT").toFixed(2));
}

// function updateSelectCount(window) {
//     let rows = getRows(window)

//     $("[data-select-count]").text(rows.length)
// }

async function quickrentSubmit(formType) {
  let rows = getRows(this);
  for (let row of rows) {
    for (let key in row) {
      if (row[key] && row[key].indexOf && row[key].indexOf("/Date(") === 0) {
        row[key] = dayjs(row[key]).format("YYYY-MM-DD HH:mm:ss");
      }
    }

    for (let column of this.output.Table.Columns) {
      if (column.IsRequired) {
        // dimension specific logic, because when an dimension value is entered, a dimensions object is created instead of copying the column to the row with a value
        if (
          row.hasOwnProperty(column.Name) &&
          column.Name.indexOf("DimensionID-") === 0
        ) {
          let requiredFieldMessage =
            window.session.translations.ExceptionRequiredFieldNotGiven;
          this.message(
            "error",
            requiredFieldMessage.replace("{}", column.Title),
          );
          return;
        }
      }
    }
  }

  // let headerData = getHeader(this)

  let opts = {
    request: this.output.Request,
    orderItems: rows,
  };

  try {
    // Set action args to save, to prevent the dirty pop-up
    session.activeWindow.lastActionArgs = ["Save"];

    let result = await this.session.request(this.output.saveAction, opts);
    Promise.resolve(this.handleActionResponse(result));
  } catch (err) {
    this.message(
      "error",
      err.data !== undefined ? err.data.Error : err.message,
    );
  }
}

async function viewAvailability(mode) {
  let req = {
    Subject: "Rental.virtual_Availability",
    Prefix: "View",
    Criteria: [],
  };

  if (mode == "category") {
    let categoryID = $(this.element).find("[name=CategoryID]").val();
    if (categoryID != "") {
      req.Criteria = [{CategoryID: categoryID}];
    } else {
      return this.message("error", window.session.translations.ChooseCategory);
    }
  } else {
    let itemIDs = $(this.element)
      .find("[name='ItemID[]']")
      .toArray()
      .map((x) => $(x).text());
    // Get all DateTimeExpectedEnds, filter out ones which are not entered for example with a Sales/use article.
    let expectedEnds = $(this.element)
      .find("[name='DateTimeExpectedEnd[]']")
      .toArray()
      .map((dateField) => $(dateField).text())
      .filter((dateValue) => dateValue != "");
    let momentDates = [];
    // Convert all dates into moment objects and push them into an array
    expectedEnds.forEach((date) =>
      momentDates.push(dayjs(Formatter.serializers.DateTime(date))),
    );

    // use the dayjs.max() function to get the biggest date.
    let maxDate = dayjs.max(momentDates);

    let rentalStarts = $(this.element)
      .find("[name='DateTimeExpectedStart[]']")
      .toArray()
      .map((dateField) => $(dateField).text())
      .filter((dateValue) => dateValue != "");
    let startMomentDates = [];
    // Convert all dates into moment objects and push them into an array
    rentalStarts.forEach((date) =>
      startMomentDates.push(dayjs(Formatter.serializers.DateTime(date))),
    );

    // use the dayjs.max() function to get the biggest date.
    let minDate = dayjs.min(startMomentDates);

    // if itemIDs contains more than 1 value, we have one inserted ItemID and one empty from the
    // new row below. Then we use them as criteria, otherwise we show the user an error.
    if (itemIDs.length > 1) {
      req.Criteria = [
        {
          ItemID: itemIDs,
          StartDate: Formatter.serializers.DateTime(minDate), // serialize the date into the format YYYY-MM-DD otherwise the server cannot handle it.
          EndDate: Formatter.serializers.DateTime(maxDate), // serialize the date into the format YYYY-MM-DD otherwise the server cannot handle it.
        },
      ];
    } else {
      return this.message("error", window.session.translations.ChooseItem);
    }
  }

  await this.session.openWindow(req);
}

async function renumberRows() {
  let customRanking = 0;
  $(this.element)
    .find(".table-row-group")
    .not(":last-child")
    .each(function () {
      $(this)
        .find("[name='Ranking[]']")
        .text((customRanking += 10));
    });
}

async function setCustomer(window, customerID, customerName) {
  window.customData.CustomerID = customerID;
  window.customData.CustomerIDName = customerName;
  window.bulkedit = true;

  await window.render();

  $("[name=Reference], [name=DateTimeStart]").prop("disabled", false);
}

/** @ignore */
export default new PartialDeliveryHook();
