
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        
        
        const __nunjucks_template_import__dep_0 = require("../elements/combobox.html");
        
        const __nunjucks_template_import__dep_1 = require("./table.html");
        
        const __nunjucks_template_import__dep_2 = require("./form.html");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.assets};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/quickscan.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("views/elements/combobox.html", false, "views/content/quickscan.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("combo", t_1);
output += "\n";
env.getTemplate("views/content/table.html", false, "views/content/quickscan.html", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("table", t_4);
output += "\n";
env.getTemplate("views/content/form.html", false, "views/content/quickscan.html", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
context.setVariable("form", t_7);
output += "\n\n<div class=\"content\">\n\t<form class=\"form-view grid row\">\n\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"isNewObject")) {
output += "\n\t\t\n\t\t\t<div class=\"col col-xs-12 col-sm-6 col-md-3\">\n\t\t\t\t<label class=\"form-field\">\n\t\t\t\t\t<div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Type"), env.opts.autoescape);
output += ": <span class=\"required-indicator\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"RequiredField"), env.opts.autoescape);
output += "\">*</span></div>\n\t\t\t\t\t<div class=\"form-input required-field\">\n\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 12, colno = 32, runtime.callWrap(runtime.memberLookup((t_1),"constructCombobox"), "combo[\"constructCombobox\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"Comboboxes")),"OrderType")])), env.opts.autoescape);
output += "\n\t\t\t\t\t</div>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"col col-xs-12 col-sm-6 col-md-3\">\n\t\t\t\t<label class=\"form-field\">\n\t\t\t\t\t<div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Customer"), env.opts.autoescape);
output += ": <span class=\"required-indicator\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"RequiredField"), env.opts.autoescape);
output += "\">*</span></div>\n\t\t\t\t\t<div class=\"form-input required-field\">\n\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 21, colno = 32, runtime.callWrap(runtime.memberLookup((t_1),"constructCombobox"), "combo[\"constructCombobox\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"Comboboxes")),"CustomerID")])), env.opts.autoescape);
output += "\n\t\t\t\t\t</div>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"col col-xs-12 col-sm-6 col-md-3\">\n\t\t\t\t<label class=\"form-field\">\n\t\t\t\t\t<div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Address"), env.opts.autoescape);
output += ": <span class=\"required-indicator\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"RequiredField"), env.opts.autoescape);
output += "\">*</span></div>\n\t\t\t\t\t<div class=\"form-input required-field\">\n\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 30, colno = 32, runtime.callWrap(runtime.memberLookup((t_1),"constructCombobox"), "combo[\"constructCombobox\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"Comboboxes")),"AddressID")])), env.opts.autoescape);
output += "\n\t\t\t\t\t</div>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"col col-xs-12 col-sm-6 col-md-3\">\n\t\t\t\t<label class=\"form-field\">\n\t\t\t\t\t<div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"TransactionType"), env.opts.autoescape);
output += ": </div>\n\t\t\t\t\t<div class=\"form-input required-field\">\n\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 39, colno = 32, runtime.callWrap(runtime.memberLookup((t_1),"constructCombobox"), "combo[\"constructCombobox\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"Comboboxes")),"TransactionTypeID")])), env.opts.autoescape);
output += "\n\t\t\t\t\t</div>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<!-- CUST for Hearingcoach! -->\n\t\t\t<div class=\"col col-xs-12 col-sm-6 col-md-3\">\n\t\t\t\t<label class=\"form-field\">\n\t\t\t\t\t<div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"ResponsibleHearingCoach"), env.opts.autoescape);
output += ":</div>\n\t\t\t\t\t<div class=\"form-input required-field\">\n\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 49, colno = 32, runtime.callWrap(runtime.memberLookup((t_1),"constructCombobox"), "combo[\"constructCombobox\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"Comboboxes")),"CUST_ResponsibleHearingCoach")])), env.opts.autoescape);
output += "\n\t\t\t\t\t</div>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t";
var t_10;
t_10 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"CustomerID") != null;
frame.set("isDisabled", t_10, true);
if(frame.topLevel) {
context.setVariable("isDisabled", t_10);
}
if(frame.topLevel) {
context.addExport("isDisabled", t_10);
}
output += "\n\t\t\t<div class=\"col col-xs-12 col-sm-6 col-md-3\">\n\t\t\t\t<label class=\"form-field\">\n\t\t\t\t\t<div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Reference"), env.opts.autoescape);
output += ":</div>\n\t\t\t\t\t<div class=\"form-input required-field\"><input type=\"text\" name=\"Reference\" class=\"editable-text no-field\" maxlength=\"50\" ";
output += runtime.suppressValue((!runtime.contextOrFrameLookup(context, frame, "isDisabled")?"disabled":""), env.opts.autoescape);
output += "></div>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t";
;
}
output += "\n\n\t\t<div class=\"col col-xs-12 col-sm-6 col-md-3\">\n\t\t\t<label class=\"form-field\">\n\t\t\t\t<div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Category"), env.opts.autoescape);
output += ":</div>\n\t\t\t\t<div class=\"form-input required-field\">\n\t\t\t\t\t";
output += runtime.suppressValue((lineno = 68, colno = 31, runtime.callWrap(runtime.memberLookup((t_1),"constructCombobox"), "combo[\"constructCombobox\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"Comboboxes")),"CategoryID")])), env.opts.autoescape);
output += "\n\t\t\t\t</div>\n\t\t\t</label>\n\t\t</div>\n\n\t\t<div class=\"col col-xs-12 col-sm-6 col-md-3 order-scanbox\">\t\t\t\n\t\t\t\t<div class=\"form-label form-label-select\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Scan"), env.opts.autoescape);
output += ":</div>\n\t\t\t\t<div id=\"selectScanOrderItem\"></div>\n\t\t</div>\n\t</form>\n\n\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"bulkedit")) {
output += "\n\t\t<div class=\"table-view unsortable\">\n\t\t\t<form class=\"table-container\">\n\n\t\t\t\t<div class=\"table-index sticky-div\">\n\t\t\t\t\t<div class=\"table-row\">\n\t\t\t\t\t\t<!-- <div class=\"field checkbox selection-box\"><div class=\"ellipsis\"><input type=\"checkbox\" title=\"Select all\"></div></div> -->\n\t\t\t\t\t\t<div class=\"table-cell delete-row material-icons\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Delete"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t<div class=\"ellipsis\">&#xe5cd;</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class=\"table-cell action-row material-icons\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Duplicate"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t<div class=\"ellipsis\">\n\t\t\t\t\t\t\t\t<i class=\"fa fa-files-o\" aria-hidden=\"true\"></i>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t";
frame = frame.push();
var t_13 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"Columns");
if(t_13) {t_13 = runtime.fromIterator(t_13);
var t_12 = t_13.length;
for(var t_11=0; t_11 < t_13.length; t_11++) {
var t_14 = t_13[t_11];
frame.set("col", t_14);
frame.set("loop.index", t_11 + 1);
frame.set("loop.index0", t_11);
frame.set("loop.revindex", t_12 - t_11);
frame.set("loop.revindex0", t_12 - t_11 - 1);
frame.set("loop.first", t_11 === 0);
frame.set("loop.last", t_11 === t_12 - 1);
frame.set("loop.length", t_12);
output += "\n\t\t\t\t\t\t\t<div class=\"table-cell ";
output += runtime.suppressValue((runtime.memberLookup((t_14),"Type") == "Money " || runtime.memberLookup((t_14),"Type") == "Decimal " || runtime.memberLookup((t_14),"Type") == "Int\n\t\t\t\t\t\t"?" number " + runtime.memberLookup((t_14),"Type"):""), env.opts.autoescape);
output += "\" data-column=\"";
output += runtime.suppressValue(runtime.memberLookup((t_14),"Name"), env.opts.autoescape);
output += "\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((t_14),"Title"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t<div class=\"ellipsis\">\n\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((t_14),"Title"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<!-- <div class=\"field\" title=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "i"), env.opts.autoescape);
output += "\"><div class=\"ellipsis\"><input type=\"checkbox\"></div></div> -->";
;
}
}
frame = frame.pop();
output += "\n\n\t\t\t\t\t\t<div class=\"table-cell dropdown-info\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"UncollapseAll"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t<div class=\"ellipsis\" data-show-all-lower-fields>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"UncollapseAll"), env.opts.autoescape);
output += "</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"table-body bulkedit\">\n\t\t\t\t\t<div class=\"table-rows\">\n\t\t\t\t\t\t<div class=\"table-row-group\" data-row-index=\"-1\">\n\t\t\t\t\t\t\t<div class=\"table-row new-row\">\n\t\t\t\t\t\t\t\t<!-- <div class=\"field checkbox selection-box\"><input type=\"checkbox\" data-commit-checkbox=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
output += "\" title=\"Select\"></div> -->\n\t\t\t\t\t\t\t\t<div class=\"table-cell delete-row material-icons\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Delete"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t<div class=\"ellipsis\">&#xe5cd;</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div class=\"table-cell action-row material-icons\" action=\"duplicate\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Duplicate"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t<div class=\"ellipsis\">\n\t\t\t\t\t\t\t\t\t\t<i class=\"far fa-copy\" aria-hidden=\"true\"></i>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_17 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"Columns");
if(t_17) {t_17 = runtime.fromIterator(t_17);
var t_16 = t_17.length;
for(var t_15=0; t_15 < t_17.length; t_15++) {
var t_18 = t_17[t_15];
frame.set("col", t_18);
frame.set("loop.index", t_15 + 1);
frame.set("loop.index0", t_15);
frame.set("loop.revindex", t_16 - t_15);
frame.set("loop.revindex0", t_16 - t_15 - 1);
frame.set("loop.first", t_15 === 0);
frame.set("loop.last", t_15 === t_16 - 1);
frame.set("loop.length", t_16);
if(runtime.memberLookup((t_18),"Combobox")) {
output += "\n\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 125, colno = 47, runtime.callWrap(runtime.memberLookup((t_1),"constructTableRecordCombobox"), "combo[\"constructTableRecordCombobox\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),runtime.memberLookup((t_18),"Combobox"),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"),runtime.memberLookup((t_18),"Name") == "DimensionID-3"])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 127, colno = 32, runtime.callWrap(runtime.memberLookup((t_4),"setColumnCell"), "table[\"setColumnCell\"]", context, [t_18,"",runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"),"",runtime.contextOrFrameLookup(context, frame, "window")])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t\t\t<div class=\"table-cell dropdown-info material-icons\" data-toggle-lower-field>&#xe313;</div>\n\t\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t\t\t<td class=\"lower-field hide\" colspan=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"Columns")),"length") + 2, env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t\t<form class=\"form-view grid row\">\n\t\t\t\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_21 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"ColumnsExtra");
if(t_21) {t_21 = runtime.fromIterator(t_21);
var t_20 = t_21.length;
for(var t_19=0; t_19 < t_21.length; t_19++) {
var t_22 = t_21[t_19];
frame.set("col", t_22);
frame.set("loop.index", t_19 + 1);
frame.set("loop.index0", t_19);
frame.set("loop.revindex", t_20 - t_19);
frame.set("loop.revindex0", t_20 - t_19 - 1);
frame.set("loop.first", t_19 === 0);
frame.set("loop.last", t_19 === t_20 - 1);
frame.set("loop.length", t_20);
output += "<div class=\"col col-xs-12 col-sm-4 ";
output += runtime.suppressValue((runtime.memberLookup((t_22),"Name") != "Comment"?"col-md-2":""), env.opts.autoescape);
output += " ";
output += runtime.suppressValue((runtime.memberLookup((t_22),"Name") == "Comment"?"col-md-12":""), env.opts.autoescape);
output += " \">\n\t\t\t\t\t\t\t\t\t\t\t\t\t<label class=\"form-field\">\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t<div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((t_22),"Title"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t";
if(runtime.memberLookup((t_22),"IsRequired")) {
output += "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t<span class=\"required-indicator\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"RequiredField"), env.opts.autoescape);
output += "\">*</span>\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t<div class=\"form-input required-field ";
output += runtime.suppressValue((runtime.memberLookup((t_22),"Type") == "Money"?"money":""), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 145, colno = 39, runtime.callWrap(runtime.memberLookup((t_7),"createInputField"), "form[\"createInputField\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),t_22,runtime.memberLookup((t_22),"Type"),null,runtime.memberLookup((t_22),"Initial"),null,null,runtime.contextOrFrameLookup(context, frame, "window")])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t";
output += "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t\t\t\t\t</form>\n\t\t\t\t\t\t\t\t\t\t</td>\n\t\t\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</form>\n\n\t\t\t\t\t<div class=\"table-footer clearfix sticky-div stick-to-bottom\">\n\t\t\t\t\t\t<div class=\"fake-button button button-menu no-hover no-action\">\n\t\t\t\t\t\t\t<span data-select-count title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"SelectCount"), env.opts.autoescape);
output += "\">0</span>\n\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"product") == "RentMagic") {
output += "\n\t\t\t\t\t\t\t<div class=\"pull-right\">\n\t\t\t\t\t\t\t\t<div class=\"fake-button button button-menu no-hover no-action\">\n\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"TotalPriceExcVAT"), env.opts.autoescape);
output += ": <span data-total-price>0.00</span>\n\t\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t\t<div class=\"fake-button button button-menu no-hover no-action\">\n\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"TotalVAT"), env.opts.autoescape);
output += ": <span data-total-btw>0.00</span>\n\t\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t\t<div class=\"fake-button button button-menu no-hover no-action\">\n\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"TotalPriceIncVAT"), env.opts.autoescape);
output += ": <span data-total-btw-price>0.00</span>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t";
;
}
output += "\n\t\t</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/quickscan.html", ctx);
          }
        
          return nunjucks.render("views/content/quickscan.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/quickscan.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        