<template>
  <div :class="`field field-${fieldType}`">
    <label>{{ field.Title }}:</label>
    <component class="field-content" v-bind:is="fieldComponent" :field="field">
    </component>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      required: true,
      type: Object,
    },
  },
  computed: {
    fieldType() {
      return `${this.field.Editor ?? this.field.Type}${
        this.field.IsReadOnly ? "ReadOnly" : ""
      }`;
    },
    fieldComponent() {
      return () => import(`./Field${this.fieldType}.vue`);
    },
  },
};
</script>

<style scoped lang="scss">
.field-LabelSelector {
  border-top: 1px solid #eeeeee;
  padding-top: 10px;
}
.field {
  margin-bottom: 20px;
  text-align: right;

  .field-content {
    margin-left: 5px;
  }

  label {
    font-weight: 900;
    color: #626262;
  }
}
</style>
