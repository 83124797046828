import Hook from "../hook";
import store from "../../state/store";
import {showLoadingAnimationIFrameElement} from "../functions/showLoadingAnimationIFrameElement";

class IframeHook extends Hook {
  async beforeRender(window) {
    if (window.output?.Request?.Subject === "Core.virtual_Iframe") {
      //window.output.Request.Criteria[0].Url = `${window.output.Request.Criteria[0].Url}?authorization=Bearer ${store.state.accessToken}`
      if (window.output.Sub) {
        window.output.Sub.Data.Url = `${
          window.output.Request.Criteria[0].Url
        }&Authorization=Bearer ${
          store.state.accessToken
        }&Warehouse=${encodeURIComponent(store.state.activeWarehouse)}`;
        return;
      }
      window.output.Data.Url = `${
        window.output.Request.Criteria[0].Url
      }&Authorization=Bearer ${
        store.state.accessToken
      }&Warehouse=${encodeURIComponent(store.state.activeWarehouse)}`;
    }
  }
  async afterRender(eventWindow) {
    if (eventWindow.output?.Sub?.Data?.Type === "iframe") {
      showLoadingAnimationIFrameElement(
        eventWindow.parent?.element ?? eventWindow.element,
      );
    }
  }
}
export default new IframeHook();
