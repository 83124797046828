<template>
  <div
    :class="{'tabs-vue': true, 'tabs-vue-fixed': windowTop > 0, 'mt-3': true}"
    v-on:scroll.passive="handleScroll"
  >
    <div class="tab" v-if="tabs.length > 1">
      <b-button
        variant="dark"
        class="ml-0 mr-2"
        size="sm"
        data-window-target="active"
        data-window-event="dispose-all"
        ><i class="fas fa-times"></i> {{ translations.CloseAll }}
      </b-button>
    </div>
    <tab-shortcut
      v-for="tabShortcut in shortcutButtons"
      :key="tabShortcut.MenuID"
      :tabShortcut="tabShortcut"
    />

    <div class="tab divider" v-if="shortcuts.length > 0"></div>
    <tab v-for="tab of tabs" :key="tab.id" :tab="tab" :activeTab="activeTab" />
  </div>
</template>

<script>
import Tab from "./tabs/tab.vue";
import TabShortcut from "./tabs/tabShortcut.vue";

export default {
  components: {
    Tab,
    "tab-shortcut": TabShortcut,
  },
  name: "tabs",
  data: () => {
    return {
      tabsFixed: false,
      lastScrollPosition: 0,
      windowTop: 0,
    };
  },
  computed: {
    tabs: function () {
      return this.$store.getters.tabs;
    },
    translations: function () {
      return this.$store.getters.translations;
    },
    activeTab: function () {
      return this.$store.getters.activeWindowID;
    },
    shortcuts: function () {
      return this.$store.getters.shortcuts;
    },
    shortcutButtons: function () {
      return this.shortcuts.Top;
    },
  },
  methods: {
    handleScroll() {
      this.windowTop = window.top.scrollY;
      // Get the current scroll position
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 0) {
        return;
      }
      // Here we determine whether we need to show or hide the navbar
      // eslint-disable-next-line no-undef
      this.tabsFixed = e.target.scrollHeight < this.lastScrollPosition;
      // Set the current scroll position as the last scroll position
      // eslint-disable-next-line no-undef
      this.lastScrollPosition = e.target.scrollHeight;
    },
  },
};
</script>

<style scoped style="scss">
.tabs-vue-fixed {
  position: fixed;
  top: 0;
  left: 55px;
  margin-top: 0 !important;
  padding-top: 16px;
  padding-left: 16px;
  z-index: 4000;
  background-color: white;
}
.tabs-vue {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.tab {
  margin-bottom: 10px;
}

.divider {
  /* background-color: black; */
  border-right: 2px solid #e5e5e5;
  /* width: 2px; */
  height: 23px;
  margin-top: 2px;
  margin-right: 10px;
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
