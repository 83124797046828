import {api} from "./api";
import store from "../../state/store";

export async function sendMail({
  subject,
  recipients,
  reportTemplate,
  body,
  emailTemplate,
  fullTableName,
  languageId,
  primaryKey,
  primaryKeys,
  optionalRecipients,
  optionalHiddenRecipients,
} = {}) {
  const result = await api.post(
    `/v2/mail/send`,
    {
      subject,
      recipients,
      reportTemplate,
      body,
      emailTemplate,
      fullTableName,
      languageID: languageId.Value,
      primaryKey,
      primaryKeys,
      optionalRecipients,
      optionalHiddenRecipients,
    },
    {
      headers: {
        Authorization: `Bearer ${store.state.accessToken}`,
      },
    },
  );

  return result.data;
}
