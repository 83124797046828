/**
 * Unpack requires
 * @private
 * @param {Object} req - Requires
 * @param {function} wrap - Wrap function
 * @returns {Object} output
 */
function unpackReq(req, wrap) {
  let out = {};

  req.keys().forEach((key) => {
    let name = key.replace(/^\.\//, "").replace(/\.\w+$/, "");
    out[name] = req(key);
  });

  return wrap ? wrap(out) : out;
}

// Import all hooks in ./hooks/*.js and in ./hooks/PRODUCT/**/*.js
export function loadHooks() {
  unpackReq(require.context("./hooks", false, /\.js$/i));
}
