import { render, staticRenderFns } from "./tabShortcut.vue?vue&type=template&id=39f1240c&"
import script from "./tabShortcut.vue?vue&type=script&lang=js&"
export * from "./tabShortcut.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/Users/dhulsman/Projects/rentmagic.frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton, BButtonGroup} from 'bootstrap-vue'
    installComponents(component, {BButton, BButtonGroup})
    

export default component.exports