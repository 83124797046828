<template>
  <div class="actions mb-4" v-if="buttons.length > 0">
    <p class="title">{{ $store.state.translations.Actions }}</p>
    <hr />

    <template v-for="action in buttons">
      <excel-import-wizard
        v-if="action.ActionName === 'ExcelImportDialogue'"
        :key="action.ActionName"
        :title="action.Title"
        :icon="action.Icon"
        :full-table-name="subject"
        :disabled="loading"
        :block="true"
      />

      <action-setup-two-factor-q-r
        v-else-if="action.ActionName === 'CreateNewQr'"
        :key="action.ActionName"
        :title="action.Title"
        :icon="action.Icon"
        :full-table-name="subject"
        :disabled="loading"
        :block="true"
      />

      <b-button
        v-else
        :key="action.ActionName"
        :data-window-event="action.Event"
        :data-window-target="action.Target || subWindowid || window.id"
        :disabled="loading"
        block
        :variant="action.DisplayType"
        ><div class="float-left" v-html="action.Icon" />
        {{ action.Title }}
      </b-button>
    </template>
  </div>
</template>

<script>
import ExcelImportWizardVue from "../../elements/ExcelImportWizard.vue";
import ActionSetupTwoFactorQR from "./actions/ActionSetupTwoFactorQR";
export default {
  components: {
    ActionSetupTwoFactorQR,
    "excel-import-wizard": ExcelImportWizardVue,
  },
  props: {
    window: {
      required: true,
      type: Object,
    },
    subWindowid: {
      required: false,
      type: String,
      default: null,
    },
    buttons: {
      required: true,
      type: Array,
    },
    subWindow: {
      required: false,
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    subject: function () {
      return (
        this.window.output.Data?.Subject || this.subWindow.output.Data?.Subject
      );
    },
    loading: function () {
      return this.$store.state.loading;
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-weight: 600;
  font-size: 14px;
}
</style>
