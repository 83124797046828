
        
    const runtime = require("../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        const __nunjucks_template_import__dep_0 = require("../../elements/combobox.html");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/modals/modalItemAvailability.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "\n";
env.getTemplate("views/elements/combobox.html", false, "views/content/modals/modalItemAvailability.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("combo", t_1);
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
["pageNumber", "buttonName", "classes"], 
[], 
function (l_pageNumber, l_buttonName, l_classes, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("pageNumber", l_pageNumber);
frame.set("buttonName", l_buttonName);
frame.set("classes", l_classes);
var t_5 = "";t_5 += "\n    <li class=\"page-item ";
t_5 += runtime.suppressValue(l_classes, env.opts.autoescape);
t_5 += "\">\n        <button class=\"page-link\" data-pagination-page=\"";
t_5 += runtime.suppressValue(l_pageNumber, env.opts.autoescape);
t_5 += "\" href=\"#\" tabindex=\"-1\">";
t_5 += runtime.suppressValue((l_buttonName?l_buttonName:l_pageNumber), env.opts.autoescape);
t_5 += "</button>\n    </li>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("paginationPageButton");
context.setVariable("paginationPageButton", macro_t_4);
output += "\n\n<div class=\"container-fluid\" id=\"modalItemAvailabilityInitialBody\">\n    <div id=\"notifyDialog\" style=\"display:none;\" class=\"alert alert-warning alert-dismissible fade show\" role=\"alert\">\n        <span id=\"notifyDialogContent\"></span>\n        <button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\">\n            <span aria-hidden=\"true\">&times;</span>\n        </button>\n    </div>\n    <div class=\"row\">\n        ";
if(runtime.contextOrFrameLookup(context, frame, "itemID") == null) {
output += "\n            <div class=\"col-12 col-lg-4 col-xl-3\">\n                <div class=\"card\">\n                    <div class=\"card-header\">\n                        <h6 mb-0 pb-0><i class=\"fas fa-search float-right\"></i> ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"ModalDayPlannerSearchTitle"), env.opts.autoescape);
output += "</h6>\n                    </div>\n                    <div class=\"card-body\">\n                        <form name=\"availabilitySearch\"> \n                            <label class=\"form-field\">\n                                <div class=\"form-label\"><i class=\"fas fa-clipboard-list\"></i> ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"ModalDayPlannerCategoriesLabel"), env.opts.autoescape);
output += "</div>\n                                <div class=\"form-input combobox-scan\" id=\"categorySelector\">\n                                    ";
output += runtime.suppressValue((lineno = 28, colno = 62, runtime.callWrap(runtime.memberLookup((t_1),"constructCombobox"), "combo[\"constructCombobox\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "comboboxData")),"CategoryID")])), env.opts.autoescape);
output += "\n                                </div>\n                            </label>\n                            <label class=\"form-field\">\n                                <div class=\"form-label\"><i class=\"fas fa-filter\"></i> ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"ModalDayPlannerFilterByTextLabel"), env.opts.autoescape);
output += "</div>\n                                <div class=\"form-input\">\n                                    <input type=\"text\" name=\"SearchCriteria\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "searchCriteria"), env.opts.autoescape);
output += "\" class=\"form-control\">\n                                </div>\n                            </label>\n                            <div class=\"input-group\">\n                                <button class=\"btn btn-primary\" type=\"submit\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"ModalDayPlannerSearchTitle"), env.opts.autoescape);
output += "</button>\n                            </div>\n                        </form>\n                    </div>\n                </div>\n            </div>\n        ";
;
}
else {
output += "\n            <div class=\"col-12 mb-3\">\n                <button class=\"btn btn-primary pl-4\" id=\"closeDetailChart\"><i class=\"fas fa-arrow-left\"></i> ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"ModalDayPlannerBackToItemOverview"), env.opts.autoescape);
output += "</button>\n            </div>\n        ";
;
}
output += "\n        <div class=\"col d-sm-block d-md-block d-lg-none d-xl-none\">\n            <hr>\n        </div>\n        ";
if(runtime.contextOrFrameLookup(context, frame, "itemID") == null) {
output += "\n            <div class=\"col-12 col-lg-8 col-xl-9\">\n        ";
;
}
else {
output += "\n            <div class=\"col-12\">\n        ";
;
}
output += "\n            <div class=\"card\" style=\"border: none;\">\n                <div class=\"card-body\">\n                    <div id=\"chartWrapper\">\n                    \n                    </div>\n                </div>\n            </div>\n            <div class=\"mt-1\">\n                ";
if(runtime.contextOrFrameLookup(context, frame, "itemID")) {
output += "\n                    ";
var t_6;
t_6 = runtime.contextOrFrameLookup(context, frame, "secondaryPageIndex");
frame.set("pageIndex", t_6, true);
if(frame.topLevel) {
context.setVariable("pageIndex", t_6);
}
if(frame.topLevel) {
context.addExport("pageIndex", t_6);
}
output += "\n                    ";
var t_7;
t_7 = "SerialItemVisChart";
frame.set("dataNavigate", t_7, true);
if(frame.topLevel) {
context.setVariable("dataNavigate", t_7);
}
if(frame.topLevel) {
context.addExport("dataNavigate", t_7);
}
output += "\n                ";
;
}
else {
output += "\n                    ";
var t_8;
t_8 = "ItemVisChart";
frame.set("dataNavigate", t_8, true);
if(frame.topLevel) {
context.setVariable("dataNavigate", t_8);
}
if(frame.topLevel) {
context.addExport("dataNavigate", t_8);
}
output += "\n                ";
;
}
output += "\n                <div id=\"page-selection\" class=\"mr-0 pr-0 float-right\"></div>\n                <a class=\"btn btn-secondary disabled mt-2\" disabled id=\"pageResults\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "dataCount"), env.opts.autoescape);
output += "</a>\n                <a class=\"btn btn-secondary disabled mt-2\" disabled id=\"pageIndicator\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Page"), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "pageIndex"), env.opts.autoescape);
output += " / ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "pageCount"), env.opts.autoescape);
output += "</a>\n\n                <ul class=\"pagination mr-0 pr-0\">\n                    ";
if(runtime.contextOrFrameLookup(context, frame, "pageIndex") > 1) {
output += "\n                        ";
output += runtime.suppressValue((lineno = 77, colno = 47, runtime.callWrap(macro_t_4, "paginationPageButton", context, [runtime.contextOrFrameLookup(context, frame, "pageIndex") - 1,runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"ModalDayPlannerPreviousButton")])), env.opts.autoescape);
output += "\n\n                        ";
if((runtime.contextOrFrameLookup(context, frame, "pageIndex") - 2) > 1) {
output += "\n                            ";
output += runtime.suppressValue((lineno = 80, colno = 51, runtime.callWrap(macro_t_4, "paginationPageButton", context, [runtime.contextOrFrameLookup(context, frame, "pageIndex") - 3,false,"d-none d-sm-block"])), env.opts.autoescape);
output += "\n                        ";
;
}
output += "\n\n                        ";
if((runtime.contextOrFrameLookup(context, frame, "pageIndex") - 1) > 1) {
output += "\n                            ";
output += runtime.suppressValue((lineno = 84, colno = 51, runtime.callWrap(macro_t_4, "paginationPageButton", context, [runtime.contextOrFrameLookup(context, frame, "pageIndex") - 2,false,"d-none d-sm-block"])), env.opts.autoescape);
output += "\n                        ";
;
}
output += "\n\n                        ";
output += runtime.suppressValue((lineno = 87, colno = 47, runtime.callWrap(macro_t_4, "paginationPageButton", context, [runtime.contextOrFrameLookup(context, frame, "pageIndex") - 1,false,"d-none d-sm-block"])), env.opts.autoescape);
output += "\n                    ";
;
}
output += "\n\n                    ";
output += runtime.suppressValue((lineno = 90, colno = 43, runtime.callWrap(macro_t_4, "paginationPageButton", context, [runtime.contextOrFrameLookup(context, frame, "pageIndex"),false,"disabled"])), env.opts.autoescape);
output += "\n\n                    ";
if(runtime.contextOrFrameLookup(context, frame, "pageIndex") < runtime.contextOrFrameLookup(context, frame, "pageCount")) {
output += "\n                        ";
output += runtime.suppressValue((lineno = 93, colno = 47, runtime.callWrap(macro_t_4, "paginationPageButton", context, [runtime.contextOrFrameLookup(context, frame, "pageIndex") + 1,false,"d-none d-sm-block"])), env.opts.autoescape);
output += "\n\n                        ";
if(runtime.contextOrFrameLookup(context, frame, "pageIndex") < (runtime.contextOrFrameLookup(context, frame, "pageCount") - 1)) {
output += "\n                            ";
output += runtime.suppressValue((lineno = 96, colno = 51, runtime.callWrap(macro_t_4, "paginationPageButton", context, [runtime.contextOrFrameLookup(context, frame, "pageIndex") + 2,false,"d-none d-sm-block"])), env.opts.autoescape);
output += "\n                        ";
;
}
output += "\n\n                        ";
if(runtime.contextOrFrameLookup(context, frame, "pageIndex") < (runtime.contextOrFrameLookup(context, frame, "pageCount") - 2)) {
output += "\n                            ";
output += runtime.suppressValue((lineno = 100, colno = 51, runtime.callWrap(macro_t_4, "paginationPageButton", context, [runtime.contextOrFrameLookup(context, frame, "pageIndex") + 3,false,"d-none d-sm-block"])), env.opts.autoescape);
output += "\n                        ";
;
}
output += "\n                    \n                        ";
output += runtime.suppressValue((lineno = 103, colno = 47, runtime.callWrap(macro_t_4, "paginationPageButton", context, [runtime.contextOrFrameLookup(context, frame, "pageIndex") + 1,runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"ModalDayPlannerNextButton"),"mr-0"])), env.opts.autoescape);
output += "\n                        </li>\n                    ";
;
}
output += "\n                </ul>\n            </div>\n        </div>\n    </div>\n</div>\n<!-- /container -->\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/modals/modalItemAvailability.html", ctx);
          }
        
          return nunjucks.render("views/content/modals/modalItemAvailability.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/modals/modalItemAvailability.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        