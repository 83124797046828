import $ from "jquery";
import Canvas from "./interface/canvas.js";
import Cookies from "js-cookie";
import Hook from "./hook";
import NotificationQueue from "./interface/notificationQueue";
import Session from "./model/session";
import interfaceTemplate from "../views/interface.vue";
import Vue from "./interface/vue";
import store from "../state/store";
import "core-js/stable";
import "regenerator-runtime/runtime";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { faGoogle } from "@fortawesome/free-brands-svg-icons/faGoogle";
import { faYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube";
import { faSearch } from "@fortawesome/pro-duotone-svg-icons/faSearch";
import { faWarehouse } from "@fortawesome/pro-duotone-svg-icons/faWarehouse";
import { faExpandArrows } from "@fortawesome/pro-duotone-svg-icons/faExpandArrows";
import { faCompressArrowsAlt } from "@fortawesome/pro-duotone-svg-icons/faCompressArrowsAlt";
import { faSlidersVSquare } from "@fortawesome/pro-light-svg-icons/faSlidersVSquare";
import { faHandshake } from "@fortawesome/pro-light-svg-icons/faHandshake";
import { faForklift } from "@fortawesome/pro-light-svg-icons/faForklift";
import { faBox } from "@fortawesome/pro-light-svg-icons/faBox";
import { faCogs } from "@fortawesome/pro-light-svg-icons/faCogs";
import { faFileChartPie } from "@fortawesome/pro-light-svg-icons/faFileChartPie";
import { faInfoSquare } from "@fortawesome/pro-light-svg-icons/faInfoSquare";
import { loadHooks } from "./hooks";
import packageJson from "../package.json";
import { resetColumns, resetTranslations, resetAll, resetSettings, resetRoles, } from "./services/maintenance";
import "./styling";
library.add(fas, faYoutube, faSearch, faWarehouse, faExpandArrows, faCompressArrowsAlt, faInfoSquare, faSlidersVSquare, faHandshake, faForklift, faBox, faCogs, faFileChartPie, faGoogle);
dom.watch();
import introjs from "intro.js";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
if (window.location.hostname !== "localhost") {
    // SENTRY PAS OP
    Sentry.init({
        release: packageJson.version,
        dsn: "https://6e58f28b7bf44698a84a86777c206ae7@sentry.rentmagic.net/3",
        integrations: [new Integrations.BrowserTracing()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.0,
    });
    // EINDE SENTRY PAS NIETMEER OP
}
// Create session and canvas
/**
 * Global session object
 * @type {Session}
 * @global
 */
let session;
let canvas;
// Initialize global broadcastChannel to listen for events send by other tabs in the asme browser
$(window).on("storage", message_receive);
async function initialize() {
    // Create session and canvas
    /**
     * Global session object
     * @type {Session}
     * @global
     */
    session = new Session(String());
    canvas = new Canvas(session);
    session.canvas = canvas;
    // Set session globally
    global.session = session;
    // Set hook globally
    global.hook = Hook;
    // Set jquery globally
    global.$ = $;
    // Set introjs globally
    global.introjs = introjs;
    global.online = true;
    global.tools = {
        resetColumns,
        resetTranslations,
        resetAll,
        resetSettings,
        resetRoles,
    };
    new Vue({
        store,
        render: (h) => h(interfaceTemplate),
    }).$mount("#app");
}
function message_receive(ev) {
    if (ev.originalEvent.key != "global-window-event")
        return; // ignore other keys
    const message = JSON.parse(ev.originalEvent.newValue);
    if (!message)
        return; // ignore empty msg or msg reset
    if (session.activeWindow) {
        const localFn = session.activeWindow[message.command];
        if (typeof localFn !== "function") {
            console.error(new Error("Broadcasted event not bound to a window-function"));
            return;
        }
        localFn.call(session.activeWindow, ...message.data);
    }
}
/**
 * Enable password viewer
 * @private
 * @returns {void}
 */
function enablePasswordViewer() {
    $(document)
        .on("mousedown", ".login .show-password", function () {
        $(this).closest(".form-element").find("input").prop("type", "text");
    })
        .on("mouseup mouseleave", ".login .show-password", function () {
        $(this).closest(".form-element").find("input").prop("type", "password");
    });
}
/**
 * Save current environment in cookie, to be used in website
 * @private
 * @returns {void}
 */
function setEnvironmentCookie() {
    const url = window.location.hostname;
    const parts = url.split(".").reverse();
    const domain = parts[1] + "." + parts[0];
    const EXP_MAX = Math.pow(2, 31) - 1;
    let str = Cookies.get("live_envs") || "";
    if (str.indexOf(parts[2]) == -1) {
        if (str) {
            str += "|";
        }
        str += parts[2];
        Cookies.set("live_envs", str, {
            expires: EXP_MAX,
            domain: "." + domain,
        });
    }
}
loadHooks();
/**
 * Load banner
 * @private
 * @returns {void}
 */
function load() {
    $("body").addClass("loaded");
}
initialize();
// Startup logic
enablePasswordViewer();
setEnvironmentCookie();
load();
async function notificationCheckTimer() {
    let notificationID = 0;
    const notificationQueue = new NotificationQueue(notificationID);
    // Interval of 5 sec for fetching notifications.
    window.setInterval(async () => {
        if (global.session.username && store.state.accessToken) {
            await notificationQueue.render();
            notificationID = notificationQueue.getNotificationID();
        }
    }, 15000);
}
// Start notification checker
notificationCheckTimer();
