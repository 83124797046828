import $, {data} from "jquery";
import clone from "clone";
import Combobox from "../interface/combobox.class";
import ComboboxUI from "../interface/combobox";
import Formatter from "../model/formatter";
import Hook from "../hook";
import ModalItemAvailability from "./Modals/modalItemAvailability";
import {unixMilisecondsToDateString} from "../util/formatting/unix";
import store from "../../state/store";

import swal from "sweetalert";

import serializeForm from "form-serialize";

// Vue related
import Vue from "vue";
import Modal from "../../views/interface/modal.vue";
import SelectScanOrderItem from "../../views/elements/select/SelectScanOrderItem.vue";
import {ButtonPlugin, ModalPlugin} from "bootstrap-vue";
import customParseFormat from "dayjs/plugin/customParseFormat";

import dayjs from "dayjs";

dayjs.extend(customParseFormat);

import {notify} from "../util/notify";

Vue.use(ButtonPlugin);
Vue.use(ModalPlugin);

Vue.config.devtools = true;

/** Rent Order Item / Quickrent */
class RentOrderItemHook extends Hook {
  /**
   * Setup values
   * @param {Window} window - Window
   * @returns {Promise} Promise
   */
  async afterProcess(window) {
    if (!window.output.Data || window.output.Data.Type != "rentform") {
      return;
    }

    window.bulkedit =
      window.output.Request.Criteria != null &&
      window.output.Request.Criteria.length > 0 &&
      Object.keys(window.output.Request.Criteria[0]).length >= 2;
    window.customData.isNewObject = !window.bulkedit;
    window.options.customKeyEvents = false;
    window.renderOptions.customButtons = true;

    window.quickrentSubmit = quickrentSubmit;
    window.viewAvailability = viewAvailability;
    window.renumberRows = renumberRows;
    window.getRow = getRow;

    window.output.Buttons = [
      {
        Event: "quickrent-submit",
        Title: window.session.translations.Save,
      },
      {
        Event:
          "action:CloseRentForm:Rental.virtual_QuickRent:../../Admin/WebServices/RentWebServices.asmx:CloseRentForm",
        Title: window.session.translations.Cancel,
      },
    ];

    if (window.output.Data.DisplayItemAndCategoryAvailabilityButtons) {
      window.output.Buttons.push({
        Event: "view-availability:category",
        Title: window.session.translations.CategoryAvailability,
      });
    }

    window.output.Buttons.push({
      Event: "renumber-rows",
      Title: window.session.translations.RenumberRows,
    });

    for (let column of window.output.Table.Columns) {
      if (
        column.Dropdown != null &&
        (column.Dropdown.TableName != null || column.Dropdown.Items != null)
      ) {
        column.Combobox = Combobox.new(null, {
          name: column.Name,
          nullable: !column.IsRequired,
          type: column.Editor ?? "list",
          tableName: column.Dropdown.TableName,
          columnName: column.Dropdown.ColumnName,
          openRef: column.Dropdown.OpenRef,
          items: column.Dropdown.Items,
        });

        if (column.Dropdown.Items) {
          column.Combobox.populate(column.Dropdown.Items);
        }
      }
    }

    window.customData.QuickRentColumns = new Array();

    for (let col in window.output.Data.QuickRentColumns) {
      let column = window.output.Data.QuickRentColumns[col];
      column.IsReadOnly = true;

      if (
        column.Dropdown != null &&
        (column.Dropdown.TableName != null || column.Dropdown.Items != null)
      ) {
        column.Combobox = Combobox.new(null, {
          name: column.Name,
          readOnly: true,
          nullable: !column.IsRequired,
          type: column.Editor ?? "list",
          tableName: column.Dropdown.TableName,
          columnName: column.Dropdown.ColumnName,
          openRef: column.Dropdown.OpenRef,
          items: column.Dropdown.Items,
        });
      }
      window.customData.QuickRentColumns.push(column);
    }

    let defaultCustomData = {
      Comboboxes: {
        CustomerID: Combobox.new(null, {
          name: "CustomerID",
          tableName: "Customer.Customer",
          columnName: "CustomerID",
          openRef: false,
        }),
        CategoryID: Combobox.new(null, {
          name: "CategoryID",
          nullable: true,
          tableName: "Rental.Category",
          columnName: "CategoryID",
          openRef: false,
        }),
      },
      QuickRentColumns: window.customData.QuickRentColumns,
      Columns: window.output.Table.Columns.filter(
        (x) => window.output.Data.HiddenColumns.indexOf(x.Name) < 0,
      ),
      ColumnsExtra: window.output.Table.Columns.filter(
        (x) => window.output.Data.HiddenColumns.indexOf(x.Name) >= 0,
      ),
      initialDate: dayjs().format("DD-MM-YYYY"),
      CanAddNewItems: window.output.Data.CanAddNewItems,
      EndDateRequired: window.output.Data.EndDateRequired,
      MaxFutureReservation: window.output.Data.MaxFutureReservation,
      DisplayInvoiceDates: window.output.Data.DisplayInvoiceDates,
      DisplayCategoryDropdown: window.output.Data.DisplayCategoryDropdown,
      DisplayItemAndCategoryAvailabilityButtons:
        window.output.Data.DisplayItemAndCategoryAvailabilityButtons,
    };

    for (let i in defaultCustomData) {
      window.customData[i] = defaultCustomData[i];
    }
    window.customData.Comboboxes.CategoryID.specification.readOnly = !window.bulkedit;
  }

  /**
   * Set combobox functionality
   * @param {Window} window - Window
   * @returns {Promise} Promise
   */
  async afterRender(window) {
    if (!window.output.Data || window.output.Data.Type != "rentform") {
      return;
    }

    if (window.customInit) {
      const element = document.getElementById("selectScanOrderItem");
      new Vue({
        el: element,
        store,
        render: (h) =>
          h(SelectScanOrderItem, {
            props: {
              opts: {
                request: window.output.Request,
                headerData: getHeader(window),
                liRowData: getRowData(window),
              },
            },
          }),
      });
      return;
    }

    window.customInit = true;

    window.toggleLoading(true);

    if (window.output.Data.Customer != null) {
      setCustomer(
        window,
        window.output.Data.Customer.Value,
        window.output.Data.Customer.Text,
      );
    }

    window.loading = false;
    await window.render();

    const element = document.getElementById("selectScanOrderItem");
    new Vue({
      el: element,
      store,
      render: (h) =>
        h(SelectScanOrderItem, {
          props: {
            opts: {
              request: window.output.Request,
              headerData: getHeader(window),
              liRowData: getRowData(window),
            },
          },
        }),
    });

    window.toggleLoading(false);

    if (window.output.Data.Rows != null && window.output.Data.Rows.length > 0) {
      addRows(
        window.element,
        window.output.Data.Columns,
        window.output.Data.Rows,
        window.output.Data.Metadata,
        null,
        window,
        true,
      );
      fixRowNumbers(window);

      if (!window.output.Data.CanAddNewItems) {
        let $lastrow = $(window.element).find(".table-row-group:last-child");
        $lastrow.remove();
      }
    }

    $(window.element).off();
    $(window.element).on("click", ".table-cell.delete-row", async function () {
      if ($(this).hasClass("disabled")) {
        return;
      }

      let $trg = $(this).closest(".table-row-group");
      if (!$trg.is(":last-child")) {
        let $composition = $trg.find("[name='Composition[]']");
        if (!$composition[0]) {
          $composition = $trg.find("[name='Composition']");
        }

        let composition = $composition.text();
        if ($composition[0] && $composition.hasClass("nonadjustable")) {
          let alertObject = {
            text: window.session.translations.RemoveCompleteComposition,
            icon: "warning",
            dangerMode: true,
            buttons: {
              cancel: window.session.translations.Cancel,
              confirm: "OK",
            },
          };

          let $allRowsForComposition = $(this)
            .closest(".table-rows")
            .find(".nonadjustable:contains('" + composition + "')")
            .closest(".table-row-group");
          swal(alertObject).then((deleteRows) => {
            if (deleteRows) {
              $($allRowsForComposition).each(function () {
                if (!$(this).is(":last-child")) {
                  $(this).remove();
                }
              });
            }
          });
        } else {
          $trg.remove();
          calculateTotals(window);
          updateSelectCount(window);
        }
      }
    });

    $(window.element).on(
      "value-accept",
      "[name='CustomerID']",
      async function (e, arg) {
        if (!$(this).val() && !arg) {
          return;
        }

        let customerID = arg || $(this).val();
        let customerName = $(this)
          .closest(".combobox")
          .find("input.combobox-input")
          .val();

        setCustomer(window, customerID, customerName);
      },
    );

    $(window.element).on(
      "value-accept blur",
      "[name='CustomerID'], [name='CategoryID']",
      function () {
        if (document.getElementById("selectScanOrderItem")) {
          setTimeout(function () {
            new Vue(
              {
                el: document.getElementById("selectScanOrderItem"),
                store,
                render: (h) =>
                  h(SelectScanOrderItem, {
                    props: {
                      opts: {
                        request: window.output.Request,
                        headerData: getHeader(window),
                        liRowData: getRowData(window),
                      },
                    },
                  }),
              },
              3000,
            );
          });
        }
      },
    );
    $(window.element).on("keyup", ".table-row-group:last-child [name]", () => {
      addNewRow(window.element);
    });

    $(window.element).on("change", "[name='Ranking[]']", function () {
      let $row = $(this).closest(".table-row-group");
      if ($row.is(":last-child")) {
        return;
      }

      let rownumber = Number($(this).text());
      let hasBeenPlaced = false;

      $(window.element)
        .find(".table-row-group")
        .not(":last-child")
        .each(function () {
          if (hasBeenPlaced || $(this).is($row)) {
            return;
          }

          let n = $(this).find("[name='Ranking[]']").text();
          if (!n) {
            return;
          }
          n = Number(n);

          if (rownumber <= n) {
            $row.insertBefore($(this));
            hasBeenPlaced = true;
          }
        });

      if (!hasBeenPlaced) {
        $row.insertBefore(
          $(window.element).find(".table-row-group:last-child"),
        );
      }

      fixRowNumbers(window);
    });

    $(window.element).on("change", "[name='SerialID']", async function () {
      await new Promise((resolve) => setTimeout(resolve, 100));
      let headerData = getHeader(window);
      let liRowData = getRowData(window);

      let opts = {
        request: window.output.Request,
        headerData,
        liRowData,
        scannedSerial: $(this).val(),
      };

      if (opts.scannedSerial === null || opts.scannedSerial === "") {
        $(this)
          .closest(".table-row-group")
          .find("[name='Amount[]']")
          .attr("contenteditable", true);

        $(this).parents(".table-cell").find("button.float-left").remove();

        return;
      }

      let otherSerials = $(global.session.activeWindow.element)
        .find("[name='SerialID']")
        .closest(".table-row-group")
        .toArray();

      for (let rowElement of otherSerials) {
        let $serialInput = $(rowElement).find("[name='SerialID']");
        let $realItemType = $(rowElement).find("[name='RealItemType']");

        if (
          $serialInput.val() == opts.scannedSerial &&
          !$serialInput.is(this) &&
          $realItemType.val() != "RentalBoth"
        ) {
          window.message(
            "warning",
            window.session.translations.SerialAlreadyOnOrder,
          );

          $(this).parents(".table-cell").find("input").val("");

          return;
        }
      }

      try {
        let data = await window.session.request(
          "/Admin/WebServices/RentWebServices.asmx/GetSerialInfo",
          opts,
        );

        if (data.DiSerialInfo && data.DiSerialInfo[0].ItemType == "Free") {
          window.message(
            "warning",
            window.session.translations.SerialDoesNotExist,
          );
          $(this).text("").focus();
          return;
        }

        let itemID = $(this)
          .closest(".table-row-group")
          .find("[name='ItemID[]']")
          .text()
          .trim();

        if (data.DiSerialInfo && data.DiSerialInfo[0].ItemID.trim() != itemID) {
          window.message(
            "warning",
            window.session.translations.SerialItemIDMismatch.replace(
              "{}",
              itemID,
            ),
          );
          $(this).text("").focus();
          return;
        }

        $(this).parents(".table-cell").find("svg").remove();
        $(this)
          .parents(".table-cell")
          .prepend(getStatusIcon(data.Metadata[0].SerialID.Status.Type));

        if (
          Number(
            $(this)
              .closest(".table-row-group")
              .find("[name='Amount[]']")
              .text(),
          ) > 1
        ) {
          $(this)
            .closest(".table-row-group")
            .find("[name='Amount[]']")
            .text("1.00");
        }

        $(this).data("server-value", opts.scannedSerial);
      } catch (err) {
        window.message("warning", err.message);
      }
    });

    $(window.element).on(
      "change value-change",
      "[name='ItemID[]']",
      async function () {
        const rowRanking = $(this)
          .closest(".table-row")
          .find("[name='Ranking[]']")
          .html();
        await new Promise((resolve) => setTimeout(resolve, 100));

        let headerData = getHeader(window);
        let liRowData = getRowData(window);

        let opts = {
          request: window.output.Request,
          headerData,
          liRowData,
          scannedSerial: $(this).text(),
          rowRanking,
        };

        if (opts.scannedSerial === null || opts.scannedSerial === "") {
          return;
        }

        try {
          let data = await window.session.request(
            "/Admin/WebServices/RentWebServices.asmx/GetSerialInfo",
            opts,
          );
          let $row = $(this).closest(".table-row-group");
          fillInputs(
            $row.get(0),
            data.DiSerialInfo[0],
            Formatter.applyMetadataRow(
              window.output.Data.Columns,
              data.Metadata[0],
            ),
            window,
          );
          $row.find(".table-row").removeClass("new-row").addClass("added-row"); // when an itemID is entered in a new empty row, this function is triggered. Remove the new-row class
          // and add added-row class otherwise fixRowNumbers will not work because it returns.
          fixRowNumbers(window);
        } catch (err) {
          window.message("warning", err.message);
          throw err;
        }
      },
    );

    $(window.element).on(
      "change value-change",
      "[name='ItemType'], [name='DateTimeExpectedStart'], [name='DateTimeExpectedEnd'], [name='DateTimeBusinessStart'], [name='DateTimeBusinessEnd'], [name='ContractID'], [name='ItemType[]'], [name='DateTimeExpectedStart[]'], [name='DateTimeExpectedEnd[]'], [name='DateTimeBusinessStart[]'], [name='DateTimeBusinessEnd[]'], [name='Amount[]'], [name='Price[]'], [name='Discount[]'], [name='VATID[]'], [name='PeriodAmount[]'], [name='Period[]'], [name='PeriodPrice[]'], [name='LockPrice[]'], [name='ExpectedCounter[]']",
      async function () {
        await new Promise((resolve) => setTimeout(resolve, 100));

        let headerChanged = $(this).parents(".form-view").length > 0;
        let tableRows = $(".table-row-group");

        if (!headerChanged) {
          tableRows = $(this).closest(".table-row-group");
        }

        // -1 ??
        for (let i = 0; i < tableRows.length - (!this.id ? 0 : 1); i++) {
          let $row = tableRows[i];
          let name = $(this).attr("name").split(/\[|\]/g).shift();

          if ($($row).find("[name='" + name + "']").length != 0) {
            $($row)
              .find("[name='" + name + "']")
              .val(this.textContent ? this.textContent : this.value)
              .text(this.textContent ? this.textContent : this.value);
          } else {
            $($row)
              .find("[name='" + name + "[]']")
              .val(this.textContent ? this.textContent : this.value)
              .text(this.textContent ? this.textContent : this.value);
          }

          let data = getRow($row, window);

          if (!data || (!data.ItemID && !data.StaticItemID)) {
            return;
          }

          // If ItemType was changed, this was from RentalPeriod to Sales, which means the user wants to sell the item.
          // A Salable item does not need period data because it does not return to the warehouse.
          if (
            name == "ItemType" &&
            global.session.translations.ItemTypeSales == data.ItemType
          ) {
            data.PeriodAmount = null;
            data.Period = null;
            data.PeriodPrice = null;
            data.DateTimeBusinessEnd = null;
            data.DateTimeExpectedEnd = null;
          }

          if (data.Dimensions != null) {
            // If we have selected a dimension on a row, we do not want it to send to the server because of a serialization issue.
            // On top of that, it does not even need the dimension data to recalculate the rowvalues.
            delete data.Dimensions;
          }

          data.DateTimeExpectedStart = setDate(
            data.DateTimeExpectedStart,
            null,
          );
          data.DateTimeExpectedEnd = setDate(data.DateTimeExpectedEnd, null);
          data.DateTimeBusinessStart = setDate(
            data.DateTimeBusinessStart,
            data.DateTimeExpectedStart,
          );
          data.DateTimeBusinessEnd = setDate(
            data.DateTimeBusinessEnd,
            data.DateTimeExpectedEnd,
          );

          dayjs.locale(global.session.language);

          if (name == "Price" /*&& data.LockPrice*/) {
            data.LockPrice = true;
            data.PriceChanged = true;
          }

          let opts = {
            request: window.output.Request,
            headerData: getHeader(window),
            liRowData: getRowData(window),
            row: data,
          };

          let result = await window.session.request(
            "/Admin/WebServices/RentWebServices.asmx/CalculateRowValues",
            opts,
          );

          let fullMeta = clone(window.output.Data.Columns);

          for (let columnName in result.Metadata) {
            if (fullMeta[columnName] != null) {
              for (let key in result.Metadata[columnName]) {
                fullMeta[columnName][key] = result.Metadata[columnName][key];
              }
            }
          }

          fillInputs($row, result.RowData, fullMeta, window);
          calculateTotals(window);
        }
      },
    );

    // #Pre render the availability modal with no content
    let availabilityModal = new Vue({
      el: "#availabilityModal",
      data: {
        html: "<h1>test</h1>",
        translations: window.translations,
      },
      render: (h) => h(Modal),
      methods: {
        setButttonText(payload) {
          this.$refs.modal.setButtonText(payload);
        },
      },
    });

    this.availabilityWindow = window;

    availabilityModal.$children[0].setButtonText(
      window.session.translations.Availability,
    );

    // Open modal in ModalItemAvailability
    $(window.element).on("click", "#availabilityModalButton", async () => {
      $("body").addClass("avoid-clicks");
      let $availabilityModalColumn = $("body").find("#availabilityModalColumn");
      let $availabilityModalBody = $("body").find("#availabilityModalBody");

      // Unbind existing listeners
      $availabilityModalColumn.unbind();
      $availabilityModalColumn.off();

      // Instantiate a new ModalItemAvailability
      let modalItemAvailability = new ModalItemAvailability(
        $availabilityModalBody,
        window.customData.Comboboxes.CategoryID,
        availabilityModal,
      );
      modalItemAvailability.render({loadAnimation: true});

      // Unbind existing events
      $("body").off(
        "click",
        "button.addItemFromModal, .addItemFromModal .fa-plus",
      );
      $("body").off(
        "click",
        "button.addSerialFromModal, .addSerialFromModal .fa-plus",
      );

      // Add the clicked on item to the order
      $("body").on(
        "click",
        "button.addItemFromModal, .addItemFromModal .fa-plus",
        {window},
        addItemFromModal,
      );

      // Add the clicked on serial item to the order
      $("body").on(
        "click",
        "button.addSerialFromModal, .addSerialFromModal .fa-plus",
        {window},
        addSerialItemFromModal,
      );

      $("body").removeClass("avoid-clicks");
    });
  }
}

// SetDateTime function for all Dates
function setDate(origDate, fallbackDate) {
  if (origDate) {
    if (origDate.indexOf("-") == 2) {
      origDate = dayjs(origDate.split("-").reverse().join("-"), "YYYY-MM-DD");
    } else {
      origDate = dayjs(origDate, "YYYY-MM-DD");
    }
  } else if (fallbackDate && !origDate) {
    if (fallbackDate.indexOf("-") == 2) {
      origDate = dayjs(
        fallbackDate.split("-").reverse().join("-"),
        "YYYY-MM-DD",
      );
    } else {
      origDate = dayjs(fallbackDate, "YYYY-MM-DD");
    }
  }
  return origDate;
}

// Set Headder Dates as new Date from Column if Exists Else as FallbackDate
function setHeaderDate(originalDate, fallbackDate) {
  let date = originalDate ?? fallbackDate;

  if (date) {
    date = dayjs(date, "DD-MM-YYYY");

    if (!date.isValid()) {
      date = dayjs(originalDate ?? fallbackDate);
    }
  } else {
    return undefined;
  }

  if (date == "Invalid Date") {
    return null;
  }

  return date.toDate();
}

async function allowScannedSerial(window) {
  if (window.output.Data.CurrentSerialsOnly) {
    let alertObject = {
      text: window.session.translations["CurrentSerialsOnlyAppendOrder?"],
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: window.session.translations.Cancel,
        confirm: window.session.translations.Insert,
      },
    };

    return await swal(alertObject);
  }
  return true;
}

async function addItemFromModal(event, window) {
  if ($(event.target).is(".addItemFromModal")) {
    event.preventDefault();
    let itemID = event.target.dataset.itemId;
    if (itemID) {
      // Execute event
      await addOrderItem(event.data.window, itemID, false);
    } else {
      notify({
        message: window.session.translations.UnknownError,
        type: "danger",
      });
    }
  }
}

async function addSerialItemFromModal(event) {
  if ($(event.target).is(".addSerialFromModal")) {
    event.preventDefault();
    if (event.target.dataset.itemId) {
      // Execute event
      let id = event.target.dataset.itemSerial
        ? event.target.dataset.itemSerial
        : event.target.dataset.itemId;
      await addOrderItem(event.data.window, id, false);
    } else {
      notify({
        message: window.session.translations.UnknownError,
        type: "danger",
      });
    }
  }
}

async function addOrderItem(
  window,
  scannedSerial,
  isScanbox = false,
  dateTimeExpectedStart,
  dateTimeExpectedEnd,
) {
  let headerData = getHeader(window);
  let liRowData = getRowData(window);

  let opts = {
    request: window.output.Request,
    headerData,
    liRowData,
    scannedSerial: scannedSerial,
  };

  // Set provided date time start and end
  if (dateTimeExpectedStart) {
    opts.headerData.DateTimeExpectedStart = dateTimeExpectedStart;
  }

  if (dateTimeExpectedEnd) {
    opts.headerData.DateTimeExpectedEnd = dateTimeExpectedEnd;
  }

  // if(scannedSerial )
  // scannedSerial = scannedSerial? headerData.scannedSerial
  if (opts.scannedSerial === null || opts.scannedSerial === "") {
    return;
  }

  let otherSerials = $(global.session.activeWindow.element)
    .find("[name='SerialID']")
    .toArray();

  for (let serialInput of otherSerials) {
    if ($(serialInput).val().trim() == opts.scannedSerial) {
      window.message(
        "warning",
        window.session.translations.SerialAlreadyOnOrder,
      );
      $(this).text($(this).data("server-value") || "");
      return;
    }
  }

  if (isScanbox) {
    $(window.element)
      .find("[name=scannedSerial]")
      .closest(".combobox")
      .find("input")
      .val("");
    delete headerData.scannedSerial;
  }

  $(window.element)
    .find(".scroll-container")
    .scrollTop($(window.element).find(".scroll-container").get(0).scrollHeight);
  let $indicatorRow;

  if (isScanbox) {
    let $lastrow = $(window.element).find(".table-row-group:last-child");
    $indicatorRow = addNewRow(window.element);
    $lastrow.remove();
  } else {
    $indicatorRow = $(this).closest(".table-row-group");
  }

  if (isScanbox || $(this).is(".new-row *")) {
    addNewRow(window.element);
  }

  $indicatorRow
    .find("[data-field-index]:first")
    .text("Loading: " + opts.scannedSerial);

  try {
    let data = await window.session.request(
      "/Admin/WebServices/RentWebServices.asmx/GetSerialInfo",
      opts,
    );
    $indicatorRow.remove();

    if (data.DiSerialInfo[0].SerialID) {
      let sameItemNoSerials = $(window.element)
        .find(".table-row-group")
        .filter(function () {
          let serialID = $(this).find("[name='SerialID']").val().trim();
          return (
            $(this).find("[name='ItemID[]']").text().trim() ==
              data.DiSerialInfo[0].ItemID &&
            (!serialID || serialID === "") && // $(this).find("[name='SerialID']").val().trim()
            $(this).find("[name='Amount[]']").text().trim() == "1.00"
          );
        });

      data.Metadata[0].Amount.IsReadOnly = true;

      if (sameItemNoSerials.length > 0) {
        // If an item which uses serials is found but without a serial and the amount is 1, enter the serialID and set Amount to read only.
        sameItemNoSerials
          .eq(0)
          .find("[name='SerialID']")
          .parents(".table-cell")
          .find("input")
          .val(data.DiSerialInfo[0].SerialID);
        sameItemNoSerials
          .eq(0)
          .find("[name='SerialID']")
          .find("button.float-left")
          .remove();
        sameItemNoSerials
          .eq(0)
          .find("[name='SerialID']")
          .parents(".table-cell")
          .prepend(
            getStatusIcon(
              data.Metadata[0].SerialID.Status.Type,
              data.Metadata[0].SerialID.Status.Description,
            ),
          );
        sameItemNoSerials
          .eq(0)
          .find("[name='SerialID']")
          .parents(".table-cell");

        sameItemNoSerials
          .eq(0)
          .find("[name='Amount[]']")
          .attr("contenteditable", false);
        let itemID = sameItemNoSerials.find("[name='ItemID[]']")[0].textContent;
        notify({
          message: window.session.translations.AddedItemToOrder.replace(
            "[SerialID]",
            scannedSerial,
          ).replace("[ItemID]", itemID),
        });
        return;
      } else if (!(await allowScannedSerial(window))) {
        return;
      }
    }

    addRows(
      window.element,
      window.output.Data.Columns,
      data.DiSerialInfo,
      data.Metadata,
      this,
      window,
    );

    fixRowNumbers(window);

    $(window.element).find("[name=scannedSerial]").focus();

    calculateTotals(window);
    updateSelectCount(window);

    // Show notification
    if (window.output.Request.Criteria[0].Target === "Rental.Reservation") {
      notify({
        message: window.session.translations.AddedItemToReservation.replace(
          "[ItemID]",
          scannedSerial,
        ),
      });
    } else {
      notify({
        message: window.session.translations.AddedItemToOrder.replace(
          "[ItemID]",
          scannedSerial,
        ),
      });
    }
  } catch (error) {
    $indicatorRow.remove();

    let message = window.session.translations.UnknownError;
    if (error.message) {
      message = error.message;
    }
    notify({message, type: "danger"});
  }
}

function getHeader(window) {
  window = window ?? session.activeWindow;

  let form = serializeForm($(window.element).find("form.form-view").get(0), {
    hash: true,
    empty: true,
    disabled: true,
  });

  form["DateTimeExpectedStart"] = setHeaderDate(
    form.DateTimeExpectedStart,
    null,
  );

  form["DateTimeExpectedEnd"] = setHeaderDate(form.DateTimeExpectedEnd, null);

  form["DateTimeBusinessStart"] = setHeaderDate(
    form.DateTimeBusinessStart,
    form.DateTimeExpectedStart,
  );
  form["DateTimeBusinessEnd"] = setHeaderDate(
    form.DateTimeBusinessEnd,
    form.DateTimeExpectedEnd,
  );

  if (
    form.DefaultPeriodID == null &&
    window.parent != null &&
    window.output.FullTable.Rows[0] != null
  ) {
    let cell = window.output.FullTable.Rows[0]
      .filter((x) => x.Column.Name == "DefaultPeriodID")
      .pop();
    if (cell != null) {
      form.DefaultPeriodID = (cell && cell.NewValue) || cell.Value;
    } else {
      form.DefaultPeriodID = null;
    }
  }
  return form;
}

function getRowData(window) {
  let rows = getRows(window);
  let liRowData = [];
  for (let row of rows) {
    if (row.Status === "Open") {
      for (let key in row) {
        if (row[key] && row[key].indexOf && row[key].indexOf("/Date(") === 0) {
          row[key] = dayjs
            .unix(Formatter.unixMilisecondsToUnix(row[key]))
            .format("YYYY-MM-DD HH:mm:ss");
        }
      }

      for (let column of window.output.Table.Columns) {
        if (column.IsRequired) {
          // dimension specific logic, because when an dimension value is entered, a dimensions object is created instead of copying the column to the row with a value
          if (
            row.hasOwnProperty(column.Name) &&
            column.Name.indexOf("DimensionID-") === 0
          ) {
            let requiredFieldMessage =
              window.session.translations.ExceptionRequiredFieldNotGiven;
            window.message(
              "error",
              requiredFieldMessage.replace("{}", column.Title),
            );
            return;
          } // TODO: create logic for normal required fields
        }
      }
      liRowData.push(row);
    }
  }

  return liRowData;
}

function getTypes(window) {
  let columnInfo = {};

  for (let info of window.output.Table.Columns) {
    columnInfo[info.Name] = info;
  }
  return columnInfo;
}

function fixRowNumbers(window) {
  let min = 0;

  $(window.element)
    .find(".table-body .table-row-group [name='Ranking[]']")
    .each(function () {
      let $row = $(this).closest(".table-row");
      if (!$row.is(".added-row")) {
        return;
      }
      let n = $(this).text();
      n = typeof n !== "undefined" ? n.trim() : n;
      n = typeof n === "undefined" || n === "" ? 0 : Number(n);
      if (n <= min) {
        min = Math.floor(min / 10) * 10 + 10;
        $(this).text(min);
      } else {
        min = n;
      }
    });

  calculateTotals(window);
}

function getRows(window) {
  let rows = [];

  $(window.element)
    .find(".table-body .table-row-group")
    .each(function () {
      if (
        (window.output.Data.CanAddNewItems &&
          $(this).is(".table-row-group:last-child")) ||
        !$(this).find(".table-row").is(".added-row")
      ) {
        return;
      }
      rows = rows.concat(getRow(this, window) || []);
    });
  return rows;
}

function getRow(el, window) {
  let data = {};
  let dirty = false;
  let dimensions = [];
  let columnInfo = getTypes(window);

  $(el)
    .find("[name]")
    .each(function () {
      let name = $(this).attr("name").split(/\[|\]/g).shift();

      if (!name) {
        return;
      }

      let value = !$(this).is("input") ? $(this).text() : $(this).val();

      if (name == "SubStatus") {
        if (value == "") {
          value = $(this).data("override-value");
        }
      } else if (name == "ItemType") {
        let selectedItem = $(this)
          .parent()
          .find(".dropdown-option.selected")
          .attr("data-value");

        if (selectedItem) {
          value = selectedItem;
        }
      } else if (typeof $(this).data("override-value") != "undefined") {
        value = $(this).data("override-value");
      }

      if (value) {
        dirty = true;
      }

      if (value == undefined) {
        value = "";
      }

      if (value.indexOf && value.indexOf("OBJECTDEF:") != 0) {
        let info = columnInfo[name] || {};

        if (
          info.Type === "Decimal" ||
          info.Type === "Money" ||
          info.Type === "Money"
        ) {
          value = value != "" ? Number(value) : null;
        } else if (
          info.Type === "DateTime" ||
          info.Type === "Date" ||
          name.startsWith("Date")
        ) {
          let parsedValue = value
            ? dayjs(value, "DD-MM-YYYY").format("YYYY-MM-DD")
            : null;

          // Sometimes the value is already formatted as YYYY-MM-DD

          if (parsedValue !== undefined && parsedValue !== null) {
            value = parsedValue;
          } else {
            value = null;
          }
        } else if (info.Type === "Boolean") {
          value = $(this).is(":checked");
        } else {
          value =
            value || (value === false || value === "false" ? false : null);
          value = typeof value === "string" ? value.trim() : value;
        }
      } else if (value.slice) {
        value = JSON.parse(value.slice("OBJECTDEF:".length));
      }

      data[name] = value;

      if (name.indexOf("DimensionID-") !== -1 && value != null) {
        let dimension = {DimensionObjectID: value};
        dimensions.push(dimension);
        delete data[name];
        data.Dimensions = dimensions;
      }
    });

  return dirty && data;
}

function fillInputs(el, row, columnInfo, window, initialData) {
  let fullReadOnly =
    Object.keys(columnInfo || {}).filter((x) => !columnInfo[x].IsReadOnly)
      .length == 0;
  $(el).find(".delete-row").toggleClass("disabled", fullReadOnly);
  $(el).find(".table-row").removeClass("new-row").addClass("added-row");

  let supportedNames = [];

  // Fills each cell in the table for bulk edit screens
  $(el)
    .find("[name]")
    .each(function () {
      let name = $(this).attr("name").replace(/\[\]$/, "");

      if (!name && $(this).find("[name='SerialID']").length > 0) {
        name = $(this).find("[name='SerialID']").attr("name");

        if (columnInfo[name] && columnInfo[name].Status) {
          $(this).prepend(
            getStatusIcon(
              columnInfo[name].Status.Type,
              columnInfo[name].Status.Description,
            ),
          );
        }
        return;
      }

      if (
        !name ||
        (name.indexOf("Option") != -1 && $(this).attr("value") != "") ||
        (name.indexOf("DimensionID-") != -1 && $(this).attr("value") != "")
      ) {
        // if no name is found, or if the name is DimensionID AND it already has a value, do not overwrite.
        return;
      }

      let column = columnInfo[name] || {};
      let value =
        (row[name] != null && typeof row[name] == "object"
          ? "OBJECTDEF:" + JSON.stringify(row[name])
          : Formatter.parseValue(column, row[name])) || String();

      supportedNames.push(name);

      if (name == "Composition" && value.endsWith(" (0)")) {
        // if composition ends with (0), remove (0) and use an unused number
        value = value.slice(0, " (0)".length * -1);
        let counter = 0;
        let $rowsWithThisComposition = null;

        do {
          counter = counter + 1;

          $rowsWithThisComposition = $(window.element)
            .find(".table-row:not(.dirty-row)")
            .parent();

          $rowsWithThisComposition = $rowsWithThisComposition
            .find(":contains('" + value + " (" + counter + ")" + "')")
            .add(
              $rowsWithThisComposition
                .find("[name='Composition']")
                .filter(function () {
                  return this.value == value + " (" + counter + ")";
                }),
            );
        } while ($rowsWithThisComposition[0]);

        value = value + " (" + counter + ")";
        if (row["CompositionRanking"] != null) {
          row["CompositionRanking"] = counter;
        }
      }

      if ($(this).parent().is(".combobox")) {
        $(this)
          .parent()
          .find(".combobox-input")
          .val(column.Description || value);
        $(this).parent().toggleClass("disabled", column.IsReadOnly);
        $(this)
          .parent()
          .find(".combobox-input")
          .prop("disabled", column.IsReadOnly);
      }

      // Set min and max value
      if (column.MinNumber) {
        $(this).attr("min", column.MinNumber);
      }
      if (column.MaxNumber) {
        $(this).attr("max", column.MaxNumber);
      }

      // Set Custom class
      if (column.CustomClass) {
        $(this).addClass(column.CustomClass);
      }

      // Set's the value and text on the cells
      if (
        column.Dropdown != null &&
        column.Dropdown.Items != null &&
        value != ""
      ) {
        $(this).data("override-value", value);
        let description = (
          column.Dropdown.Items.filter((x) => x.Value == value)[0] || {}
        ).Text;
        $(this).text(description);
      } else if ($(this).is("input") && $(this)[0].type === "checkbox") {
        $(this).prop("checked", value);
        $(this).prop("disabled", Boolean(column.IsReadOnly));
      } else if ($(this).is("input")) {
        $(this).val(value);
        $(this).prop("disabled", Boolean(column.IsReadOnly));
      } else {
        // If we have a status property in our metadata for this cell, we append a status button to the value
        if (
          typeof columnInfo[name].Status !== "undefined" &&
          columnInfo[name].Status
        ) {
          if (column.Dropdown != null) {
            $(this).data("override-value", value);
            $(this).prepend(getStatusIcon(columnInfo[name].Status.Type));
          } else {
            $(this).html(value);
            $(this).prepend(getStatusIcon(columnInfo[name].Status.Type));
          }
        } else {
          $(this).text(value);
        }
        $(this).prop("contenteditable", !column.IsReadOnly);
      }

      $(this).data("server-value", value);

      if (initialData) {
        $(this).data("initial-data", true);
      }
    });

  // Fill row cells with data
  for (let name in row) {
    if (supportedNames.indexOf(name) === -1) {
      let value =
        (row[name] != null && typeof row[name] == "object"
          ? "OBJECTDEF:" + JSON.stringify(row[name])
          : Formatter.parseValue(columnInfo[name] || {}, row[name])) ||
        String();
      $(el).append(
        $("<input>").prop("type", "hidden").prop("name", name).val(value),
      );
      if (name === "SerialID") {
        $(el).append(
          "<i data-toggle-remove='data-toggle-remove' class='fas fa-times pull-right'></i>",
        );
      }
    }
  }
}

function correctTableHeaders(window) {
  $(".table-view", window.element).each(function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let tableElement = this;
    let headerRow = $(
      ".table-index .table-row:first-child .table-cell",
      tableElement,
    );
    let firstContentRow = $(
      ".table-body .table-row:first-child .table-cell",
      tableElement,
    );

    headerRow.each(function () {
      let index = $(this).index();
      let cell = firstContentRow.eq(index);

      if (!cell || !cell.length) {
        return;
      }
      let width = cell.outerWidth();

      $(this).css({
        width: width,
        "min-width": width,
        "max-width": width,
      });
    });
  });
}

function addRows(
  container,
  columnInfo,
  DiSerialInfo,
  metadata,
  source,
  window,
  initialData,
) {
  for (let i = 0; i < DiSerialInfo.length; i++) {
    let row = DiSerialInfo[i];

    if (
      row.Amount &&
      String(row.Amount).indexOf(",") !== -1 &&
      String(row.Amount).indexOf(".") === -1
    ) {
      row.Amount = row.Amount.replace(/,/g, ".");
    }

    let $lastRow = $(container).find(".table-row-group:last-child");
    let $newRow = $lastRow.clone();
    let $placerow =
      source && $(source).attr("name") === "ItemID[]"
        ? $(source).closest(".table-row-group")
        : $lastRow;

    fillInputs(
      $newRow.get(0),
      row,
      Formatter.applyMetadataRow(columnInfo, metadata && metadata[i]),
      window,
      initialData,
    );

    let fullRow = null;
    if (initialData) {
      fullRow = window.output.FullTable.Rows[i];
    }

    for (const prop of $newRow.find(".dropdown.combobox")) {
      // Create new combobox object for every new row, every dropdown
      let previousCb = ComboboxUI.getClass($(prop));

      let cell = null;
      if (fullRow) {
        let column = fullRow.find((o) => {
          return o.Column.Name === previousCb.specification.name;
        });
        if (column) {
          cell = column.Combobox.cell;
        }
      }

      let newCb = Combobox.new(cell, {
        name: previousCb.specification.name,
        nullable: previousCb.specification.nullable,
        type: previousCb.specification.type,
        tableName: previousCb.specification.tableName,
        columnName: previousCb.specification.columnName,
        openRef: previousCb.specification.openRef,
        items: previousCb.specification.items,
      });

      if (newCb.specification.items) {
        newCb.populate(newCb.specification.items);
      }

      if (cell != null) {
        $(prop).find("input.combobox-input").val(newCb.startingText);
      }

      $(prop).attr("data-id", newCb.id);
    }

    $newRow
      .find(".table-row")
      .removeClass("new-row")
      .addClass("added-row")
      .addClass("dirty-row");
    $newRow.insertBefore($placerow);
  }

  // Remove 'dirty-row', all new rows have been created
  $(container).find(".dirty-row").removeClass("dirty-row");

  correctTableHeaders(window);
}

function addNewRow(el) {
  let $old = $(el).find(".table-row-group:last-child");

  if ($old.length < 0) {
    return;
  }

  let $trClone = $old.clone();
  $trClone.find("[contenteditable]").html("");
  $trClone.find("select").val("");
  $trClone.removeClass("dirty");
  $trClone.find("input").val(String());

  $trClone.find(".dropdown.combobox").each(function () {
    // Create new combobox object for every new row, every dropdown
    let previousCb = ComboboxUI.getClass($(this));

    let newCb = Combobox.new(null, {
      name: previousCb.specification.name,
      nullable: previousCb.specification.nullable,
      type: previousCb.specification.type,
      tableName: previousCb.specification.tableName,
      columnName: previousCb.specification.columnName,
      openRef: previousCb.specification.openRef,
      items: previousCb.specification.items,
    });

    if (newCb.specification.items) {
      newCb.populate(newCb.specification.items);
    }

    $(this).attr("data-id", newCb.id);
  });

  $trClone.insertAfter($old);
  return $trClone;
}

function sumTotal(window, name) {
  let total = 0;

  $(window.element)
    .find("[name='" + name + "[]'], [name='" + name + "']")
    .each(function () {
      total +=
        Number($(this).is("input") ? $(this).val() : $(this).text()) || 0;
    });

  return total;
}

function calculateTotals(window) {
  $("[data-total-price]").text(sumTotal(window, "TotalExcVAT").toFixed(2));
  $("[data-total-btw]").text(
    Math.max(
      0,
      sumTotal(window, "TotalIncVAT") - sumTotal(window, "TotalExcVAT"),
    ).toFixed(2),
  );
  $("[data-total-btw-price]").text(sumTotal(window, "TotalIncVAT").toFixed(2));

  $("[data-period-price-total-exc-vat]").text(
    sumTotal(window, "PeriodPriceTotalExcVAT").toFixed(2),
  );
  $("[data-period-price-total-vat]").text(
    sumTotal(window, "PeriodPriceTotalVAT").toFixed(2),
  );
  $("[data-period-price-total-inc-vat]").text(
    sumTotal(window, "PeriodPriceTotalIncVAT").toFixed(2),
  );
}

function updateSelectCount(window) {
  let rows = getRows(window);

  $("[data-select-count]").text(rows.length);
}

async function quickrentSubmit() {
  let headerData = getHeader(this);
  let liRowData = getRowData(this);

  let opts = {
    request: this.output.Request,
    headerData,
    liRowData: liRowData,
  };

  try {
    let result = await this.session.request(
      "/Admin/WebServices/RentWebServices.asmx/SaveButtonClick",
      opts,
    );
    Promise.resolve(this.handleActionResponse(result));
  } catch (err) {
    this.message(
      "error",
      err.data !== undefined ? err.data.Error : err.message,
    );
  }
}

/**
 * View availability
 * @param {*} mode
 * @returns {void}
 */
async function viewAvailability(mode) {
  let req = {
    Subject: "Rental.virtual_Availability",
    Prefix: "View",
    Criteria: [],
  };

  if (mode == "category") {
    let categoryID = $(this.element).find("[name=CategoryID]").val();
    if (categoryID != "") {
      req.Criteria = [{CategoryID: categoryID}];
    } else {
      return this.message("error", window.session.translations.ChooseCategory);
    }
  } else {
    let itemIDs = $(this.element)
      .find("[name='ItemID[]']")
      .toArray()
      .map((x) => $(x).text());
    // Get all DateTimeExpectedEnds, filter out ones which are not entered for example with a Sales/use article.
    let expectedEnds = $(this.element)
      .find("input", "[name='DateTimeExpectedEnd[]']")
      .toArray()
      .map((dateField) => $(dateField).text())
      .filter((dateValue) => dateValue != "");
    let momentDates = [];
    // Convert all dates into moment objects and push them into an array
    expectedEnds.forEach((date) =>
      momentDates.push(dayjs(Formatter.serializers.DateTime(date))),
    );
    // use the dayjs.max() function to get the biggest date.
    let maxDate = dayjs.max(momentDates);

    let rentalStarts = $(this.element)
      .find("input", "[name='DateTimeExpectedStart[]']")
      .toArray()
      .map((dateField) => $(dateField).text())
      .filter((dateValue) => dateValue != "");
    let startMomentDates = [];
    // Convert all dates into moment objects and push them into an array
    rentalStarts.forEach((date) =>
      startMomentDates.push(dayjs(Formatter.serializers.DateTime(date))),
    );

    // use the dayjs.max() function to get the biggest date.
    let minDate = dayjs.min(startMomentDates);

    // if itemIDs contains more than 1 value, we have one inserted ItemID and one empty from the
    // new row below. Then we use them as criteria, otherwise we show the user an error.
    if (itemIDs.length > 1) {
      req.Criteria = [
        {
          ItemID: itemIDs,
          StartDate: Formatter.serializers.DateTime(minDate), // serialize the date into the format YYYY-MM-DD otherwise the server cannot handle it.
          EndDate: Formatter.serializers.DateTime(maxDate), // serialize the date into the format YYYY-MM-DD otherwise the server cannot handle it.
        },
      ];
    } else {
      return this.message("error", window.session.translations.ChooseItem);
    }
  }

  await this.session.openWindow(req);
}

async function renumberRows() {
  let customRanking = 0;
  $(this.element)
    .find(".table-row-group")
    .not(":last-child")
    .each(function () {
      $(this)
        .find("[name='Ranking[]']")
        .text((customRanking += 10));
    });
}

async function setCustomer(window, customerID, customerName) {
  window.customData.Comboboxes.CategoryID.specification.readOnly = false;

  window.customData.CustomerID = customerID;
  window.customData.CustomerIDName = customerName;

  window.customData.Comboboxes.CustomerID.specification.readOnly = true;
  window.customData.Comboboxes.CustomerID.setInitialValues(
    window.customData.CustomerIDName,
    window.customData.CustomerID,
  );

  // Set ExtraKeys of all ComboBoxes
  for (let col in window.customData.QuickRentColumns) {
    let column = window.customData.QuickRentColumns[col];

    if (column.Dropdown != null) {
      for (let key in column.Dropdown.ExtraKeys) {
        let filter = column.Dropdown.ExtraKeys[key];
        if (filter == "CustomerID") {
          column.Combobox.specification.filter = {CustomerID: customerID};
        }
      }
    }

    column.IsReadOnly = false;

    if (column.Combobox != null) {
      column.Combobox.specification.readOnly = false;
    }
  }

  window.bulkedit = true;

  await window.render();

  let availabilityModal = new Vue({
    el: "#availabilityModal",
    data: {
      html: "",
      translations: window.translations,
    },
    render: (h) => h(Modal),
    methods: {
      setButttonText(payload) {
        this.$refs.modal.setButtonText(payload);
      },
    },
  });

  availabilityModal.$children[0].setButtonText(
    window.session.translations.Availability,
  );

  $("[name=Reference], [name=DateTimeExpectedStart]").prop("disabled", false);
}

function getStatusIcon(status) {
  if (status === "success") {
    return '<i class="fas fa-check text-success mr-1"></i>';
  }

  if (status === "danger") {
    return '<i class="fas fa-times text-danger mr-1"></i>';
  }

  if (status === "warning") {
    return '<i class="fas fa-warning text-warning mr-1"></i>';
  }

  if (status === "info") {
    return '<i class="fas fa-info text-info mr-1"></i>';
  }
}

/** @ignore */
export default new RentOrderItemHook();
export {getRow, getHeader};
