<template>
  <span>
    <button
      data-window-event="reset"
      :data-window-target="windowID"
      :disabled="!showCurrencyToggle || bulkedit"
      value="ToggleCurrency"
      class="btn-link btn-icon text-dark mt-1 mr-2"
    >
      <div class="float-left">
        <span style="font-weight: bold">{{ clientCurrencySymbol }}</span>
      </div>
    </button>
  </span>
</template>

<script>
export default {
  props: {
    window: {
      required: true,
      type: Object,
    },
    windowID: {
      required: true,
      type: String,
    },
    subWindow: {
      required: false,
      type: Object,
    },
    bulkedit: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    clientCurrency: function () {
      return this.getCurrencyInfo().ClientCurrency;
    },
    clientCurrencySymbol: function () {
      return this.getCurrencyInfo().ClientCurrencySymbol;
    },
    showCurrencyToggle: function () {
      return this.getCurrencyInfo().ShowCurrencyToggle;
    },
  },
  methods: {
    getCurrencyInfo: function () {
      let window = this.window;

      if (this.subWindow) {
        window = this.subWindow;
      }

      if (
        window.output &&
        window.output.Request &&
        window.output.Request.Data &&
        window.output.Request.Data.ShowCurrencyToggle
      ) {
        return {
          ShowCurrencyToggle: window.output.Request.Data.ShowCurrencyToggle,
          ClientCurrency: window.output.Request.Data.ClientCurrency,
          ClientCurrencySymbol: window.output.Request.Data.ClientCurrencySymbol,
        };
      }

      return {
        ShowCurrencyToggle: false,
        ClientCurrency: null,
      };
    },
  },
};
</script>

<style></style>
