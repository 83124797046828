<template>
  <div>
    <a target="__BLANK" class="btn btn-secondary d-block" :href="manualURL">
      <i class="float-left fas fa-book"></i> {{ translations.Manual }}
    </a>
  </div>
</template>
<script>
export default {
  props: {
    manualURL: {
      type: String,
    },
  },
  computed: {
    translations: function () {
      return this.$store.getters.translations;
    },
  },
};
</script>
<style scoped lang="scss">
svg {
  margin-top: 3px;
}
</style>
