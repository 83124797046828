import axios from "axios";
import store from "../../state/store";

export async function getSerialInfo({opts}) {
  const result = await axios.post(
    `Admin/WebServices/RentWebServices.asmx/GetSerialInfo`,
    opts,
    {
      headers: {
        Authorization: `Bearer ${store.state.accessToken}`,
      },
      params: {
        warehouse: store.state.activeWarehouse,
      },
    },
  );

  return result.data;
}
