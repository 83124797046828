<template>
  <div class="login-overlay">
    <div class="two-factor-setup">
      <div class="header">
        <h5 class="w-100">
          {{ translations.TwoFactorSetupTitle }}
        </h5>
      </div>
      <div class="options">
        <two-factor-setup-option-q-r />
      </div>
      <two-factor-setup-verify />
      <hr />
      <div class="downloads">
        <div class="w-100">
          <p>
            {{ translations.TwoFactorSetupAppDescription }}
          </p>
        </div>
        <a
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          ><img
            alt="Get it on Google Play"
            src="/static/en_badge_web_generic_playstore.png"
        /></a>
        <a
          href="https://apps.apple.com/us/app/google-authenticator/id388497605?itsct=apps_box_badge&amp;itscg=30200"
          style=""
          ><img
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1284940800&h=7fc6b39acc8ae5a42ad4b87ff8c7f88d"
            alt="Download on the App Store"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
import TwoFactorSetupOptionQR from "./TwoFactorSetupOptionQR";
import TwoFactorSetupVerify from "./TwoFactorSetupVerify";
export default {
  name: "TwoFactorSetup",
  components: {
    TwoFactorSetupVerify,
    TwoFactorSetupOptionQR,
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
  },
};
</script>

<style scoped lang="scss">
.downloads {
  display: flex;
  padding: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  a {
    width: 30%;
    display: flex;
    justify-content: center;
  }

  img {
    display: block;
    max-width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .two-factor-setup {
    border-radius: 0px;
  }
}
.login-overlay {
  height: 100vh;
  width: 100vw;

  position: fixed;
  top: 0;
  left: 0;
  background-color: #362f2f7a;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .two-factor-setup {
    background-color: white;

    overflow: auto;
    max-height: 100%;
    position: fixed;
    width: 500px;
    border-radius: 0.5rem;
    box-shadow: 0 4px 25px 0 rgb(0 0 0 / 30%);
    .header {
      text-align: left;
      padding: 15px;
      border-bottom: 3px solid #f26522;
    }
    .options {
      .title {
        font-size: 16px;
        font-weight: 600;
      }
      display: flex;
      justify-content: space-between;
      padding: 15px;
    }
  }
}
</style>
