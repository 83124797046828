<template>
  <b-modal
    :id="randomID"
    :ref="randomID"
    class="mail-send-modal"
    v-model="show"
    hide-footer
    size="xl"
    :title="`${event.args.fields[5]} - ${translations.ModalSendMailTitle}`"
  >
    <form class="grid" @submit.prevent="submit">
      <div class="modal-body mail-send-modal">
        <div class="container-fluid">
          <div
            id="notifyDialog"
            style="display: none"
            class="alert alert-warning alert-dismissible fade show"
            role="alert"
          >
            <span id="notifyDialogContent"></span>
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="row">
            <div
              class="col col-xl-4 col-sm-12 list-contacts"
              style="height: 100%"
            >
              <!-- Report Template -->
              <div class="col">
                <label class="form-field">
                  <div
                    :title="translations.modalMailSendReportTemplateLabel"
                    class="form-label"
                  >
                    {{ translations.modalMailSendReportTemplateLabel }}:
                  </div>
                  <div class="form-input">
                    <v-select
                      :append-to-body="true"
                      v-model="selectedReportTemplate"
                      label="Text"
                      :options="reportTemplates"
                    />
                  </div>
                </label>
              </div>

              <!-- Languages -->
              <div class="col">
                <label class="form-field">
                  <div
                    :title="this.event.args.fields[2].Title"
                    class="form-label"
                  >
                    {{ this.event.args.fields[2].Title }}:
                  </div>
                  <div class="form-input">
                    <v-select
                      :append-to-body="true"
                      v-model="form.languageId"
                      label="Text"
                      :options="languages"
                    />
                  </div>
                </label>
              </div>

              <!-- Mail Template -->
              <div class="col">
                <label class="form-field">
                  <div
                    :title="translations.modalMailSendMailTemplateLabel"
                    class="form-label"
                  >
                    {{ translations.modalMailSendMailTemplateLabel }}:
                  </div>
                  <div class="form-input">
                    <v-select
                      :append-to-body="true"
                      v-model="selectedEmailTemplate"
                      label="Text"
                      :options="emailTemplates"
                    />
                  </div>
                </label>
              </div>

              <!-- TO Recipients -->
              <div class="col">
                <div class="form-field">
                  <div
                    :title="translations.modalMailSendAddEmail"
                    class="form-label"
                  >
                    {{ translations.modalMailSendAddEmail }}:
                    <span title="" class="required-indicator">*</span>
                  </div>
                  <div class="form-input">
                    <v-select
                      multiple
                      :clearable="false"
                      :append-to-body="true"
                      taggable
                      push-tags
                      :clearSearchOnBlur="vSelectBlur"
                      :clearSearchOnSelect="false"
                      v-model="form.recipients"
                      :options="contacts"
                    >
                      <template #search="{attributes, events}">
                        <input
                          class="vs__search"
                          :required="!form.recipients.length"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>

              <!-- CC Recipients -->
              <div class="col">
                <div class="form-field">
                  <div
                    :title="`${translations.modalMailSendAddEmail} (CC)`"
                    class="form-label"
                  >
                    {{ translations.modalMailSendAddEmail }} (CC):
                  </div>
                  <div class="form-input">
                    <v-select
                      multiple
                      taggable
                      :append-to-body="true"
                      push-tags
                      :clearSearchOnBlur="vSelectBlur"
                      v-model="form.optionalRecipients"
                      :options="contacts"
                    />
                  </div>
                </div>
              </div>

              <!-- BCC Recipients -->
              <div class="col">
                <div class="form-field">
                  <div
                    :title="`${translations.modalMailSendAddEmail} (BCC)`"
                    class="form-label"
                  >
                    {{ translations.modalMailSendAddEmail }} (BCC):
                  </div>
                  <div class="form-input">
                    <v-select
                      multiple
                      :append-to-body="true"
                      taggable
                      :clearSearchOnBlur="vSelectBlur"
                      push-tags
                      v-model="form.optionalHiddenRecipients"
                      :options="contacts"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col col-xl-8 col-sm-12">
              <div class="col col-xs-12">
                <text-field
                  :value="form.subject"
                  @input="(e) => (form.subject = e.target.value)"
                  :label="translations.EmailSubject"
                  :required="true"
                />
              </div>

              <div class="col col-xs-12">
                <ckeditor v-model="form.body" />
              </div>
            </div>
          </div>
          <!--/row-->
        </div>
        <!-- /container -->
      </div>
      <div class="modal-footer pb-0">
        <div class="msg-actions text-">
          <button class="btn btn-primary mr-auto" type="submit">
            <i v-if="loading" class="fas fa-spinner fa-spin"></i>
            {{ translations.ButtonSendMailTitle }}
          </button>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import TextField from "../form/TextField.vue";
import VSelect from "vue-select";
import {generateID} from "@/util/generateID";
import {sendMail} from "@/services/mail";
import Ckeditor from "@/components/Ckeditor.vue";

export default {
  components: {
    TextField,
    VSelect,
    Ckeditor,
  },
  props: {
    event: {
      type: Object,
    },
  },
  data() {
    return {
      form: {
        subject: "",
        recipients: [],
        reportTemplate: null,
        body: "",
        emailTemplate: null,
        fullTableName: null,
        languageId: null,
        primaryKey: null,
        primaryKeys: null,
        optionalRecipients: [],
        optionalHiddenRecipients: [],
      },

      subject: "",
      selectedReportTemplate: null,
      selectedEmailTemplate: null,

      loading: false,
      editor: null,
      show: false,
    };
  },
  computed: {
    randomID() {
      return generateID(5);
    },
    translations() {
      return this.$store.state.translations;
    },
    emailTemplates() {
      return this.event.args.fields[3].DropdownItems;
    },
    languages() {
      return this.event.args.fields[2].DropdownItems;
    },
    reportTemplates: function () {
      return this.event.args.fields[1].DropdownItems;
    },
    contacts: function () {
      return this.event.args.fields[4].DropdownItems.map(
        (contact) => contact.Value,
      );
    },
  },
  watch: {
    selectedEmailTemplate(val) {
      this.form.body = val.Attributes.Body;
      this.form.subject = val.Attributes.Subject;
      this.subject = val.Attributes.Subject;
      this.form.emailTemplate = val.Value;
    },
    selectedReportTemplate(val) {
      this.form.reportTemplate = val.Value;
    },
    subject(val) {
      this.form.subject = val;
    },
  },
  methods: {
    onUpdate(event) {
      console.log({...event});
    },
    submit: async function () {
      if (this.form.recipients.length < 1) return;
      this.loading = true;
      const result = await sendMail({...this.form});

      global.session.activeWindow.handleActionResponse(result);
      this.$bvModal.hide(this.randomID);
      this.show = false;
      this.loading = false;
    },
    vSelectBlur() {
      return true;
    },
  },
  async mounted() {
    this.show = true;
  },
  created() {
    this.form.languageId = this.event.args.fields[2].Initial;
    this.form.primaryKey = this.event.args.fields[7].PrimaryKey[0];
    this.form.fullTableName = this.event.args.fields[6].Subject;
    this.form.primaryKeys = this.event.args.fields[5];
    this.form.recipients = this.event.args.fields[8].PredefinedEmails;
    this.selectedReportTemplate =
      this.event.args.fields[1]?.Initial ?? this.reportTemplates[0];
    this.selectedEmailTemplate =
      this.event.args.fields[3]?.Initial ?? this.emailTemplates[0];
    this.form.body = this.selectedEmailTemplate.Attributes.Body ?? "";
    this.form.Subject = this.selectedEmailTemplate.Attributes.Subject ?? "";
  },
};
</script>
<style lang="scss">
.v-select .vs__dropdown-toggle {
  height: 31px !important;
}
.mail-send-modal {
  .vs__selected-options {
    display: flex;
    flex-basis: 100%;
    flex-grow: 1;
    flex-wrap: wrap;
    padding: 0 2px;
    overflow: hidden;
    position: relative;
    flex-direction: row;
  }
  .v-select .vs__dropdown-toggle {
    height: auto !important;
    background: white;
    overflow: hidden;
    border-radius: 3px;
    box-shadow: 1px 1px 1px 0px rgb(0 0 0 / 9%) !important;
  }
}
</style>
