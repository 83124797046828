import initializeTableElements from "./actions/initializeTableElements";

const actions = {
  addTab({commit}, window) {
    //global.session.tabs.unshift(window)
    commit("refreshTabs");
  },

  async resetWindow({commit, dispatch}, {windowid, shouldConfirm}) {
    let window = global.session.windows[windowid];

    const cachedWindowTitle = $(window.element).find("h4").html();

    if (window.output.Request.Subject === "Rental.Category") {
      window.reload();
    }

    const oldCustomTitle = window.customTitle;
    window.customTitle = "<i class='fas fa-spinner fa-spin'></i>";

    $(window.element).find("h4").html("<i class='fas fa-spinner fa-spin'></i>");

    let alertObject = {
      text: global.session.translations.WindowWarningOnClose,
      icon: "warning",
      dangerMode: true, // setting dangerMode to true makes the Cancel button bigger
      buttons: [global.session.translations.Cancel, true],
    };
    let dirtySub = false;
    // check if a closing confimation should be asked for
    if (window.sub) {
      dirtySub = window.sub.window.isDirty();
    }

    if (shouldConfirm !== false) {
      if (
        (shouldConfirm === "confirm" || window.isDirty() || dirtySub) &&
        !(await swal(alertObject))
      ) {
        $(window.element).find("h4").replaceWith(cachedWindowTitle);

        return;
      }
    }

    if (window.sub) {
      await window.resetSub();
    } else {
      await window.fetch(true);
    }

    window.loading = false;
    window.customTitle = oldCustomTitle;

    const newTitle = `<h4>${window.output.Title} <small>${
      window.output.SubTitle ? window.output.SubTitle : ""
    }</small></h4>`;

    $(window.element).find("h4").replaceWith(newTitle);
    $(window.element).css("pointer-events", "auto");
    $(window.element).css("opacity", "1");

    commit("refreshTabs");
    commit("updateWindow");

    if (window.sub) {
      $(window.sub.window.element).removeClass("dirty");
    } else {
      $(window.element).removeClass("dirty");
    }
  },

  removeTabByIndex({commit}, index) {
    global.session.tabs.splice(index, 1);
    commit("updateWindow");
  },

  closeAllTabs({commit}) {
    if (global.session.activeWindow) {
      const alertObject = {
        text: global.session.translations.WarningAllWindowsWillClose,
        icon: "warning",
        dangerMode: true,
        buttons: {
          cancel: global.session.translations.Cancel,
          confirm: "OK",
        },
      };
      swal(alertObject).then((shouldClose) => {
        if (shouldClose) {
          // if close is clicked, shouldClose resolves to NULL
          // set the combobox value to the clicked item
        }
        global.session.activeWindow.disposeAll();
        commit("refreshTabs");
      });
    }
  },

  removeTabByID({commit}, windowid) {
    const tabs = global.session.tabs.filter(function (tab) {
      if (tab.id === windowid) {
        tab.dispose(true, false, true);
        return;
      }
      if (tab.id !== windowid) {
        return tab;
      }
    });

    global.session.tabs = tabs;
    commit("updateWindow");
  },

  replaceTabByIndex({commit}, {index, newWindow}) {
    global.session.tabs.splice(index, 0, newWindow);
    commit("refreshTabs");
    commit("setActiveWindowID", newWindow.id);
  },

  initializeTableElements({commit}, {window}) {
    initializeTableElements(window);
  },

  reopenLastWindow({state}) {
    const oldWindows = state.oldWindows;
    if (oldWindows) {
      for (const windowInput of oldWindows) {
        global.session.openWindow(windowInput);
      }
    }
  },
};

export default actions;
