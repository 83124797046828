
        
    const runtime = require("../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        
        const __nunjucks_template_import__dep_0 = require("../form/inputField.html");
        
        const __nunjucks_template_import__dep_1 = require("../content.html");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.assets};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/modals/modalGeneric.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("views/content/form/inputField.html", false, "views/content/modals/modalGeneric.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("inputField", t_1);
output += "\n";
env.getTemplate("views/content/content.html", false, "views/content/modals/modalGeneric.html", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("contentElement", t_4);
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "title")) {
output += "\n\t<div class=\"modal-header\">\n\t\t<h5 class=\"modal-title\" id=\"modalLable\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "title"), env.opts.autoescape);
output += "</h5>\n\t\t<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">\n\t\t\t<span aria-hidden=\"true\">&times;</span>\n\t\t</button>\n\t</div>\n";
;
}
output += "\n\n<form class=\"grid ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "simple")?"simple":""), env.opts.autoescape);
output += "\" id=\"modalGenericSend\">\n\t<div class=\"modal-body\">\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "type") == "image") {
output += "\n\t\t\t<img src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "url"), env.opts.autoescape);
output += "\" style=\"width: 100%\"/>\n\t\t";
;
}
else {
output += "\n\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "type") != "dialog") {
output += "\n\t\t\t<img src=\"/App_Themes/images/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "type"), env.opts.autoescape);
output += ".svg\">\n\t\t";
;
}
output += "\n\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "message")) {
output += "\n\t\t\t<h4>";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "message"), env.opts.autoescape);
output += "</h4>\n\t\t";
;
}
output += "\n\n\t\t";
frame = frame.push();
var t_9 = runtime.contextOrFrameLookup(context, frame, "content");
if(t_9) {t_9 = runtime.fromIterator(t_9);
var t_8 = t_9.length;
for(var t_7=0; t_7 < t_9.length; t_7++) {
var t_10 = t_9[t_7];
frame.set("element", t_10);
frame.set("loop.index", t_7 + 1);
frame.set("loop.index0", t_7);
frame.set("loop.revindex", t_8 - t_7);
frame.set("loop.revindex0", t_8 - t_7 - 1);
frame.set("loop.first", t_7 === 0);
frame.set("loop.last", t_7 === t_8 - 1);
frame.set("loop.length", t_8);
output += "\n\t\t\t";
if(runtime.memberLookup((t_10),"IsFooter") != true) {
output += "\n\t\t\t\t<div class=\"col-xs-12\">\n\t\t\t\t\t";
output += runtime.suppressValue((lineno = 29, colno = 39, runtime.callWrap(runtime.memberLookup((t_4),"constructContent"), "contentElement[\"constructContent\"]", context, [runtime.memberLookup((t_10),"Type"),runtime.memberLookup((t_10),"Text")])), env.opts.autoescape);
output += "\n\t\t\t\t</div>\n\t\t\t";
;
}
output += "\n\t\t";
;
}
}
frame = frame.pop();
output += "\n\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "args") && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "args")),"fields")) {
output += "\n\t\t\t";
frame = frame.push();
var t_13 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "args")),"fields");
if(t_13) {t_13 = runtime.fromIterator(t_13);
var t_12 = t_13.length;
for(var t_11=0; t_11 < t_13.length; t_11++) {
var t_14 = t_13[t_11];
frame.set("col", t_14);
frame.set("loop.index", t_11 + 1);
frame.set("loop.index0", t_11);
frame.set("loop.revindex", t_12 - t_11);
frame.set("loop.revindex0", t_12 - t_11 - 1);
frame.set("loop.first", t_11 === 0);
frame.set("loop.last", t_11 === t_12 - 1);
frame.set("loop.length", t_12);
output += "\n\t\t\t\t<div class=\"col-xs-12\">\n\t\t\t\t\t<label class=\"form-field\" ";
if(runtime.memberLookup((t_14),"Type") == "Boolean") {
output += " style=\"display: flex; justify-content: center;\" ";
;
}
output += ">\n\t\t\t\t\t\t<div class=\"form-label text-left\">";
output += runtime.suppressValue(runtime.memberLookup((t_14),"Title"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t";
if(runtime.memberLookup((t_14),"IsRequired")) {
output += "\n\t\t\t\t\t\t\t\t<span class=\"required-indicator\" title=\"\">*</span>\n\t\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class=\"form-input required-field ";
output += runtime.suppressValue((runtime.memberLookup((t_14),"Type") == "Money"?"money":""), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t";
var t_15;
t_15 = null;
frame.set("initialValue", t_15, true);
if(frame.topLevel) {
context.setVariable("initialValue", t_15);
}
if(frame.topLevel) {
context.addExport("initialValue", t_15);
}
output += "\n\t\t\t\t\t\t\t";
if(runtime.memberLookup((t_14),"Initial")) {
output += "\n\t\t\t\t\t\t\t\t";
var t_16;
t_16 = runtime.memberLookup((runtime.memberLookup((t_14),"Initial")),"Value");
frame.set("initialValue", t_16, true);
if(frame.topLevel) {
context.setVariable("initialValue", t_16);
}
if(frame.topLevel) {
context.addExport("initialValue", t_16);
}
output += "\n\t\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 48, colno = 37, runtime.callWrap(runtime.memberLookup((t_1),"createInputField"), "inputField[\"createInputField\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),t_14,runtime.memberLookup((t_14),"Type"),runtime.contextOrFrameLookup(context, frame, "initialValue"),runtime.memberLookup((t_14),"Initial"),null,null,runtime.contextOrFrameLookup(context, frame, "window")])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t";
;
}
else {
output += "\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "type") == "choice") {
output += "\n\t\t\t\t<select name=\"choice\">\n\t\t\t\t\t";
frame = frame.push();
var t_19 = runtime.contextOrFrameLookup(context, frame, "args");
if(t_19) {t_19 = runtime.fromIterator(t_19);
var t_18 = t_19.length;
for(var t_17=0; t_17 < t_19.length; t_17++) {
var t_20 = t_19[t_17];
frame.set("option", t_20);
frame.set("loop.index", t_17 + 1);
frame.set("loop.index0", t_17);
frame.set("loop.revindex", t_18 - t_17);
frame.set("loop.revindex0", t_18 - t_17 - 1);
frame.set("loop.first", t_17 === 0);
frame.set("loop.last", t_17 === t_18 - 1);
frame.set("loop.length", t_18);
output += "\n\t\t\t\t\t\t<option>";
output += runtime.suppressValue(t_20, env.opts.autoescape);
output += "</option>\n\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t</select>\n\t\t\t";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "type") == "date") {
output += "\n\t\t\t\t<div class=\"date-picker\">\n\t\t\t\t\t<input type=\"text\" name=\"givendate\" autocomplete=\"no\" class=\"editable-text date\">\n\t\t\t\t</div>\n\t\t\t";
;
}
;
}
output += "\n\t\t";
;
}
output += "\n\t";
;
}
output += "\n\t";
frame = frame.push();
var t_23 = runtime.contextOrFrameLookup(context, frame, "content");
if(t_23) {t_23 = runtime.fromIterator(t_23);
var t_22 = t_23.length;
for(var t_21=0; t_21 < t_23.length; t_21++) {
var t_24 = t_23[t_21];
frame.set("element", t_24);
frame.set("loop.index", t_21 + 1);
frame.set("loop.index0", t_21);
frame.set("loop.revindex", t_22 - t_21);
frame.set("loop.revindex0", t_22 - t_21 - 1);
frame.set("loop.first", t_21 === 0);
frame.set("loop.last", t_21 === t_22 - 1);
frame.set("loop.length", t_22);
output += "\n\t\t";
if(runtime.memberLookup((t_24),"IsFooter") == true) {
output += "\n\t\t\t";
output += runtime.suppressValue((lineno = 69, colno = 37, runtime.callWrap(runtime.memberLookup((t_4),"constructContent"), "contentElement[\"constructContent\"]", context, [runtime.memberLookup((t_24),"Type"),runtime.memberLookup((t_24),"Text")])), env.opts.autoescape);
output += "\n\t\t";
;
}
output += "\n\t";
;
}
}
frame = frame.pop();
output += "\n\t</div>\n\t<div class=\"modal-footer\">\n\n\t\t<div class=\"msg-actions\">\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "type") == "dialog") {
output += "\n\t\t\t\t<p class=\"button button-menu left\" data-dismiss=\"modal\" data-close data-dismiss=\"modal\" data-close>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Cancel"), env.opts.autoescape);
output += "</p>\n\t\t\t\t<button type=\"submit\" class=\"button button-default left\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Submit"), env.opts.autoescape);
output += "</button>\n\t\t\t";
;
}
else {
output += "\n\t\t\t\t<div class=\"text-center\">\n\t\t\t\t\t<button type=\"submit\" class=\"button button-default\" data-dismiss=\"modal\" data-close>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Cancel"), env.opts.autoescape);
output += "</button>\n\t\t\t\t</div>\n\t\t\t";
;
}
output += "\n\t\t</div>\n\t</div>\n</form>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/modals/modalGeneric.html", ctx);
          }
        
          return nunjucks.render("views/content/modals/modalGeneric.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/modals/modalGeneric.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        