<template>
  <div
    class="webinar-hint"
    v-if="
      setting !== false &&
      setting !== 'false' &&
      setting !== 'False' &&
      $store.state.accessToken
    "
  >
    <div class="webinar-hint-button" v-if="show === false" @click="show = true">
      <i class="fas fa-question"></i>
    </div>

    <div class="webinar-hint-label" v-if="show === false" @click="show = true">
      {{ webinar.Label }}
    </div>

    <div
      class="webinar-dismiss-button"
      v-if="show === false"
      @click="dismissYoutubeVideo()"
    >
      <i class="fas fa-times"></i>
    </div>

    <draggable-dialog v-if="show === true">
      <template v-slot:header>
        <div class="close" @click="show = false">
          <i class="fas fa-times float-right"></i>
        </div>
        {{ webinar.Title }}
      </template>
      <template v-slot:main>
        <iframe
          width="560"
          height="315"
          :src="webinar.URL"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </template>
    </draggable-dialog>
  </div>
</template>

<script>
import DraggableDialog from "../canvas/DraggableDialog.vue";

export default {
  components: {
    DraggableDialog,
  },
  data() {
    return {
      show: false,
      setting: false,
    };
  },
  computed: {
    webinar() {
      return (
        this.$store.getters.selectedWindow.output?.Options?.Webinar ?? false
      );
    },
    subject() {
      return this.$store.getters.selectedWindow?.output?.Data?.Subject;
    },
    settings() {
      return this.$store.state.user.settings[
        `${this.subject.replace(".", "")}-webinar`
      ];
    },
  },
  created() {
    this.setting =
      this.$store.state.user.settings[
        `${this.subject.replace(".", "")}-webinar`
      ]?.Value ?? null;
  },
  methods: {
    dismissYoutubeVideo() {
      this.$store.dispatch("user/setUserSetting", {
        key: `${this.subject.replace(".", "")}-webinar`,
        value: false,
      });
      this.setting =
        this.$store.state.user.settings[
          `${this.subject.replace(".", "")}-webinar`
        ]?.Value ?? null;
    },
  },
};
</script>

<style>
.close {
  cursor: pointer;
}
.dialog-drag {
  border: none;
  z-index: 9000000;
  border-radius: 3px !important;
}
.dialog-drag iframe {
  min-width: 650px;
  min-height: 378px;
}
button.pin {
  display: none;
}
.dialog-drag .dialog-header {
  background-color: #343a40;
  border-top-right-radius: 6px !important;
  border-top-left-radius: 6px !important;
  color: white;
  font-weight: 600;
  font-size: 0.9em;
  padding: 0.25em 3em 0.25em 1em;
  position: relative;
  text-align: left;
  width: auto;
}
.dialog-drag .dialog-body {
  padding: 0px;
  border: none;
  margin-bottom: -5px;
}

.dialog-drag .dialog-header button.close:after {
  content: "";
  font-family: "Font Awesome 5 Pro";
  color: white;
}

.webinar-hint {
  z-index: 9000;
  display: flex;
  position: fixed;
  bottom: 20px;
  left: 70px;
  align-items: center;
}

.webinar-hint-label {
  background-color: white;
  border-radius: 3px !important;
  height: 30px;
  margin-left: 10px;
  padding-right: 10px;
  border: 1px solid #e0e0e0;
  font-weight: 700;
  padding-left: 10px;
  box-shadow: 1px 1px 1px 0px rgb(0 0 0 / 17%) !important;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.webinar-dismiss-button {
  font-size: 14px;
  z-index: 2000000000000;
  background-color: #343a40;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: -3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  min-height: 30px;
  display: flex;
  align-items: center;
}

.webinar-dismiss-button:hover {
  cursor: pointer;
}
.webinar-hint-button {
  width: 50px;

  height: 50px;
  background-color: #f26522;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  z-index: 9034340999;
  font-size: 25px;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 1px 1px 1px 0px rgb(0 0 0 / 17%) !important;
}
</style>
