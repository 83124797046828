import $ from "jquery";
import Combobox from "../interface/combobox.class";
import Formatter from "../model/formatter";
import swal from "sweetalert";
import Hook from "../hook";
import {notify} from "../util/notify";

import store from "../../state/store";

/** Return view */
class ReturnHook extends Hook {
  /**
   * Setup variables
   * @param {Window} window - Window
   * @returns {Promise} Promise
   */
  async afterProcess(window) {
    if (!window.output.Data || window.output.Data.Type != "returnview") {
      return;
    }

    window.customData.canSelectValue = false;
    window.returnReturn = async () => {
      if (!window.customData.rows) {
        return;
      }

      if (window.customData.rows.filter((r) => r.selected).length == 0) {
        window.message(
          "warning",
          window.session.translations.AdminDesktop_AlertNoRowsSelected,
        );
        return;
      }

      let notes = $("[name=notes]").val();

      let result = await window.session.request(
        "/Admin/WebServices/ReturnWebServices.asmx/CreateReturn",
        {
          returnItems: window.customData.rows
            .filter((r) => r.selected)
            .map((r) => {
              r.old["NotesOnReturnHeader"] = notes;
              return r.old;
            }),
        },
      );

      await window.handleActionResponse(result);
    };

    let types = await window.session.combobox(
      "Rental.vw_ReturnType",
      "ReturnType",
    );

    window.customData.rows = [];
    window.customData.combobox = {
      type: Combobox.new(null, {
        name: "Type",
      }),
      value: Combobox.new(null, {
        name: "Value",
        tableName: "Rental.OrderItem",
        columnName: "ReturnValue",
        readOnly: true,
      }),
      scan: Combobox.new(null, {
        name: "search",
        freeType: true,
      }),
    };

    window.customData.combobox.type.populate(types);
    window.customData.isSearch = false;
    window.options.noNewLines = true;
    window.customData.columns = window.output.Table.Columns;
    window.customData.OnlyShowScan = window.output.Data.OnlyShowScan;
    window.customData.EnableSelectAll = true; // window.output.Data.EnableSelectAll
    window.customData.SortScannedRows = window.output.Data.SortScannedRows;
    window.customData.OnlyAutoCheckIfAmountEqualsAmountToReturn =
      window.output.Data.OnlyAutoCheckIfAmountEqualsAmountToReturn;

    window.output.Buttons = [
      {
        Event: "returnReturn",
        Title: window.session.translations.QuickReturn,
      },
      {
        Event: window.parent ? "reset:confirm" : "dispose:confirm",
        Title: window.session.translations.Cancel,
      },
    ];

    if (window.output.Data.DefaultType) {
      let value = window.output.Data.DefaultType;
      let text = types.find((type) => type.Value === value).Text;
      let isAll = value == "All";

      window.customData.combobox.type.specification.readOnly = true;
      window.customData.combobox.value.specification.readOnly = isAll;
      window.customData.combobox.scan.specification.readOnly = !isAll;

      window.customData.SelectText = text;
      window.customData.SelectValue = value;
      window.customData.combobox.type.setInitialValues(text, value);
      window.customData.isSearch = !isAll;
    }
  }

  /**
   * Rental view functionality
   * @param {Window} window - Window
   * @returns {Promise} Promise
   */
  async afterRender(window) {
    if (
      !window.output.Data ||
      window.output.Data.Type != "returnview" ||
      window.customInit
    ) {
      return;
    }

    window.customInit = true;

    $(window.element).off();

    if (window.input.Criteria.length === 1) {
      for (let index in window.customData.combobox.type.items) {
        let item = window.customData.combobox.type.items[index];

        if (window.input.Criteria[0][item.value]) {
          window.customData.SelectText = item.text;
          window.customData.SelectValue = item.value;
          window.customData.SelectionObjectValue =
            window.input.Criteria[0][item.value];

          scanSerial(window, null);

          window.customData.combobox.value.setInitialValues(
            window.customData.SelectionObjectValue,
            window.customData.SelectionObjectValue,
          );
          break;
        }
      }
    }

    $(window.element).on("value-accept", "[name=Type]", async function (arg) {
      let val = $(this).val();

      if (!val) {
        return;
      }

      let isAll = val == "All";

      window.customData.combobox.type.specification.readOnly = true;
      window.customData.combobox.value.specification.readOnly = isAll;
      window.customData.combobox.scan.specification.readOnly = !isAll;

      window.customData.SelectText = $(this)
        .closest(".combobox")
        .find("input.combobox-input")
        .val();
      window.customData.SelectValue = val;
      window.customData.combobox.type.setInitialValues(
        window.customData.SelectText,
        window.customData.SelectValue,
      );
      window.customData.isSearch = !isAll;

      let $valcombo = $(window.element)
        .find("[name=Value]")
        .closest(".combobox");
      $valcombo.find("input").val("");
      window.customData.combobox.value.specification.filter = {
        ReturnType: val,
      };

      await window.render();
    });

    $(window.element).on("change", "[name=Value]", async function (e, arg) {
      let val =
        typeof arg === "string" ? arg || $(this).val() : $(this).val() || null;

      // Value changed, clear the current customData
      window.customData.rows = [];

      // Clear the html rows
      $(".table-rows", $(this).closest(".window-body")).remove();

      window.customData.SelectionObjectValue = val;
      window.customData.combobox.value.setInitialValues(
        $(this).closest(".combobox").find("input.combobox-input").val(),
        val,
      );

      if (!val) {
        return;
      }

      scanSerial(window, null);
    });

    $(window.element).on(
      "value-accept",
      "[name=search]",
      async function (e, arg) {
        let val = (arg || $(this).val() || "").trim();
        $(this).val("");
        $(this).closest(".combobox").find("input.combobox-input").val("");

        if (!val) {
          return;
        }

        e.stopImmediatePropagation();

        scanSerial(window, val);
      },
    );

    $(window.element).on("keyup", "[name=scan]", async function (e) {
      if (e.which !== 13) {
        return;
      }

      let val = ($(this).val() || "").trim();
      $(this).val("");

      if (!val) {
        return;
      }

      e.stopImmediatePropagation();

      scanSerial(window, val);
    });

    $(window.element).on("click", ".table-body .field", function (e, arg) {
      if ($(document).find(".calendar-box").length) {
        // check if any .calendar-box exists without .hide

        if ($(document).find(".calendar-box:not(.hide)").length) {
          return;
        }
      }
      if ($(this).is("[contenteditable]") || $(this).is(".plusminusinput")) {
        return;
      }

      let $el = $(this).closest(".table-row").find(".selection-box input");
      $el.prop("checked", !$el.prop("checked")).change();
    });

    $(window.element).on("change", "[name='Option']", function (e) {
      let $row = $(this).closest(".table-row");
      let rowIndex = $row.data("rowid");

      window.customData.rows[rowIndex].old["Option"] = $(this).val();
    });

    $(window.element).on(
      "change value-change",
      ".table-body .plusminusinput-input",
      function (e) {
        let $row = $(this).closest(".table-row");
        let rowIndex = $row.data("rowid");
        let col = window.customData.columns[$(this).attr("data-field-index")];
        let colName = col.Name;
        let val = $(this).val();

        let newVal = Formatter.serializeValue(col, val);

        window.customData.rows[rowIndex].old[colName] = newVal;
        if (
          !window.customData.OnlyAutoCheckIfAmountEqualsAmountToReturn ||
          window.customData.rows[rowIndex].old["Amount"] ==
            window.customData.rows[rowIndex].old["AmountToReturn"]
        ) {
          selectRow(window, $row, true);
          sortRows(window, $row);
        }
      },
    );

    $(window.element).on(
      "blur paste change value-change",
      ".table-body [contenteditable]",
      function (e) {
        let $row = $(this).closest(".table-row");
        let rowIndex = $row.data("rowid");
        let colName = $(this)
          .attr("name")
          .replace(/(\[|\])/g, String());
        let col = window.customData.columns
          .filter((c) => c.Name === colName)
          .pop();
        let val = $(this).text();

        let newVal = Formatter.serializeValue(col, val);

        if (colName == "Counter") {
          let diff =
            newVal - window.customData.rows[rowIndex].old.MinCounterValue;
          window.customData.rows[rowIndex].old.OrderItemCounter = diff;
          $row.find("[name='OrderItemCounter[]']").text(String(diff));
        }

        window.customData.rows[rowIndex].old[colName] = newVal;

        if (
          !window.customData.OnlyAutoCheckIfAmountEqualsAmountToReturn ||
          window.customData.rows[rowIndex].old["Amount"] ==
            window.customData.rows[rowIndex].old["AmountToReturn"]
        ) {
          selectRow(window, $row, true);
          sortRows(window, $row);
        }
      },
    );

    $(window.element).on(
      "change",
      ".table-row > .selection-box input",
      async function (e, arg) {
        // if element with .calender-box exists return

        if ($(document).find(".calendar-box").length) {
          // check if any .calendar-box exists without .hide

          if ($(document).find(".calendar-box:not(.hide)").length) {
            return;
          }
        }

        let $row = $(this).closest(".table-row");

        if (!$row.length || !$row.attr("data-id")) {
          return;
        }

        let rowIndex = $row.data("rowid");
        let row = window.customData.rows[rowIndex];

        if (!row) {
          return;
        }

        // The server can send a confirmation warning, if the user cancels the swal message we stop processing

        if (row.old.ConfirmWarning && $(this).is(":checked")) {
          const confirm = await swal({
            icon: "warning",
            text: unescape(row.old.ConfirmWarning.replace(/(<([^>]+)>)/gi, "")),
            buttons: {
              cancel: global.session.translations.Cancel,
              confirm: "OK",
            },
          });

          if (!confirm) {
            $(this).trigger("click");
            return;
          } else {
            row.old.ConfirmWarning = null;
          }
        }

        let check = $(this).prop("checked");

        if (rowIndex > -1 && window.customData.rows.length > rowIndex) {
          window.customData.rows[rowIndex].selected = check;
        }

        sortRows(window, $row);

        let id = $row.attr("data-id");
        let $opt = $(window.element)
          .find("[name=search]")
          .closest(".combobox")
          .find(`.option[data-value='${id}']`);
        $opt.toggleClass("hide", check).toggleClass("exclude", check);

        let fieldIndexAmount = $("[data-column]", $(window.element)).index(
          $('[data-column="Amount"]'),
        );

        let cellAmount = $("[data-field-index=" + fieldIndexAmount + "]", $row);

        if (check) {
          $opt.removeClass("selected");

          if (cellAmount[0].tagName == "DIV") {
            if (+cellAmount.text() == 0) {
              cellAmount.text(+cellAmount.text() + 1);
            }
          } else {
            if (
              +cellAmount.val() < +cellAmount.attr("max") &&
              !(
                event.target &&
                $(event.target).parent().hasClass("plusminusinput")
              )
            ) {
              cellAmount.val(+cellAmount.val() + 1);
            }
          }

          selectRow(window, $(this).closest(".table-row"), true);
        } else {
          if (cellAmount[0]?.tagName == "DIV") {
            cellAmount.text(window.customData.rows[rowIndex].used.Amount);
          } else {
            cellAmount.val(window.customData.rows[rowIndex].used.Amount);
          }
        }
      },
    );
  }
}

function formatRow(window, r, serializeValues = false) {
  let row = {};

  for (let columnName in r) {
    let column = window.output.Data.Columns[columnName];

    if (column != null) {
      row[columnName] = serializeValues
        ? Formatter.serializeValue(column, r[columnName])
        : Formatter.parseValue(column, r[columnName]);
    }
  }

  return row;
}

function createCells(window, r) {
  let row = formatRow(window, r);
  let cells = {};

  for (let columnName in r) {
    let column = window.output.Data.Columns[columnName];

    if (column != null && column.Dropdown) {
      cells[columnName] = {
        Column: column,
        SerializedValue: row[columnName],
        Value: row[columnName],
        Initial: {
          Description: row[columnName],
          Value: row[columnName],
        },
        IsDirty: false,
      };

      /*
      new DropdownMetadata("Rental.OptionItem", "Option", new List<string> { "ItemID" })
            {
                OpenRef = false,
            };*/

      cells[columnName].Combobox = Combobox.new(cells[columnName], null);
    }
  }

  return cells;
}

function selectRow(window, $row, checkInput) {
  if ($(document).find(".calendar-box").length) {
    // check if any .calendar-box exists without .hide

    if ($(document).find(".calendar-box:not(.hide)").length) {
      return;
    }
  }
  if (checkInput) {
    $row.find(".selection-box input").prop("checked", true).change();
  } else {
    let fieldIndexReturnWarning = $("[data-column]", $(window.element)).index(
      $('[data-column="ReturnWarning"]'),
    );

    let cellReturnWarning = $(
      "[data-field-index=" + fieldIndexReturnWarning + "]",
      $row,
    );

    const message = cellReturnWarning.text().trim();
    if (message != "") {
      notify({
        message: message,
        type: "danger",
      });
    }
  }
}

function sortRows(window, $row) {
  // Move the selected row up or down depending on window.customData.SortScannedRows and it's checked value, make sure all unselected rows are last or first
  let $el = $row.find(".selection-box input");
  let upOrDown = "";
  if ($el.length != 1 || !$el.prop("checked")) {
    if (window.customData.SortScannedRows == 0) {
      upOrDown = "Down";
    } else {
      upOrDown = "Up";
    }
  } else {
    if (window.customData.SortScannedRows == 0) {
      upOrDown = "Up";
    } else {
      upOrDown = "Down";
    }
  }

  let $sibblingRow = $row;
  if (upOrDown == "Up") {
    $sibblingRow = $sibblingRow.prev();
  } else {
    $sibblingRow = $sibblingRow.next();
  }

  let $sibblingEl = $sibblingRow.find(".selection-box input");
  if (
    $el.length == 1 &&
    $sibblingEl.length == 1 &&
    !$el.prop("checked") &&
    !$sibblingEl.prop("checked")
  ) {
    return;
  }

  if ($sibblingRow.length == 1) {
    while ($sibblingRow) {
      $sibblingEl = $sibblingRow.find(".selection-box input");
      if (
        ($el.length != 1 ||
          $sibblingEl.length != 1 ||
          $el.prop("checked") != $sibblingEl.prop("checked")) &&
        ((upOrDown == "Up" && $sibblingRow.prev().length == 1) ||
          (upOrDown == "Down" && $sibblingRow.next().length == 1))
      ) {
        if (upOrDown == "Up") {
          $sibblingRow = $sibblingRow.prev();
        } else {
          $sibblingRow = $sibblingRow.next();
        }
      } else {
        if (
          $sibblingEl.length > 0 &&
          $sibblingEl.prop("checked") &&
          $el.length > 0 &&
          $el.prop("checked")
        ) {
          if (upOrDown == "Up") {
            $row.insertAfter($sibblingRow);
          } else {
            $row.insertBefore($sibblingRow);
          }
        } else {
          if (upOrDown == "Up") {
            $row.insertBefore($sibblingRow);
          } else {
            $row.insertAfter($sibblingRow);
          }
        }
        $sibblingRow = null;
      }
    }
  }
}

async function scanSerial(window, val) {
  window.customData.combobox.type.specification.readOnly = true;
  val = val?.replace(store.state.settings.ScanFieldFilterValue ?? "", "");

  let $rows = $(window.element)
    .find(".table-body [data-id]")
    .filter(function () {
      return (
        $(this).attr("data-id") != null &&
        val != null &&
        $(this).attr("data-id").toLowerCase() === val.toLowerCase()
      );
    });

  let $row = $rows.first().closest(".table-row");
  let $el = $row.find(".selection-box input");

  if ($el.length > 0) {
    let fieldIndexAmount = $("[data-column]", $(window.element)).index(
      $('[data-column="Amount"]'),
    );

    let cellAmount = $("[data-field-index=" + fieldIndexAmount + "]", $row);

    if (
      $el.prop("checked") &&
      (cellAmount[0].tagName == "DIV" ||
        +cellAmount.val() == +cellAmount.attr("max"))
    ) {
      await window.message(
        "warning",
        window.session.translations.ReturnItemAlreadySelected,
      );
      return;
    }

    if (!window.customData.OnlyAutoCheckIfAmountEqualsAmountToReturn) {
      selectRow(window, $row, true);
    } else {
      if (cellAmount[0].tagName == "DIV") {
        if (+cellAmount.text() == 0) {
          cellAmount.text(+cellAmount.text() + 1);
        }

        selectRow(window, $row, true);
      } else {
        if (+cellAmount.val() < +cellAmount.attr("max")) {
          cellAmount.val(+cellAmount.val() + 1);
        }

        cellAmount.change();
      }
    }

    if ($el.prop("checked")) {
      sortRows(window, $row);
    }
    await window.message(
      "success",
      window.session.translations.ReturnItemSuccesfullySelected,
    );
  } else {
    let opts = {
      selectionType: window.customData.SelectValue || null,
      selectionValue: window.customData.SelectionObjectValue || null,
      scanValue: val || null,
    };

    await requestRows(window, opts, val);
    // await window.message("warning", window.session.translations.ReturnItemNoneSelected)
  }
}

async function requestRows(window, opts, val) {
  window.toggleLoading(true);
  let newdata;
  try {
    newdata = await window.session.request(
      "/Admin/WebServices/ReturnWebServices.asmx/" +
        (val != null ? "ScanSerial" : "GetReturnSelectionData"),
      opts,
    );
  } catch (error) {
    window.toggleLoading(false);
    await window.swalMessage("warning", error.data.Message ?? error.data.Error);
    $("[name=scan], [name=search]").parent().find("input").first().focus();
    return;
  }

  window.toggleLoading(false);

  if (newdata.SerialInfo.length == 0) {
    await window.message("warning", window.session.translations.NoResults);
    return;
  }

  let lockedCustomerID;
  let lockedOrderID;
  let hasWarehouseMovements;

  for (let row of window.customData.rows || []) {
    if (row.selected) {
      lockedCustomerID = row.old.ActualCustomerID;
      lockedOrderID = row.old.OrderID;
      hasWarehouseMovements = row.old.IsWarehouseMovement;
      break;
    }
  }

  if (lockedCustomerID != null) {
    for (let newRow of newdata.SerialInfo || []) {
      if (newRow.IsWarehouseMovement != hasWarehouseMovements) {
        window.message(
          "warning",
          window.session.translations.CannotMixMovements,
        );
        return;
      }

      if (
        newRow.OrderID != lockedOrderID &&
        (window.customData.SelectValue == null ||
          window.customData.SelectValue == "Order")
      ) {
        window.message("warning", window.session.translations.CannotMixOrders);
        return;
      }

      if (
        $(window.element).find("[name='Type']").val() !== "All" &&
        newRow.ActualCustomerID != lockedCustomerID
      ) {
        window.message(
          "warning",
          window.session.translations.CannotMixCustomers,
        );
        return;
      }
    }
  }

  for (let r of newdata.SerialInfo) {
    let editable = {
      DateTimeRentalEnd: true,
      DateTimeReturned: true,
      Option: false, // Should be true if option is editable
      NotesOnReturn: true,
    };

    if (r.IsCounter) {
      r.Counter = r.MinCounterValue || 0;
      editable.Counter = true;
      r.OrderItemCounter = 0;
      editable.OrderItemCounter = true;
    }

    if (r.Amount || r.Amount == 0) {
      if (r.Amount > 1 || r.AmountToReturn > 1) {
        editable.Amount = true;
      }

      if (
        val != null &&
        r.ItemID == val &&
        r.Amount == 0 &&
        r.AmountToReturn >= 1
      ) {
        r.Amount = 1;
      }
    }

    let selected = val != null && r.ItemID == val;

    if (selected && r.ConfirmWarning) {
      // replace &eacute; in r.ConfirmWarning
      r.ConfirmWarning = r.ConfirmWarning.replaceAll(/&eacute;/g, "é");
      // &nbsp;
      r.ConfirmWarning = r.ConfirmWarning.replaceAll(/&nbsp;/g, " ");
      // &amp;
      r.ConfirmWarning = r.ConfirmWarning.replaceAll(/&amp;/g, "&");
      const confirm = await swal({
        icon: "warning",
        text: unescape(
          r.ConfirmWarning.replace(/(<([^>]+)>)/gi, "")
            .replaceAll("&auml;", "ä")
            .replaceAll("&uuml;", "ü"),
        ),
        buttons: {
          cancel: global.session.translations.Cancel,
          confirm: "OK",
        },
      });

      if (!confirm) {
        selected = false;
      } else {
        r.ConfirmWarning = null;
      }
    }

    window.customData.rows.push({
      used: formatRow(window, r),
      cells: createCells(window, r),
      old: r,
      editable: editable,
      selected: val != null && r.ItemID == val,
    });
  }

  window.customData.rows.sort(function (a, b) {
    if (a.selected && !b.selected) {
      if (window.customData.SortScannedRows == 0) {
        return -1;
      } else {
        return 1;
      }
    }
    if (!a.selected && b.selected) {
      if (window.customData.SortScannedRows == 0) {
        return 1;
      } else {
        return -1;
      }
    }
    return 0;
  });

  window.customData.combobox.type.setInitialValues(
    window.customData.SelectText,
    window.customData.SelectValue,
  );

  if (val == null) {
    window.customData.combobox.scan.specification.readOnly = false;
    window.customData.isSearch = true;

    let seen = {};
    window.customData.combobox.scan.populate(
      window.customData.rows
        .map((r) => ({
          Text: r.old.ItemID + " - " + r.old.ItemDescription,
          Value: r.old.ItemID,
        }))
        .filter((x) => (seen[x.Value] = !seen.hasOwnProperty(x.Value))),
    );
  }

  await window.render();

  $(window.element)
    .find(".selection-box input")
    .each(function () {
      let row = window.customData.rows[$(this).attr("data-commit-checkbox")];
      let selected = row != null && row.selected;
      $(this).prop("checked", selected);
      $(this).closest(".table-row").toggleClass("dirty", selected);

      if (selected) {
        selectRow(window, $(this).closest(".table-row"), true);
      }
    });

  /*On blur of Amount column, set amount to value + .00, just like for example quickrent form.
    The function valueChange in canvas.js does not work here (which handles the rounding normally), because it returns
    because the return webservice does not have Rows*/
  $(window.element).on("blur", "[name='Amount[]']", function () {
    let numericScale = window.output.Data.Columns["Amount"].NumericScale;
    let amountVal = Number($(this).text());

    $(this).text(amountVal.toFixed(numericScale));
  });
}

/** @ignore */
export default new ReturnHook();
