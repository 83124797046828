import { api } from "./api";
/**
 * Requests a validation email to verify the users email address
 * @param {string} username The username of the user being requested for
 * @param {string} username The password of the user being requested for
 * @returns {Object} Status message
 */
export async function requestValidationEmail({ username, password }) {
    const result = await api.post("/v2/twostep/sendemail", {
        username,
        password,
    }, {
        params: {
            username,
            password,
        },
    });
    return result.data;
}
export async function validateToken({ username, token, type }) {
    const result = await api.post("/v2/TwoStep/Validate", { username, twoFactorCode: token, type }, {
        params: {
            username,
            twoFactorCode: token,
            type,
        },
    });
    return result.data;
}
export async function requestQRCode({ username }) {
    const result = await api.get("/v2/TwoStep/CreateTwoFactorQr", {
        params: {
            username,
        },
    });
    return result.data;
}
