<template>
  <form v-on:submit.prevent="submit">
    <p><strong>2.</strong> {{ translations.TwoFactorSetupAppStepTwoTitle }}</p>

    <div class="input-group">
      <input
        id="input-1"
        class="form-control"
        v-model="verificationToken"
        maxlength="6"
        minlength="6"
        type="text"
        :placeholder="translations.TwoFactorSetupAppInputFieldTokenplaceholder"
        required
      />
      <div class="input-group-append">
        <button
          class="btn btn-primary ml-0"
          v-if="successMessage.length < 1"
          type="submit"
        >
          {{ translations.Activate }}
        </button>
      </div>
    </div>
    <div class="form-input w-100"></div>
    <div class="alert alert-success d-block mt-3" v-if="successMessage">
      {{ successMessage }}
    </div>

    <div class="alert alert-danger d-block mt-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
    <div class="text-right"></div>
  </form>
</template>

<script>
import {validateToken} from "@/services/twoStep";

export default {
  name: "TwoFactorSetupVerify",
  data() {
    return {
      verificationToken: null,
      successMessage: "",
      errorMessage: "",
      username: "",
    };
  },
  created() {
    this.username =
      document.querySelector("[name='username']")?.value ??
      this.$store.state.user.me.UserName;
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
  },
  methods: {
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    async submit() {
      this.errorMessage = "";
      const result = await validateToken({
        username: this.username,
        token: this.verificationToken,
      });

      if (result.Verified === true) {
        this.successMessage = this.translations.TwoFactorSetupVerifySuccess;
        await this.sleep(5000);
        location.reload();
        return;
      }
      if (result.Verified === false) {
        this.errorMessage = this.translations.TwoFactorSetupVerifyError;
        return;
      }

      this.errorMessage = this.translations.UnknownError;
    },
  },
};
</script>

<style scoped>
form {
  text-align: left;
  padding: 15px;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

input#input-1 {
  height: 31px !important;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
</style>
