
        
    const runtime = require("../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/modals/modalRelatedDimensionObjectsContainer.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div>\n    <h5>Top 10 ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"ModalDimensionObjectSelectorRelevantPersons"), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "companyName"), env.opts.autoescape);
output += "</h5>\n    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "relevantDimensionObjects")),"length") > 0) {
output += "\n        ";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "relevantDimensionObjects");
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("relevantDimensionObject", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n            <p style=\"cursor: pointer;\" dimensionObjectID=";
output += runtime.suppressValue(runtime.memberLookup((t_4),"DimensionObjectID"), env.opts.autoescape);
output += "><span class=\"text-primary\" ><i class=\"fas fa-arrow-alt-circle-right\"></i></span> ";
output += runtime.suppressValue(runtime.memberLookup((t_4),"Description"), env.opts.autoescape);
output += " (";
output += runtime.suppressValue(runtime.memberLookup((t_4),"Birthdate"), env.opts.autoescape);
output += ")</p>\n        ";
;
}
}
frame = frame.pop();
output += "\n    ";
;
}
else {
output += "\n        <p>-</p>\n    ";
;
}
output += "\n\n        ";
output += "\n    </div>\n    <div>\n        <h5>Top 10 ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"ModalDimensionObjectSelectorRelevantPersonsOtherCompanies"), env.opts.autoescape);
output += "</h5>\n        ";
output += "\n    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "otherRelatedDimensionObjects")),"length") > 0) {
output += "\n        ";
frame = frame.push();
var t_7 = runtime.contextOrFrameLookup(context, frame, "otherRelatedDimensionObjects");
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("relevantDimensionObject", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n            <p style=\"cursor: pointer;\" value=";
output += runtime.suppressValue(runtime.memberLookup((t_8),"DimensionObjectID"), env.opts.autoescape);
output += " dimensionObjectID=";
output += runtime.suppressValue(runtime.memberLookup((t_8),"DimensionObjectID"), env.opts.autoescape);
output += ">\n            <span class=\"text-primary\" ><i class=\"fas fa-arrow-alt-circle-right\"></i></span> ";
output += runtime.suppressValue(runtime.memberLookup((t_8),"Description"), env.opts.autoescape);
output += " (";
if(runtime.memberLookup((t_8),"Birthdate")) {
output += " ";
output += runtime.suppressValue(runtime.memberLookup((t_8),"Birthdate"), env.opts.autoescape);
output += " ";
;
}
else {
output += " - ";
;
}
output += ")\n                <br>\n                <strong>";
output += runtime.suppressValue(runtime.memberLookup((t_8),"CompanyName"), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(runtime.memberLookup((t_8),"City"), env.opts.autoescape);
output += "</strong>\n            </p>\n        ";
;
}
}
frame = frame.pop();
output += "\n    ";
;
}
else {
output += "\n        <p>-</p>\n    ";
;
}
output += "\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/modals/modalRelatedDimensionObjectsContainer.html", ctx);
          }
        
          return nunjucks.render("views/content/modals/modalRelatedDimensionObjectsContainer.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/modals/modalRelatedDimensionObjectsContainer.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        