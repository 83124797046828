
        
    const runtime = require("../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/login.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"container\">\n\t<div class=\"box pull-center\">\n\t\t<form method=\"post\">\n\t\t\t<div class=\"logo\">\n\t\t\t\t<span class=\"logo-icon infodatek-icon infodatek-icon-";
output += runtime.suppressValue(env.getFilter("lower").call(context, runtime.contextOrFrameLookup(context, frame, "product")), env.opts.autoescape);
output += "\"></span><br>\n\t\t\t\t<span class=\"logo-text\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "product"), env.opts.autoescape);
output += "</span>\n\t\t\t</div>\n\n\t\t\t<div class=\"spinner pull-center ";
output += runtime.suppressValue((!runtime.contextOrFrameLookup(context, frame, "loading")?"hide":""), env.opts.autoescape);
output += "\"></div><br>\n\n\t\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ssoData")),"status") != null) {
output += "\n\t\t \t\t<div>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ssoData")),"status")), env.opts.autoescape);
output += "</div>\n\t\t \t";
;
}
output += "\n\n\t\t\t";
if(!runtime.contextOrFrameLookup(context, frame, "active")) {
output += "\n\t\t\t\t<div class=\"form-element\">\n\t\t\t\t\t<select name=\"language\" autofocus>\n\t\t\t\t\t\t";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "languages");
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("lang", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t\t\t\t\t<option value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"ID"), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((runtime.memberLookup((t_4),"ID") == runtime.contextOrFrameLookup(context, frame, "language")?"selected":""), env.opts.autoescape);
output += ">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"Name"), env.opts.autoescape);
output += "</option>\n\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</select>\n\t\t\t\t</div>\n\t\t\t";
;
}
output += "\n\t\t\t\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "err")) {
output += "\n\t\t\t\t<div class=\"error-box\">";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "err")),"data") && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "err")),"data")),"Data") && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "err")),"data")),"Data")),"Translation")?runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "err")),"data")),"Data")),"Translation"):runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "err")),"message")), env.opts.autoescape);
output += "</div>\n\t\t\t";
;
}
output += "\n\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "screen") == null) {
output += "\n\t\t\t\n\t\t\t\t<div class=\"form-element\">\n\t\t\t\t\t<input type=\"text\" name=\"username\" required placeholder=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"UserName"), env.opts.autoescape);
output += "\" class=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ssoData")),"token")?"d-none":""), env.opts.autoescape);
output += "\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "form")),"username") || runtime.contextOrFrameLookup(context, frame, "username") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ssoData")),"userid"), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "active")?"disabled":""), env.opts.autoescape);
output += ">\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"form-element with-side\">\n\t\t\t\t\t<div class=\"show-password material-icons ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ssoData")),"token")?"d-none":""), env.opts.autoescape);
output += "\">&#xe8f4;</div>\n\t\t\t\t\t<input type=\"password\" name=\"password\" autocomplete=\"current-password\" class=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ssoData")),"token")?"d-none":""), env.opts.autoescape);
output += "\" required value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "form")),"password") || runtime.contextOrFrameLookup(context, frame, "password") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ssoData")),"token"), env.opts.autoescape);
output += "\" placeholder=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"Password"), env.opts.autoescape);
output += "\">\n\t\t\t\t</div>\n\n                ";
if(runtime.contextOrFrameLookup(context, frame, "twoFactorAuthentication")) {
output += "\n                    <div class=\"form-element\">\n                        <input type=\"text\" name=\"twoFactorCode\" placeholder=\"Two-factor code\" >\n                    </div>\n\n                    ";
if(runtime.contextOrFrameLookup(context, frame, "twoStepSetupRequired")) {
output += "\n                        <div class=\"form-element\">\n                            <button name=\"setup2fa\" class=\"button button-default \">Setup my 2FA</button>\n                        </div>\n                        <div id=\"two-factor-authentication\"></div>\n                    ";
;
}
output += "\n                ";
;
}
output += "\n\n\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "err") && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "err")),"data") && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "err")),"data")),"KickUsers")) {
output += "\n\t\t\t\t\t<div class=\"form-element\">\n\t\t\t\t\t\t<select name=\"kick\">\n\t\t\t\t\t\t\t";
frame = frame.push();
var t_7 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "err")),"data")),"KickUsers");
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("user", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n\t\t\t\t\t\t\t\t<option value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_8),"Key"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_8),"Value"), env.opts.autoescape);
output += "</option>\n\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t</select>\n\t\t\t\t\t</div>\n\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t<input type=\"hidden\" name=\"kick\" value=\"\">\n\t\t\t\t";
;
}
output += "\n\n\t\t\t\t<div class=\"form-element\">\n\t\t\t\t\t<button name=\"login\" class=\"button button-default login-button\" type=\"submit\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"LogIn"), env.opts.autoescape);
output += "</button>\n\t\t\t\t\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"form-element\">\n\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "googleSSO") && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ssoData")),"token") == null) {
output += "\n\t\t\t\t\t\t<a name=\"logingooglesso\" class=\"button button-default login-button\" type=\"submit\" href=\"/api/v2/saml/start\">";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"LoginUsingGoogle")), env.opts.autoescape);
output += "</a>\n\t\t\t\t\t";
;
}
output += "\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"form-element ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ssoData")),"token")?"d-none":""), env.opts.autoescape);
output += "\">\n\t\t\t\t\t<button class=\"button button-menu ";
if(runtime.contextOrFrameLookup(context, frame, "canRegister")) {
output += " half ";
;
}
output += " password-lost-button\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"ForgotPassword"), env.opts.autoescape);
output += "</button>\n\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "canRegister")) {
output += "\n\t\t\t\t\t\t<button class=\"button button-menu half register-button\" type=\"submit\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"Register"), env.opts.autoescape);
output += "</button>\n\t\t\t\t\t";
;
}
output += "\n\t\t\t\t</div>\n\t\t\t";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "screen") == "ResetPassword") {
output += "\n\t\t\t\t<div class=\"form-element with-side \">\n\t\t\t\t\t<div class=\"show-password material-icons\">&#xe8f4;</div>\n\t\t\t\t\t<input type=\"password\" name=\"password\" placeholder=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"Password"), env.opts.autoescape);
output += "\">\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"form-element with-side\">\n\t\t\t\t\t<div class=\"show-password material-icons\">&#xe8f4;</div>\n\t\t\t\t\t<input type=\"password\" name=\"confirmPassword\" placeholder=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"ConfirmPassword"), env.opts.autoescape);
output += "\">\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"form-element\">\n\t\t\t\t\t<button class=\"button button-default password-reset-button\" type=\"submit\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"ChangePassword"), env.opts.autoescape);
output += "</button>\n\t\t\t\t</div>\n\t\t\t";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "screen") == "ForgotPassword") {
output += "\n\t\t\t\t<p>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"ForgotPasswordInfo"), env.opts.autoescape);
output += "</p>\n\n\t\t\t\t<div class=\"form-element\">\n\t\t\t\t\t<input type=\"text\" name=\"email\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "form")),"email"), env.opts.autoescape);
output += "\" placeholder=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"EmailAddress"), env.opts.autoescape);
output += "\">\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"form-element\">\n\t\t\t\t\t<button class=\"button button-menu half login-screen-return\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"Cancel"), env.opts.autoescape);
output += "</button>\n\t\t\t\t\t<button class=\"button button-default half password-request-button\" type=\"submit\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"Submit"), env.opts.autoescape);
output += "</button>\n\t\t\t\t</div>\n\t\t\t";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "screen") == "Register") {
output += "\n\t\t\t\t<p>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"RegisterUserInfo"), env.opts.autoescape);
output += "</p>\n\t\t\t\t<div class=\"form-element\">\n\t\t\t\t\t<input type=\"text\" name=\"username\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "form")),"username"), env.opts.autoescape);
output += "\" placeholder=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"UserName"), env.opts.autoescape);
output += " \">\n\t\t\t\t</div>\n\t\t\t\t<div class=\"form-element\">\n\t\t\t\t\t<input type=\"text\" name=\"email\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "form")),"email"), env.opts.autoescape);
output += "\" placeholder=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"EmailAddress"), env.opts.autoescape);
output += " \">\n\t\t\t\t</div>\n\t\t\t\t<div class=\"form-element\">\n\t\t\t\t\t<input type=\"password\" name=\"password\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "form")),"password"), env.opts.autoescape);
output += "\" placeholder=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"Password"), env.opts.autoescape);
output += "\">\n\t\t\t\t</div>\n\t\t\t\t<div class=\"form-element\">\n\t\t\t\t\t<input type=\"password\" name=\"confirmPassword\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "form")),"confirmPassword"), env.opts.autoescape);
output += "\" placeholder=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"ConfirmPassword"), env.opts.autoescape);
output += "\">\n\t\t\t\t</div>\n\t\t\t\t<div class=\"form-element\">\n\t\t\t\t\t<button class=\"button button-menu half login-screen-return\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"Cancel"), env.opts.autoescape);
output += "</button>\n\t\t\t\t\t<button class=\"button button-default half register-send-button\" type=\"submit\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"Register"), env.opts.autoescape);
output += "</button>\n\t\t\t\t</div>\n\t\t\t";
;
}
;
}
;
}
;
}
output += "\n\t\t</form>\n\t</div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/login.html", ctx);
          }
        
          return nunjucks.render("views/login.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/login.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        