
        
    const runtime = require("../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        const __nunjucks_template_import__dep_0 = require("./formElement.html");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets};
    
    
            const __nunjucks_template_import__global_generateID = require("../../../src/util/generateRandomID.js");
            __nunjucks_module_dependencies__.globals['generateID'] = {
                module: __nunjucks_template_import__global_generateID && __nunjucks_template_import__global_generateID.default || __nunjucks_template_import__global_generateID
            };
            const __nunjucks_template_import__global_translation = require("../../../src/util/translation.js");
            __nunjucks_module_dependencies__.globals['translation'] = {
                module: __nunjucks_template_import__global_translation && __nunjucks_template_import__global_translation.default || __nunjucks_template_import__global_translation
            };
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/form/columnGroup.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("views/content/form/formElement.html", false, "views/content/form/columnGroup.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("formElement", t_1);
output += "\n";
var macro_t_4 = runtime.makeMacro(
["data", "section"], 
[], 
function (l_data, l_section, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", l_data);
frame.set("section", l_section);
var t_5 = "";t_5 += "\n  ";
frame = frame.push();
var t_8 = runtime.memberLookup((l_section),"Columns");
if(t_8) {t_8 = runtime.fromIterator(t_8);
var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("col", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
t_5 += "\n    ";
if(runtime.memberLookup((t_9),"IsVisible") && !runtime.memberLookup((t_9),"IsAdvanced")) {
t_5 += "\n      ";
t_5 += runtime.suppressValue((lineno = 5, colno = 37, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "formElement")),"createFormElement"), "formElement[\"createFormElement\"]", context, [l_data,t_9,runtime.memberLookup((t_9),"Name")])), env.opts.autoescape);
t_5 += "\n    ";
;
}
t_5 += "\n  ";
;
}
}
frame = frame.pop();
t_5 += "\n\n  <!-- Render advanced fields after the normal fields so they are at the bottom of the section -->\n  ";
if(env.getFilter("length").call(context, runtime.memberLookup((l_section),"AdvancedColumns"))) {
t_5 += "\n    ";
var t_10;
t_10 = (lineno = 11, colno = 32, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "generateID"), "generateID", context, [7]));
frame.set("randomID", t_10, true);
if(frame.topLevel) {
context.setVariable("randomID", t_10);
}
if(frame.topLevel) {
context.addExport("randomID", t_10);
}
t_5 += "\n    <div class=\"col-12 ml-0 text-right\">\n      <button\n        class=\"btn btn-link ml-0 pl-2\"\n        type=\"button\"\n        display-toggle\n        data-target=\"#";
t_5 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "randomID"), env.opts.autoescape);
t_5 += "\"\n      >\n        <i class=\"fas fa-chevron-down\"></i>\n        ";
t_5 += runtime.suppressValue((lineno = 20, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "translation"), "translation", context, ["ShowAdvanced"])), env.opts.autoescape);
t_5 += "\n      </button>\n      <div class=\"collapse\" id=\"";
t_5 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "randomID"), env.opts.autoescape);
t_5 += "\" data-collapse style=\"display:none;\">\n        <div class=\"row no-gutters text-left\">\n\n          ";
frame = frame.push();
var t_13 = runtime.memberLookup((l_section),"AdvancedColumns");
if(t_13) {t_13 = runtime.fromIterator(t_13);
var t_12 = t_13.length;
for(var t_11=0; t_11 < t_13.length; t_11++) {
var t_14 = t_13[t_11];
frame.set("col", t_14);
frame.set("loop.index", t_11 + 1);
frame.set("loop.index0", t_11);
frame.set("loop.revindex", t_12 - t_11);
frame.set("loop.revindex0", t_12 - t_11 - 1);
frame.set("loop.first", t_11 === 0);
frame.set("loop.last", t_11 === t_12 - 1);
frame.set("loop.length", t_12);
t_5 += "\n            ";
if(runtime.memberLookup((t_14),"IsVisible")) {
t_5 += "\n              ";
t_5 += runtime.suppressValue((lineno = 27, colno = 45, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "formElement")),"createFormElement"), "formElement[\"createFormElement\"]", context, [l_data,t_14,runtime.memberLookup((t_14),"Name")])), env.opts.autoescape);
t_5 += "\n            ";
;
}
t_5 += "\n          ";
;
}
}
frame = frame.pop();
t_5 += "\n        </div>\n      </div>\n    </div>\n    ";
;
}
t_5 += "\n  ";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("createColumnGroup");
context.setVariable("createColumnGroup", macro_t_4);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/form/columnGroup.html", ctx);
          }
        
          return nunjucks.render("views/content/form/columnGroup.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/form/columnGroup.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        