import $ from "jquery";
import Hook from "../hook";

/** Price condition */
class PriceConditionHook extends Hook {
  /**
   * Add Price condition event
   * @param {Window} window - Window
   * @returns {Promise} Promise
   */
  async afterRender(window) {
    if (
      window.output.Request.Subject != "Rental.PriceCondition" ||
      window.output.Request.Prefix == "Multi"
    ) {
      return;
    }

    let $exclusiveInputs = [
      $("[name='ItemID']", window.element),
      $("[name='CompositionID']", window.element),
      $("[name='ItemGroupID']", window.element),
    ];

    let clearOthers = function clearOthers() {
      let $otherInputs = $exclusiveInputs.filter(($x) => !$x.is(this));

      for (let $input of $otherInputs) {
        $input.val("");
        $input.parent().find(".combobox-input").val("");
      }
    };

    for (let $input of $exclusiveInputs) {
      $input.off("change");
      $input.change(clearOthers);
    }

    /*$(window.element).on("value-accept", "[name='RentCode']", function() {
				let rentCode = $("[name='RentCode']", window.element)
				rentCode.parent().find(".combobox-input").prop("disabled", true)
				rentCode.parent().toggleClass("disabled", true)
		})*/

    let rentCode = $(window.element).find("[name='RentCode']");
    rentCode.parent().find(".combobox-input").prop("disabled", true);
    rentCode.parent().addClass("disabled");

    $(window.element).on("value-accept", "[name='RentType']", function () {
      let rentType = $("[name='RentType']", window.element);
      rentType.parent().find(".combobox-input").prop("disabled", true);
      rentType.parent().toggleClass("disabled", true);

      let rentCode = $("[name='RentCode']", window.element);
      rentCode.parent().find(".combobox-input").prop("disabled", false);
      rentCode.parent().removeClass("disabled");
    });
  }
}

/** @ignore */
export default new PriceConditionHook();

/* async afterProcess(window) {
	if (window.output.Request.Subject != "Rental.PriceCondition" || window.output.Request.Prefix == "Multi") {
        return
    }
	let rentCode = $(window.element).find("[name='RentCode']")
	rentCode.parent().find(".combobox-input").prop("disabled", true)
	rentCode.parent().addClass("disabled")
} */
