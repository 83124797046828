import {api} from "./api";
import store from "../../state/store";
import {NewRowRequestDataModel} from "@/models/datagrid/DatagridNewRowRequestData";

export async function newRow(requestData) {
  const result = await api.post(`/v2/datagrid/new`, requestData.criteria, {
    params: {subject: requestData.subject},
    headers: {
      Authorization: `Bearer ${store.state.accessToken}`,
    },
  });

  return result.data.Data;
}

export async function newScanRow(requestData) {
  const result = await api.post(`/v2/datagrid/scan`, requestData.criteria, {
    params: {subject: requestData.subject, search: requestData.search},
    headers: {
      Authorization: `Bearer ${store.state.accessToken}`,
    },
  });

  return result.data.Data;
}

/**
 * Save table data
 * @param {object} requestData
 * @param {string} requestData.subject
 * @param {object} requestData.data
 * @returns {object}
 */
export async function saveTable(requestData) {
  const result = await api.post(`/v2/datagrid/save`, requestData.data, {
    params: {subject: requestData.subject},
    headers: {
      Authorization: `Bearer ${store.state.accessToken}`,
    },
  });

  return result.data.Data;
}

/**
 * Change row data
 * @param {object} requestData
 * @param {string} requestData.subject
 * @param {object} requestData.data
 * @returns {object}
 */
export async function changeRow(requestData) {
  const result = await api.post(`/v2/datagrid/change`, requestData.data, {
    params: {subject: requestData.subject},
    headers: {
      Authorization: `Bearer ${store.state.accessToken}`,
    },
  });

  return result.data.Data;
}
