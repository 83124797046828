<template>
  <div class="w-100">
    <date-range-picker
      ref="picker"
      class="float-right"
      :locale-data="dataFormat"
      v-model="dateRange"
      :date-format="dateFormat"
      :showDropdowns="true"
      :autoApply="true"
      :ranges="false"
      :singleDatePicker="true"
      opens="right"
      @change-month="updateClosingDays($event)"
    >
    </date-range-picker>
    <date-field
      :date="exposedStartDate"
      :showTime="showTime"
      :originalValue="value"
      :name="name"
      :readonly="readonly"
      :randomID="randomID"
      @change="updateStartDate($event)"
    >
      <b-button v-if="readonly != 'true'" variant="link" @click="togglePicker()"
        ><i class="fas fa-calendar-alt text-dark"></i></b-button
    ></date-field>

    <input
      :id="randomID"
      vue-input
      v-model="exposedStartDate"
      :name="name"
      hidden
    />
  </div>
</template>

<script>
import DateRangePicker from "./calendar/DateRangePicker.vue";

import dayjs from "dayjs";
import Axios from "axios";
import generateRandomID from "../../src/util/generateRandomID";

import dateField from "./dateField.vue";

export default {
  name: "DateTime",
  components: {"date-range-picker": DateRangePicker, dateField},
  props: {
    value: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    index: {
      type: String,
      required: true,
    },
    postback: {
      type: Boolean,
      required: false,
      default: false,
    },
    filterOnOpeningsDay: {
      type: String,
      required: false,
      default: "false",
    },
    showTime: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      dateRange: {
        startDate: "",
        endDate: "",
      },
      exposedStartDate: "",
      disabledDates: [],
      checkedDates: [],
    };
  },
  computed: {
    startDate: function () {
      if (this.dateRange.startDate) {
        return dayjs(this.dateRange.startDate).format("DD-MM-YYYY");
      }
      return "";
    },
    randomID: function () {
      return generateRandomID(8);
    },
    translations: function () {
      return this.$store.state.translations;
    },
    dataFormat: function () {
      return {
        direction: "ltr",
        format: "dd-mm-yyyy",
        separator: " - ",
        changed: false,
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: [
          this.translations["calendar-sun"],
          this.translations["calendar-mon"],
          this.translations["calendar-tue"],
          this.translations["calendar-wed"],
          this.translations["calendar-thu"],
          this.translations["calendar-fri"],
          this.translations["calendar-sat"],
        ],
        monthNames: [
          this.translations["calendar-jan"],
          this.translations["calendar-feb"],
          this.translations["calendar-mar"],
          this.translations["calendar-apr"],
          this.translations["calendar-may"],
          this.translations["calendar-jun"],
          this.translations["calendar-jul"],
          this.translations["calendar-aug"],
          this.translations["calendar-sep"],
          this.translations["calendar-oct"],
          this.translations["calendar-nov"],
          this.translations["calendar-dec"],
        ],
        firstDay: 0,
      };
    },
  },
  watch: {
    dateRange: function () {
      this.changed = true;
      if (
        dayjs(this.dateRange.startDate).format("DD-MM-YYYY") !== "Invalid Date"
      ) {
        this.exposedStartDate = dayjs(this.dateRange.startDate).format(
          "DD-MM-YYYY",
        );
      }
    },
    startDate: function () {
      if (this.changed) {
        // eslint-disable-next-line no-undef
        $(document).find(`#${this.randomID}`).trigger("change");
        if (this.postback == true) {
          this.processChange();
        }
        global.session.activeWindow.sub.window.dirty = true;
        return;
      }
    },
  },
  created: async function () {
    if (this.value) {
      this.dateRange.startDate = dayjs(this.value, "DD-MM-YYYY").format(
        "YYYY-MM-DD",
      );
      this.updateClosingDays(this.dateRange.startDate);
    } else {
      this.updateClosingDays();
    }

    if (
      dayjs(this.value, "DD-MM-YYYY").format("DD-MM-YYYY") !== "Invalid Date"
    ) {
      this.exposedStartDate = dayjs(this.value, "DD-MM-YYYY").format(
        "DD-MM-YYYY",
      );
    } else if (dayjs(this.value).format("DD-MM-YYYY") !== "Invalid Date") {
      this.exposedStartDate = dayjs(this.value).format("DD-MM-YYYY");
    }
  },
  methods: {
    updateStartDate(val) {
      if(val === ''){
        this.dateRange.startDate = null;
        this.dateRange.endDate = null;
        this.exposedStartDate = null;
      }
      this.dateRange.startDate = dayjs(val).toDate();
      this.dateRange.endDate = dayjs(val).toDate();

      if (
        dayjs(this.dateRange.startDate).format("DD-MM-YYYY") !== "Invalid Date"
      ) {
        this.exposedStartDate = dayjs(this.dateRange.startDate).format(
          "DD-MM-YYYY",
        );
      }
    },
    togglePicker() {
      this.$refs.picker.openPicker();
    },
    processChange() {
      const values = {};
      for (let rowcell of this.$store.state.window.output?.FullTable?.Rows[0] ??
        this.$store.state.window.output.Sub.Table.Rows[0]) {
        values[rowcell.Column.Name] = rowcell.NewValue;
      }

      values[this.name] = this.startDate;

      Promise.resolve(global.session.activeWindow.postbackValues(values));
    },
    dateFormat(classes, date) {
      if (this.disabledDates.length > 0) {
        for (const disabledDate of this.disabledDates) {
          if (dayjs(disabledDate).isSame(dayjs(date), "day")) {
            classes.disabled = true;
          }
        }
      }
      return classes;
    },
    updateClosingDays: async function (date) {
      if (this.filterOnOpeningsDay === "false") {
        return;
      }

      if (!date) {
        date = new Date();
      }

      if (this.checkedDates.includes(dayjs(date).format("YYYY-MM-DD"))) {
        return;
      }

      const start = dayjs(date).subtract("2", "month").format("YYYY-MM-DD");
      const end = dayjs(date).add("2", "month").format("YYYY-MM-DD");

      const disabledDates = await Axios.get("/api/v2/closingdays", {
        params: {
          warehouse: this.$store.state.activeWarehouse,
          dateFrom: start,
          dateTo: end,
        },
        headers: {
          Authorization: `Bearer ${this.$store.state.accessToken}`,
        },
      });

      this.disabledDates = this.disabledDates.concat(disabledDates.data);

      this.checkedDates.push(dayjs(date).format("YYYY-MM-DD"));
    },
  },
};
</script>

<style lang="scss">
// .vue-daterange-picker[data-v-00277188] {
//   position: relative;
//   display: inline-block;
//   min-width: 60px;
// }
.vue-daterange-picker {
  border: 1px solid #ced4da;
  border-radius: 3px;
  height: 31.5px;
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.09) !important;
}

.reportrange-text {
  border: none !important;
}
.reportrange-text[data-v-00277188] {
  background: #fff;
  cursor: pointer;
  padding: 5px 10px;

  width: 100%;
}
</style>
