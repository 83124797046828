
        
    const runtime = require("../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        
        
        
        
        
        const __nunjucks_template_import__dep_0 = require("./formInput.html");
        
        const __nunjucks_template_import__dep_1 = require("./inputValue.html");
        
        const __nunjucks_template_import__dep_2 = require("../../elements/combobox.html");
        
        const __nunjucks_template_import__dep_3 = require("../../elements/vueDateTime.html");
        
        const __nunjucks_template_import__dep_4 = require("../../elements/vueCkeditor.html");
        
        const __nunjucks_template_import__dep_5 = require("../../elements/vueFormField.html");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_4.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_5.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_4.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_5.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_4.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_5.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_4.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_5.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_4.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_5.__nunjucks_module_dependencies__.assets};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/form/inputField.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("views/content/form/formInput.html", false, "views/content/form/inputField.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("forminput", t_1);
output += "\n";
env.getTemplate("views/content/form/inputValue.html", false, "views/content/form/inputField.html", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("inputValue", t_4);
output += "\n";
env.getTemplate("views/elements/combobox.html", false, "views/content/form/inputField.html", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
context.setVariable("combo", t_7);
output += "\n";
env.getTemplate("views/elements/vueDateTime.html", false, "views/content/form/inputField.html", false, function(t_11,t_10) {
if(t_11) { cb(t_11); return; }
t_10.getExported(function(t_12,t_10) {
if(t_12) { cb(t_12); return; }
context.setVariable("vueDateTime", t_10);
output += "\n";
env.getTemplate("views/elements/vueCkeditor.html", false, "views/content/form/inputField.html", false, function(t_14,t_13) {
if(t_14) { cb(t_14); return; }
t_13.getExported(function(t_15,t_13) {
if(t_15) { cb(t_15); return; }
context.setVariable("vueCkeditor", t_13);
output += "\n";
env.getTemplate("views/elements/vueFormField.html", false, "views/content/form/inputField.html", false, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
t_16.getExported(function(t_18,t_16) {
if(t_18) { cb(t_18); return; }
context.setVariable("vueFormField", t_16);
output += "\n\n";
var macro_t_19 = runtime.makeMacro(
["session", "col", "coltype", "value", "initial", "index", "cell"], 
[], 
function (l_session, l_col, l_coltype, l_value, l_initial, l_index, l_cell, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("session", l_session);
frame.set("col", l_col);
frame.set("coltype", l_coltype);
frame.set("value", l_value);
frame.set("initial", l_initial);
frame.set("index", l_index);
frame.set("cell", l_cell);
var t_20 = "";t_20 += "\n  <!-- START -->\n  ";
if(runtime.memberLookup((l_col),"IsAutoNumber") && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Request")),"Prefix") == "New") {
t_20 += "\n    ";
t_20 += runtime.suppressValue((lineno = 10, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "constructFormInput"), "constructFormInput", context, ["text",l_col,l_coltype,l_index,l_value])), env.opts.autoescape);
t_20 += "\n  ";
;
}
else {
if(l_cell && runtime.memberLookup((l_cell),"Combobox")) {
t_20 += "\n    ";
t_20 += runtime.suppressValue((lineno = 12, colno = 30, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "combo")),"constructCombobox"), "combo[\"constructCombobox\"]", context, [l_session,runtime.memberLookup((l_cell),"Combobox")])), env.opts.autoescape);
t_20 += "\n  ";
;
}
else {
if(runtime.memberLookup((l_col),"Combobox")) {
t_20 += "\n    ";
t_20 += runtime.suppressValue((lineno = 14, colno = 30, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "combo")),"constructCombobox"), "combo[\"constructCombobox\"]", context, [l_session,runtime.memberLookup((l_col),"Combobox")])), env.opts.autoescape);
t_20 += "\n  ";
;
}
else {
if(l_coltype == "String" && runtime.memberLookup((l_col),"Editor") == "ckeditor" && runtime.memberLookup((l_col),"IsReadOnly") != true) {
t_20 += "\n    ";
t_20 += runtime.suppressValue((lineno = 16, colno = 42, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "vueCkeditor")),"generateCkEditorElement"), "vueCkeditor[\"generateCkEditorElement\"]", context, [l_value,runtime.memberLookup((l_col),"Name"),l_index])), env.opts.autoescape);
t_20 += "\n  ";
;
}
else {
if(runtime.memberLookup((l_col),"Editor") == "dateCalendar") {
t_20 += "\n    ";
t_20 += runtime.suppressValue((lineno = 18, colno = 42, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "vueDateTime")),"generateCalenderElement"), "vueDateTime[\"generateCalenderElement\"]", context, [l_value,runtime.memberLookup((l_col),"Name"),l_index,false,false,runtime.memberLookup((l_col),"IsReadOnly"),false,runtime.memberLookup((l_col),"IsPostback")])), env.opts.autoescape);
t_20 += "\n  ";
;
}
else {
if(runtime.memberLookup((l_col),"Editor") == "dateTimeCalendar") {
t_20 += "\n    ";
t_20 += runtime.suppressValue((lineno = 20, colno = 42, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "vueDateTime")),"generateCalenderElement"), "vueDateTime[\"generateCalenderElement\"]", context, [l_value,runtime.memberLookup((l_col),"Name"),l_index,true,false,runtime.memberLookup((l_col),"IsReadOnly"),false,runtime.memberLookup((l_col),"IsPostback")])), env.opts.autoescape);
t_20 += "\n  ";
;
}
else {
if(runtime.memberLookup((l_col),"Editor") == "dateTimeCalendarOpeningsDay") {
t_20 += "\n    ";
t_20 += runtime.suppressValue((lineno = 22, colno = 42, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "vueDateTime")),"generateCalenderElement"), "vueDateTime[\"generateCalenderElement\"]", context, [l_value,runtime.memberLookup((l_col),"Name"),l_index,true,true,runtime.memberLookup((l_col),"IsReadOnly"),false,runtime.memberLookup((l_col),"IsPostback")])), env.opts.autoescape);
t_20 += "\n  ";
;
}
else {
if(runtime.memberLookup((l_col),"Editor") == "dateCalendarOpeningsDay") {
t_20 += "\n    ";
t_20 += runtime.suppressValue((lineno = 24, colno = 42, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "vueDateTime")),"generateCalenderElement"), "vueDateTime[\"generateCalenderElement\"]", context, [l_value,runtime.memberLookup((l_col),"Name"),l_index,false,true,runtime.memberLookup((l_col),"IsReadOnly"),false,runtime.memberLookup((l_col),"IsPostback")])), env.opts.autoescape);
t_20 += "\n  ";
;
}
else {
if(runtime.memberLookup((l_col),"Editor") == "SingleUpload") {
t_20 += "\n    ";
t_20 += runtime.suppressValue((lineno = 26, colno = 40, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "vueFormField")),"generateVueFormField"), "vueFormField[\"generateVueFormField\"]", context, [l_value,runtime.memberLookup((l_col),"Name"),l_index,runtime.memberLookup((l_col),"IsReadOnly"),runtime.memberLookup((l_col),"IsPostback"),runtime.memberLookup((l_col),"Editor")])), env.opts.autoescape);
t_20 += "\n  ";
;
}
else {
if(runtime.memberLookup((l_col),"Editor") == "color") {
t_20 += "\n    ";
t_20 += runtime.suppressValue((lineno = 28, colno = 35, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "forminput")),"constructFormInput"), "forminput[\"constructFormInput\"]", context, [runtime.memberLookup((l_col),"Editor"),l_col,l_coltype,l_index,l_value])), env.opts.autoescape);
t_20 += "\n  ";
;
}
else {
if(runtime.memberLookup((l_col),"Editor") == "textarea") {
t_20 += "\n    ";
t_20 += runtime.suppressValue((lineno = 30, colno = 35, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "forminput")),"constructFormInput"), "forminput[\"constructFormInput\"]", context, [runtime.memberLookup((l_col),"Editor"),l_col,"text",l_index,l_value])), env.opts.autoescape);
t_20 += "\n  ";
;
}
else {
if(l_coltype == "String") {
t_20 += "\n    ";
t_20 += runtime.suppressValue((lineno = 32, colno = 36, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "forminput")),"constructFormInput"), "forminput[\"constructFormInput\"]", context, ["text",l_col,l_coltype,l_index,l_value])), env.opts.autoescape);
t_20 += "\n  ";
;
}
else {
if(l_coltype == "Time") {
t_20 += "\n    ";
t_20 += runtime.suppressValue((lineno = 34, colno = 36, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "forminput")),"constructFormInput"), "forminput[\"constructFormInput\"]", context, ["time",l_col,l_coltype,l_index,l_value])), env.opts.autoescape);
t_20 += "\n  ";
;
}
else {
if(l_coltype == "Text") {
t_20 += "\n    ";
t_20 += runtime.suppressValue((lineno = 36, colno = 35, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "forminput")),"constructFormInput"), "forminput[\"constructFormInput\"]", context, ["text",l_col,l_coltype,l_index,l_value])), env.opts.autoescape);
t_20 += "\n  ";
;
}
else {
if(l_coltype == "Boolean") {
t_20 += "\n    ";
t_20 += runtime.suppressValue((lineno = 38, colno = 35, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "forminput")),"constructFormInput"), "forminput[\"constructFormInput\"]", context, ["checkbox",l_col,l_coltype,l_index,l_value])), env.opts.autoescape);
t_20 += "\n  ";
;
}
else {
if((l_coltype == "DateTime" || l_coltype == "Date" || l_coltype == "Time")) {
t_20 += "\n    ";
t_20 += runtime.suppressValue((lineno = 40, colno = 42, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "vueDateTime")),"generateCalenderElement"), "vueDateTime[\"generateCalenderElement\"]", context, [l_value,runtime.memberLookup((l_col),"Name"),l_index,false,false,runtime.memberLookup((l_col),"IsReadOnly"),l_coltype == "DateTime" || l_coltype == "Time",runtime.memberLookup((l_col),"IsPostback")])), env.opts.autoescape);
t_20 += "\n  ";
;
}
else {
if(l_coltype == "Password") {
t_20 += "\n    ";
t_20 += runtime.suppressValue((lineno = 42, colno = 35, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "forminput")),"constructFormInput"), "forminput[\"constructFormInput\"]", context, ["password",l_col,l_coltype,l_index,l_value])), env.opts.autoescape);
t_20 += "\n  ";
;
}
else {
if(l_coltype == "Image" && runtime.memberLookup((l_col),"IsReadOnly")) {
t_20 += "\n    <img src=\"";
t_20 += runtime.suppressValue(l_value, env.opts.autoescape);
t_20 += "\"/>\n  ";
;
}
else {
if(l_coltype == "Money" || l_coltype == "Decimal" || l_coltype == "Int" || runtime.memberLookup((l_col),"Editor") == "number") {
t_20 += "\n    ";
t_20 += runtime.suppressValue((lineno = 46, colno = 34, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "forminput")),"constructFormInput"), "forminput[\"constructFormInput\"]", context, ["decimal",l_col,l_coltype,l_index,l_value])), env.opts.autoescape);
t_20 += "\n  ";
;
}
else {
if(runtime.memberLookup((l_col),"IsVisible") == false || l_coltype == "hidden") {
t_20 += "\n    ";
t_20 += runtime.suppressValue((lineno = 48, colno = 35, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "forminput")),"constructFormInput"), "forminput[\"constructFormInput\"]", context, ["hidden",l_col,l_coltype,l_index,l_value])), env.opts.autoescape);
t_20 += "\n  ";
;
}
else {
t_20 += "\n    ";
if(runtime.memberLookup((l_col),"VATPercentage") > 0) {
t_20 += "\n      <span\n    class=\"toggle-btw material-icons\"\n    data-toggle-btw=\"";
t_20 += runtime.suppressValue(runtime.memberLookup((l_col),"VATPercentage"), env.opts.autoescape);
t_20 += "\"\n    title=\"";
t_20 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_session),"translations")),"ToggleVATDescription"), env.opts.autoescape);
t_20 += "\"\n    >&#xe8d4;</span\n  >\n    ";
;
}
t_20 += "\n    ";
t_20 += runtime.suppressValue((lineno = 58, colno = 35, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "forminput")),"constructFormInput"), "forminput[\"constructFormInput\"]", context, ["text",l_col,l_coltype,l_index,l_value])), env.opts.autoescape);
t_20 += "\n  ";
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
t_20 += "\n  <!-- END -->\n";
;
frame = callerFrame;
return new runtime.SafeString(t_20);
});
context.addExport("createInputField");
context.setVariable("createInputField", macro_t_19);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/form/inputField.html", ctx);
          }
        
          return nunjucks.render("views/content/form/inputField.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/form/inputField.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        