import Hook from "../hook";

import store from "../../state/store";

class TableHook extends Hook {
  async afterRender(window) {
    if (window.output.Request.Prefix === "Multi") {
      // eslint-disable-next-line no-undef
      session.canvas.correctTableHeaders(window);

      store.dispatch("initializeTableElements", {window});
    }
  }
  async afterFocus(window) {
    if (
      window.output.Request.Prefix === "Multi" ??
      window.output.Data.Type === "table"
    ) {
      // eslint-disable-next-line no-undef
      session.canvas.correctTableHeaders(window);
    }
  }
}

export default new TableHook();
