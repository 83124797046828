
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
            const __nunjucks_template_import__global_generateID = require("../../src/util/generateRandomID.js");
            __nunjucks_module_dependencies__.globals['generateID'] = {
                module: __nunjucks_template_import__global_generateID && __nunjucks_template_import__global_generateID.default || __nunjucks_template_import__global_generateID
            };
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/elements/combobox.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["session", "combo", "width", "placeholder"], 
[], 
function (l_session, l_combo, l_width, l_placeholder, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("session", l_session);
frame.set("combo", l_combo);
frame.set("width", l_width);
frame.set("placeholder", l_placeholder);
var t_2 = "";t_2 += "\n\t";
var t_3;
t_3 = runtime.memberLookup((l_combo),"specification");
frame.set("spec", t_3, true);
if(frame.topLevel) {
context.setVariable("spec", t_3);
}
if(frame.topLevel) {
context.addExport("spec", t_3);
}
t_2 += "\n\t";
var t_4;
t_4 = false;
frame.set("doublebutton", t_4, true);
if(frame.topLevel) {
context.setVariable("doublebutton", t_4);
}
if(frame.topLevel) {
context.addExport("doublebutton", t_4);
}
t_2 += "\n\n\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"tableName") && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"openRef")) {
t_2 += "\n\t\t";
var t_5;
t_5 = true;
frame.set("doublebutton", t_5, true);
if(frame.topLevel) {
context.setVariable("doublebutton", t_5);
}
if(frame.topLevel) {
context.addExport("doublebutton", t_5);
}
t_2 += "\n\t";
;
}
t_2 += "\n\n\t<div class=\"dropdown combobox ";
t_2 += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "doublebutton")?"double-button":""), env.opts.autoescape);
t_2 += " ";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"type"), env.opts.autoescape);
t_2 += " ";
t_2 += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"freeType")?"free":""), env.opts.autoescape);
t_2 += " ";
t_2 += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"readOnly")?"disabled":""), env.opts.autoescape);
t_2 += "\" style=\"width:";
t_2 += runtime.suppressValue(l_width, env.opts.autoescape);
t_2 += "\" data-id=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((l_combo),"id"), env.opts.autoescape);
t_2 += "\">\n\n\t\t";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"readOnly")) {
t_2 += "\n\t\t\t<input autocomplete=\"";
t_2 += runtime.suppressValue((lineno = 11, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "generateID"), "generateID", context, [7])), env.opts.autoescape);
t_2 += "\" field-name=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_combo),"cell")),"Column")),"Name"), env.opts.autoescape);
t_2 += "-combo\" placeholder=\"";
t_2 += runtime.suppressValue(l_placeholder, env.opts.autoescape);
t_2 += "\" ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_combo),"cell")),"Column")),"Index") != false) {
t_2 += "data-field-index=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_combo),"cell")),"Column")),"Index"), env.opts.autoescape);
t_2 += "\" ";
;
}
t_2 += "class=\"combobox-input free\" value=\"";
t_2 += runtime.suppressValue((lineno = 11, colno = 274, runtime.callWrap(runtime.memberLookup((runtime.memberLookup((l_combo),"text")),"trim"), "combo[\"text\"][\"trim\"]", context, [])), env.opts.autoescape);
t_2 += "\">\n\t\t";
;
}
else {
t_2 += "\n\t\t\t<span ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_combo),"cell")),"Column")),"Index") != false) {
t_2 += "data-field-index=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_combo),"cell")),"Column")),"Index"), env.opts.autoescape);
t_2 += "\" ";
;
}
t_2 += "class=\"combobox-input\">";
t_2 += runtime.suppressValue((lineno = 13, colno = 151, runtime.callWrap(runtime.memberLookup((runtime.memberLookup((l_combo),"text")),"trim"), "combo[\"text\"][\"trim\"]", context, [])), env.opts.autoescape);
t_2 += "</span>\n\t\t";
;
}
t_2 += "\n\n\t\t";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"readOnly") && !runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"freeType")) {
t_2 += "\n\t\t\t<div data-toggle-combobox class=\"button material-icons\" tabindex=\"-1\">&#xe313;</div>\n\t\t";
;
}
t_2 += "\n\n\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"openRef") && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"tableName") && !runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"freeType")) {
t_2 += "\n\t\t\t<div data-window-event=\"openref:";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"name"), env.opts.autoescape);
t_2 += ":";
t_2 += runtime.suppressValue(runtime.memberLookup((l_combo),"value"), env.opts.autoescape);
t_2 += "\" class=\"ref-button button material-icons\" tabindex=\"-1\">&#xe0b2;</div>\n\t\t";
;
}
t_2 += "\n\n\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"readOnly")) {
t_2 += "\n\t\t\t<span></span>\n\t\t";
;
}
else {
t_2 += "<div class=\"menu-overflow\">\n\t\t\t\t<div class=\"menu hide\">\n\t\t\t\t\t";
t_2 += runtime.suppressValue((lineno = 29, colno = 19, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "createItems"), "createItems", context, [l_session,l_combo])), env.opts.autoescape);
t_2 += "\n\t\t\t\t</div>\n\t\t\t</div>";
;
}
t_2 += "\n\n\t\t<input type=\"hidden\" data-hidden-combobox value=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((l_combo),"value"), env.opts.autoescape);
t_2 += "\" name=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"name"), env.opts.autoescape);
t_2 += "\">\n\t</div>";
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("constructCombobox");
context.setVariable("constructCombobox", macro_t_1);
output += "\n\n";
var macro_t_6 = runtime.makeMacro(
["session", "combo", "index", "isDimensionObject"], 
[], 
function (l_session, l_combo, l_index, l_isDimensionObject, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("session", l_session);
frame.set("combo", l_combo);
frame.set("index", l_index);
frame.set("isDimensionObject", l_isDimensionObject);
var t_7 = "";t_7 += "\n\t";
var t_8;
t_8 = runtime.memberLookup((l_combo),"specification");
frame.set("spec", t_8, true);
if(frame.topLevel) {
context.setVariable("spec", t_8);
}
if(frame.topLevel) {
context.addExport("spec", t_8);
}
t_7 += "\n\t";
var t_9;
t_9 = false;
frame.set("doublebutton", t_9, true);
if(frame.topLevel) {
context.setVariable("doublebutton", t_9);
}
if(frame.topLevel) {
context.addExport("doublebutton", t_9);
}
t_7 += "\n\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"tableName") && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"openRef")) {
t_7 += "\n\t\t";
var t_10;
t_10 = true;
frame.set("doublebutton", t_10, true);
if(frame.topLevel) {
context.setVariable("doublebutton", t_10);
}
if(frame.topLevel) {
context.addExport("doublebutton", t_10);
}
t_7 += "\n\t";
;
}
t_7 += "\n\n\t<div class=\"table-cell field dropdown combobox ";
t_7 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"type"), env.opts.autoescape);
t_7 += " ";
t_7 += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"Type") == "Money" || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"Type") == "Decimal" || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"Type") == "Int"?" number " + runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"Type"):""), env.opts.autoescape);
t_7 += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"CustomClass")?" " + runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"CustomClass"):""), env.opts.autoescape);
t_7 += "\"\n\tname=\"";
t_7 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"Name"), env.opts.autoescape);
t_7 += "\" ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"Width")) {
t_7 += " style=\"width: ";
t_7 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"Width"), env.opts.autoescape);
t_7 += "\" ";
;
}
t_7 += "\n\tdata-id=\"";
t_7 += runtime.suppressValue(runtime.memberLookup((l_combo),"id"), env.opts.autoescape);
t_7 += "\">\n\n\t\t";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"readOnly")) {
t_7 += "\n\t\t\t<input autocomplete=\"no\" ";
if(l_index != false) {
t_7 += "data-field-index=\"";
t_7 += runtime.suppressValue(l_index, env.opts.autoescape);
t_7 += "\" ";
;
}
t_7 += "class=\"combobox-input free\" value=\"";
t_7 += runtime.suppressValue(runtime.memberLookup((l_combo),"text"), env.opts.autoescape);
t_7 += "\">\n\t\t";
;
}
else {
t_7 += "\n\t\t\t<span ";
if(l_index != false) {
t_7 += "data-field-index=\"";
t_7 += runtime.suppressValue(l_index, env.opts.autoescape);
t_7 += "\" ";
;
}
t_7 += "class=\"combobox-input\">";
t_7 += runtime.suppressValue(runtime.memberLookup((l_combo),"text"), env.opts.autoescape);
t_7 += "</span>\n\t\t";
;
}
t_7 += "\n\n\t\t";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"readOnly") && !runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"freeType")) {
t_7 += "\n\t\t\t<div data-toggle-combobox class=\"button material-icons\" tabindex=\"-1\">&#xe313;</div>\n\t\t";
;
}
t_7 += "\n\t\t";
if(l_isDimensionObject) {
t_7 += "\n\t\t\t<div id=\"openDimensionObjectModal\" class=\"ref-button button material-icons\" style=\"font-size:13px; right: 24px; line-height: initial;;\" data-toggle-modal dimensiontabindex=\"-1\">\n\t\t\t\t<i class=\"fas fa-clipboard-list\" style=\"pointer-events:none;\"></i>\n\t\t\t</div>\n\t\t";
;
}
t_7 += "\n\n\t\t<div class=\"menu-overflow\">\n\t\t\t<div class=\"menu hide\">\n\t\t\t\t";
t_7 += runtime.suppressValue((lineno = 66, colno = 18, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "createItems"), "createItems", context, [l_session,l_combo])), env.opts.autoescape);
t_7 += "\n\t\t\t</div>\n\t\t</div>\n\t\t<input type=\"hidden\" data-hidden-combobox value=\"";
t_7 += runtime.suppressValue(runtime.memberLookup((l_combo),"value"), env.opts.autoescape);
t_7 += "\" name=\"";
t_7 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"name"), env.opts.autoescape);
t_7 += "\">\n\t</div>";
;
frame = callerFrame;
return new runtime.SafeString(t_7);
});
context.addExport("constructTableRecordCombobox");
context.setVariable("constructTableRecordCombobox", macro_t_6);
output += "\n\n";
var macro_t_11 = runtime.makeMacro(
["value", "text", "checked", "combovalue", "attributes", "type", "combo"], 
[], 
function (l_value, l_text, l_checked, l_combovalue, l_attributes, l_type, l_combo, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("value", l_value);
frame.set("text", l_text);
frame.set("checked", l_checked);
frame.set("combovalue", l_combovalue);
frame.set("attributes", l_attributes);
frame.set("type", l_type);
frame.set("combo", l_combo);
var t_12 = "";t_12 += "<div class=\"option dropdown-option ";
t_12 += runtime.suppressValue((runtime.memberLookup((l_attributes),"Type") === "ItemSerial"?"pl-3":""), env.opts.autoescape);
t_12 += " ";
t_12 += runtime.suppressValue((l_combovalue == l_value?"selected":""), env.opts.autoescape);
t_12 += "\" ";
t_12 += runtime.suppressValue((lineno = 74, colno = 141, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "buildAttributes"), "buildAttributes", context, [l_attributes])), env.opts.autoescape);
t_12 += " data-value=\"";
t_12 += runtime.suppressValue(l_value, env.opts.autoescape);
t_12 += "\">";
if(l_type === "multi-selector") {
t_12 += "<input type=\"checkbox\" ";
t_12 += runtime.suppressValue((l_checked?"checked":""), env.opts.autoescape);
t_12 += " >";
;
}
if(runtime.memberLookup((l_attributes),"Type") === "Item") {
t_12 += "<span class=\"\">\n\t\t\t\t\t";
t_12 += "\n\t\t\t\t\t<text>";
t_12 += runtime.suppressValue(l_text, env.opts.autoescape);
t_12 += "</text>\n\t\t\t\t\t<i class=\"pull-right fa fa-cube\"></i>\n\t\t\t\t</span>";
;
}
else {
if(runtime.memberLookup((l_attributes),"Type") === "ItemSerial") {
t_12 += "<span class=class=\"text-primary\">\n\t\t\t\t\t";
t_12 += "\n\t\t\t\t\t<small>\n\t\t\t\t\t\t<text>";
t_12 += runtime.suppressValue(l_text, env.opts.autoescape);
t_12 += "</text>\n\t\t\t\t\t</small>\n\t\t\t\t\t<i class=\"pull-right fa fa-tag\"></i>\n\t\t\t\t</span>";
;
}
else {
if(runtime.memberLookup((l_attributes),"Type") === "Composition") {
t_12 += "<i>\n\t\t\t\t\t<text>";
t_12 += runtime.suppressValue(l_text, env.opts.autoescape);
t_12 += "</text>\n\t\t\t\t</i>\n\t\t\t\t<i class=\"pull-right fa fa-cubes\"></i>";
;
}
else {
if(runtime.memberLookup((runtime.memberLookup((l_attributes),"Color")),"length") > 0) {
t_12 += "\n\t\t\t\t<span class=\"badge badge-secondary\" style=\"background-color:";
t_12 += runtime.suppressValue(runtime.memberLookup((l_attributes),"Color"), env.opts.autoescape);
t_12 += ";color:";
t_12 += runtime.suppressValue((lineno = 99, colno = 116, runtime.callWrap(runtime.memberLookup((l_combo),"getColorByBgColor"), "combo[\"getColorByBgColor\"]", context, [runtime.memberLookup((l_attributes),"Color")])), env.opts.autoescape);
t_12 += "\">";
t_12 += runtime.suppressValue(l_text, env.opts.autoescape);
t_12 += "</span>";
;
}
else {
t_12 += "<text>";
t_12 += runtime.suppressValue(l_text, env.opts.autoescape);
t_12 += "</text>";
;
}
;
}
;
}
;
}
t_12 += "\n\n\t\t\t</div>";
;
frame = callerFrame;
return new runtime.SafeString(t_12);
});
context.addExport("buildOption");
context.setVariable("buildOption", macro_t_11);
output += "\n\n\t\t";
var macro_t_13 = runtime.makeMacro(
["attributes"], 
[], 
function (l_attributes, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("attributes", l_attributes);
var t_14 = "";t_14 += "data-option=\"true\"";
if(l_attributes) {
frame = frame.push();
var t_17 = l_attributes;
if(t_17) {t_17 = runtime.fromIterator(t_17);
var t_15;
if(runtime.isArray(t_17)) {
var t_16 = t_17.length;
for(t_15=0; t_15 < t_17.length; t_15++) {
var t_18 = t_17[t_15][0];
frame.set("[object Object]", t_17[t_15][0]);
var t_19 = t_17[t_15][1];
frame.set("[object Object]", t_17[t_15][1]);
frame.set("loop.index", t_15 + 1);
frame.set("loop.index0", t_15);
frame.set("loop.revindex", t_16 - t_15);
frame.set("loop.revindex0", t_16 - t_15 - 1);
frame.set("loop.first", t_15 === 0);
frame.set("loop.last", t_15 === t_16 - 1);
frame.set("loop.length", t_16);
t_14 += "data-attr-";
t_14 += runtime.suppressValue(t_18, env.opts.autoescape);
t_14 += "=\"";
t_14 += runtime.suppressValue(t_19, env.opts.autoescape);
t_14 += "\"\n\t\t";
;
}
} else {
t_15 = -1;
var t_16 = runtime.keys(t_17).length;
for(var t_20 in t_17) {
t_15++;
var t_21 = t_17[t_20];
frame.set("value", t_20);
frame.set("text", t_21);
frame.set("loop.index", t_15 + 1);
frame.set("loop.index0", t_15);
frame.set("loop.revindex", t_16 - t_15);
frame.set("loop.revindex0", t_16 - t_15 - 1);
frame.set("loop.first", t_15 === 0);
frame.set("loop.last", t_15 === t_16 - 1);
frame.set("loop.length", t_16);
t_14 += "data-attr-";
t_14 += runtime.suppressValue(t_20, env.opts.autoescape);
t_14 += "=\"";
t_14 += runtime.suppressValue(t_21, env.opts.autoescape);
t_14 += "\"\n\t\t";
;
}
}
}
frame = frame.pop();
;
}
;
frame = callerFrame;
return new runtime.SafeString(t_14);
});
context.addExport("buildAttributes");
context.setVariable("buildAttributes", macro_t_13);
output += "\n\n\t\t\t";
var macro_t_22 = runtime.makeMacro(
["session", "combo"], 
[], 
function (l_session, l_combo, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("session", l_session);
frame.set("combo", l_combo);
var t_23 = "";t_23 += "\n\t\t\t\t";
var t_24;
t_24 = runtime.memberLookup((l_combo),"specification");
frame.set("spec", t_24, true);
if(frame.topLevel) {
context.setVariable("spec", t_24);
}
if(frame.topLevel) {
context.addExport("spec", t_24);
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"type") == "treeview") {
t_23 += "\n\t\t\t\t\t";
t_23 += runtime.suppressValue((lineno = 117, colno = 18, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "makeBranch"), "makeBranch", context, [runtime.memberLookup((l_combo),"tree"),runtime.memberLookup((l_combo),"value")])), env.opts.autoescape);
t_23 += "\n\t\t\t\t";
;
}
else {
frame = frame.push();
var t_27 = runtime.memberLookup((l_combo),"items");
if(t_27) {t_27 = runtime.fromIterator(t_27);
var t_26 = t_27.length;
for(var t_25=0; t_25 < t_27.length; t_25++) {
var t_28 = t_27[t_25];
frame.set("item", t_28);
frame.set("loop.index", t_25 + 1);
frame.set("loop.index0", t_25);
frame.set("loop.revindex", t_26 - t_25);
frame.set("loop.revindex0", t_26 - t_25 - 1);
frame.set("loop.first", t_25 === 0);
frame.set("loop.last", t_25 === t_26 - 1);
frame.set("loop.length", t_26);
t_23 += "\n\t\t\t\t\t\t";
t_23 += runtime.suppressValue((lineno = 120, colno = 19, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "buildOption"), "buildOption", context, [runtime.memberLookup((t_28),"value"),runtime.memberLookup((t_28),"text"),runtime.memberLookup((t_28),"checked"),runtime.memberLookup((l_combo),"value"),runtime.memberLookup((t_28),"attributes"),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"type"),l_combo])), env.opts.autoescape);
t_23 += "\n\t\t\t\t\t";
;
}
}
frame = frame.pop();
t_23 += "\n\t\t\t\t\t<div class=\"no-items";
t_23 += runtime.suppressValue((runtime.memberLookup((runtime.memberLookup((l_combo),"items")),"length") > 0?" hide":""), env.opts.autoescape);
t_23 += "\">\n\t\t\t\t\t\t";
t_23 += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"freeType")?runtime.memberLookup((runtime.memberLookup((l_session),"translations")),"NoSuggestions"):runtime.memberLookup((runtime.memberLookup((l_session),"translations")),"NoResults")), env.opts.autoescape);
t_23 += "\n\t\t\t\t\t</div>\n\t\t\t\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "spec")),"fetch")) {
t_23 += "\n\t\t\t\t\t\t<div class=\"show-more\" title=\"Meer\">\n\t\t\t\t\t\t\t<span class=\"material-icons\">&#xe5c5;</span>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t";
;
}
t_23 += "\n\t\t\t\t";
;
}
;
frame = callerFrame;
return new runtime.SafeString(t_23);
});
context.addExport("createItems");
context.setVariable("createItems", macro_t_22);
output += "\n\n\t\t\t";
var macro_t_29 = runtime.makeMacro(
["parent", "combovalue"], 
[], 
function (l_parent, l_combovalue, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("parent", l_parent);
frame.set("combovalue", l_combovalue);
var t_30 = "";t_30 += "\n\t\t\t\t";
frame = frame.push();
var t_33 = l_parent;
if(t_33) {t_33 = runtime.fromIterator(t_33);
var t_32 = t_33.length;
for(var t_31=0; t_31 < t_33.length; t_31++) {
var t_34 = t_33[t_31];
frame.set("branch", t_34);
frame.set("loop.index", t_31 + 1);
frame.set("loop.index0", t_31);
frame.set("loop.revindex", t_32 - t_31);
frame.set("loop.revindex0", t_32 - t_31 - 1);
frame.set("loop.first", t_31 === 0);
frame.set("loop.last", t_31 === t_32 - 1);
frame.set("loop.length", t_32);
t_30 += "\n\t\t\t\t\t";
if(runtime.memberLookup((t_34),"children")) {
t_30 += "\n\t\t\t\t\t\t<div class='branch collapsed";
t_30 += runtime.suppressValue((l_combovalue == runtime.memberLookup((t_34),"value")?" selected":""), env.opts.autoescape);
t_30 += "' id=\"";
t_30 += runtime.suppressValue(runtime.memberLookup((t_34),"value"), env.opts.autoescape);
t_30 += "\">\n\t\t\t\t\t\t\t<div class='branch-name'>\n\t\t\t\t\t\t\t\t<span class=\"dropdown-option";
t_30 += runtime.suppressValue((l_combovalue == runtime.memberLookup((t_34),"value")?" selected":""), env.opts.autoescape);
t_30 += "\" ";
t_30 += runtime.suppressValue((lineno = 138, colno = 100, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "buildAttributes"), "buildAttributes", context, [runtime.contextOrFrameLookup(context, frame, "attributes")])), env.opts.autoescape);
t_30 += " data-value=\"";
t_30 += runtime.suppressValue(runtime.memberLookup((t_34),"value"), env.opts.autoescape);
t_30 += "\" ";
t_30 += runtime.suppressValue((runtime.memberLookup((t_34),"enabled") == false?"disabled":""), env.opts.autoescape);
t_30 += " >";
t_30 += runtime.suppressValue(runtime.memberLookup((t_34),"text"), env.opts.autoescape);
t_30 += "</span>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<div class=\"branches\">\n\t\t\t\t\t\t\t\t";
t_30 += runtime.suppressValue((lineno = 141, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "makeBranch"), "makeBranch", context, [runtime.memberLookup((t_34),"children"),l_combovalue])), env.opts.autoescape);
t_30 += "\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t";
;
}
else {
t_30 += "\n\t\t\t\t\t\t<div class=\"stick dropdown-option";
t_30 += runtime.suppressValue((l_combovalue == runtime.memberLookup((t_34),"value")?" selected":""), env.opts.autoescape);
t_30 += "\" ";
t_30 += runtime.suppressValue((lineno = 145, colno = 103, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "buildAttributes"), "buildAttributes", context, [runtime.contextOrFrameLookup(context, frame, "attributes")])), env.opts.autoescape);
t_30 += " data-value=\"";
t_30 += runtime.suppressValue(runtime.memberLookup((t_34),"value"), env.opts.autoescape);
t_30 += "\">\n\t\t\t\t\t\t\t<span>";
t_30 += runtime.suppressValue(runtime.memberLookup((t_34),"text"), env.opts.autoescape);
t_30 += "</span></div>\n\t\t\t\t\t";
;
}
t_30 += "\n\t\t\t\t";
;
}
}
frame = frame.pop();
t_30 += "\n\t\t\t";
;
frame = callerFrame;
return new runtime.SafeString(t_30);
});
context.addExport("makeBranch");
context.setVariable("makeBranch", macro_t_29);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/elements/combobox.html", ctx);
          }
        
          return nunjucks.render("views/elements/combobox.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/elements/combobox.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        