import $ from "jquery";
import arrayToTree from "array-to-tree";

import camelCase from "camelcase";
import clone from "clone";
import Combobox from "../interface/combobox.class";
import download from "../util/download";
import equal from "deep-equal";
import formatter from "./formatter";
import Hook from "../hook";

import serializeForm from "../util/serializeForm";
import shortid from "shortid";
import swal from "sweetalert";
import templater from "../interface/templater.js";
import store from "../../state/store";

import {dirtyCheckWithPrompt} from "../interface/window/dirty";

import {notify} from "../util/notify";

import {_checkButton} from "../hooks/window-action/_checkButton";

/**
 * Window object
 * @property {string} id - Window Identifier
 * @property {Window} parent - Parent window
 * @property {Object} sub - Sub window object
 * @property {Session} session - Current session
 * @property {boolean} loading - If the window is loading
 * @property {Element} element - window html element
 * @property {Object} input - Server Input object
 * @property {Object} output - Server output object
 * @property {Object} output.Request - Request object
 * @property {Object} output.Data - Data object
 * @property {number} counter - counter
 * @property {Object} lastSuccessInput - Last succesful input values
 * @property {Object} actionInputArgs - (Dialogue)Action input
 * @property {*} lastActionArgs - Last succesful action input values
 * @property {boolean} waitingForReload - Windows is waiting for reload
 * @property {*} error - Window error
 * @property {boolean} empty - Window is empty
 * @property {Object} options - Window logic options
 * @property {boolean} options.customKeyEvents - Ignore normal key events like escape, enter etc.
 * @property {boolean} options.noNewLines - Do not add new line to bulkedit
 * @property {boolean} options.autoFocus - Autofocus first input
 * @property {boolean} options.noReset - Allow reset
 * @property {Object} renderOptions - Window render options
 * @property {boolean} renderOptions.actionbar - Show action bar
 * @property {boolean} renderOptions.customButtons - Use custom buttons in bulkedit mode
 * @property {boolean} renderOptions.overlay - Cover the whole form(also cover subforms)
 * @property {boolean} renderOptions.inactive - Cover content
 * @property {Object} filters - Window filters
 * @property {*} bulkedit - Window is in bulkedit mode
 * @property {Array} selection - List of selection
 * @property {Array} tabs - List of tabs
 * @property {string | null} customTemplateName - (optional) Custom template name
 * @property {string | null} customTitle - (optional) Custom title
 * @property {Object} customData - (optional) custom Data
 * @property {boolean} customInit - (optional) custum Init function check
 * @property {Object} ckeditors - Contains all active window ckeditor object (required in ckeditor5)
 */
export default class Window {
  id = null;
  element = null;
  parent = null;
  sub = null;
  session = null;
  loading = true;
  subWindow = false;

  input = {};

  output = {
    Request: {},
    Data: {},
  };

  previousPostbackValues = null;

  counter = 0;
  lastSuccessInput = null;
  actionInputArgs = {};
  lastActionArgs = null;
  waitingForReload = false;
  error = null;
  empty = false;

  options = {
    customKeyEvents: false,
    noNewLines: false,
    autoFocus: true,
    noReset: false,
  };

  renderOptions = {
    actionbar: true,
    customButtons: false,
    overlay: false,
    inactive: false,
  };

  // table properties
  filters = {};

  bulkedit = false;
  selection = [];
  tabs = [];

  // custom variables
  customTemplateName = null;
  customTitle = null;
  customData = {};
  customInit = false;

  ckeditors = {};

  /**
   * Window constructor
   * @param {Session} session - Session
   * @param {Window} parent - Parent window
   */
  constructor(session, parent = null) {
    this.parent = parent || null;
    this.session = session;
    this.dayjs = require("dayjs");
    const weekOfYear = require("dayjs/plugin/weekOfYear");
    this.dayjs.extend(weekOfYear);
    if (global.session.language === "nl") import("dayjs/locale/nl");
    if (global.session.language === "fr") import("dayjs/locale/fr");
    if (global.session.language === "de") import("dayjs/locale/de");
    this.dayjs.locale(global.session.language);

    // set ID
    this.id = shortid.generate();

    // Register window in session and run create event
    if (this.session !== null) {
      this.session.windows[this.id] = this;
      Hook.run("create", this);
    }

    /*if (this.parent) {
      // because subwindows are hard to reset
      this.options.noReset = true
    }*/

    return this;
  }

  /**
   * Emit event
   * @deprecated
   * @param {*} eventName - Event name
   * @param {Array} args - Arguments
   * @returns {Promise} Promise
   */
  async emit(eventName, ...args) {
    try {
      // await this.session.hooks.run(eventName, this, { args })
      Hook.run(eventName, this, null, {args});
    } catch (err) {
      this.setError(err);
    }
  }

  /**
   * set and render Window error
   * @param {string} err - Error
   * @returns {void}
   */
  setError(err) {
    console.error(err);
    this.empty = false;
    this.loading = false;
    this.error = err;
    this.render();
  }

  /**
   * Load default options, output values, filters, options and renderOptions
   * @param {Array} ignore - Properties that have to be ignored
   * @returns {void}
   * @deprecated
   */
  loadDefaultValues(...ignore) {
    let template = new Window(this.session);

    for (let i of ["input", "output", "filters", "renderOptions", "options"]) {
      if (ignore.indexOf(i) === -1) {
        this[i] = clone(template[i]);
      }
    }
  }

  /**
   * Run event
   * @param {string} eventName - Event name
   * @param {Array} args - Arguments
   * @returns {Promise} Return a promise, maybe
   */
  async event(eventName, ...args) {
    let event = camelCase(eventName);
    try {
      // check if the event is a function
      if (typeof this[event] !== "function") {
        throw new Error("Unknown function '" + event + "'");
      }

      // get what is hopefully a promise
      let maybePromise = this[event].call(this, ...args);

      // check if it is a promise
      if (maybePromise && typeof maybePromise.then === "function") {
        // then run it, with loading indication
        this.toggleLoading(true);
        maybePromise = await Promise.resolve(maybePromise);
        this.toggleLoading(false);
      }

      return maybePromise;
    } catch (err) {
      console.error(event, err);
      this.toggleLoading(false);
      $("body").removeClass("avoid-clicks");
      this.message("error", err.message || "An error occurred");
      throw err;
    }
  }

  async confirm(text, responseOnConfirm, responseOnCancel) {
    try {
      let confirmationObject = {
        text: text,
        icon: "warning",
        dangerMode: true, // setting dangerMode to true makes the Cancel button bigger
        buttons: [this.session.translations.Cancel, true],
      };

      if (!(await swal(confirmationObject))) {
        if (responseOnCancel) {
          this.handleActionResponse(responseOnCancel);
        }
        return;
      }
    } catch (error) {
      console.error(error);
    }

    if (responseOnConfirm) {
      this.handleActionResponse(responseOnConfirm);
    }

    return true;
  }

  /**
   * Close and remove window and optioinally the parent object
   * @see {@link Canvas}
   * @see {@link Session}
   * @param {boolean} shouldConfirm - If confirmation is needed to close the window
   * @param {boolean} keepTabSelection - If the window is being reloaded
   * @param {boolean} tabDeleted - If the tab reference is already gone
   * @returns {void}
   */
  async disposeParent(
    shouldConfirm = true,
    keepTabSelection = false,
    tabDeleted = false,
  ) {
    // Run hookable event
    Hook.run("dispose", this, async (e) => {
      try {
        if (
          shouldConfirm === true &&
          (await dirtyCheckWithPrompt(this)) === false
        ) {
          e.cancel = true;
          return;
        }
      } catch (error) {
        console.error(error);
      }

      // If we're working from a tab remove parent window to
      if (this.parent) {
        // remove combobox references
        this.parent.removeComboboxReferences();

        // delete reference from session
        delete this.parent.session.windows[this.parent.id];

        // remove self from session tab
        let index = this.parent.session.tabs.indexOf(this.parent);
        if (index > -1 && !tabDeleted) {
          store.dispatch("removeTabByIndex", index);
        }
      }

      // remove combobox references
      this.removeComboboxReferences();

      // delete reference from session
      delete this.session.windows[this.id];

      // remove self from session tab
      let index = this.session.tabs.indexOf(this);
      if (index > -1 && !tabDeleted) {
        store.dispatch("removeTabByIndex", index);
      }

      // If we are the active window
      if (
        this.session.activeWindow === this ||
        (this.session.activeWindow.sub &&
          this.session.activeWindow.sub.window === this)
      ) {
        // Focus a new window

        this.session.activeWindow = null;

        let newIndex = Math.max(index - 1, 0);
        let newWindow = this.session.tabs[newIndex];

        if (newWindow) {
          newWindow.focus();
        }
      }
      store.commit("updateWindow");
    });
  }

  /**
   * Close and remove window object
   * @see {@link Canvas}
   * @see {@link Session}
   * @param {boolean} shouldConfirm - If confirmation is needed to close the window
   * @param {boolean} keepTabSelection - If the window is being reloaded
   * @param {boolean} tabDeleted - If the tab reference is already gone
   * @returns {void}
   */
  async dispose(
    shouldConfirm = true,
    keepTabSelection = false,
    tabDeleted = false,
  ) {
    // Run hookable event
    Hook.run("dispose", this, async (e) => {
      try {
        if (
          shouldConfirm === true &&
          (await dirtyCheckWithPrompt(this)) === false
        ) {
          e.cancel = true;
          return;
        }
      } catch (error) {
        console.error(error);
      }

      // // If we're working from a tab remove parent window to
      // if (this.parent) {
      //   // remove combobox references
      //   this.parent.removeComboboxReferences()

      //   // delete reference from session
      //   delete this.parent.session.windows[this.parent.id]

      //   // remove self from session tab
      //   let index = this.parent.session.tabs.indexOf(this.parent)
      //   if (index > -1 && !tabDeleted) {
      //     store.dispatch("removeTabByIndex", index)
      //   }
      // }

      // remove combobox references
      this.removeComboboxReferences();

      // delete reference from session
      delete this.session.windows[this.id];

      // remove self from session tab
      let index = this.session.tabs.indexOf(this);
      if (index > -1 && !tabDeleted) {
        store.dispatch("removeTabByIndex", index);
      }

      // If we are the active window
      if (
        this.session.activeWindow === this ||
        (this.session.activeWindow.sub &&
          this.session.activeWindow.sub.window === this)
      ) {
        // Focus a new window

        this.session.activeWindow = null;

        let newIndex = Math.max(index - 1, 0);
        let newWindow = this.session.tabs[newIndex];

        if (newWindow) {
          newWindow.focus();
        }
      }
      store.commit("updateWindow");
      store.commit("refreshTabs");
    });
  }

  /**
   * Disposes all windows
   * @returns {void}
   */
  async disposeAll() {
    let i;
    let tabAmount = this.session.tabs.length;
    let windowCount = Object.keys(this.session.windows).length;
    for (i = 0; i < tabAmount; i++) {
      // Take the first tab in of the array.
      // Because after an delete in an array the next value moves from index number.
      let tab = this.session.tabs[0];
      $(tab.element).empty();
      $(tab.element).remove();
    }

    for (i = 0; i < windowCount; i++) {
      // Take the first tab in of the array.
      // Because after an delete in an array the next value moves from index number.
      let window = this.session.windows[Object.keys(this.session.windows)[0]];
      $(window.element).empty();
      $(window.element).remove();
    }

    this.session.tabs = [];

    this.session.windows = {};
    this.session.activeWindow = null;

    store.state.tabs = [];

    await store.commit("refreshTabs");
    store.commit("updateWindow");
  }

  /**
   * Remove combobox references
   * @returns {void}
   */
  removeComboboxReferences() {
    // If there are rows
    if (
      this.output &&
      this.output.Table &&
      this.output.Table.Rows &&
      this.output.Table.Rows.length
    ) {
      this.output.Table.Rows.forEach((row) => {
        // remove each row's combobox
        row.forEach((column) => {
          if (column.Combobox) {
            column.Combobox.remove();
          }
        });
      });
    }
  }

  /**
   * Get window title
   * @param {boolean} bare - Append counter to string
   * @returns {string} Window title
   */
  title(bare = false) {
    return (
      (this.customTitle ||
        this.output.Title ||
        this.session.translations.LoadingWindow) +
      (!bare && this.counter !== 0 && !this.session.kiosk
        ? " (" + this.counter + ")"
        : "")
    );
  }

  /**
   * Set new window title
   * @param {string} title - New custom window title
   * @returns {Window} returns self
   */
  setTitle(title) {
    this.customTitle = title;
    return this;
  }

  /**
   * Set counter
   * @todo Document. What is this?
   * @returns {void}
   */
  setCounter() {
    if (this.parent !== null) {
      return;
    }

    let base = this.title();
    let counters = [];

    for (let window of this.session.tabs) {
      if (window === this) {
        continue;
      }

      if (window.title(true) === base) {
        counters.push(window.counter);
      }
    }

    while (counters.indexOf(this.counter) !== -1) {
      this.counter++;
    }
  }

  /**
   * Open table row in new window
   * @param {string} id - Row id
   * @param {string} fieldIndex - Optional field (column) index (when a specific linked column is clicked)
   * @param {string} subject - Provide your subject manually
   * @param {string} key - Key to be provided manually when not using a standard table
   * @param {string} keyReference - KeyReference to be provided manually when not using a standard table
   * @returns {void}
   */
  openRow(id, fieldIndex, subject, key, keyReference) {
    // run hookable function
    // Set input of new window
    let input = {};
    let column = null;
    input.Subject = this.input.Subject;
    input.Prefix = "Single";
    input.Criteria = {};

    if (subject) {
      input.Subject = subject;
    }

    if (fieldIndex) {
      column = this.output.Table.Columns[fieldIndex];
    }

    if (keyReference) {
      // if key is provided manually (when not using a standard table) create criteria from key
      input.Criteria = [{[keyReference]: key}];
    } else if (column && column.Dropdown && column.Dropdown.OpenRef) {
      // if fieldIndex is not null and fieldIndex has a reference to another table, open that table
      input.Subject = column.Dropdown.TableName;
      input.Criteria = [
        {
          [column.Dropdown.ColumnName]: this.output.FullTable.Rows[id].find(
            (cell) => {
              return cell.Column.Name === column.Name;
            },
          ).Value,
        },
      ];
    } else if (this.output.FullTable.Rows[id]) {
      // build criteria based on the selected rowID
      input.Criteria = [this.buildCriteriaNew(this.output.FullTable.Rows[id])];
    }

    // temporaraly store the clicked rowID in input.rowID for hooks
    input.rowID = id;

    // Cancel if composition ID is NULL
    if (input.Criteria.length && input.Criteria[0].CompositionID === null)
      return;

    return Hook.run(
      "openrow",
      this,
      async () => {
        // remove temporariy rowID
        delete input.rowID;

        // Create the new window
        await this.session.openWindow(input);

        store.commit("updateWindow");
      },
      input,
    );
  }

  /**
   * Process server output to be used with window
   * @param {Object} output - Server output
   * @returns {Promise} Promise
   */
  async process(output, confirm) {
    $("body").addClass("avoid-clicks");
    try {
      await Hook.run("process", this, async () => {
        // go to table view, e. g. no bulk edit
        this.bulkedit = false;
        this.output = output;

        // process data
        formatter.format(this);

        // set input to what was send to server(because some hook might have edited)
        if (this.output.Request !== null) {
          // Always use criteria as selection for Form's (single's or custom prefixes)
          if (
            this.output.Data &&
            this.output.Data.Type == "form" &&
            this.output.Request.Prefix != "New"
          ) {
            this.selection = this.output.Request.Criteria;
          } else {
            this.selection = [];
          }

          this.input = clone(this.output.Request);
        }

        // remove __type property
        if (this.output.Request.__type) {
          delete this.output.Request.__type;
        }

        // set tabs
        if (!this.parent) {
          this.tabs = this.output.Tabs || [];
        }

        // set sub window
        if (this.output.Sub !== null) {
          await this.injectSub(this.output.Sub, true, null, false);
        }

        // if there is no default form to open but there are tabs we use a dummy window.
        if (this.tabs.length && !this.sub) {
          this.sub = {
            dummy: true,
          };
        }

        // keep inactive
        if (this.parent && this.parent.isDirty()) {
          this.renderOptions.inactive = true;
        }

        if (this.output.Data && this.output.Data.Type == "iframe") {
          this.renderOptions.actionbar = false;
        }
        //
        await this.loadSub();

        this.setCounter();

        // TODO: Added because toggleLoading was not disabled after bulk-edit of existing orders. - 1-9-2017
        // this.toggleLoading(false)
      });
    } catch (error) {
      console.log({error});
    }
    $("body").removeClass("avoid-clicks");
  }

  showHint(fieldName) {
    if (this.introJsInstance == null) {
      this.introJsInstance = global.introjs(this.element);
      this.introJsInstance.setOptions({hintPosition: "top-left"});

      // remove the old hints from the DOM-tree, preventing that hints will appear in the wrong tab
      // $("*[data-hint]").remove()

      this.introJsInstance.addHints();
    }

    let hint = this.introJsInstance._introItems.find(
      (x) =>
        (x.targetElement != null &&
          $(x.targetElement).attr("name") == fieldName) ||
        (x.element.dataset != null && $(x.element).attr("name") == fieldName),
    );

    if (hint != null) {
      let indexOfHint = this.introJsInstance._introItems.indexOf(hint);

      if (indexOfHint > -1) {
        this.introJsInstance.showHint(indexOfHint);
        this.introJsInstance.showHintDialog(indexOfHint);
      }
    }
  }

  /**
   * Signal session that there may be inconsistant data(race conditions)
   * @see {@link Session.updateRelatedWindows}
   * @returns {void}
   */
  reloadRelated(table) {
    $("body").addClass("avoid-clicks");
    this.session.updateRelatedWindows(this, table);
    $("body").removeClass("avoid-clicks");
  }

  /**
   * Reload with new data
   * @param {boolean} keepSelection - If window should keep selection
   * @returns {Object} fetched data
   */
  async reload(keepSelection = false) {
    $("body").addClass("avoid-clicks");
    if (!keepSelection) {
      // Always use criteria as selection for Form's (single's or custom prefixes)
      if (
        this.output &&
        this.output.Data &&
        this.output.Data.Type &&
        this.output.Data.Type == "form" &&
        this.output.Request.Prefix != "New"
      ) {
        this.selection = this.output.Request.Criteria;
      } else {
        this.selection = [];
      }
    }

    // not useable with bulk edit
    this.bulkedit = false;

    // get new data
    let result = await this.fetch();
    $("body").removeClass("avoid-clicks");
    store.commit("refreshTabs");
    store.commit("updateWindow");
    return result;
  }

  async reloadParent(keepSelection = false) {
    if (this.parent) {
      this.parent.reload(keepSelection);
    }
  }

  /**
   * Reset window completely
   * @param {string} shouldConfirm - If window should ask for comfirmation before closing
   * @param {boolean} shouldFocus - Should this window be focused?
   * @returns {Object} newly fetched data
   */
  async reset(shouldConfirm = null, shouldFocus = true) {
    store.dispatch("resetWindow", {
      windowid: this.id,
      shouldConfirm,
      shouldFocus,
    });
  }

  getActiveSubWindow() {
    return store.getters.subWindow;
  }

  async resetSubPropper(shouldConfirm = null) {
    if (!this.parent) return;
    const parentWindow = this.parent;
    const newWindow = new Window(this.session);

    newWindow.input = this.input;

    await this.dispose();

    parentWindow.sub.window = newWindow;

    await newWindow.fetch();
    newWindow.render();
  }

  async resetSub(shouldConfirm = null, shouldFocus = true) {
    const subWindow = this.sub.window;
    subWindow.subWindow = this.sub != null;

    // check if a closing confimation should be asked for
    if (
      (shouldConfirm === "confirm" || this.isDirty()) &&
      !confirm(this.session.translations.WindowWarningOnClose)
    ) {
      return;
    }

    if (subWindow.parent) {
      await subWindow.parent.toggleLoading(true);

      subWindow.dispose(false, true);

      let newWindow = subWindow.session.newWindow(subWindow.input);
      newWindow.parent = subWindow.parent;
      subWindow.parent.sub = {
        window: newWindow,
      };

      await subWindow.parent.toggleLoading(false);
      await subWindow.parent.render();
      await newWindow.render();
      await newWindow.reload();
    }
  }

  /**
   * Fetch data from server, process and render window
   * @returns {Promise} Promise
   */
  async fetch(rerender = false) {
    $("body").addClass("avoid-clicks");
    // if there is no input, we cannot get data from server, exit
    if (!this.input) {
      this.loading = false;
      $("body").removeClass("avoid-clicks");
      return await this.render();
    }

    // reset error and set loading
    this.loading = true;
    this.error = null;
    this.render({rerender});
    store.commit("updateWindow");

    this.rerender = rerender;
    return await Hook.run("fetch", this, async () => {
      // send request and process output
      const fetchType = this.parent ? "SubWindow" : "Window";

      let url = `/Admin/WebServices/CoreWebServices.asmx/Open${fetchType}`;
      this.output = await this.session.request(url, {
        request: this.input,
      });

      await this.process(this.output);

      // store values for later
      this.lastSuccessInput = clone(this.input);

      // we are done loading, render output
      this.loading = false;
      await this.render({rerender: this.rerender});

      $("body").removeClass("avoid-clicks");
      store.commit("updateWindow");
    });
  }

  /**
   * Load sub window
   * @returns {Promise} Promise
   */
  async loadSub() {
    // we cannot load sub window we do not have
    if (!this.sub) {
      return;
    }

    $("body").addClass("avoid-clicks");
    // load sub
    if (this.sub.dummy) {
      let child = new Window(this.session, this);
      this.sub.window = child;
      child.empty = true;
    }
    $("body").removeClass("avoid-clicks");
  }

  /**
   * Dummy method, hooked by canvas
   * @see {@link Canvas}
   * @returns {Promise} Promise
   */
  async render() {
    return await Hook.run("render", this, async () => {
      await this.session.canvas.update(this, null);
      let $editorSearchElement = null;
      if (window.parent) $editorSearchElement = $(window.element);
      if (window.sub) $editorSearchElement = $(window.sub.window.element);

      if ($editorSearchElement) {
        $editorSearchElement.find("[data-html-editor]").each(async function () {
          if ($(this).prop("disabled")) {
            return;
          }
          try {
            // Ignore nunjucks because of HTTML-comment error.
            // Nunjucks removes HTML-comments.
            // Use html from window.output
            let row = null;
            if (activeWindow && activeWindow.output) {
              row = activeWindow.output.Table.Rows[0];
            }

            if (row != null) {
              for (let i = 0; i < row.length; i++) {
                if (row[i].Column.Name == $(this).attr("name")) {
                  $(this).html(row[i].Value);
                }
              }
            }
          } catch (err) {
            $("body").removeClass("avoid-clicks");
            //console.error(err)
          }
        });
      }
      store.commit("updateWindow");
      store.commit("refreshTabs");
    });
  }

  async swalMessage(type, text) {
    await swal({icon: type, text: text?.replace(/(<([^>]+)>)/gi, "")});
  }

  async showNotification(type, text) {
    notify({message: text, type});
  }

  /**
   * Show message to user, get's overruled somewhere
   * @see {@link Canvas}
   * @param {string} type - message type(warning/succes/error/..)
   * @param {string} message - message text
   * @param {*} args - arguments
   * @returns {void | Object} Message output
   */
  message(type, message, args = null, content, options) {
    let simple = type === "warning" || type === "success";
    let event = {
      type,
      message,
      args,
      simple,
      window: this,
      content,
      options,
    };

    return Hook.run(
      "message",
      this,
      () => {
        // Should not be reached
        //
      },
      event,
    );
  }

  async focusOrOpenNewWindow(
    output,
    inSub = false,
    noRender = false,
    noProcessing = false,
    mustConfirm = true,
  ) {
    const subject = output.Request.Subject;
    const prefix = output.Request.Prefix;
    const windows = Object.keys(global.session.windows).map(
      (key) => global.session.windows[key],
    );

    let targetWindow = null;

    windows.forEach(function (window) {
      if (
        window.output?.Request.Subject === subject &&
        window.output?.Request.Prefix === prefix
      ) {
        targetWindow = window;
      }
    });

    if (targetWindow) {
      this.focus(targetWindow);
    } else {
      this.insertWindow(
        output,
        (inSub = false),
        (noRender = false),
        (noProcessing = false),
        (mustConfirm = true),
      );
    }
  }

  /**
   * Focus self
   * @see {@link Session.activeWindow}
   * @returns {Promise} Promise
   */
  async focus(window) {
    const targetWindow = window ?? this;
    // prevent if we are already the active window
    if (
      targetWindow.session.activeWindow &&
      targetWindow.session.activeWindow.id === targetWindow.id
    ) {
      return null;
    }

    // hookable
    const focus = await Hook.run("focus", targetWindow, () => {
      this.session.activeWindow = targetWindow;
    });
    store.commit("updateWindow");

    const tabID = targetWindow.id;
    let tabs = targetWindow.session.tabs;

    tabs = targetWindow.session.tabs;

    if (!tabs.find((tab) => tab.id === tabID)) {
      tabs.unshift(targetWindow);
    }

    targetWindow.session.tabs = tabs;

    store.commit("setActiveWindowID", targetWindow.id);
    store.commit("updateWindow");
    return focus;
  }

  getColorByBgColor(bgColor) {
    if (!bgColor) {
      return "";
    }
    return parseInt(bgColor.replace("#", ""), 16) > 0xffffff / 2
      ? "#000"
      : "#fff";
  }

  /**
   * Go to page
   * @param {number} pageNo - Page number
   * @param {boolean} noReload - Should we reload?
   * @returns {void}
   */
  page(pageNo, noReload) {
    let event = {page: Number(pageNo)};

    return Hook.run(
      "paginate",
      this,
      async () => {
        if (event.page > 0) {
          this.input.Data.PageNumber = event.page;

          if (noReload) {
            return;
          }

          await this.reload(true);
        }
      },
      event,
    );
  }

  /**
   * Render window
   * @returns {string} HTML code
   */
  html() {
    $("body").addClass("avoid-clicks");
    let result = templater.renderWindow(this);
    $("body").removeClass("avoid-clicks");
    return result;
  }

  /**
   * Set page size
   * @param {number} size - page size
   * @returns {Promise} Promise
   */
  async pagesize(size) {
    // get current values
    // let currentSize = this.input.Data.PageSize
    // let currentPage = this.input.Data.PageNumber

    // calculate offset and new page size
    //let rowOffset = currentSize * currentPage
    //let newPage = Math.floor(rowOffset / size)
    const $pageSizeSelector = $(this.element).find(".number-of");

    $pageSizeSelector.prop("disabled", true);
    // set new values
    this.input.Data.PageSize = Number(size);
    this.input.Data.PageNumber = 1; //Math.max(newPage, 1)

    // get new values and re-render
    await this.fetch();
    $pageSizeSelector.prop("disabled", false);
  }

  /**
   * Build criteria
   * @param {Array} rowsIndexes - Number indexes of key rows
   * @returns {Array} Criteria per row
   */
  buildCriteria(rowsIndexes) {
    // get criteria for each row index
    return rowsIndexes.map((index) => this.buildCriteriaNew(index));
  }
  /**
   * Set loading, get's proxied
   * @see {@link Canvas}
   * @param {boolean} isLoading - New value
   * @returns {Promise} Promise
   */
  async toggleLoading(isLoading) {
    await Hook.run("toggleLoading", this, () => {
      this.loading = isLoading;
      store.state.loading = isLoading;
    });
  }

  /**
   * Open new tab or window in the current browser
   * @param {string} url - url to load in the new tab/window
   * @param {boolean} setFocus - indication if the new tab/window should receive focus
   *  @returns {Promise} Promise
   */
  async openbrowserwindow(url, setFocus) {
    var win = window.open(url, "_blank");
    if (setFocus) {
      win.focus();
    }
  }

  /**
   * Open tab in parent
   * @param {*} tabId - Tab ID
   *  @returns {Promise} Promise to open tab in parent
   */
  async opentabparent(tabId) {
    // Do not open ourself
    if (this.parent.sub.Id === tabId) {
      return;
    }

    // open tab
    await this.parent.opentab(tabId);
  }

  /**
   * Open tab in sub window
   * @param {*} tabId - Tab ID
   * @returns {Promise} Promise to open tab
   */
  async opentab(tabId) {
    // get tab
    let tab = this.tabs.filter((t) => t.Id === tabId).pop();

    // set request options
    let opts = {
      selectionInfo: {
        ActionName: tab.ActionName,
        Arguments: {},
        Request: this.output.Request,
        Data: {
          Selection: this.selection,
        },
      },
    };

    // Send request to server to open tab
    let result = await this.session.request(
      tab.WebService + "/" + tab.WebMethod,
      opts,
    );

    if (result !== null) {
      // set new selected tab
      this.tabs.forEach((x) => {
        x.Selected = tab === x;
      });

      // Store ActionName in input.Data.SelectedTabActionName to keep selected tab after reset
      this.input.Data.SelectedTabActionName = tab.ActionName;

      // Load and render sub window
      await this.injectSub(result);
      await this.sub.window.render();
    }
  }

  /**
   * Insert sub window or create new window
   * @param {Object} output - Window output
   * @param {boolean} inSub - Insert as sub window?
   * @param {boolean} noRender - should we render the window?
   * @param {boolean} noProcessing - Should we process the output?
   * @returns {Promise} Promise
   */
  async insertWindow(
    output,
    inSub = false,
    noRender = false,
    noProcessing = false,
    mustConfirm = true,
  ) {
    if (mustConfirm !== false) {
      if ((await dirtyCheckWithPrompt(this)) === false) return;
    }

    let window = new Window(this.session, inSub ? this : null);
    window.input = output.Request;

    // process data if neccessairy
    if (!noProcessing) {
      await window.process(output);
    }

    // we have done all the loading already
    window.loading = false;

    // where should we place the new window

    if (inSub) {
      // into self
      this.sub = {
        window: window,
      };
    } else {
      // as new tab
      //this.session.tabs.unshift(window)
      window.focus();
    }

    if (!noRender) {
      await window.render();
    }
    store.commit("updateWindow");
    store.commit("refreshTabs");
  }

  /**
   * Insert sub, get's proxied to insertWindow
   * @param {Object} output - Window output
   * @param {boolean} noRender - should we render the window?
   * @param {boolean} noProcessing - Should we process the output?
   * @returns {Promise} Promise
   * DEPRECATED
   */
  injectSub(
    output,
    noRender = false,
    noProcessing = false,
    mustConfirm = true,
  ) {
    return this.insertWindow(output, true, noRender, noProcessing, mustConfirm);
  }

  /**
   * Insert a sub (open a tab) in the active window (parent of active window if applicable)
   *
   * @param {Object} output
   * @memberof Window
   */
  async openTab(output) {
    // check if we need to show a confirmation
    if (output.NoDirtyCheck) {
      global.skipDirty = output.FullTableName;
    }
    global.session.activeWindow.action(
      output.ActionName,
      null,
      output.WebService,
      output.WebMethod,
    );
  }

  /**
   * Send updateWindow request to parent
   * @param {Object} output - New output object
   * @returns {Promise} Promise
   */
  async updateParentWindow(output) {
    if (this.parent != null) {
      this.parent.updateWindow(output);
    } else {
      this.updateWindow(output);
    }
  }

  async reloadSub(output) {
    this.input.Criteria = [];
    if (output) {
      this.input.Criteria = output.Request.Criteria;
    }
    this.fetch();
    store.commit("updateWindow");
  }

  /**
   * Replace window with new output
   * @param {Object} output - New output object
   */
  async replaceWindow(output) {
    if (this.parent) {
      await this.toggleLoading(true);
      // Ensure no changes are recorded

      // process and render
      await this.process(output);
      await this.toggleLoading(false);
      await this.fetch();
      await this.render();

      store.commit("updateWindow");
      $(this.element).removeClass("dirty");
      return;
    } else {
      // set loading (get's removed by process)
      await this.toggleLoading(true);

      // process and render
      await this.process(output);
      await this.toggleLoading(false);

      await this.render();

      $(this.element).removeClass("dirty");
      store.commit("updateWindow");
    }
  }

  /**
   * Update window with new output
   * @param {Object} output - New output object
   * @returns {Promise} Promise
   */
  async updateWindow(output) {
    if (this.parent) {
      if (
        output.Data &&
        (output.Data.Type === "rentform" ||
          output.Data.Type === "partialdelivery")
      ) {
        if (this.bulkedit) {
          if (!this.dirtyCheckWithPrompt(window)) return;
        }

        await this.toggleLoading(true);

        // process and render
        await this.process(output, true);
        await this.toggleLoading(false);

        await this.render();
        store.commit("updateWindow");
        $(this.element).removeClass("dirty");

        return;
      }

      this.parent.input.Criteria = output.Request.Criteria;
      this.parent.fetch();
      $(this.element).removeClass("dirty");
      return;
    } else {
      if (this.sub && this.sub.window && this.sub.window.bulkedit) {
        if (!(await dirtyCheckWithPrompt(this.sub.window))) return;
      }

      // set loading (get's removed by process)
      await this.toggleLoading(true);

      // process and render
      await this.process(output);
      await this.toggleLoading(false);

      await this.render();
      $(this.element).removeClass("dirty");
      store.commit("updateWindow");
    }
  }

  /**
   * Run action
   * @param {string} name - Action name
   * @param {string} targetTableName  - Name of target table
   * @param {string} webserviceUrl - Service URL
   * @param {string} webMethodName - Webmethod name
   * @param {boolean} doNotUseInternal - Should we use internal?
   * @returns {Promise} Promise
   */
  async action(
    name,
    targetTableName,
    webserviceUrl,
    webMethodName,
    doNotUseInternal,
  ) {
    let continueAction = true;
    continueAction = await _checkButton({
      name,
      targetTableName,
      webserviceUrl,
      webMethodName,
      doNotUseInternal,
      window: this,
    });

    if (!continueAction) return;

    this.toggleLoading(true);
    // $(document)
    //   .find(`[data-html-editor]`)
    //   .trigger("change")

    let actionName = name.replace(/\|/g, ":");
    if (!doNotUseInternal) {
      this.lastActionArgs = [
        actionName,
        targetTableName,
        webserviceUrl,
        webMethodName,
        doNotUseInternal,
      ];
    }

    let button = null;
    let tab = null;
    let selectedTab = null;

    if (this.output.Buttons && this.output.Tabs) {
      button = this.output.Buttons.filter(
        (x) => x.ActionName == actionName,
      ).pop();
      tab =
        button ||
        this.output.Tabs.filter((x) => x.ActionName == actionName).pop();
    }
    if (tab) {
      this.tabs.forEach((x) => {
        x.Selected = tab === x;
      });

      // Store ActionName in input.Data.SelectedTabActionName to keep selected tab after reset
      if (this.output.Tabs.length > 0) {
        selectedTab = this.output.Tabs.filter(
          (x) => x.ActionName == actionName,
        ).pop();
        this.input.Data.SelectedTabActionName =
          selectedTab != undefined ? selectedTab.ActionName : null;
      }
    }

    let spec = button || tab;

    let customFn = this["action" + actionName];

    if (typeof customFn === "function" && !doNotUseInternal) {
      let result = customFn.call(
        this,
        targetTableName,
        webserviceUrl,
        webMethodName,
        true,
      );
      await Promise.resolve(result);
      this.toggleLoading(false);
      return;
    }

    // Move primitive properties from customData into actionInputArgs
    if (this.customData) {
      for (var property in this.customData) {
        if (typeof this.customData[property] !== "object") {
          this.actionInputArgs[property] = this.customData[property];
        }
      }
    }

    if (this.sub && this.sub.window) {
      if (
        this.sub.window.output &&
        this.sub.window.output.Request.Data &&
        this.sub.window.output.Request.Data.ClientCurrency
      ) {
        this.output.Request.Data.ClientCurrency =
          this.sub.window.output.Request.Data.ClientCurrency;
      }
    }

    let opts = {
      selectionInfo: {
        ActionName: actionName,
        Arguments: this.actionInputArgs || {},
        Request: this.output.Request,
        Data: {
          Selection: !selectedTab ? this.selection : this.sub.window.selection,
        },
      },
    };

    this.actionInputArgs = {};

    // if (spec != null && spec.SendData != null && spec.SendData.indexOf("Changes") >= 0) {
    if (this.output.Table != null) {
      let changes = [];

      for (
        let rowIndex = 0;
        rowIndex < this.output.Table.Rows.length;
        rowIndex++
      ) {
        let formData = {};
        let criteria =
          this.output.Request.Prefix != "New"
            ? this.buildCriteriaNew(this.output.FullTable.Rows[rowIndex])
            : null;

        for (let cell of this.output.FullTable.Rows[rowIndex]) {
          let col = cell.Column;
          let cname = col.Name;
          let windowElement = this.element;
          if (this.sub) {
            windowElement = this.sub.window.element;
          }
          let elementValue = $(windowElement)
            .find(`[vue-input][name='${cname}']`)
            .val();

          if (col.IsAutoNumber && this.output.Request.Prefix === "New") {
            formData[cname] = null;
            continue;
          }

          if (col.IsReadOnly) {
            continue;
          }

          let formValue = cell.NewValue;

          if (cell.Value !== elementValue && elementValue !== undefined) {
            formData[cname] = formatter.serializeValue(col, elementValue);
            continue;
          }

          if (
            !cell.IsDirty &&
            this.output.Request.Prefix != "New" &&
            this.output.Request.Prefix != "Preview" &&
            this.output.Request.Prefix != "Multi"
          ) {
            continue;
          }

          formData[cname] = formatter.serializeValue(col, formValue);
        }
        changes.push({Criteria: criteria, Values: formData});
      }

      opts.selectionInfo.Data.Changes = changes;
    }

    if (
      spec != null &&
      spec.SendData != null &&
      spec.SendData.indexOf("Form") >= 0
    ) {
      let form = serializeForm(this.element);

      for (let x in form) {
        if (String(form[x]).length < 1) {
          delete form[x];
          continue;
        }
      }

      if (this.output.Data != null && this.output.Data.Type == "tiles") {
        delete form.scan;
      }

      opts.selectionInfo.Data.Form = form;
    }

    let result;
    this.toggleLoading(true);
    try {
      result = await this.session.request(
        (webserviceUrl || "/Admin/WebServices/CoreWebServices.asmx") +
          "/" +
          webMethodName,
        opts,
      );
    } catch (error) {
      let errorMessage;
      if (error.message != undefined) {
        errorMessage = error.message;
      } else if (error.data.Error != undefined) {
        errorMessage = error.data.Error;
      } else if (error.data.Message != undefined) {
        errorMessage = error.data.Message;
      } else if (error.statusText != undefined) {
        errorMessage = error.statusText;
      }

      this.message("error", errorMessage);
      console.error(error);
      return;
    }

    if (webMethodName === "SaveData") {
      $(this.element).find(".form-field").removeClass("dirty");
    }

    this.actionInputArgs = {};
    await this.handleActionResponse(result);
    this.toggleLoading(false);
  }

  /**
   * Build criteria from primary keys of row
   * @param {Object} row - Row object
   * @returns {Object} criteria
   */
  buildCriteriaNew(row) {
    let criteria = {};

    for (let cell of row) {
      if (cell.Column.IsPrimaryKey) {
        criteria[cell.Column.Name] = cell.Value;

        if (cell.Column.Type == "Date") {
          criteria[cell.Column.Name] = formatter.serializers.Date(cell.Value);
        } else if (cell.Column.Type == "DateTime") {
          criteria[cell.Column.Name] = formatter.serializers.DateTime(
            cell.Value,
          );
        }
      }
    }

    return criteria;
  }

  /**
   * Inject Window, proxy of updateWindow
   * @see {@link Window.updateWindow}
   * @param {Window} window - Window to inject
   * @return {Promise} Promise to update window
   */
  injectWindow(window) {
    return this.updateWindow(window);
  }

  async injectTab(output) {
    if (this.bulkedit) {
      if (!this.dirtyCheckWithPrompt(window)) return;
    }

    await this.toggleLoading(true);

    // process and render
    await this.process(output, true);
    await this.toggleLoading(false);

    await this.render();
    store.commit("updateWindow");
    $(this.element).removeClass("dirty");

    return;
  }

  /**
   * Hanlde action response
   * @param {Object} response - Action response
   * @returns {Promise} Promse to handle action
   */
  async handleActionResponse(response) {
    if (!response || !response.IsActionResponse) {
      console.error("Not an action response:", response);
      // throw new Error("Type mismatch, expected an action response.")
      return;
    }

    for (let eventArgs of response.Events) {
      await this.event(...eventArgs);
    }
  }

  /**
   * Export table to excel
   * @param {string} targetTableName - Action target table
   * @param {string} webserviceUrl - Service URL
   * @param {string} webMethodName - Method name
   * @returns {Promise} Promise
   */
  async actionExcel() {
    let downloadLink = `/Admin/Pages/ExportToExcel.aspx?Warehouse=${this.session.comboboxes.selectedValues.Warehouse}`;
    let context = {
      ActionName: "Excel",
      Request: this.input,
      Arguments: {},
      Data: {},
    };

    await download(downloadLink, {
      context,
    });
  }

  async actionDownLoadTemplate() {
    let downloadLink = `/Admin/Pages/DownloadTemplate.aspx?Warehouse=${this.session.comboboxes.selectedValues.Warehouse}`;
    let context = {
      ActionName: "Excel",
      Request: this.input,
      Arguments: {},
      Data: {},
    };

    await download(downloadLink, {
      context,
    });
  }

  async actionOpenExcelFileUpdate() {
    let window = global.session.activeWindow;
    document.getElementById("ExcelFileCatcherUpdate-" + window.id).oninput =
      function () {
        window.actionImportExcelFile(this, "UpdateFromExcel");
      };
  }

  async actionOpenExcelFileInput() {
    let window = global.session.activeWindow;
    document.getElementById("ExcelFileCatcherInsert-" + window.id).oninput =
      function () {
        window.actionImportExcelFile(this, "ImportFromExcel");
      };
  }

  async actionImportExcelFile(element, page) {
    let window = global.session.activeWindow;
    // let file = element.files[0];
    // let extension = file.substr((file.lastIndexOf('.') +1));
    let url =
      "/Admin/Pages/" +
      page +
      ".aspx?subject=" +
      encodeURIComponent(window.output.Request.Subject);

    for (const [key, value] of Object.entries(
      window.output.Request.Criteria[0],
    )) {
      url += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    }

    let filename = element.files[0].name;
    let extension = filename.substr(filename.lastIndexOf(".") + 1);
    let allowed_extensions = new Array("xls", "xlsx", "docb", "ods");

    if (
      filename &&
      filename.length > 0 &&
      allowed_extensions.includes(extension)
    ) {
      let data = new FormData();
      let file = element.files[0];
      data.append("file" + 0, file);
      $.ajax({
        type: "POST",
        url: url,
        contentType: false,
        processData: false,
        data: data,
        success: function (result) {
          if (result.Error) {
            let dbError = result.Error.Message;
            let friendlyUserError = dbError.substring(dbError.indexOf(":") + 1);
            window.swalMessage("warning", friendlyUserError);
          } else {
            window.handleActionResponse(result);
          }
        },
        error: function (error) {
          // alert('error!')
        },
      });
      this.reload();
      /*
      element.oninput = function() {
        window.actionImportExcelFile(this, page)
      }
      */
    } else {
      window.swalMessage("warning", this.session.translations.InvalidExcelFile);
    }

    document.getElementById("ExcelFileCatcherInsert-" + window.id).value = null;
    document.getElementById("ExcelFileCatcherUpdate-" + window.id).value = null;
  }

  /**
   * Set bulkedit
   * @param {string} targetTableName - Action target table
   * @param {string} webserviceUrl - Service URL
   * @param {string} webMethodName - Method name
   * @returns {Promise} Promise
   */
  async actionBulk(targetTableName, webserviceUrl, webMethodName) {
    this.bulkedit = targetTableName;

    this.toggleLoading(true);
    await this.render();
    this.toggleLoading(false);
  }

  /**
   * Cancel bulk edit
   * @returns {Promise} Promise
   */
  async cancelBulk() {
    if (
      this.selection.length &&
      !confirm(this.session.translations.WindowWarningOnClose)
    ) {
      return;
    }

    this.bulkedit = false;
    await this.render();
  }

  /**
   * Save bulk edit
   * @returns {Promise} Promise
   */
  async saveBulk() {
    let _this = this;
    let uniChanges = [];

    // If we aren't in bulk mode, maybe our parent or sub are
    if (!this.bulkedit) {
      if (this.sub && this.sub.window && this.sub.window.bulkedit) {
        await this.sub.window.saveBulk();
      }

      if (this.parent && this.parent.bulkedit) {
        await this.parent.saveBulk();
      }

      return;
    }

    // get all rows
    let $tableRows = $(this.element).find(".table-row").toArray();

    // construct data and criteria for each row
    for (let tableRowEl of $tableRows) {
      let data = {};
      let criteria = null;
      let rowData = this.output.Data.Rows[$(tableRowEl).attr("data-row-index")];

      if (rowData != null) {
        criteria = {};
        // construct criteria
        for (let columnName in this.output.Data.Columns) {
          let column = this.output.Data.Columns[columnName];

          if (column.IsPrimaryKey) {
            criteria[columnName] = rowData[columnName];
          }
        }
      }

      // Get all values, sanitized
      $(tableRowEl)
        .find("[name]")
        .each(function forEachName() {
          let name = $(this).attr("name").split(/\[|\]/g).shift();
          let value = $(this).is("[contenteditable]")
            ? $(this).text()
            : $(this).val();

          let col =
            _this.output.Table.Rows[$(tableRowEl).attr("data-row-index")]
              .Column;
          if (!col) {
            return;
          }

          value = formatter.serializeValue(col, value);
          data[name] = value;
        });

      let newValues = {};

      for (let columnName in data) {
        if (rowData == null || data[columnName] !== rowData[columnName]) {
          newValues[columnName] = data[columnName];
        }
      }

      let commitChanges = $(tableRowEl)
        .find("[data-commit-checkbox]")
        .prop("checked");

      if (commitChanges) {
        uniChanges.push({Criteria: criteria, Values: newValues});
      }
    }

    let opts = {
      changes: uniChanges,
      tableName: this.bulkedit,
    };

    await Hook.run("saveBulk", this, async () => {
      // save values
      let save = await this.session.request(
        "/Admin/WebServices/CoreWebServices.asmx/SaveBulk",
        opts,
      );

      this.bulkedit = false;
      this.session.updateRelatedWindows(this);
      await this.reload();
    });
  }

  /**
   * Alias for dispose
   * @see {dispose}
   * @returns {void}
   */
  close() {
    return this.dispose();
  }

  /**
   * Action to download file
   * @returns {Promise} Promise
   */
  async actionDownloadFile() {
    let url =
      "/Admin/Pages/DownloadFile.aspx?tableName=" +
      encodeURIComponent(this.output.Request.Subject);
    let queue = [];

    for (let rowCriteria of this.selection) {
      url = url + "&" + $.param(rowCriteria);
    }

    queue.push(download(url));
    await Promise.all(queue);
  }

  downloadLink(url, fileName) {
    const link = document.createElement("a");
    link.download = fileName ?? "file";
    link.href = url;

    link.click();
  }

  /**
   * Action to upload file
   * @param {Array} droppedFiles - Array of files
   * @returns {Promise} Promise
   */
  async actionUploadFile(droppedFiles, primaryKey) {
    let files;
    if (droppedFiles) {
      files = droppedFiles;
    } else {
      files = $("[name='FileUpload']").prop("files");
    }

    if (!files || !files.length) {
      return;
    }

    let data = new FormData();

    for (let f of files) {
      data.append(f.name, f);
    }

    let requestUrl = this.output.Options.UploadUrl;

    if (this.output.Data.Type === "quickeditform") {
      requestUrl = requestUrl.replace(/\?.*/, "");
      requestUrl =
        requestUrl + "?tableName=" + this.output.Data.UploadFullTableName;
    }

    let result;

    this.toggleLoading(true);
    try {
      if (primaryKey) {
        result = await this.session.request(requestUrl, data, primaryKey);
      } else {
        result = await this.session.request(requestUrl, data, {});
      }

      this.handleActionResponse(result);
    } finally {
      this.toggleLoading(false);
    }
  }

  /**
   * Open window based on given values
   * @deprecated
   * @param {string} tableName - Table name
   * @param {string} primaryKeyName - Name of primary key column
   * @param {string} primaryKeyValue - Value of primary key column
   * @param {Array} extraParams - Extra parameters that have to be copied
   * @returns {Promise} To create window
   */
  async open(tableName, primaryKeyName, primaryKeyValue, extraParams) {
    let params = {
      Subject: tableName,
      Prefix: "Multi",
    };

    if (primaryKeyName != null) {
      params.Criteria = [{[primaryKeyName]: primaryKeyValue}];
      params.Prefix = "Single";
    }

    if (extraParams != null) {
      for (let key in extraParams) {
        params[key] = extraParams[key];
      }
    }

    await this.session.openWindow(params);
    store.commit("updateWindow");
    store.commit("refreshTabs");
  }

  /**
   * Create window based on criteria
   * @param {string} tableName - Table name
   * @param {Object} rowCriteria - Row critiria object
   * @deprecated
   * @returns {Promise} To create window
   */
  async openCriteria(tableName, rowCriteria) {
    $("body").addClass("avoid-clicks");

    await this.session.openWindow({
      Subject: tableName,
      Prefix: "Single",
      Criteria: [rowCriteria],
    });
    $("body").removeClass("avoid-clicks");
  }

  /**
   * Remove text query
   * @param {*} id - Search id
   * @returns {Promise} To re-render
   */
  async removeTextQuery(id) {
    if (!this.input.Data.Search.length) {
      return;
    }

    // let textFilter = this.input.Data.Search[id]
    this.input.Data.Search.splice(id, 1);
    // $(this.element)
    //   .find(`.filter[data-window-filter-id='${id}']`)
    //   .remove()

    await this.reload();
  }

  /**
   * Remove Date filter
   * @param {*} id - Date filter id
   * @returns {Promise} To re-render
   */
  async removeDateQuery(id) {
    //
    let otherFilter = this.filters.date[id];
    this.input.Data.Filters[otherFilter.suffix] = null;
    otherFilter.value = null;
    await this.reload();
  }

  /**
   * @deprecated
   * @todo what is this?
   * @param {string} name - Name
   * @param {Array} args - Array of arguments
   * @returns {Promise} Nothing
   */
  async filterAction(name, ...args) {
    //
  }

  /**
   * Open from tree
   * @param {*} id - Tree id
   * @returns {Promise} Promise
   */
  async opentree(id) {
    if (!id) {
      return;
    }
    // find selected element and set 'selected'
    $(this.element).find("[data-window-tree-id]").removeClass("selected");
    $(this.element)
      .find("[data-window-tree-id='" + id + "']")
      .addClass("selected");

    // Criteria is TableName + ID (f.e. CategoryID)
    let criteria = this.output.Request.Subject.split(".")[1] + "ID";
    let input = {
      Subject: this.output.Request.Subject,
      Prefix: "Single",
      Criteria: [{[criteria]: id}],
    };

    // reload sub
    if (this.sub !== null) {
      this.sub.window.empty = false;
      this.sub.window.input = input;
      this.sub.window.reload();
      return;
    }
  }

  /**
   * Open from tree
   * @param {*} id - Tree id
   * @returns {Promise} Promise
   */
  async openWindowFromTree(id) {
    if (!id) {
      return;
    }

    // Criteria is TableName + ID (f.e. CategoryID)
    let criteria = this.output.Request.Subject.split(".")[1] + "ID";
    let input = {
      Subject: this.output.Request.Subject,
      Prefix: "Single",
      Criteria: [{[criteria]: id}],
    };
    this.session.openWindow(input);

    // reload sub
    // if (this.sub !== null) {
    //   this.sub.window.empty = false
    //   this.sub.window.input = input
    //   this.sub.window.reload()
    //   return
    // }
  }

  /**
   * Open reference
   * @param {string} columnName - Name of column
   * @param {*} value - Value of cell
   * @returns {Promise} To create window
   */
  async openref(columnName, value) {
    let col = this.output.Table.Columns.filter(
      (c) => c.Name === columnName,
    ).pop();

    if (col == null) {
      return;
    }

    let foreign = col.Dropdown;

    // construct input
    let input = {};
    input.Subject = foreign.TableName;
    input.Prefix = "Multi";
    input.Criteria = [{}];

    // Go to foreign field of a value is selected,
    // otherwise, go to table
    if (value) {
      input.Prefix = "Single";
      input.Criteria[0][foreign.ColumnName] = value;
    }

    // Add extra keys to criteria
    if (
      foreign.ExtraKeys != null &&
      this.output.Data != null &&
      this.output.Data.Rows != null &&
      this.output.Data.Rows[0] != null
    ) {
      let currentDataRow = this.output.FullTable.Rows[0];
      for (let primaryKey of foreign.ExtraKeys) {
        let filteredRow = currentDataRow.filter(
          (x) => x.Column.Name == primaryKey,
        );
        if (filteredRow.length > 0) {
          let primaryKeyCell = filteredRow[0];
          input.Criteria[0][primaryKey] = primaryKeyCell.IsDirty
            ? primaryKeyCell.NewValue
            : primaryKeyCell.Value;
        }
      }
    }

    // create window
    await this.session.openWindow(input);
    store.commit("updateWindow");
  }

  /**
   * Resize window
   * @returns {Promise} Promise
   */
  async resize() {
    await Hook.run("resize", this);
  }

  /**
   * Clear html thoroughly
   * @returns {void}
   */
  clearHTML() {
    if (!this.element) {
      return;
    }

    if (this.element.firstChild) {
      while (this.element.children.length > 0) {
        this.element.removeChild(this.element.children[0]);
      }
    }

    this.element.innerHTML = "";
  }

  /**
   * Toggle if column should be visible
   * @param {string} columnName - Name of column
   * @returns {Promise} Promise
   */
  async toggleColumn(columnName) {
    let index = this.output.Data.HiddenColumns.indexOf(columnName);
    let columnsToHide = this.output.Data.HiddenColumns;
    if (index === -1) {
      columnsToHide.push(columnName);
    } else {
      columnsToHide.splice(index, 1);
    }
    // let result = await this.session.request("/Admin/WebServices/CoreWebServices.asmx/SaveHiddenColumns", {
    // 	subject: this.output.Request.Subject,
    // 	columnNames: columnsToHide,
    // 	prefix: this.output.Request.Prefix
    // })

    await this.handleActionResponse(result);
  }

  /**
   * Get selected filter item
   * @deprecated
   * @param {*} filter - Filter
   * @returns {*} Selected
   */
  selectedFilterItem(filter) {
    this.input.liDiFilterOptions = this.input.liDiFilterOptions || [];
    let selected = this.input.liDiFilterOptions
      .filter((f) => filter.list.filter((opt) => equal(f, opt.value)).length)
      .pop();

    if (!selected && filter.suffix) {
      selected = this.input.liDiFilterOptions
        .filter(
          (f) =>
            filter.list.filter((opt) => {
              let arg = $.extend({}, opt.value);

              for (let prop in arg) {
                if (arg.hasOwnProperty(prop)) {
                  arg[prop] = arg[prop] + " " + filter.suffix;
                }
              }

              return equal(f, arg);
            }).length,
        )
        .pop();
    }

    return selected;
  }

  /**
   * Process a change in value
   * @param {number} row - Row id
   * @param {number} index - Column index
   * @param {*} val - Cell value
   * @returns {*} Returns cell if there was a change relative to the value in the database
   */
  processChange(row, index, val) {
    let isNew = this.output.Request.Prefix == "New";

    if (this.output.Table.Rows.length < row) {
      return null;
    }

    let cell = this.output.Table.Rows[row][index];
    if (!cell) {
      return null;
    }
    // val = formatter.parseValue(cell.Column, val)

    // Value as it came from the server.
    let serverValue = (cell.Initial && cell.Initial.Value) || cell.Value;
    let triggerChange =
      cell.NewValue != val && !(cell.Value === null && val === "");

    // Dirty if: not equal the server value AND not equal the last new value.
    cell.IsDirty = serverValue != val;
    cell.NewValue = val;

    // In the event of a date do not process change if the values are the same
    if (cell.NewValue === cell.Value && cell.Type === "Date") return;

    if (triggerChange) {
      //

      if (cell.Column.IsPostback) {
        let values = {};

        for (let rowcell of this.output.FullTable.Rows[row]) {
          values[rowcell.Column.Name] = rowcell.NewValue;
        }

        Promise.resolve(this.postbackValues(values));
      }
    }

    return triggerChange && !isNew ? cell : null;
  }

  /**
   * Send postback to server
   * @param {Object} values - Row values
   * @returns {Promise} Promise
   */
  async postbackValues(values) {
    let previousValues = this.previousPostbackValues;
    this.previousPostbackValues = values;

    let result = await this.session.request(
      "/Admin/WebServices/CoreWebServices.asmx/PostbackValues",
      {
        request: this.input,
        values: values,
        previousValues: previousValues,
      },
    );

    await this.handleActionResponse(result);
    this.dirty = true;
  }

  /**
   * Apply values to window
   * @param {*} newValues - New values
   * @returns {void}
   */
  applyChanges(newValues) {
    let inputElements = $(this.element).find("[name]").toArray();
    let inputs = {};
    for (let inputElement of inputElements) {
      let name = $(inputElement).attr("name");
      inputs[name] = inputElement;
    }

    let row = this.output.Table.Rows[0];

    for (let columnName in newValues) {
      let cell = row[columnName];

      if (cell != null) {
        cell.NewValue = newValues[columnName];
      }

      let inputElement = inputs[columnName];

      if (inputElement != null) {
        let serializedValue =
          cell != null
            ? formatter.parseValue(cell.Column, newValues[columnName])
            : newValues[columnName];

        if ($(inputElement).is("[data-html-editor]")) {
          $(inputElement).html(serializedValue);
        } else if ($(inputElement).is("[contenteditable]")) {
          $(inputElement).text(serializedValue);
        } else if ($(inputElement).is("input[type=checkbox]")) {
          $(inputElement).prop("checked", serializedValue);
        } else {
          $(inputElement).val(serializedValue);
        }

        $(inputElement).change();
      }
    }
  }

  /**
   * Check if dirty values exists
   * @returns {boolean} True if the window is dirty
   */
  isDirty() {
    if (this.bulkedit) {
      return true;
    }

    if (this.output != null && this.output.Table != null) {
      for (let row of this.output.Table.Rows) {
        if (row.filter((cell) => cell.IsDirty).length > 0) {
          return true;
        }
      }
    }

    if (this.dirty) return true;

    return false;
  }
  /**
   * Checks if it is related to the given tablename
   * @param {string} tablename - Table name
   * @returns {boolean} True if related
   */
  isRelated(tablename) {
    if (!tablename || (this.parent && this.parent.waitingForReload)) {
      return false;
    }

    return (
      (typeof tablename === "string" &&
        this.output.Request.Subject === tablename) ||
      (typeof tablename === "object" &&
        tablename.indexof(this.output.Request.Subject) > -1) ||
      (typeof tablename === "string" &&
        this.output.Request.LinkedSubject &&
        this.output.Request.LinkedSubject === tablename) ||
      (typeof tablename === "object" &&
        this.output.Request.LinkedSubject &&
        tablename.indexof(this.output.Request.LinkedSubject) > -1)
    );
  }

  /**
   * Select a row
   * @param {number} rowIndex - Index of row
   * @param {boolean} checked - If the row is selected
   * @returns {void}
   */
  selectRow(rowIndex, checked) {
    if (
      this.output == null ||
      this.output.Data == null ||
      (this.output.Data.Type != "table" &&
        this.output.Data.Type != "upload" &&
        this.output.Data.Type != "quickeditform")
    ) {
      return;
    }

    // Get criteria
    let primaryKeys = this.buildCriteriaNew(
      this.output.FullTable.Rows[rowIndex],
    );

    // Ignore if all primaryKeys are null
    if (Object.values(primaryKeys).every((x) => x === null)) {
      return;
    }

    if (checked) {
      if (
        this.selection.filter((existing) => equal(existing, primaryKeys))
          .length == 0
      ) {
        // append criteria
        this.selection.push(primaryKeys);
      }
    } else {
      // remove critiria
      this.selection = this.selection.filter(
        (existing) => !equal(existing, primaryKeys),
      );
    }
  }

  /**
   * Checks if the given row is selected
   * @param {number} rowIndex - Row number
   * @returns {boolean} True if the row is selected
   */
  isSelectedRow(rowIndex) {
    // We do not store the row indexes, so this way you can still check if the row is selected
    // room for improvement
    let primaryKeys = this.buildCriteriaNew(
      this.output.FullTable.Rows[rowIndex],
    );
    return (
      this.selection.filter((existing) => equal(existing, primaryKeys)).length >
      0
    );
  }

  /**
   * Reverse column sorting
   * @param {string} column - Column name
   * @returns {Promise} Promise to reload
   */
  async toggleColumnSorting(column) {
    if (this.bulkedit) {
      return;
    }

    let columnSpec = this.output.FullTable.Columns.filter(
      (x) => x.Name == column,
    ).pop();

    if (
      columnSpec == null ||
      !columnSpec.CanSortOn ||
      (columnSpec.Dropdown || {}).Items != null
    ) {
      return;
    }

    this.input.Data.SortReverse =
      this.input.Data.SortOn == column ? !this.input.Data.SortReverse : false;
    this.input.Data.SortOn = column;

    await this.reload();
  }

  /**
   * Alias for download
   * @param {string} url - Download URL
   * @param {Object} opts - Download options
   * * @param {Object} method - Post or Get
   * @returns {Promise} Promise to download
   */
  async download(url, opts, method) {
    await download(url, opts, method);
  }

  /**
   * Random function, why does it exist?
   * @deprecated
   * @returns {number} Math.random()
   */
  random() {
    return Math.random();
  }

  /**
   * Select permissions for table
   * @todo fill roleID, possilbly depricated
   * @param {string} tableName - Table name
   * @param {string} roleID - Role ID
   * @param {string} title - Title of permission screen
   * @param {boolean} parent - Parent permission screen
   * @returns {Promise} Promise
   */
  async selectPermissions(tableName, roleID, title, parent) {
    let url =
      "/Admin/WebServices/MembershipWebServices.asmx/GetRolePermissionData";
    let input = {
      tableName,
      roleID,
      title,
    };

    // Clear permission browsing history if opening parent permission screen
    if (parent === "true") {
      this.session.previousPermissionScreen = [];
    }

    // Check if permission screen tablename is not already in the history
    if (
      !this.session.previousPermissionScreen
        .map((permissionScreen) => {
          return permissionScreen.tableName;
        })
        .includes(tableName)
    ) {
      this.session.previousPermissionScreen.push({
        tableName,
        roleID,
        title,
      });
    }

    // Fixed strange browser bug, uncheck all checkboxes before reloading data.
    // Firefox & Chrome are remembering Checked action buttons.
    //$("input:checkbox", window.element).removeAttr("checked")

    let output = await this.session.request(url, input);
    output.FormPermissionMenu = arrayToTree(output.FormPermissionMenu, {
      parentProperty: "ParentID",
      customID: "ID",
    });

    output.inputVars = input;
    //output.breadCrumbs = [].concat(breadcrumbs.split(/;/g))

    this.previousPermissionScreen = this.session.previousPermissionScreen;

    this.setupCustomEnv("permissions", output, true);
  }

  /**
   * Opens previous permissions screen based on saved history of window object
   */
  async openPreviousPermissionsScreen() {
    if (this.session.previousPermissionScreen.length < 1) return;

    this.session.previousPermissionScreen.splice(-1, 1);

    const previousPermissionScreen =
      this.session.previousPermissionScreen[
        this.session.previousPermissionScreen.length - 1
      ];

    let url =
      "/Admin/WebServices/MembershipWebServices.asmx/GetRolePermissionData";
    let input = {
      tableName: previousPermissionScreen.tableName,
      roleID: previousPermissionScreen.roleID,
    };
    // Fixed strange browser bug, uncheck all checkboxes before reloading data.
    // Firefox & Chrome are remembering Checked action buttons.
    //$("input:checkbox", window.element).removeAttr("checked")

    let output = await this.session.request(url, input);
    output.FormPermissionMenu = arrayToTree(output.FormPermissionMenu, {
      parentProperty: "ParentID",
      customID: "ID",
    });

    output.inputVars = input;
    //output.breadCrumbs = [].concat(breadcrumbs.split(/;/g))

    this.setupCustomEnv("permissions", output, true);
  }

  /**
   * Select permissions for table for sub
   * @todo fill roleID, possilbly depricated
   * @param {string} tableName - Table name
   * @param {*} roleID - Role ID
   * @param {string} breadcrumbs - breadcrums
   * @returns {Promise} Promise
   */
  async selectPermissionsSub(tableName = null, roleID, breadcrumbs = "") {
    roleID =
      global.session.activeWindow.sub.window.output.FullTable.Rows[0].filter(
        (row) => row.Column.Name === "RoleId",
      )[0].Value;

    this.injectSub({}, true, true);
    this.sub.window.selectPermissions(tableName, roleID, breadcrumbs);
  }

  /**
   * Save permissions
   * @param {string} tableName - Table name
   * @param {*} roleID - Role id
   * @returns {Promise} Promise
   */
  async savePermissions(tableName, roleID) {
    if (!roleID) {
      roleID =
        global.session.activeWindow.sub.window.output.Request.Criteria[0]
          .RoleId;
    }
    let permissionInfo = {
      tableName: tableName,
      roleID: roleID,
    };

    ["FormPermissions", "Actions", "Columns", "Tabs"].forEach((i) => {
      let values = serializeForm(
        $(this.element)
          .find("[data-form-id=" + i + "]")
          .get(0),
      );

      let formattedNeed = false;
      let formatted = {};

      for (let j in values) {
        let bracketText = (j.match(/\[([^)]+)\]/) || [null, null])[1] || null;

        // let val = values[i] === "on"
        let val = values[j];
        if (bracketText) {
          formattedNeed = true;
          let colName = j.substr(0, j.length - bracketText.length - 2);
          formatted[colName] = formatted[colName] || {};
          formatted[colName][bracketText] = val;
        } else {
          values[j] = val;
        }
      }

      permissionInfo[i] = formattedNeed ? formatted : values || {};
    });

    let url =
      "/Admin/WebServices/MembershipWebServices.asmx/SetRolePermissionData";
    let output = await this.session.request(url, {
      permissionInfo,
    });

    this.handleActionResponse(output);
  }

  /**
   * Save checked (and unchecked) values from treeview
   */
  async saveTreeView() {
    let initialValues = this.output.Data.initialValues;
    let values = serializeForm(this.element);

    // remove values that are the same in the currentlist and in the initial list
    for (let property in initialValues) {
      if (
        initialValues.hasOwnProperty(property) &&
        values.hasOwnProperty(property) &&
        initialValues[property] === values[property]
      ) {
        delete values[property];
      }
    }

    let data = {
      subject: this.output.Request.LinkedSubject,
      criteria:
        this.output.Request.Criteria.length > 0
          ? this.output.Request.Criteria[0]
          : null,
      values: values,
    };

    let output = await this.session.request(
      "/Admin/WebServices/CoreWebServices.asmx/SaveTree",
      data,
    );

    this.handleActionResponse(output);
  }

  /**
   * Cancel tree, alias for reset
   * @see {@link Window.reset}
   * @returns {Promise} Promise
   */
  async cancelTreeView() {
    await this.reset();
  }

  /**
   * Set custum values
   * @param {string} templateName - Custom template name
   * @param {Object} customData - Custom data
   * @param {boolean} keepInput - Keep input
   * @returns {void}
   */
  setupCustomEnv(templateName, customData, keepInput) {
    this.customTemplateName = templateName;
    this.customData = customData;
    this.input = keepInput ? this.input : null;
    this.render();
  }

  /**
   * Open dialog with user
   * @param {string} instructionText - Instruction text displayed in message
   * @param {Array} fields - Fields to show in dialog
   * @param {boolean} isStack - If is stack
   * @returns {Promise} Promise
   */
  async dialog(instructionText, fields = null, isStack, content, options) {
    if (fields) {
      fields.forEach((f) => {
        if (f.Initial != null && f.Initial.Value != null) {
          f.Initial.Value = formatter.parseValue(f, f.Initial.Value);
        }

        if (f.DropdownItems) {
          f.Combobox = new Combobox();
          f.Combobox.populate(f.DropdownItems);
          f.Combobox.specification.name = f.Name;
          if (
            f.Initial != null &&
            f.Initial.Value != null &&
            f.Initial.Text != null
          ) {
            f.Combobox.setInitialValues(f.Initial.Text, f.Initial.Value);
          }
        } else if (f.Dropdown) {
          f.Combobox = Combobox.new(null, {
            name: f.Dropdown.ColumnName,
            tableName: f.Dropdown.TableName,
            columnName: f.Dropdown.ColumnName,
          });
        }
      });
    }

    this.actionInputArgs = this.actionInputArgs || {};
    let formMessage = await this.message(
      "dialog",
      instructionText,
      {fields},
      content,
      options,
    );
    let form = formMessage.form;

    if (form == null) {
      this.lastActionArgs = null;
      this.actionInputArgs = {};
      return;
    }

    for (let key in form) {
      let col = fields.filter((x) => x.Name == key)[0];
      this.actionInputArgs[key] = formatter.serializeValue(col, form[key]);
    }

    if (!isStack && this.lastActionArgs) {
      this.toggleLoading(true);
      await this.action(...this.lastActionArgs);
      this.toggleLoading(false);
    }
  }

  /**
   * Open category in new window
   * @param {*} categoryID - Category ID
   * @deprecated
   * @returns {Promise} Promise
   */
  async openCategory(categoryID) {
    // This wasn't defined..?
    let newInput = {};
    newInput.Criteria = [{CategoryID: categoryID}];
    await this.session.openWindow(newInput);
  }

  /**
   * Opens help window
   * @returns {void}
   */
  help() {
    if (!this.output || !this.output.Options || !this.output.Options.HelpUrl) {
      this.message("warning", this.session.translations.NoHelpAvailable);
      return;
    }

    global.window.open(
      this.output.Options.HelpUrl.replace(
        "[Session.productDomain]",
        this.session.productDomain,
      ),
    );
  }

  /**
   * Opens url in new tab in browser
   * @param {string} url - URL
   * @returns {void}
   */
  async openUrl(url) {
    if (url == null) {
      this.message("warning", this.session.translations.NoHelpAvailable);
      return;
    }

    global.window.open(url);
  }

  /**
   * Reloads the complete web page with plain javascript reload() command.
   */
  async reloadWebpage() {
    location.reload();
  }

  /**
   * Get row css. Render function
   * @param {number} rowIndex - Row index
   * @returns {string} CSS information
   */
  getCssInfo(rowIndex) {
    let rowMeta =
      ((this.output.Data || {}).Metadata[rowIndex] || {})["*"] || {};

    let bg = rowMeta.BackgroundColor;
    let fc = rowMeta.Color;

    return (
      (bg != null ? "background-color: " + bg + ";" : "") +
      (fc != null ? "color: " + fc + ";" : "")
    );
  }

  /**
   * Get Columns
   * @returns {Array} List of searchable Columns
   */
  getSearchColumns() {
    return (
      (this.output &&
        this.output.Table &&
        this.output.Table.Columns.filter(
          (x) =>
            x.CanSortOn &&
            ["String", "Int", "Decimal", "Money", "DateTime", "Date"].indexOf(
              x.Type,
            ) >= 0,
        )) ||
      []
    );
  }
}
