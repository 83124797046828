
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/elements/horizontalCalender.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"table-view ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"bulkedit")?" unsortable":""), env.opts.autoescape);
output += "\">\n    ";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("header"))(env, context, frame, runtime, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
output += t_1;
output += "\n    <div class=\"row no-gutters horizontal-agenda\">\n        <div class=\"col-12\" style=\"\">\n            <div>\n                <div class=\"table-container\" style=\"overflow: hidden auto;\">\n                    ";
var t_3;
t_3 = 0;
frame.set("rowIndex", t_3, true);
if(frame.topLevel) {
context.setVariable("rowIndex", t_3);
}
if(frame.topLevel) {
context.addExport("rowIndex", t_3);
}
output += "\n                    ";
frame = frame.push();
var t_6 = env.getFilter("groupby").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Data")),"Rows"),"DueDateRaw");
if(t_6) {t_6 = runtime.fromIterator(t_6);
var t_4;
if(runtime.isArray(t_6)) {
var t_5 = t_6.length;
for(t_4=0; t_4 < t_6.length; t_4++) {
var t_7 = t_6[t_4][0];
frame.set("[object Object]", t_6[t_4][0]);
var t_8 = t_6[t_4][1];
frame.set("[object Object]", t_6[t_4][1]);
frame.set("loop.index", t_4 + 1);
frame.set("loop.index0", t_4);
frame.set("loop.revindex", t_5 - t_4);
frame.set("loop.revindex0", t_5 - t_4 - 1);
frame.set("loop.first", t_4 === 0);
frame.set("loop.last", t_4 === t_5 - 1);
frame.set("loop.length", t_5);
output += "\n                    ";
if((lineno = 84, colno = 27, runtime.callWrap(runtime.memberLookup(((lineno = 83, colno = 26, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"dayjs"), "window[\"dayjs\"]", context, [t_7]))),"format"), "the return value of (window[\"dayjs\"])[\"format\"]", context, ["x"])) < (lineno = 84, colno = 62, runtime.callWrap(runtime.memberLookup(((lineno = 84, colno = 48, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"dayjs"), "window[\"dayjs\"]", context, [runtime.contextOrFrameLookup(context, frame, "date")]))),"format"), "the return value of (window[\"dayjs\"])[\"format\"]", context, ["x"]))) {
output += " \n                    <table class=\"table table-hover table-striped table-danger table-bordered mb-0\">\n                        ";
;
}
else {
output += "\n                        <table class=\"table table-hover table-striped table-bordered mb-0\">\n                            ";
;
}
output += "\n                            <tbody>\n                                <tr class=\"table-active bg-secondary\">\n                                    <td class=\"table-active\" colspan=\"4\">\n                                        <strong>\n                                            <a>\n                                                <i class=\"fas fa-calendar-day\"></i>\n                                                ";
output += runtime.suppressValue((lineno = 95, colno = 75, runtime.callWrap(runtime.memberLookup(((lineno = 95, colno = 63, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"dayjs"), "window[\"dayjs\"]", context, [t_7]))),"format"), "the return value of (window[\"dayjs\"])[\"format\"]", context, ["dddd DD MMMM YYYY"])), env.opts.autoescape);
output += "</a>\n                                        </strong>\n                                        <strong class=\"float-right\">\n                                            <a>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Week"), env.opts.autoescape);
output += "\n                                                ";
output += runtime.suppressValue((lineno = 99, colno = 73, runtime.callWrap(runtime.memberLookup(((lineno = 99, colno = 63, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"dayjs"), "window[\"dayjs\"]", context, [t_7]))),"week"), "the return value of (window[\"dayjs\"])[\"week\"]", context, [])), env.opts.autoescape);
output += "</a>\n                                        </strong>\n                                    </td>\n                                </tr>\n                                ";
frame = frame.push();
var t_11 = t_8;
if(t_11) {t_11 = runtime.fromIterator(t_11);
var t_10 = t_11.length;
for(var t_9=0; t_9 < t_11.length; t_9++) {
var t_12 = t_11[t_9];
frame.set("item", t_12);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
output += "\n                                <tr data-row-index=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "rowIndex"), env.opts.autoescape);
output += "\" data-subject=\"";
output += runtime.suppressValue(runtime.memberLookup((t_12),"Subject"), env.opts.autoescape);
output += "\"\n                                    data-key=\"";
output += runtime.suppressValue(runtime.memberLookup((t_12),"ID"), env.opts.autoescape);
output += "\" data-keyreference=\"";
output += runtime.suppressValue(runtime.memberLookup((t_12),"KeyReference"), env.opts.autoescape);
output += "\">\n                                    <td width=\"160px\" class=\"text-center\">\n                                        <!-- Type 1 Outgoing order -->\n                                        ";
if(runtime.memberLookup((t_12),"TaskDescription") == "IncomingItem") {
output += "\n                                        <span class=\"badge badge-info\">\n                                            <i class=\"fas fa-arrow-down\"></i>\n                                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),runtime.memberLookup((t_12),"TaskDescription")), env.opts.autoescape);
output += "</span>\n                                        ";
;
}
else {
if(runtime.memberLookup((t_12),"TaskDescription") == "OutgoingItem") {
output += "\n                                        <span class=\"badge badge-success\">\n                                            <i class=\"fas fa-arrow-up\"></i>\n                                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),runtime.memberLookup((t_12),"TaskDescription")), env.opts.autoescape);
output += "</span>\n                                        ";
;
}
else {
if(runtime.memberLookup((t_12),"TaskDescription") == "OutgoingItem") {
output += "\n                                        <span class=\"badge badge-secondary\">\n                                            <i class=\"fas fa-dolly\"></i>\n                                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),runtime.contextOrFrameLookup(context, frame, "stocktransfer")), env.opts.autoescape);
output += "</span>\n                                        ";
;
}
else {
if(runtime.memberLookup((t_12),"TaskDescription") == "Invoice") {
output += "\n                                        <span class=\"badge badge-secondary\">\n                                            <i class=\"fas fa-file-invoice\"></i>\n                                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),runtime.memberLookup((t_12),"TaskDescription")), env.opts.autoescape);
output += "</span>\n                                        ";
;
}
else {
output += "\n                                        <span class=\"badge badge-secondary\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),runtime.memberLookup((t_12),"TaskDescription")), env.opts.autoescape);
output += "</span>\n                                        ";
;
}
;
}
;
}
;
}
output += "\n                                    </td>\n                                    <td width=\"40px;\" class=\"text-center\">";
output += runtime.suppressValue(runtime.memberLookup((t_12),"Amount"), env.opts.autoescape);
output += "</td>\n                                    <td>\n                                        <a>\n                                            <strong>";
output += runtime.suppressValue(runtime.memberLookup((t_12),"ID"), env.opts.autoescape);
output += "\n                                            </strong>- ";
output += runtime.suppressValue(runtime.memberLookup((t_12),"CustomerID"), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue(runtime.memberLookup((t_12),"CustomerCompanyName"), env.opts.autoescape);
output += "\n                                            <i>";
output += runtime.suppressValue(runtime.memberLookup((t_12),"Reference"), env.opts.autoescape);
output += "\n                                                ";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((t_12),"OtherDescription")), env.opts.autoescape);
output += "</i>\n                                            <span class=\"float-right\">";
output += runtime.suppressValue(runtime.memberLookup((t_12),"UserCreated"), env.opts.autoescape);
output += "</span></a>\n                                    </td>\n                                </tr>\n                                ";
var t_13;
t_13 = runtime.contextOrFrameLookup(context, frame, "rowIndex") + 1;
frame.set("rowIndex", t_13, true);
if(frame.topLevel) {
context.setVariable("rowIndex", t_13);
}
if(frame.topLevel) {
context.addExport("rowIndex", t_13);
}
output += "\n                                ";
;
}
}
frame = frame.pop();
output += "\n                            </tbody>\n                        </table>\n                        ";
;
}
} else {
t_4 = -1;
var t_5 = runtime.keys(t_6).length;
for(var t_14 in t_6) {
t_4++;
var t_15 = t_6[t_14];
frame.set("day", t_14);
frame.set("row", t_15);
frame.set("loop.index", t_4 + 1);
frame.set("loop.index0", t_4);
frame.set("loop.revindex", t_5 - t_4);
frame.set("loop.revindex0", t_5 - t_4 - 1);
frame.set("loop.first", t_4 === 0);
frame.set("loop.last", t_4 === t_5 - 1);
frame.set("loop.length", t_5);
output += "\n                    ";
if((lineno = 84, colno = 27, runtime.callWrap(runtime.memberLookup(((lineno = 83, colno = 26, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"dayjs"), "window[\"dayjs\"]", context, [t_14]))),"format"), "the return value of (window[\"dayjs\"])[\"format\"]", context, ["x"])) < (lineno = 84, colno = 62, runtime.callWrap(runtime.memberLookup(((lineno = 84, colno = 48, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"dayjs"), "window[\"dayjs\"]", context, [runtime.contextOrFrameLookup(context, frame, "date")]))),"format"), "the return value of (window[\"dayjs\"])[\"format\"]", context, ["x"]))) {
output += " \n                    <table class=\"table table-hover table-striped table-danger table-bordered mb-0\">\n                        ";
;
}
else {
output += "\n                        <table class=\"table table-hover table-striped table-bordered mb-0\">\n                            ";
;
}
output += "\n                            <tbody>\n                                <tr class=\"table-active bg-secondary\">\n                                    <td class=\"table-active\" colspan=\"4\">\n                                        <strong>\n                                            <a>\n                                                <i class=\"fas fa-calendar-day\"></i>\n                                                ";
output += runtime.suppressValue((lineno = 95, colno = 75, runtime.callWrap(runtime.memberLookup(((lineno = 95, colno = 63, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"dayjs"), "window[\"dayjs\"]", context, [t_14]))),"format"), "the return value of (window[\"dayjs\"])[\"format\"]", context, ["dddd DD MMMM YYYY"])), env.opts.autoescape);
output += "</a>\n                                        </strong>\n                                        <strong class=\"float-right\">\n                                            <a>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Week"), env.opts.autoescape);
output += "\n                                                ";
output += runtime.suppressValue((lineno = 99, colno = 73, runtime.callWrap(runtime.memberLookup(((lineno = 99, colno = 63, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"dayjs"), "window[\"dayjs\"]", context, [t_14]))),"week"), "the return value of (window[\"dayjs\"])[\"week\"]", context, [])), env.opts.autoescape);
output += "</a>\n                                        </strong>\n                                    </td>\n                                </tr>\n                                ";
frame = frame.push();
var t_18 = t_15;
if(t_18) {t_18 = runtime.fromIterator(t_18);
var t_17 = t_18.length;
for(var t_16=0; t_16 < t_18.length; t_16++) {
var t_19 = t_18[t_16];
frame.set("item", t_19);
frame.set("loop.index", t_16 + 1);
frame.set("loop.index0", t_16);
frame.set("loop.revindex", t_17 - t_16);
frame.set("loop.revindex0", t_17 - t_16 - 1);
frame.set("loop.first", t_16 === 0);
frame.set("loop.last", t_16 === t_17 - 1);
frame.set("loop.length", t_17);
output += "\n                                <tr data-row-index=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "rowIndex"), env.opts.autoescape);
output += "\" data-subject=\"";
output += runtime.suppressValue(runtime.memberLookup((t_19),"Subject"), env.opts.autoescape);
output += "\"\n                                    data-key=\"";
output += runtime.suppressValue(runtime.memberLookup((t_19),"ID"), env.opts.autoescape);
output += "\" data-keyreference=\"";
output += runtime.suppressValue(runtime.memberLookup((t_19),"KeyReference"), env.opts.autoescape);
output += "\">\n                                    <td width=\"160px\" class=\"text-center\">\n                                        <!-- Type 1 Outgoing order -->\n                                        ";
if(runtime.memberLookup((t_19),"TaskDescription") == "IncomingItem") {
output += "\n                                        <span class=\"badge badge-info\">\n                                            <i class=\"fas fa-arrow-down\"></i>\n                                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),runtime.memberLookup((t_19),"TaskDescription")), env.opts.autoescape);
output += "</span>\n                                        ";
;
}
else {
if(runtime.memberLookup((t_19),"TaskDescription") == "OutgoingItem") {
output += "\n                                        <span class=\"badge badge-success\">\n                                            <i class=\"fas fa-arrow-up\"></i>\n                                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),runtime.memberLookup((t_19),"TaskDescription")), env.opts.autoescape);
output += "</span>\n                                        ";
;
}
else {
if(runtime.memberLookup((t_19),"TaskDescription") == "OutgoingItem") {
output += "\n                                        <span class=\"badge badge-secondary\">\n                                            <i class=\"fas fa-dolly\"></i>\n                                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),runtime.contextOrFrameLookup(context, frame, "stocktransfer")), env.opts.autoescape);
output += "</span>\n                                        ";
;
}
else {
if(runtime.memberLookup((t_19),"TaskDescription") == "Invoice") {
output += "\n                                        <span class=\"badge badge-secondary\">\n                                            <i class=\"fas fa-file-invoice\"></i>\n                                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),runtime.memberLookup((t_19),"TaskDescription")), env.opts.autoescape);
output += "</span>\n                                        ";
;
}
else {
output += "\n                                        <span class=\"badge badge-secondary\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),runtime.memberLookup((t_19),"TaskDescription")), env.opts.autoescape);
output += "</span>\n                                        ";
;
}
;
}
;
}
;
}
output += "\n                                    </td>\n                                    <td width=\"40px;\" class=\"text-center\">";
output += runtime.suppressValue(runtime.memberLookup((t_19),"Amount"), env.opts.autoescape);
output += "</td>\n                                    <td>\n                                        <a>\n                                            <strong>";
output += runtime.suppressValue(runtime.memberLookup((t_19),"ID"), env.opts.autoescape);
output += "\n                                            </strong>- ";
output += runtime.suppressValue(runtime.memberLookup((t_19),"CustomerID"), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue(runtime.memberLookup((t_19),"CustomerCompanyName"), env.opts.autoescape);
output += "\n                                            <i>";
output += runtime.suppressValue(runtime.memberLookup((t_19),"Reference"), env.opts.autoescape);
output += "\n                                                ";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((t_19),"OtherDescription")), env.opts.autoescape);
output += "</i>\n                                            <span class=\"float-right\">";
output += runtime.suppressValue(runtime.memberLookup((t_19),"UserCreated"), env.opts.autoescape);
output += "</span></a>\n                                    </td>\n                                </tr>\n                                ";
var t_20;
t_20 = runtime.contextOrFrameLookup(context, frame, "rowIndex") + 1;
frame.set("rowIndex", t_20, true);
if(frame.topLevel) {
context.setVariable("rowIndex", t_20);
}
if(frame.topLevel) {
context.addExport("rowIndex", t_20);
}
output += "\n                                ";
;
}
}
frame = frame.pop();
output += "\n                            </tbody>\n                        </table>\n                        ";
;
}
}
}
frame = frame.pop();
output += "\n                </div>\n            </div>\n        </div>\n    </div>\n     ";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"bulkedit") && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Data")),"Totals")),"RowCount")),"Value") > 0) {
output += "<div class=\"table-footer clearfix sticky-div stick-to-bottom ml-2 mr-2\">\n                ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination") && !runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"bulkedit")) {
output += "<ul class=\"pagination\">\n                        <li class=\"button button-menu material-icons\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"First"), env.opts.autoescape);
output += "\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"first") || "", env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"first")?"disabled":""), env.opts.autoescape);
output += ">&#xe045;</li>\n                        <li class=\"button button-menu material-icons\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Previous"), env.opts.autoescape);
output += "\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"prev") || "", env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"prev")?"disabled":""), env.opts.autoescape);
output += ">&#xe314;</li>\n                        ";
frame = frame.push();
var t_23 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"sequence");
if(t_23) {t_23 = runtime.fromIterator(t_23);
var t_22 = t_23.length;
for(var t_21=0; t_21 < t_23.length; t_21++) {
var t_24 = t_23[t_21];
frame.set("item", t_24);
frame.set("loop.index", t_21 + 1);
frame.set("loop.index0", t_21);
frame.set("loop.revindex", t_22 - t_21);
frame.set("loop.revindex0", t_22 - t_21 - 1);
frame.set("loop.first", t_21 === 0);
frame.set("loop.last", t_21 === t_22 - 1);
frame.set("loop.length", t_22);
output += "<li class=\"page ";
output += runtime.suppressValue((runtime.memberLookup((t_24),"current")?"current":""), env.opts.autoescape);
output += "\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((t_24),"page"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_24),"page"), env.opts.autoescape);
output += "</li>\n                        ";
;
}
}
frame = frame.pop();
output += "\n                        <li class=\"button button-menu material-icons\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Next"), env.opts.autoescape);
output += "\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"next") || "", env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"next")?"disabled":""), env.opts.autoescape);
output += ">&#xe315;</li>\n                        <li class=\"button button-menu material-icons\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Last"), env.opts.autoescape);
output += "\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"last") || "", env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"last")?"disabled":""), env.opts.autoescape);
output += ">&#xe044;</li>\n                    </ul>\n                ";
;
}
output += "\n\n                ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Totals") && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Totals")),"length") > 0) {
output += "\n                    <div class=\"pull-right\">\n                        ";
frame = frame.push();
var t_27 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Totals");
if(t_27) {t_27 = runtime.fromIterator(t_27);
var t_26 = t_27.length;
for(var t_25=0; t_25 < t_27.length; t_25++) {
var t_28 = t_27[t_25];
frame.set("col", t_28);
frame.set("loop.index", t_25 + 1);
frame.set("loop.index0", t_25);
frame.set("loop.revindex", t_26 - t_25);
frame.set("loop.revindex0", t_26 - t_25 - 1);
frame.set("loop.first", t_25 === 0);
frame.set("loop.last", t_25 === t_26 - 1);
frame.set("loop.length", t_26);
output += "<div class=\"fake-button button button-menu no-hover no-action\">\n                                ";
output += runtime.suppressValue(runtime.memberLookup((t_28),"Title"), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue(runtime.memberLookup((t_28),"Value"), env.opts.autoescape);
output += "\n                            </div>";
;
}
}
frame = frame.pop();
output += "\n                    </div>\n                ";
;
}
output += "\n\n                <div class=\"fake-button button button-menu no-hover no-action\">\n                    ";
if(!runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Options")),"DisableSelection")) {
output += "\n                        <span data-select-count title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"AmountSelectedRows"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"selection")),"length"), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t/\n\t\t\t\t\t";
;
}
output += "\n                    <span title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"TotalAmountRows"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Data")),"Totals")),"RowCount")),"Value"), env.opts.autoescape);
output += "</span>\n                </div>\n            </div>\n        ";
;
}
output += "\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_header(env, context, frame, runtime, cb) {
var lineno = 1;
var colno = 7;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <div class=\"table-form sticky-div ml-1 mr-1 mt-1\">\n\n\n        <form class=\"search-bar\">\n            <div class=\"search-box\">\n                ";
frame = frame.push();
var t_31 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"filters")),"date");
if(t_31) {t_31 = runtime.fromIterator(t_31);
var t_30 = t_31.length;
for(var t_29=0; t_29 < t_31.length; t_29++) {
var t_32 = t_31[t_29];
frame.set("filter", t_32);
frame.set("loop.index", t_29 + 1);
frame.set("loop.index0", t_29);
frame.set("loop.revindex", t_30 - t_29);
frame.set("loop.revindex0", t_30 - t_29 - 1);
frame.set("loop.first", t_29 === 0);
frame.set("loop.last", t_29 === t_30 - 1);
frame.set("loop.length", t_30);
if(runtime.memberLookup((t_32),"value")) {
output += "<span class=\"filter\" data-window-event=\"remove-date-query:";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
output += "\">\n                    <span class=\"close material-icons\" style=\"font-size:inherit;\">&#xe5cd;</span>\n                    <span>";
output += runtime.suppressValue(runtime.memberLookup((t_32),"text"), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue(runtime.memberLookup((t_32),"value"), env.opts.autoescape);
output += "</span>\n                </span>\n                ";
;
}
else {
output += "<div class=\"dropdown filter-option date-picker\" data-type=\"Date\" data-replace>\n                    <button class=\"toggle-menu button button-menu\">\n                        <span>";
output += runtime.suppressValue(runtime.memberLookup((t_32),"text"), env.opts.autoescape);
output += "</span>\n                        <span class=\"material-icons\" style=\"font-size:inherit;\">&#xe5c5;</span>\n                    </button>\n                    <input type=\"hidden\" class=\"date\" data-index=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
output += "\">\n                </div>";
;
}
;
}
}
frame = frame.pop();
output += "\n\n                ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Filters")) {
output += "\n                ";
frame = frame.push();
var t_35 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Filters");
if(t_35) {t_35 = runtime.fromIterator(t_35);
var t_34 = t_35.length;
for(var t_33=0; t_33 < t_35.length; t_33++) {
var t_36 = t_35[t_33];
frame.set("filter", t_36);
frame.set("loop.index", t_33 + 1);
frame.set("loop.index0", t_33);
frame.set("loop.revindex", t_34 - t_33);
frame.set("loop.revindex0", t_34 - t_33 - 1);
frame.set("loop.first", t_33 === 0);
frame.set("loop.last", t_33 === t_34 - 1);
frame.set("loop.length", t_34);
if(runtime.memberLookup((t_36),"Type") == "List") {
output += "\n                <div class=\"filter-option right\">\n                    <select data-filter-target=\"";
output += runtime.suppressValue(runtime.memberLookup((t_36),"Target"), env.opts.autoescape);
output += "\">\n                        ";
frame = frame.push();
var t_39 = runtime.memberLookup((t_36),"Options");
if(t_39) {t_39 = runtime.fromIterator(t_39);
var t_38 = t_39.length;
for(var t_37=0; t_37 < t_39.length; t_37++) {
var t_40 = t_39[t_37];
frame.set("item", t_40);
frame.set("loop.index", t_37 + 1);
frame.set("loop.index0", t_37);
frame.set("loop.revindex", t_38 - t_37);
frame.set("loop.revindex0", t_38 - t_37 - 1);
frame.set("loop.first", t_37 === 0);
frame.set("loop.last", t_37 === t_38 - 1);
frame.set("loop.length", t_38);
output += "\n                        <option ";
output += runtime.suppressValue((runtime.memberLookup((t_36),"Selected") == runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0")?"selected":""), env.opts.autoescape);
output += " value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_40),"Key"), env.opts.autoescape);
output += "\n                        </option>\n                        ";
;
}
}
frame = frame.pop();
output += "\n                    </select>\n                </div>\n                ";
;
}
if(runtime.memberLookup((t_36),"Type") == "Treeview") {
output += "\n                <div class=\"dropdown right filter-option\">\n                    <div class=\"button button-menu fake-button toggle-menu\">\n                        ";
output += runtime.suppressValue(runtime.memberLookup((t_36),"text"), env.opts.autoescape);
output += "\n                    </div>\n                    <div class=\"menu hide tree-view white\">\n                        ";
output += runtime.suppressValue((lineno = 42, colno = 37, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "makeBranch"), "makeBranch", context, [t_36,runtime.memberLookup((t_36),"list")])), env.opts.autoescape);
output += "\n                    </div>\n                </div>\n                ";
;
}
;
}
}
frame = frame.pop();
output += "\n                ";
;
}
output += "\n\n                ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Request")),"Data") && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Request")),"Data")),"Search") && runtime.memberLookup(((lineno = 55, colno = 33, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"getSearchColumns"), "window[\"getSearchColumns\"]", context, []))),"length") > 0) {
output += "\n                ";
frame = frame.push();
var t_43 = runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Request")),"Data")),"Search");
if(t_43) {t_43 = runtime.fromIterator(t_43);
var t_42 = t_43.length;
for(var t_41=0; t_41 < t_43.length; t_41++) {
var t_44 = t_43[t_41];
frame.set("searchObj", t_44);
frame.set("loop.index", t_41 + 1);
frame.set("loop.index0", t_41);
frame.set("loop.revindex", t_42 - t_41);
frame.set("loop.revindex0", t_42 - t_41 - 1);
frame.set("loop.first", t_41 === 0);
frame.set("loop.last", t_41 === t_42 - 1);
frame.set("loop.length", t_42);
output += "\n                ";
var t_45;
t_45 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0");
frame.set("offset", t_45, true);
if(frame.topLevel) {
context.setVariable("offset", t_45);
}
if(frame.topLevel) {
context.addExport("offset", t_45);
}
output += "\n                ";
frame = frame.push();
var t_48 = t_44;
if(t_48) {t_48 = runtime.fromIterator(t_48);
var t_46;
if(runtime.isArray(t_48)) {
var t_47 = t_48.length;
for(t_46=0; t_46 < t_48.length; t_46++) {
var t_49 = t_48[t_46][0];
frame.set("[object Object]", t_48[t_46][0]);
var t_50 = t_48[t_46][1];
frame.set("[object Object]", t_48[t_46][1]);
frame.set("loop.index", t_46 + 1);
frame.set("loop.index0", t_46);
frame.set("loop.revindex", t_47 - t_46);
frame.set("loop.revindex0", t_47 - t_46 - 1);
frame.set("loop.first", t_46 === 0);
frame.set("loop.last", t_46 === t_47 - 1);
frame.set("loop.length", t_47);
output += "\n                <div class=\"filter\" data-window-event=\"remove-text-query:";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "offset"), env.opts.autoescape);
output += "\">\n                    <span class=\"close material-icons\" style=\"font-size:inherit;\">&#xe5cd;</span>\n                    <span>";
output += runtime.suppressValue(t_50, env.opts.autoescape);
output += "</span>\n                </div>\n                ";
;
}
} else {
t_46 = -1;
var t_47 = runtime.keys(t_48).length;
for(var t_51 in t_48) {
t_46++;
var t_52 = t_48[t_51];
frame.set("columnSelection", t_51);
frame.set("searchTerm", t_52);
frame.set("loop.index", t_46 + 1);
frame.set("loop.index0", t_46);
frame.set("loop.revindex", t_47 - t_46);
frame.set("loop.revindex0", t_47 - t_46 - 1);
frame.set("loop.first", t_46 === 0);
frame.set("loop.last", t_46 === t_47 - 1);
frame.set("loop.length", t_47);
output += "\n                <div class=\"filter\" data-window-event=\"remove-text-query:";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "offset"), env.opts.autoescape);
output += "\">\n                    <span class=\"close material-icons\" style=\"font-size:inherit;\">&#xe5cd;</span>\n                    <span>";
output += runtime.suppressValue(t_52, env.opts.autoescape);
output += "</span>\n                </div>\n                ";
;
}
}
}
frame = frame.pop();
output += "\n                ";
;
}
}
frame = frame.pop();
output += "\n\n                <div class=\"search-input-container\">\n                    <input class=\"input ignore-next ignore-reset\"\n                        placeholder=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"PlaceholderSearch"), env.opts.autoescape);
output += "\">\n                </div>\n                ";
;
}
output += "\n            </div>\n        </form>\n    </div>\n    ";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_header: b_header,
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/elements/horizontalCalender.html", ctx);
          }
        
          return nunjucks.render("views/elements/horizontalCalender.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/elements/horizontalCalender.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        