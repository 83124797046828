import { api } from "./api";
import store from "../../state/store";
/**
 * Gives the currently logged in user their login details back
 * @returns {Object} User details object
 */
export async function getUserInfo() {
    const result = await api.get("/v2/users/me");
    return result.data;
}
/**
 * Modify an existing user property
 * @param {string} settingsCode Key of the user property
 * @param {string} value
 * @param {string} type
 * @param {boolean} visible
 * @returns
 */
export async function modifyUserSetting({ settingsCode, value, type = "string", visible = false, }) {
    const result = await api.patch(`/v2/user/settings`, {
        UserId: store.state.user.UserID,
        SettingCode: settingsCode,
        Value: value,
        Type: type,
        Visible: visible,
    });
    return result.data;
}
/**
 * Crate a new user property
 * @param {string} settingsCode Key of the user property
 * @param {string} value
 * @param {string} type
 * @param {boolean} visible
 * @returns
 */
export async function createUserSetting({ settingsCode, value, type = "boolean", visible = false, }) {
    const result = await api.post(`/v2/user/settings`, {
        UserID: store.state.user.me.UserID,
        SettingCode: settingsCode,
        Value: value,
        Type: type,
        Visible: visible,
    });
    return result.data;
}
