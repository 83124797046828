
        
    const runtime = require("../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        const __nunjucks_template_import__dep_0 = require("./inputValue.html");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/form/formInput.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("views/content/form/inputValue.html", false, "views/content/form/formInput.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("inputValue", t_1);
output += "\n";
var macro_t_4 = runtime.makeMacro(
["type", "col", "coltype", "index", "value"], 
[], 
function (l_type, l_col, l_coltype, l_index, l_value, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("type", l_type);
frame.set("col", l_col);
frame.set("coltype", l_coltype);
frame.set("index", l_index);
frame.set("value", l_value);
var t_5 = "";t_5 += "<!-- If we're dealing with a coltype that is not type TEXT in the database -->\n  ";
if(l_coltype != "text") {
t_5 += "\n    ";
var t_6;
t_6 = "";
frame.set("InputClasses", t_6, true);
if(frame.topLevel) {
context.setVariable("InputClasses", t_6);
}
if(frame.topLevel) {
context.addExport("InputClasses", t_6);
}
t_5 += "\n    ";
var t_7;
t_7 = "";
frame.set("InputProperties", t_7, true);
if(frame.topLevel) {
context.setVariable("InputProperties", t_7);
}
if(frame.topLevel) {
context.addExport("InputProperties", t_7);
}
t_5 += "\n\n    <!-- Set the css classes for the <input> element-->\n    ";
var t_8;
t_8 = "form-control ";
frame.set("InputClasses", t_8, true);
if(frame.topLevel) {
context.setVariable("InputClasses", t_8);
}
if(frame.topLevel) {
context.addExport("InputClasses", t_8);
}
t_5 += "\n    ";
var t_9;
t_9 = runtime.contextOrFrameLookup(context, frame, "InputClasses") + ((l_coltype != "Boolean"?"editable-text":""));
frame.set("InputClasses", t_9, true);
if(frame.topLevel) {
context.setVariable("InputClasses", t_9);
}
if(frame.topLevel) {
context.addExport("InputClasses", t_9);
}
t_5 += "\n    ";
var t_10;
t_10 = runtime.contextOrFrameLookup(context, frame, "InputClasses") + ((l_coltype == "Date" || l_coltype == "DateTime"?" date":""));
frame.set("InputClasses", t_10, true);
if(frame.topLevel) {
context.setVariable("InputClasses", t_10);
}
if(frame.topLevel) {
context.addExport("InputClasses", t_10);
}
t_5 += "\n    ";
var t_11;
t_11 = runtime.contextOrFrameLookup(context, frame, "InputClasses") + ((l_coltype == "Money" || l_coltype == "Decimal" || l_coltype == "Int"?" number " + l_coltype:""));
frame.set("InputClasses", t_11, true);
if(frame.topLevel) {
context.setVariable("InputClasses", t_11);
}
if(frame.topLevel) {
context.addExport("InputClasses", t_11);
}
t_5 += "\n\n    <!-- Set properties for the Input Element like disabled when the col is read-only. -->\n    ";
var t_12;
t_12 = ((runtime.memberLookup((l_col),"IsReadOnly")?" disabled ":""));
frame.set("InputProperties", t_12, true);
if(frame.topLevel) {
context.setVariable("InputProperties", t_12);
}
if(frame.topLevel) {
context.addExport("InputProperties", t_12);
}
t_5 += "\n    ";
var t_13;
t_13 = runtime.contextOrFrameLookup(context, frame, "InputProperties") + ((l_coltype == "Date" || l_coltype == "DateTime"?" autocomplete=no ":""));
frame.set("InputProperties", t_13, true);
if(frame.topLevel) {
context.setVariable("InputProperties", t_13);
}
if(frame.topLevel) {
context.addExport("InputProperties", t_13);
}
t_5 += "\n    ";
var t_14;
t_14 = runtime.contextOrFrameLookup(context, frame, "InputProperties") + ((l_coltype == "Boolean" && l_value?" checked":""));
frame.set("InputProperties", t_14, true);
if(frame.topLevel) {
context.setVariable("InputProperties", t_14);
}
if(frame.topLevel) {
context.addExport("InputProperties", t_14);
}
t_5 += "\n    ";
var t_15;
t_15 = runtime.contextOrFrameLookup(context, frame, "InputProperties") + ((l_coltype == "Decimal" || l_coltype == "Int"?" step='any' ":""));
frame.set("InputProperties", t_15, true);
if(frame.topLevel) {
context.setVariable("InputProperties", t_15);
}
if(frame.topLevel) {
context.addExport("InputProperties", t_15);
}
t_5 += "\n    ";
var t_16;
t_16 = runtime.contextOrFrameLookup(context, frame, "InputProperties") + ((l_col && runtime.memberLookup((l_col),"Length")?" maxlength=" + runtime.memberLookup((l_col),"Length"):""));
frame.set("InputProperties", t_16, true);
if(frame.topLevel) {
context.setVariable("InputProperties", t_16);
}
if(frame.topLevel) {
context.addExport("InputProperties", t_16);
}
t_5 += "\n\n    <!-- Build the INPUT fields based on the variables defined above -->\n    <input\n      type=\"";
t_5 += runtime.suppressValue(l_type, env.opts.autoescape);
t_5 += "\"\n      data-field-index=\"";
t_5 += runtime.suppressValue(l_index, env.opts.autoescape);
t_5 += "\"\n      name=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_5 += "\"\n      placeholder=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((l_col),"Placeholder"), env.opts.autoescape);
t_5 += "\"\n      class=\"";
t_5 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "InputClasses"), env.opts.autoescape);
t_5 += "\"\n      ";
if(runtime.memberLookup((l_col),"MinNumber") != null) {
t_5 += "\n        min=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((l_col),"MinNumber"), env.opts.autoescape);
t_5 += "\"\n      ";
;
}
t_5 += "\n      ";
if(runtime.memberLookup((l_col),"MaxNumber") != null) {
t_5 += "\n        max=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((l_col),"MaxNumber"), env.opts.autoescape);
t_5 += "\"\n      ";
;
}
t_5 += "\n      value=\"";
t_5 += runtime.suppressValue((lineno = 34, colno = 35, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "inputValue")),"setValue"), "inputValue[\"setValue\"]", context, [l_col,l_value,l_coltype])), env.opts.autoescape);
t_5 += "\"\n      ";
if(runtime.memberLookup((l_col),"FieldDescription") != "null") {
t_5 += "\n        data-hint=\"";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((l_col),"FieldDescription")), env.opts.autoescape);
t_5 += "\"\n        data-step=\"";
t_5 += runtime.suppressValue(l_index, env.opts.autoescape);
t_5 += "\"\n      ";
;
}
t_5 += "\n      ";
t_5 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "InputProperties"), env.opts.autoescape);
t_5 += "\n/>\n  ";
;
}
else {
t_5 += "\n    <textarea\n      rows=\"4\"\n      data-field-index=\"";
t_5 += runtime.suppressValue(l_index, env.opts.autoescape);
t_5 += "\"\n      placheholder=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((l_col),"Placeholder"), env.opts.autoescape);
t_5 += "\"\n      name=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_5 += "\"\n      class=\"form-control\"\n    >\n      ";
t_5 += runtime.suppressValue(l_value, env.opts.autoescape);
t_5 += "\n    </textarea>";
;
}
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("constructFormInput");
context.setVariable("constructFormInput", macro_t_4);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/form/formInput.html", ctx);
          }
        
          return nunjucks.render("views/content/form/formInput.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/form/formInput.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        