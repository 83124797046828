import $ from "jquery";

/** Basic dropdown functionality, plus positioning logic */
export default class Dropdown {
  /**
   * Add event listeners
   * @param {document} doc - Page document
   * @returns {void}
   */
  static init(doc = document) {
    function fixMenu($el, right) {
      // TODO fix for right
      if ($el.parent().is(".menu-overflow")) {
        $el = $el.parent();
      }

      $el.css({right: 4});
      let inWindow = $el.closest(".canvas").length !== 0;
      let off = right
        ? inWindow
          ? $(".canvas").outerWidth() - innerWidth
          : 0
        : inWindow
        ? $(".canvas").outerWidth() - innerWidth
        : 0;
      let setDX = right ? 0 : -off - $el.offset().left;
      if (setDX > 0 && !right) {
        $el.css({right: 4 - setDX});
      }

      if (setDX > 0 && right) {
        $el.css({left: 4 - setDX});
      }
    }

    $(doc).on("click", ".toggle-menu", function toggleMenu(e) {
      if (e.which !== 1) {
        return;
      }

      let $el = $(this).parent().find(".menu");
      let toggle = $el.hasClass("hide");
      $(".menu").addClass("hide");
      $el.toggleClass("hide", !toggle);

      if (toggle) {
        e.stopImmediatePropagation();
        fixMenu($el, $el.hasClass("right"));
      }
    });

    $(window).resize(() => {
      $(".menu").each(function () {
        if (!$(this).hasClass("hide") && !$(this).hasClass("calendar")) {
          fixMenu($(this), $(this).hasClass("right"));
        }
      });
    });
  }
}
