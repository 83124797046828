<template>
  <div id="canvas" :class="classes" :style="styleProperties">
    <div>
      <div class="float-right">
        <canvas-currency-toggle
          v-if="windowID"
          :bulkedit="bulkedit"
          :windowID="windowID"
          :window="window"
          :subWindow="subWindow"
        />

        <button
          v-if="
            !window.options || (window.options.noReset == false || window.options.NoReset == false && !bulkedit)
          "
          data-window-event="reset"
          :data-window-target="window.id"
          class="btn-link text-dark btn-icon mt-1 mr-2"
          :class="{'d-none':noReset}"

        >
          <div class="float-left">
            <i class="fas fa-sync-alt"></i>
          </div>
        </button>

        <button
          data-window-event="dispose"
          data-window-target="active"
          class="btn-link text-dark btn-icon mt-1 mr-2"
        >
          <div class="float-left">
            <i class="fas fa-times"></i>
          </div>
        </button>

        <!-- END Currency check -->
      </div>
      <h4>
        <span v-html="title" /> <small>{{ subTitle }}</small>
      </h4>

      <hr />
    </div>

    <div data-window-anchor></div>
  </div>
</template>

<script>
import CanvasCurrencyToggle from "./canvas/CanvasCurrencyToggle.vue";
export default {
  components: {
    "canvas-currency-toggle": CanvasCurrencyToggle,
  },
  data: () => {
    return {
      windowHTML: "",
      windowID: "",
    };
  },
  props: {
    window: {
      type: Object,
      required: false,
    },
  },
  computed: {
    loading: function () {
      return this.$store.state.loading;
    },
    noReset() {
      return this.window.output?.Options?.NoReset ?? false;
    
    },
    styleProperties: function () {
      if (this.loading) {
        return {
          opacity: 0.4,
          "pointer-events": "none",
        };
      }
      return {};
    },
    classes: function () {
      if (this.$store.state.settings.SimpleInterface) {
        return "simple-interface";
      }
      return "advanced-interface";
    },
    bulkedit: function () {
      if (this.window.bulkedit) return true;
      if (this.subWindow && this.subWindow.bulkedit) return true;
      return false;
    },
    subWindow: function () {
      return this.$store.getters.subWindow;
    },
    title() {
      if (!this.window.output || this.loading) {
        return global.session.translations.LoadingWindow;
      }
      const titleSuffix =
        this.window.counter && this.window.counter !== 0
          ? ` (${this.window.counter})`
          : "";

      if (this.window.counter !== 0)
        return (
          (this.window.customTitle ||
            this.window.output.Title ||
            global.session.translations.LoadingWindow) + titleSuffix
        );
      return "";
    },

    subTitle() {
      if (window.output) {
        return window.output.SubTitle;
      }
      return "";
    },
  },
  created() {
    //this.renderWindow()
  },
  watch: {
    subWindow() {
      if (this.subWindow) {
        this.windowID = this.subWindow.id;
        return;
      }
      this.windowID = this.window.id;
    },
    window() {
      if (this.subWindow) {
        this.windowID = this.subWindow.id;
        return;
      }
      this.windowID = this.window.id;
    },
  },
};
</script>
