
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        const __nunjucks_template_import__dep_0 = require("../elements/combobox.html");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/checklist.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("views/elements/combobox.html", false, "views/content/checklist.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("combo", t_1);
var macro_t_4 = runtime.makeMacro(
["col", "val", "coltype"], 
[], 
function (l_col, l_val, l_coltype, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("col", l_col);
frame.set("val", l_val);
frame.set("coltype", l_coltype);
var t_5 = "";if(l_coltype == "Boolean") {
t_5 += "on";
;
}
else {
if(runtime.memberLookup((l_col),"DefaultValue")) {
t_5 += runtime.suppressValue(runtime.memberLookup((l_col),"DefaultValue"), env.opts.autoescape);
;
}
else {
t_5 += runtime.suppressValue(l_val, env.opts.autoescape);
;
}
;
}
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("setValue");
context.setVariable("setValue", macro_t_4);
output += "<!--\n    @macro constructFormInput\n\n    Used for generating <input> fields based on provided criteria\n    @param {string} type    Types send and defined by the server IE DateTime for a calendar or Boolean for a checkbox\n    @param {object} col     Has properties that play a role on more properties on your input field like the property \"disabled\"\n    @param {string} coltype Just like the Type property coltype is send and defined by the server IE DateTime for a calendar or Boolean for a checkbox\n    @param {int}    index   A number to define what order in the dom the field is in (unique identifier)\n    @param {string} value   The value of the input if one exists\n-->\n";
var macro_t_6 = runtime.makeMacro(
["type", "col", "coltype", "index", "value"], 
[], 
function (l_type, l_col, l_coltype, l_index, l_value, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("type", l_type);
frame.set("col", l_col);
frame.set("coltype", l_coltype);
frame.set("index", l_index);
frame.set("value", l_value);
var t_7 = "";t_7 += "<!-- If we're dealing with a coltype tha tis not type TEXT in the database -->\n    ";
if(l_coltype != "text") {
t_7 += "\n\n        ";
var t_8;
t_8 = "";
frame.set("InputClasses", t_8, true);
if(frame.topLevel) {
context.setVariable("InputClasses", t_8);
}
if(frame.topLevel) {
context.addExport("InputClasses", t_8);
}
t_7 += "\n        ";
var t_9;
t_9 = "";
frame.set("InputProperties", t_9, true);
if(frame.topLevel) {
context.setVariable("InputProperties", t_9);
}
if(frame.topLevel) {
context.addExport("InputProperties", t_9);
}
t_7 += "\n\n        <!-- Set the css classes for the <input> element-->\n        ";
var t_10;
t_10 = ((l_coltype == "Boolean"?"form-check-input":"form-control"));
frame.set("InputClasses", t_10, true);
if(frame.topLevel) {
context.setVariable("InputClasses", t_10);
}
if(frame.topLevel) {
context.addExport("InputClasses", t_10);
}
t_7 += "\n        ";
var t_11;
t_11 = runtime.contextOrFrameLookup(context, frame, "InputClasses") + ((l_coltype != "Boolean"?" editable-text":""));
frame.set("InputClasses", t_11, true);
if(frame.topLevel) {
context.setVariable("InputClasses", t_11);
}
if(frame.topLevel) {
context.addExport("InputClasses", t_11);
}
t_7 += "\n        ";
var t_12;
t_12 = runtime.contextOrFrameLookup(context, frame, "InputClasses") + ((l_coltype == "Date" || l_coltype == "DateTime"?" date":""));
frame.set("InputClasses", t_12, true);
if(frame.topLevel) {
context.setVariable("InputClasses", t_12);
}
if(frame.topLevel) {
context.addExport("InputClasses", t_12);
}
t_7 += "\n        ";
var t_13;
t_13 = runtime.contextOrFrameLookup(context, frame, "InputClasses") + ((l_coltype == "Money" || l_coltype == "Decimal" || l_coltype == "Int"?" number " + l_coltype:""));
frame.set("InputClasses", t_13, true);
if(frame.topLevel) {
context.setVariable("InputClasses", t_13);
}
if(frame.topLevel) {
context.addExport("InputClasses", t_13);
}
t_7 += "\n\n        <!-- Set properties for the Input Element like disabled when the col is read-only. -->\n        ";
var t_14;
t_14 = ((runtime.memberLookup((l_col),"IsReadOnly")?" disabled ":""));
frame.set("InputProperties", t_14, true);
if(frame.topLevel) {
context.setVariable("InputProperties", t_14);
}
if(frame.topLevel) {
context.addExport("InputProperties", t_14);
}
t_7 += "\n        ";
var t_15;
t_15 = runtime.contextOrFrameLookup(context, frame, "InputProperties") + ((l_coltype == "Date" || l_coltype == "DateTime"?" autocomplete=no ":""));
frame.set("InputProperties", t_15, true);
if(frame.topLevel) {
context.setVariable("InputProperties", t_15);
}
if(frame.topLevel) {
context.addExport("InputProperties", t_15);
}
t_7 += "\n        ";
var t_16;
t_16 = runtime.contextOrFrameLookup(context, frame, "InputProperties") + ((l_coltype == "Boolean" && l_value?" checked ":""));
frame.set("InputProperties", t_16, true);
if(frame.topLevel) {
context.setVariable("InputProperties", t_16);
}
if(frame.topLevel) {
context.addExport("InputProperties", t_16);
}
t_7 += "\n        ";
var t_17;
t_17 = runtime.contextOrFrameLookup(context, frame, "InputProperties") + ((l_coltype == "Decimal" || l_coltype == "Int"?" step='any' ":""));
frame.set("InputProperties", t_17, true);
if(frame.topLevel) {
context.setVariable("InputProperties", t_17);
}
if(frame.topLevel) {
context.addExport("InputProperties", t_17);
}
t_7 += "\n        ";
var t_18;
t_18 = runtime.contextOrFrameLookup(context, frame, "InputProperties") + ((l_col && runtime.memberLookup((l_col),"Length")?" maxlength=" + runtime.memberLookup((l_col),"Length"):""));
frame.set("InputProperties", t_18, true);
if(frame.topLevel) {
context.setVariable("InputProperties", t_18);
}
if(frame.topLevel) {
context.addExport("InputProperties", t_18);
}
t_7 += "\n\n        <!-- Build the INPUT fields based on the variables defined above -->\n        <input\n            type=\"";
t_7 += runtime.suppressValue(l_type, env.opts.autoescape);
t_7 += "\"\n            data-field-index=\"";
t_7 += runtime.suppressValue(l_index, env.opts.autoescape);
t_7 += "\"\n            name=\"";
t_7 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_7 += "\"\n            class=\"";
t_7 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "InputClasses"), env.opts.autoescape);
t_7 += "\"\n            value=\"";
t_7 += runtime.suppressValue((lineno = 48, colno = 30, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "setValue"), "setValue", context, [l_col,l_value,l_coltype])), env.opts.autoescape);
t_7 += "\"\n            ";
t_7 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "InputProperties"), env.opts.autoescape);
t_7 += "\n        >\n    ";
;
}
else {
t_7 += "\n        <textarea rows=\"4\" data-field-index=\"";
t_7 += runtime.suppressValue(l_index, env.opts.autoescape);
t_7 += "\" name=\"";
t_7 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_7 += "\" class=\"form-control\">";
t_7 += runtime.suppressValue(l_value, env.opts.autoescape);
t_7 += "</textarea>";
;
}
;
frame = callerFrame;
return new runtime.SafeString(t_7);
});
context.addExport("constructFormInput");
context.setVariable("constructFormInput", macro_t_6);
output += "\n\n";
var macro_t_19 = runtime.makeMacro(
["session", "col", "coltype", "value", "initial", "index", "cell"], 
[], 
function (l_session, l_col, l_coltype, l_value, l_initial, l_index, l_cell, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("session", l_session);
frame.set("col", l_col);
frame.set("coltype", l_coltype);
frame.set("value", l_value);
frame.set("initial", l_initial);
frame.set("index", l_index);
frame.set("cell", l_cell);
var t_20 = "";t_20 += "\n    <!-- START -->\n\t";
if(runtime.memberLookup((l_col),"IsAutoNumber") && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Request")),"Prefix") == "New") {
t_20 += "\n\t\t";
t_20 += runtime.suppressValue((lineno = 60, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "constructFormInput"), "constructFormInput", context, ["text",l_col,l_coltype,l_index,l_value])), env.opts.autoescape);
t_20 += "\n\t";
;
}
else {
if(l_cell && runtime.memberLookup((l_cell),"Combobox")) {
t_20 += "\n\t\t";
t_20 += runtime.suppressValue((lineno = 62, colno = 28, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "combo")),"constructCombobox"), "combo[\"constructCombobox\"]", context, [l_session,runtime.memberLookup((l_cell),"Combobox")])), env.opts.autoescape);
t_20 += "\n\t";
;
}
else {
if(runtime.memberLookup((l_col),"Combobox")) {
t_20 += "\n\t\t";
t_20 += runtime.suppressValue((lineno = 64, colno = 28, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "combo")),"constructCombobox"), "combo[\"constructCombobox\"]", context, [l_session,runtime.memberLookup((l_col),"Combobox")])), env.opts.autoescape);
t_20 += "\n\t";
;
}
else {
if(l_coltype == "String" && runtime.memberLookup((l_col),"Editor") == "ckeditor" && runtime.memberLookup((l_col),"IsReadOnly") != true) {
t_20 += "\n\t\t<div name=\"";
t_20 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_20 += "\" class=\"editable-text\" style=\"width:100%;\" contenteditable=\"true\" data-html-editor>";
t_20 += runtime.suppressValue(env.getFilter("safe").call(context, l_value), env.opts.autoescape);
t_20 += "</div>\n\t";
;
}
else {
if(l_coltype == "String") {
t_20 += "\n\t\t\t";
t_20 += runtime.suppressValue((lineno = 68, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "constructFormInput"), "constructFormInput", context, ["text",l_col,l_coltype,l_index,l_value])), env.opts.autoescape);
t_20 += "\n\t";
;
}
else {
if(l_coltype == "Text") {
t_20 += "\n\t";
t_20 += runtime.suppressValue(l_coltype, env.opts.autoescape);
t_20 += "\n\t\t\t";
t_20 += runtime.suppressValue((lineno = 71, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "constructFormInput"), "constructFormInput", context, ["text",l_col,l_coltype,l_index,l_value])), env.opts.autoescape);
t_20 += "\n\t";
;
}
else {
if(l_coltype == "Boolean") {
t_20 += "\n\t\t";
t_20 += runtime.suppressValue((lineno = 73, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "constructFormInput"), "constructFormInput", context, ["checkbox",l_col,l_coltype,l_index,l_value])), env.opts.autoescape);
t_20 += "\n\t";
;
}
else {
if((l_coltype == "DateTime" || l_coltype == "Date" || l_coltype == "Time")) {
t_20 += "\n\t\t<div class=\"date-picker\" data-type=\"";
t_20 += runtime.suppressValue(l_coltype, env.opts.autoescape);
t_20 += "\" data-replace=\"";
t_20 += runtime.suppressValue((lineno = 75, colno = 76, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "setValue"), "setValue", context, [l_col,l_value,l_coltype])), env.opts.autoescape);
t_20 += "\">\n\t\t\t";
t_20 += runtime.suppressValue((lineno = 76, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "constructFormInput"), "constructFormInput", context, ["text",l_col,l_coltype,l_index,l_value])), env.opts.autoescape);
t_20 += "\n\t\t</div>\n\t";
;
}
else {
if(l_coltype == "Password") {
t_20 += "\n\t\t";
t_20 += runtime.suppressValue((lineno = 79, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "constructFormInput"), "constructFormInput", context, ["password",l_col,l_coltype,l_index,l_value])), env.opts.autoescape);
t_20 += "\n\t";
;
}
else {
if(l_coltype == "Image" && runtime.memberLookup((l_col),"IsReadOnly")) {
t_20 += "\n\t\t<img src=\"";
t_20 += runtime.suppressValue(l_value, env.opts.autoescape);
t_20 += "\">\n\t";
;
}
else {
if(l_coltype == "Money" || l_coltype == "Decimal" || l_coltype == "Int") {
t_20 += "\n\t\t";
t_20 += runtime.suppressValue((lineno = 83, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "constructFormInput"), "constructFormInput", context, ["number",l_col,l_coltype,l_index,l_value])), env.opts.autoescape);
t_20 += "\n\t";
;
}
else {
if(runtime.memberLookup((l_col),"IsVisible") == false || l_coltype == "hidden") {
t_20 += "\n\t\t";
t_20 += runtime.suppressValue((lineno = 85, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "constructFormInput"), "constructFormInput", context, ["hidden",l_col,l_coltype,l_index,l_value])), env.opts.autoescape);
t_20 += "\n\t";
;
}
else {
t_20 += "\n\t\t";
if(runtime.memberLookup((l_col),"VATPercentage") > 0) {
t_20 += "\n\t\t<span class=\"toggle-btw material-icons\" data-toggle-btw=\"";
t_20 += runtime.suppressValue(runtime.memberLookup((l_col),"VATPercentage"), env.opts.autoescape);
t_20 += "\" title=\"";
t_20 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_session),"translations")),"ToggleVATDescription"), env.opts.autoescape);
t_20 += "\">&#xe8d4;</span>\n\t\t";
;
}
t_20 += "\n\t\t";
t_20 += runtime.suppressValue((lineno = 90, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "constructFormInput"), "constructFormInput", context, ["text",l_col,l_coltype,l_index,l_value])), env.opts.autoescape);
t_20 += "\n\t";
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
t_20 += "\n<!-- END -->\n";
;
frame = callerFrame;
return new runtime.SafeString(t_20);
});
context.addExport("createInputField");
context.setVariable("createInputField", macro_t_19);
output += "\n\n";
var macro_t_21 = runtime.makeMacro(
["data", "rowIndex"], 
[], 
function (l_data, l_rowIndex, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", l_data);
frame.set("rowIndex", l_rowIndex);
var t_22 = "";t_22 += "\n\n<!-- START -->\n<div class=\"container checklist\">\n    <div class=\"card mt-2\">\n    <div class=\"card-header\">\n        <h5><i class=\"fas fa-ballot-check\"></i> ";
t_22 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Checklist"), env.opts.autoescape);
t_22 += ": ";
t_22 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_data),"Data")),"Rows")),0)),"Description"), env.opts.autoescape);
t_22 += "</h5>\n    </div>\n    <div class=\"card-body\">\n\t<form class=\"row\" data-row-index=";
t_22 += runtime.suppressValue(l_rowIndex, env.opts.autoescape);
t_22 += ">\n\t\t";
frame = frame.push();
var t_25 = runtime.memberLookup((runtime.memberLookup((l_data),"Table")),"Columns");
if(t_25) {t_25 = runtime.fromIterator(t_25);
var t_24 = t_25.length;
for(var t_23=0; t_23 < t_25.length; t_23++) {
var t_26 = t_25[t_23];
frame.set("col", t_26);
frame.set("loop.index", t_23 + 1);
frame.set("loop.index0", t_23);
frame.set("loop.revindex", t_24 - t_23);
frame.set("loop.revindex0", t_24 - t_23 - 1);
frame.set("loop.first", t_23 === 0);
frame.set("loop.last", t_23 === t_24 - 1);
frame.set("loop.length", t_24);
t_22 += "\n            <!--Start loop-->\n            ";
if(runtime.memberLookup((t_26),"IsVisible")) {
t_22 += "\n                ";
var t_27;
t_27 = null;
frame.set("coltype", t_27, true);
if(frame.topLevel) {
context.setVariable("coltype", t_27);
}
if(frame.topLevel) {
context.addExport("coltype", t_27);
}
t_22 += "\n                ";
var t_28;
t_28 = null;
frame.set("value", t_28, true);
if(frame.topLevel) {
context.setVariable("value", t_28);
}
if(frame.topLevel) {
context.addExport("value", t_28);
}
t_22 += "\n                ";
var t_29;
t_29 = null;
frame.set("cell", t_29, true);
if(frame.topLevel) {
context.setVariable("cell", t_29);
}
if(frame.topLevel) {
context.addExport("cell", t_29);
}
t_22 += "\n                ";
var t_30;
t_30 = runtime.memberLookup((t_26),"Initial");
frame.set("initial", t_30, true);
if(frame.topLevel) {
context.setVariable("initial", t_30);
}
if(frame.topLevel) {
context.addExport("initial", t_30);
}
t_22 += "\n                ";
var t_31;
t_31 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0");
frame.set("index", t_31, true);
if(frame.topLevel) {
context.setVariable("index", t_31);
}
if(frame.topLevel) {
context.addExport("index", t_31);
}
t_22 += "\n\n                ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_data),"Table")),"Rows")),l_rowIndex) && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_data),"Table")),"Rows")),l_rowIndex)),runtime.contextOrFrameLookup(context, frame, "index"))) {
t_22 += "\n\n                    ";
var t_32;
t_32 = runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_data),"Table")),"Rows")),l_rowIndex)),runtime.contextOrFrameLookup(context, frame, "index"));
frame.set("cell", t_32, true);
if(frame.topLevel) {
context.setVariable("cell", t_32);
}
if(frame.topLevel) {
context.addExport("cell", t_32);
}
t_22 += "\n                    ";
t_26 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "cell")),"Column");
frame.set("col", t_26, true);
if(frame.topLevel) {
context.setVariable("col", t_26);
}
if(frame.topLevel) {
context.addExport("col", t_26);
}
t_22 += "\n                    ";
var t_33;
t_33 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "cell")),"Value");
frame.set("value", t_33, true);
if(frame.topLevel) {
context.setVariable("value", t_33);
}
if(frame.topLevel) {
context.addExport("value", t_33);
}
t_22 += "\n                    ";
var t_34;
t_34 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "cell")),"Initial");
frame.set("initial", t_34, true);
if(frame.topLevel) {
context.setVariable("initial", t_34);
}
if(frame.topLevel) {
context.addExport("initial", t_34);
}
t_22 += "\n                    ";
var t_35;
t_35 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "cell")),"Type") || runtime.memberLookup((t_26),"Type");
frame.set("coltype", t_35, true);
if(frame.topLevel) {
context.setVariable("coltype", t_35);
}
if(frame.topLevel) {
context.addExport("coltype", t_35);
}
t_22 += "\n\n                    ";
if(runtime.contextOrFrameLookup(context, frame, "index") > 0) {
t_22 += "\n                        ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_data),"Table")),"Rows")),l_rowIndex)),runtime.contextOrFrameLookup(context, frame, "index") - 1)),"Column")),"FormGroup") != runtime.memberLookup((t_26),"FormGroup")) {
t_22 += "\n                            <br style=\"clear: both\"><br>\n                            </form><form class=\"form-view grid row ";
t_22 += runtime.suppressValue((runtime.memberLookup((t_26),"FormGroup") != null?"form-group":""), env.opts.autoescape);
t_22 += "\" data-row-index=";
t_22 += runtime.suppressValue(l_rowIndex, env.opts.autoescape);
t_22 += ">\n                        ";
;
}
t_22 += "\n                    ";
;
}
t_22 += "\n\n\t\t        ";
;
}
else {
t_22 += "\n\n                ";
var t_36;
t_36 = runtime.memberLookup((t_26),"Type");
frame.set("coltype", t_36, true);
if(frame.topLevel) {
context.setVariable("coltype", t_36);
}
if(frame.topLevel) {
context.addExport("coltype", t_36);
}
t_22 += "\n\n\t\t    ";
;
}
t_22 += "\n\n           ";
var t_37;
t_37 = "form-group";
frame.set("formGroupClasses", t_37, true);
if(frame.topLevel) {
context.setVariable("formGroupClasses", t_37);
}
if(frame.topLevel) {
context.addExport("formGroupClasses", t_37);
}
t_22 += "\n\n            <div class=\"col-12\">\n                <div class=\"";
t_22 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "formGroupClasses"), env.opts.autoescape);
t_22 += "\">\n                <!-- If we are dealing with a checkbox the label goes after the checkbox rather than before -->\n                ";
if(runtime.contextOrFrameLookup(context, frame, "coltype") != "Boolean") {
t_22 += "\n                    <label>";
if(runtime.memberLookup((t_26),"IsRequired")) {
t_22 += "\n                            <span class=\"text-danger\" title=\"";
t_22 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"RequiredField"), env.opts.autoescape);
t_22 += "\">*</span>\n                        ";
;
}
t_22 += runtime.suppressValue(runtime.memberLookup((t_26),"Title"), env.opts.autoescape);
t_22 += "\n                        <small id=\"emailHelp\" class=\"form-text text-muted\"></small>\n                    </label>\n                    ";
t_22 += runtime.suppressValue((lineno = 146, colno = 39, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "createInputField"), "createInputField", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),t_26,runtime.contextOrFrameLookup(context, frame, "coltype"),runtime.contextOrFrameLookup(context, frame, "value"),runtime.contextOrFrameLookup(context, frame, "initial"),runtime.contextOrFrameLookup(context, frame, "index"),runtime.contextOrFrameLookup(context, frame, "cell")])), env.opts.autoescape);
t_22 += "\n                ";
;
}
else {
t_22 += "\n                    <div class=\"float-right mt-2\">\n                        <label class=\"switch\">\n                            ";
t_22 += runtime.suppressValue((lineno = 150, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "createInputField"), "createInputField", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),t_26,runtime.contextOrFrameLookup(context, frame, "coltype"),runtime.contextOrFrameLookup(context, frame, "value"),runtime.contextOrFrameLookup(context, frame, "initial"),runtime.contextOrFrameLookup(context, frame, "index"),runtime.contextOrFrameLookup(context, frame, "cell")])), env.opts.autoescape);
t_22 += "\n                            <span class=\"slider round\"></span>\n                        </label>\n                    </div>\n                    <label>";
t_22 += runtime.suppressValue(runtime.memberLookup((t_26),"Title"), env.opts.autoescape);
t_22 += "</label>\n                    <!--\n                        Very fancy aditional text disabled for now  (14-04-2020)\n                        <small id=\"emailHelp\" class=\"form-text text-muted\">When enabled the user is free to login and do what they want. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac tempor nunc, vel efficitur lacus. Nam vitae ante justo.</small>\n                    -->\n                ";
;
}
t_22 += "\n                </div>\n                <hr>\n            </div>\n\n    \t\t";
;
}
t_22 += "\n\n\t\t";
;
}
}
frame = frame.pop();
t_22 += "\n\t\t<br style=\"clear: both\"><br>\n\t</form>\n    </div>\n    </div>\n</div>\n\n<!-- END -->\n";
;
frame = callerFrame;
return new runtime.SafeString(t_22);
});
context.addExport("createInputForm");
context.setVariable("createInputForm", macro_t_21);
output += "\n\n\n<div class=\"content\">\n\t";
output += runtime.suppressValue((lineno = 178, colno = 19, runtime.callWrap(macro_t_21, "createInputForm", context, [runtime.contextOrFrameLookup(context, frame, "data"),0])), env.opts.autoescape);
output += "\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/checklist.html", ctx);
          }
        
          return nunjucks.render("views/content/checklist.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/checklist.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        