import {api} from "./api";
import store from "../../state/store";
import axios from "axios";

export async function getStockItemMovementItemCounts({
  itemID,
  startDate,
  warehouseID,
}) {
  const result = await api.get(`/v2/screen/stock/details`, {
    params: {itemID, date: startDate, warehouse: warehouseID},
    headers: {
      Authorization: `Bearer ${store.state.accessToken}`,
    },
  });

  return result.data;
}

export async function getScanBoxItems({searchValue, params}) {
  const result = await axios.post(
    "/Admin/WebServices/CoreWebServices.asmx/MainRadComboBox_GetItems",
    {
      context: {
        Text: searchValue,
        NumberOfItems: 0,
        DiValues: {},
        DiPrimaryKeys: {
          RentalType: "",
          CategoryID: params?.categoryID ?? null,
          CustomerID: params?.customerID ?? null,
          scannedSerial: "",
          ContactID: params?.contactID ?? null,
          DateTimeExpectedStart: params?.dateTimeExpectedStart ?? null,
          DateTimeExpectedEnd: params?.dateTimeExpectedEnd ?? null,
          Reference: params?.reference ?? null,
          PrimaryKey: null,
          DefaultPeriodID: null,
        },
        TableName: "Rental.OrderItem",
        ColumnName: "ItemID",
        RowCount: 20,
        Subject: "Rental.virtual_QuickRent",
        Prefix: "Multi",
      },
    },
    {
      params: {
        warehouse: store.state.activeWarehouse,
      },
      headers: {
        Authorization: `Bearer ${store.state.accessToken}`,
      },
    },
  );
  return result.data;
}
