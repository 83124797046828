const axios = require("axios");
import store from "../../state/store";

const api = axios.create({
  baseURL: "/api",
  validateStatus: function (status) {
    return status; // Resolve only if the status code is less than 500
  },
});

api.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${store.state.accessToken}`;
    config.headers["Content-Language"] = store.state.language;

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  function (response) {
    if (response.data.Actions) {
      for (const action of response.data.Actions) {
        import(`../actions/${action.Function}.ts`).then((module) => {
          module.default(action.Data);
        });
      }
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

export {api};
