export function showLoadingAnimationIFrameElement(windowElement) {
  const iframeElement = windowElement.querySelector("iframe");
  const loadingElement = windowElement.querySelector(".iframe-loading");

  if (iframeElement === null) return;
  iframeElement.style.opacity = 0;

  iframeElement.addEventListener("load", function () {
    // Hide the loading indicator
    loadingElement.classList.remove("d-flex");
    loadingElement.classList.add("d-none");

    // Bring the iframe back
    iframeElement.style.opacity = 1;
  });
}
