import $ from "jquery";
import arrayToTree from "array-to-tree";
import axios from "axios";
import Cookies from "js-cookie";
import Combobox from "../interface/combobox.class";
import dayjs from "dayjs";
import Tutorial from "../interface/tutorial";
import EventEmitter from "events";
import Setup from "../interface/setup";
import Window from "./window";
import store from "../../state/store";

import {drawVueManualModal} from "../interface/vueManualModal";
import {notify} from "@/util/notify";

/**
 * App session class
 * @property {Canvas} canvas - Session Canvas
 * @property {string} url - Session URL
 * @property {*} token - Token
 * @property {string} product - Product name
 * @property {string} username - Username
 * @property {boolean} active - Session is active
 * @property {boolean} loading - Session is loading
 * @property {boolean} locked - Session is locked
 * @property {boolean} kiosk - Session is in kiosk mode
 * @property {boolean} fullscreen - Session is fullscreen
 * @property {boolean} canViewSettingsMenu - Session can view settings menu setting
 * @property {boolean} canRegister - Session can register
 * @property {*} box - Box
 * @property {*} err - Session error
 * @property {*} comboboxes.selectedValues - Selected properties for comboboxes
 * @property {Object} comboboxes.Combobox - Warehouse or Affiliate-combobox
 * @property {Object} translations - Server translation strings
 * @property {string} language - Selected language
 * @property {Array} languages - Languages available
 * @property {*} moduleMenu - Module menu
 * @property {Object} profile - Profile
 * @property {Object} form - Form
 * @property {Object} settings - Settings
 * @property {Object} shortcuts - Shortcuts
 * @property {Object} scanbox - Combobox-object for scanbox
 * @property {Array} tabs - Tabbed Windows
 * @property {Object} windows - All Windows
 * @property {Window} activeWindow - Active window
 * @property {string} userID - User ID
 * @property {*} setup - Setup
 * @property {*} screen - Screen
 * @property {*} licenseExpirationMessage - License expiration message
 */
export default class Session extends EventEmitter {
  canvas = null;
  url = null;
  token = null;
  product = "RentMagic";
  productDomain = "rentmagic";
  username = null;
  active = false;
  loading = false;
  locked = false;
  kiosk = false;
  fullscreen = true;
  canViewSettingsMenu = true;
  canRegister = false;
  box = null;
  err = null;
  googleSSO = false;
  twoFactorAuthentication = false;
  ssoData = {};
  comboboxes = {};
  translations = {};
  language = null;
  languages = [];
  moduleMenu = null;
  profile = {};
  form = {};
  settings = {};
  shortcuts = {};
  scanbox = null;
  tabs = [];
  tutorial = new Tutorial(this);
  windows = {};
  activeWindow = null;
  setup = null;
  screen = null;
  licenseExpirationMessage = null;
  twoFactorRequired = false;
  twoStepQR = false;

  /**
   * Session constructor
   * @todo comment
   * @param {string} url - Session URL
   */
  constructor(url) {
    super();
    this.url = url;

    if (global.translations != null) {
      this.languages = Object.keys(global.translations).map((x) => ({
        ID: x,
        Name: global.translations[x].NativeLanguageName,
      }));
      this.switchLanguage(null, true);
    }

    if (global.location.hash.slice(0, "#install".length) == "#install") {
      // if (global.location.hash.slice(0, 3) == "#k=") {
      this.setup = new Setup(this);
      this.setup.start();
      return;
    }

    if (global.location.hash.slice(0, 22) == "#userconfirmationCode=") {
      let code = location.hash.slice(22);
      this.executeAppWebservice({code: code}, "UserConfirmation");
    } else if (global.location.hash.slice(0, 15) == "#userresetCode=") {
      let code = location.hash.slice(15, 15 + 32); // Code is md5 Hash of 32 characters
      this.userID = location.hash.slice(15 + 32 + "&userID=".length); // UserID is after code

      this.executeAppWebservice(
        {code: code, userID: this.userID},
        "ValidateResetCode",
      );
    } else {
      this.auth();
    }
  }

  /**
   * Opens a screen based on a menuID
   *
   * @param {string} menuID
   * @memberof Session
   */
  async openWindowByMenuID(menuID) {
    let window = new Window(this);
    window.loading = true;

    store.dispatch("addTab", window);
    try {
      await window.render();
      window.focus();

      let output = await this.request(
        "/Admin/WebServices/CoreWebServices.asmx/OpenMenuNode",
        {
          ID: menuID,
        },
      );

      await window.process(output);
      window.loading = false;
      await window.render();

      drawVueManualModal({
        windowID: window.id,
        helpUrl: window.output.Options.HelpUrl,
      });
    } catch (err) {
      console.error(err);
      window.dispose();
    }
    store.commit("updateWindow");
    store.commit("refreshTabs");
  }

  /** Show default startup window */
  async showDefaultWindow(searchTerm) {
    if (searchTerm != "") {
      let window = new Window(this);
      window.loading = true;
      store.dispatch("addTab", window);

      try {
        await window.render();
        store.commit("updateWindow");
        window.focus();

        let output = await this.request(
          "/Admin/WebServices/CoreWebServices.asmx/OpenNodeByValue",
          {
            value: null,
            description: searchTerm,
          },
        );

        await window.process(output);
        window.loading = false;
        await window.render();
        store.commit("updateWindow");
        drawVueManualModal({
          windowID: window.id,
          helpUrl: window.output.Options.HelpUrl || null,
        });
        store.commit("updateWindow");
      } catch (err) {
        console.error(err);
        window.dispose();
      }
    } else {
      try {
        let window = await this.openWindow(
          null,
          "startup",
          this.translations.Welcome,
        );
        window.options.noReset = true;

        await window.render();
        store.commit("updateWindow");
        if (location.hostname === "localhost") {
          store.dispatch("reopenLastWindow");
        }

        drawVueManualModal({
          windowID: window.id,
          helpUrl:
            window.output.Options != null
              ? window.output.Options.HelpUrl
              : null,
        });
      } catch (err) {
        console.error(err);
      }
      store.commit("updateWindow");
    }
  }

  /**
   * Switch to other language
   * @param {string} langID - Language id
   * @param {boolean} doNotEmitUpdate - Is update neccessairy?
   * @todo comment
   * @returns {void}
   */
  switchLanguage(langID, doNotEmitUpdate = false) {
    // set language is defined by:
    // 1. inputted languageID
    // 2. cookie -> which is the user preference cookie
    // 3. default language
    // 4. first language send

    // if (langID === "nl") {
    //   import("../util/ckeditor/translations/nl.js")
    // } else if (langID === "fr") {
    //   import("../util/ckeditor/translations/fr.js")
    // } else if (langID === "de") {
    //   import("../util/ckeditor/translations/de.js")
    // }

    if (global.translations[langID] != null) {
      console.log({langID});
    } else if (global.translations[Cookies.get("lang")] != null) {
      langID = Cookies.get("lang");
    } else if (global.translations[global.defaultLanguage] != null) {
      langID = global.defaultLanguage;
    } else {
      langID = Object.keys(global.translations)[0];
    }

    this.language = langID;
    store.state.language = langID;
    this.translations = global.translations[langID];

    store.commit("setTranslations", {
      translations: this.translations,
    });

    Cookies.set("lang", langID, {expires: 365});

    if (!doNotEmitUpdate) {
      global.session.emit("updated");
    }
    dayjs.locale(langID);
  }

  /**
   * Create window
   * @param {Object} input - Input object
   * @param {string} customTemplate - Custom template name, to create custom window
   * @param {string} customTitle - Custom title
   * @param {Object} customData - Custom data object
   * @returns {Window} Window
   */
  newWindow(input, customTemplate = null, customTitle = null, customData = {}) {
    let window = new Window(this);
    window.input = input;
    window.customTemplateName = customTemplate || null;
    window.customTitle = customTitle;
    window.customData = customData;
    return window;
  }

  /**
   * Create window, and add it to tabs
   * @param {Object} input - Input object
   * @param {string} customTemplate - Custom template name, to create custom window
   * @param {string} customTitle - Custom title
   * @param {Object} customData - Custom data object
   * @returns {Window} Window
   */
  createWindow(
    input,
    customTemplate = null,
    customTitle = null,
    customData = {},
  ) {
    let window = this.newWindow(input, customTemplate, customTitle, customData);

    store.dispatch("addTab", window);
    return window;
  }
  /**
   * Create window, load it and focus it
   * @param {Object} input - Input object
   * @param {string} customTemplate - Custom template name, to create custom window
   * @param {string} customTitle - Custom title
   * @param {Object} customData - Custom data object
   * @returns {Window} Promise
   */
  async openWindow(
    input,
    customTemplate = null,
    customTitle = null,
    customData = {},
  ) {
    if (input?.Criteria[0] === null) return;
    let window = this.createWindow(
      input,
      customTemplate,
      customTitle,
      customData,
    );
    // create, then focus and reload
    window.focus();
    await window.reload();
    store.commit("refreshTabs");
    store.commit("updateWindow");
    return window;
  }

  /**
   * Load combobox data
   * @param {string} table - Table name
   * @param {string} column - Column name
   * @param {Object} DiPrimaryKeys - Primary key dictictionary
   * @param {string} text - Input text
   * @param {Object} values - Values dictionary for primary keys
   * @param {string} webservice - Unused
   * @param {number} nOfItems - Number of items to load
   * @param {number | null} rowCount - Row offset/count
   * @returns {Promise} Promise
   */
  async combobox(
    table,
    column,
    DiPrimaryKeys,
    text,
    values,
    webservice,
    nOfItems,
    rowCount = null,
    subject = "",
    prefix = "",
  ) {
    let data = await this.request(
      "/Admin/WebServices/CoreWebServices.asmx/MainRadComboBox_GetItems",
      {
        context: {
          Text: text || "",
          NumberOfItems: nOfItems || 0,
          DiValues: values || {},
          DiPrimaryKeys: DiPrimaryKeys || {},
          TableName: table,
          ColumnName: column || null,
          RowCount: rowCount,
          Subject: subject,
          Prefix: prefix,
        },
      },
    );

    return data;
  }

  /**
   * Excecute app webservice on server
   * @param {Object} opts - Options
   * @param {string} serviceName - Name of webservice
   * @param {boolean} loginUser - User login request?
   * @returns {Promise} Promise
   */
  async executeAppWebservice(opts, serviceName, loginUser = false) {
    try {
      // try to excecute service

      // options are required
      if (!opts) {
        this.loading = false;
        this.emit("updated");
        return null;
      }

      // reset error
      this.err = null;

      // we are loading
      this.loading = true;
      this.emit("updated");

      // set variables
      opts.product = this.product;

      // perform request
      let result = await this.request(
        "/Public/WebServices/AppWebServices.asmx/" +
          serviceName +
          "?LangID=" +
          this.language,
        opts,
      );

      this.googleSSO = result.GoogleSSO;
      this.twoFactorAuthentication = result.TwoStepRequired;

      if (loginUser) {
        store.commit("setToken", {
          token: result.Token,
        });

        store.dispatch("user/fetchUserData");

        store.commit("user/setUserSettings", result.UserSettings);

        dayjs.locale(this.language);

        const warehouses = [];
        for (const warehouse of result.Comboboxes[0].values) {
          warehouses.indexOf(warehouse) === -1 &&
            warehouses.push(warehouse.Value);
        }

        store.commit("setWarehouses", {
          warehouses,
        });
        store.commit("setActiveWarehouse", {
          warehouse: result.Comboboxes[0].selectedValue,
        });

        // log user in special case
        if (serviceName == "GetWelcomeInfo" && result.UserName == null) {
          this.canRegister = result.CanRegister;
          this.googleSSO = result.GoogleSSO;
          this.twoFactorAuthentication = result.TwoStepRequired;

          throw new Error("Not logged in yet");
        }

        // Show message
        if (result.Message != null) {
          // alert(result.Message)
          this.licenseExpirationMessage = result.Message;
        }

        // Load values
        this.switchLanguage(result.LanguageID, true);
        this.username = result.UserName;
        this.shortcuts = result.Shortcuts;
        this.scanbox = Combobox.new(null, {
          name: "Scanbox",
          tableName: "Core.virtual_Scanbox",
          columnName: "Value",
          openRef: false,
          lazyLoading: true,
        });
        this.profile = result.Profile;
        this.settings = result.SettingsValues;
        this.username = result.UserName;
        this.canViewSettingsMenu = result.CanViewSettingsMenu;

        store.commit("setShortcuts", {shortcuts: result.Shortcuts});
        store.commit("setSettings", {settings: result.SettingsValues});
        store.commit("setScanbox", this.scanbox);

        for (let combobox of result.Comboboxes) {
          if (combobox.id != null) {
            let selectedText = combobox.selectedValue;

            // set selected value
            if (combobox.selectedValue != null) {
              if (this.comboboxes.selectedValues == null) {
                this.comboboxes.selectedValues = {};
              }

              if (this.comboboxes.selectedValues[combobox.id] == null) {
                this.comboboxes.selectedValues[combobox.id] =
                  combobox.selectedValue;
              } else {
                combobox.selectedValue =
                  this.comboboxes.selectedValues[combobox.id];
              }
            }

            // create combobox containing available values
            if (combobox.values != null) {
              if (this.comboboxes.Combobox == null) {
                this.comboboxes.Combobox = {};
              }

              this.comboboxes.Combobox[combobox.id] = Combobox.new(null, null);
              this.comboboxes.Combobox[combobox.id].populate(combobox.values);

              // Gets the current selected text out of the selected combobox
              let currentCombobox = combobox;
              combobox.values.every(function (element) {
                if (element.Value == currentCombobox.selectedValue) {
                  selectedText = element.Text;
                  return false; // breaks the loop
                } else {
                  return true; // continue
                }
              });

              this.comboboxes.Combobox[combobox.id].setInitialValues(
                selectedText,
                combobox.selectedValue,
              );
            }
          }
        }

        store.commit("setHeaderComboboxes", this.comboboxes);

        // create menu
        if (result.Menu != null) {
          let tree = arrayToTree(result.Menu, {
            parentProperty: "ParentID",
            customID: "ID",
          });

          // check for errors
          for (let branch1 of tree) {
            const children = branch1.children ?? [];
            for (let branch2 of children) {
              if (!branch2.children || !branch2.children.length) {
                let i = children.indexOf(branch2);
                children.splice(i, 1);
              }
            }
          }

          // set menu
          this.moduleMenu = tree;
        }

        // set more values
        this.fullscreen = this.profile.UseFullScreen.Value === true;

        this.locked = false;
        this.active = true;

        // Get user data from Rest API
        this.getUserData().then((data) => {
          this.currentUser = data;
        });
      }
    } catch (err) {
      // throw error

      this.twoStepSetupRequired = err.data?.TwoStepSetupRequired;
      this.twoStepQR = err.data?.TwoStepApp;

      if (err.data && typeof err.data.Screen != "undefined") {
        this.openScreen(err.data.Screen);
      }

      if (serviceName != "GetWelcomeInfo") {
        console.error(err);
        this.err = err;
      }
    }

    this.loading = false;
    this.emit("updated");
  }

  /**
   * send authentication request
   * @param {Object} opts - Options
   * @returns {Promise} Promise
   */
  async auth(opts = null) {
    let wasInactive = this.active == false;
    let searchTerm = "";

    if (global.location.hash.slice(0, "#open=".length) == "#open=") {
      searchTerm = location.hash.slice("#open=".length, location.hash.length);
    }
    if (global.location.search.slice(0, "?OPEN-".length) == "?OPEN-") {
      window.location.replace(
        global.location.toString().replace("?OPEN-", "#open="),
      );
    }

    if (opts) {
      opts.product = "RentMagic";
      await this.executeAppWebservice(opts, "LoginKick", true);
    } else {
      await this.executeAppWebservice({}, "GetWelcomeInfo", true);
    }

    if (wasInactive && this.active) {
      this.openSelectedMenuID({
        DefaultMenuID: this.profile.DefaultMenuID,
        searchTerm,
      });
    }
  }

  async openSelectedMenuID({DefaultMenuID, searchTerm}) {
    // Show default window when the user logs or refreshes/creates a new browser tab
    if (DefaultMenuID && DefaultMenuID.Value) {
      if (DefaultMenuID.Value === "none") {
        return;
      }

      await this.openWindowByMenuID(DefaultMenuID.Value);
      return;
    }

    await this.showDefaultWindow(searchTerm);
  }

  /**
   * Log user out
   * @param {boolean} alreadyLoggedOut - If already logged out
   * @param {boolean} lock - If should lock
   * @param {*} err - Error
   * @returns {Promise} Promise
   */
  async logout(alreadyLoggedOut, lock, err) {
    let result;

    store.commit("logout");

    if (!alreadyLoggedOut) {
      result = await this.request(
        "/Public/WebServices/AppWebServices.asmx/Logout",
        {},
      );
    }

    this.err = err;

    if (lock) {
      this.locked = true;
      this.emit("updated");
    } else {
      if (typeof result === "string" && result != "") {
        await swal(result);
      }

      location.reload();
    }

    return result;
  }

  /**
   * Send Axios request
   *
   * @param {string} uri - Request URI
   * @param {Object} postData - Request data
   * @param {Object} extraParams - Extra request parameters
   * @returns {Object} data response from the request ran by Axios
   */
  async request(uri, postData = {}, extraParams = {}, method = null) {
    try {
      let params = this.comboboxes.selectedValues;
      if (params == null) {
        params = {};
      }

      params.Warehouse = store.state.activeWarehouse;

      params.BoxID = this.box;

      for (let key in extraParams) {
        params[key] = extraParams[key];
      }

      let result = await axios({
        method: method ? method : postData ? "post" : "get",
        url: this.url + uri,
        data: postData,
        params: params,
        headers: {
          "Content-Language": this.language,
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      });

      let data =
        result.data != null && result.data.d !== undefined
          ? result.data.d
          : result.data;

      if (data && data.Error) {
        let err = new Error(
          typeof data.Error === "object" ? data.Error.Message : data.Error,
        );
        err.data = typeof data.Error === "object" ? data.Error : data;

        if (typeof data.Error === "string") {
          notify({message: data.Error, type: "danger"});
        }

        throw err;
      }

      if (data.GlobalActions && Array.isArray(data.GlobalActions)) {
        for (const action of data.GlobalActions) {
          import(`../actions/${action.Function}.ts`)?.then((module) => {
            try {
              module.default(action.Data);
            } catch (err) {
              console.log(`Action ${action.Function} not found: ${err}`);
            }
          });
        }
      }

      return data;
    } catch (err) {
      if (err.data && err.data.Data && err.data.Data.ShowLockScreen === true) {
        this.logout(true, true, err);
      }

      if (global.session.activeWindow?.loading && !err.data.Error) {
        notify({
          message: store.state.translations.SomethingWentWrongTryAgainLater,
          type: "danger",
        });
        global.session.activeWindow.dispose();
      }

      throw err;
    }
  }

  /**
   * Get closest window
   * @param {Element} el - HTML element
   * @returns {Window | null} closest window
   */
  getClosestWindow(el) {
    let id = $(el).closest("[data-window-id]").attr("data-window-id");
    return this.windows[id] || null;
  }

  /**
   * Update windows with related tables
   * @param {Window} window - Current window
   * @param {string} table - Related table
   * @returns {void}
   */
  updateRelatedWindows(window, table) {
    if (!window) {
      return;
    }

    let tablename =
      table ||
      (window && window.output && window.output.Request.Subject
        ? window.output.Request.Subject
        : null);
    let refTableName = null;

    this.tabs.forEach(async function (w) {
      let relatedWindow = null;

      if (w.id === window.id || (w.sub && w.sub.window.id === window.id)) {
        return;
      }
      while (w != null && relatedWindow == null) {
        if (
          w.id === window.id ||
          !w.output ||
          !w.output.Request.Subject ||
          w.waitingForReload
        ) {
          w = null;
        } else if (w.isRelated(tablename) || w.isRelated(refTableName)) {
          relatedWindow = w;
        } else if (w.sub && w.sub.window) {
          w = w.sub.window;
        } else {
          w = null;
        }
      }

      if (relatedWindow) {
        await store.dispatch("resetWindow", {
          windowid: relatedWindow.id,
          shouldFocus: session.activeWindow.id == relatedWindow.id,
          shouldConfirm: false,
        });
      }
      store.commit("updateWindow");
      store.commit("refreshTabs");
    });
  }

  /**
   * Send a message to all the other active windows in the other tabs in the same browsers
   * @param {string} name - Name of the action to execute on active windows on other tabs
   * @param {Array} args - Array of arguments for the action
   * @returns {Promise} Nothing
   */
  broadcastWindowEvent(eventName, ...args) {
    localStorage.setItem(
      "global-window-event",
      JSON.stringify({command: eventName, data: args}),
    );
    localStorage.removeItem("global-window-event");
  }

  /**
   * Set screen
   * @param {string} screenName - Screen name
   * @returns {void}
   */
  openScreen(screenName) {
    this.screen = screenName;
    this.err = null;
    this.emit("updated");
  }

  /**
   * 	Get user related information of the currently logged in user
   *  @returns {object} Userdata of the logged in user
   */
  async getUserData() {
    let requestUrl = "/api/v1/CurrentUserInfo";

    let result = await window.session.request(requestUrl, null);

    return result;
  }
}
