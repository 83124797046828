
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
            const __nunjucks_template_import__global_generateID = require("../../src/util/generateRandomID.js");
            __nunjucks_module_dependencies__.globals['generateID'] = {
                module: __nunjucks_template_import__global_generateID && __nunjucks_template_import__global_generateID.default || __nunjucks_template_import__global_generateID
            };
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/elements/vueDateTime.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["value", "name", "index", "timePicker", "filterOnOpeningsDay", "readOnly", "showTime", "postback"], 
[], 
function (l_value, l_name, l_index, l_timePicker, l_filterOnOpeningsDay, l_readOnly, l_showTime, l_postback, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("value", l_value);
frame.set("name", l_name);
frame.set("index", l_index);
frame.set("timePicker", l_timePicker);
frame.set("filterOnOpeningsDay", l_filterOnOpeningsDay);
frame.set("readOnly", l_readOnly);
frame.set("showTime", l_showTime);
frame.set("postback", l_postback);
var t_2 = "";var t_3;
t_3 = (lineno = 1, colno = 32, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "generateID"), "generateID", context, [7]));
frame.set("calenderID", t_3, true);
if(frame.topLevel) {
context.setVariable("calenderID", t_3);
}
if(frame.topLevel) {
context.addExport("calenderID", t_3);
}
t_2 += "\n\n  <div\n    data-editor-type=\"calendar\"\n    id=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "calenderID"), env.opts.autoescape);
t_2 += "\"\n    value=\"";
t_2 += runtime.suppressValue(l_value, env.opts.autoescape);
t_2 += "\"\n    name=\"";
t_2 += runtime.suppressValue(l_name, env.opts.autoescape);
t_2 += "\"\n    read-only=\"";
t_2 += runtime.suppressValue(l_readOnly, env.opts.autoescape);
t_2 += "\"\n    show-time=\"";
t_2 += runtime.suppressValue(l_showTime, env.opts.autoescape);
t_2 += "\"\n    time-picker=\"";
t_2 += runtime.suppressValue(l_timePicker, env.opts.autoescape);
t_2 += "\"\n    filter-on-openings-day=\"";
t_2 += runtime.suppressValue(l_filterOnOpeningsDay, env.opts.autoescape);
t_2 += "\"\n    postback=\"";
t_2 += runtime.suppressValue(l_postback, env.opts.autoescape);
t_2 += "\"\n    index=\"";
t_2 += runtime.suppressValue(l_index, env.opts.autoescape);
t_2 += "\"></div>";
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("generateCalenderElement");
context.setVariable("generateCalenderElement", macro_t_1);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/elements/vueDateTime.html", ctx);
          }
        
          return nunjucks.render("views/elements/vueDateTime.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/elements/vueDateTime.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        