import swal from "sweetalert";
/**
 * Checks wether the window (or sub window) is dirty
 * @param {Object} window
 * @returns {Boolean} True if window is dirty, False if not
 */
export function dirtyCheck(window) {
  let subWindow = null;
  if (!window) return false;

  //if (window.output?.Request.Prefix === "New") return false;

  // Check if we're dealing with a save action
  if (window.lastActionArgs && window.lastActionArgs[0] === "Save") {
    return false;
  }

  if (global.skipDirty === window.input?.Subject) {
    console.log(global.skipDirty);
    global.skipDirty = false;
    return false;
  }

  if (window.sub && window.sub.window) {
    subWindow = window.sub.window;
  }

  if (window.bulkedit || (subWindow && subWindow.bulkedit)) return true;

  // If sub window is dirty
  if (subWindow) {
    return subWindow.isDirty();
  }

  return window.isDirty();
}

/**
 * Checks if window or subwindow is dirty and will prompt user to continue
 * @param {Object} window
 * @returns {Boolean} True if we can continue False if we cannot
 */
export async function dirtyCheckWithPrompt(window) {
  if (!window) return;

  const alertObject = {
    text: global.session.translations.WindowWarningOnClose,
    icon: "warning",
    dangerMode: true, // setting dangerMode to true makes the Cancel button bigger
    buttons: [global.session.translations.Cancel, true],
  };

  // if the window is dirty and we should confirm and the user has cancelled the close dialog, cancel the dispose action.
  // cancelling the alert resolves the promise to NULL, whereas clicking OK resolves to true
  if (dirtyCheck(window)) {
    return (await swal(alertObject)) !== null;
  }

  return true;
}
