<template>
  <div>
    <component :is="template" :field="field" v-model="value" />
    <input
      :id="randomID"
      v-if="changed"
      vue-input
      v-model="jsonFormattedValue"
      :name="field.name"
      hidden
    />
  </div>
</template>

<script>
import generateRandomID from "@/util/generateRandomID";

export default {
  name: "FormField",
  props: {
    field: {},
  },
  data() {
    return {
      value: null,
      jsonValue: null,
      changed: false,
      initialDataSet: false,
    };
  },
  computed: {
    jsonFormattedValue() {
      return JSON.stringify(this.value);
    },
    randomID() {
      return generateRandomID(8);
    },
    template() {
      return () => import(`./FormField${this.field.editor}.vue`);
    },
  },

  created() {
    this.value = this.field.value;
  },
  watch: {
    value() {
      if (!this.initialDataSet) {
        this.initialDataSet = true;
        return;
      }
      this.changed = true;
      this.jsonValue = JSON.stringify(this.value);
      // eslint-disable-next-line no-undef
      $(document).find(`#${this.randomID}`).trigger("change");

      if (this.field.postback == true) {
        this.processChange();
      }

      //global.session.activeWindow.sub?.window.dirty = true;

      return;
    },
  },
  methods: {
    processChange() {
      const values = {};
      for (let rowcell of this.$store.state.window.output?.FullTable?.Rows[0] ??
        this.$store.state.window.output.Sub.Table.Rows[0]) {
        values[rowcell.Column.Name] = rowcell.NewValue;
      }

      values[this.field.name] = this.value;

      Promise.resolve(global.session.activeWindow.postbackValues(values));
    },
  },
};
</script>

<style></style>
