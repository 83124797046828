<template>
  <b-modal
    :id="randomID"
    :ref="modalBodyID"
    body-class="vue-generic-modal"
    hide-footer
    hide-header
    :size="modalSize"
    @shown="afterRenderModal()"
  >
    <div :id="modalBodyID" v-html="modalBody" />
  </b-modal>
</template>

<script>
import $ from "jquery";
import serializeForm from "../../src/util/serializeForm";

import {generateID} from "../../src/util/generateID";

import vueDateTimeTemplate from "../elements/vueDateTime.vue";
import store from "../../state/store";
import Vue from "../../src/interface/vue";

export default {
  props: {
    modalBodyID: {
      type: String,
    },
    modalTitle: {
      type: String,
    },
    modalSize: {
      type: String,
    },
    modalBody: {
      type: String,
    },
    event: {
      type: Object,
    },
    actionName: {
      type: String,
    },
    selectionInfo: {
      type: Object,
    },
    done: {
      type: Function,
    },
  },
  computed: {
    randomID: function () {
      return generateID(5);
    },
    closeActiveModal: function () {
      return this.$store.state.closeActiveModal;
    },
  },
  methods: {
    openModal: async function () {
      await this.$bvModal.show(this.randomID);
    },
    closeModal: async function () {
      await this.$refs[this.modalBodyID].hide();
      window.session.activeWindow.toggleLoading();
      this.$store.commit("closeActiveModal", false);
    },

    destroySelf() {
      // destroy the vue listeners, etc
      this.$destroy();

      // remove the element from the DOM
      this.$el.parentNode.removeChild(this.$el);
    },
    initiateCalendarFields: function () {
      const calendarFields = $(`#${this.randomID}`).find(
        "div [data-editor-type='calendar']",
      );
      for (const calendarField of calendarFields) {
        new Vue({
          el: `#${calendarField.id}`,
          store,

          render: (h) =>
            h(vueDateTimeTemplate, {
              props: {
                name: $(calendarField).attr("name"),
                index: $(calendarField).attr("index"),
                value: $(calendarField).attr("value"),
                filterOnOpeningsDay: $(calendarField).attr(
                  "filter-on-openings-day",
                ),
                readonly: $(calendarField).attr("readonly"),
                timePicker: $(calendarField).attr("timePicker"),
              },
            }),
        });
      }
    },
    afterRenderModal: function () {
      if (this.actionName === "MailReport") {
        const html = $("body")
          .find("#mailTemplateSelector")
          .find(".option.selected")
          .data("attr-body");
        $(document).find("#mailTemplate").html(html);
      }
      this.initiateCalendarFields();
    },
  },
  async mounted() {
    this.$root.$on("bv::modal::hide", () => {
      window.session.activeWindow.toggleLoading();
    });
    await this.openModal();
  },
  created() {
    const closeModal = () => {
      this.$bvModal.hide(this.randomID);
    };
    const modalID = this.randomID;

    $("body").on("click", ".modal [data-dismiss='modal']", function () {
      closeModal();
    });

    const {done} = this;

    $("body").on(
      "submit",
      `#${modalID} form#modalGenericSend`,
      async function sendMail() {
        let formData = serializeForm(this);

        closeModal();

        done(formData);
      },
    );
  },
  watch: {
    closeActiveModal: function () {
      if (this.closeActiveModal) {
        this.closeModal();
      }
    },
  },
};
</script>
