import { render, staticRenderFns } from "./ActionSetupTwoFactorQR.vue?vue&type=template&id=fd529d80&"
import script from "./ActionSetupTwoFactorQR.vue?vue&type=script&lang=js&"
export * from "./ActionSetupTwoFactorQR.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/Users/dhulsman/Projects/rentmagic.frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton} from 'bootstrap-vue'
    installComponents(component, {BButton})
    


    import installDirectives from "!/Users/dhulsman/Projects/rentmagic.frontend/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBModal} from 'bootstrap-vue'
    installDirectives(component, {'b-modal': VBModal})
    

export default component.exports