<template>
  <div class="tab">
    <b-button-group @click.middle.stop.prevent="dispose(tab.id)">
      <b-button
        block
        size="sm"
        @@click.middle.stop.prevent="dispose(tab.id)"
        :class="{
          'w-auto': true,
          'button-default': true,
          'fade-in': true,
          active: tab.id === activeTab,
        }"
        :data-window-target="tab.id"
        data-window-event="focus"
        variant="primary"
        v-b-tooltip="{hover: true, delay: {show: 1000, hide: -1}}"
        :title="tab.title"
        v-html="overrideTitle || tab.title"
      />

      <b-button
        variant="dark"
        size="sm"
        @click.middle.stop.prevent="dispose(tab.id)"
        :data-window-target="target"
        data-window-event="dispose"
        ><i class="fas fa-times"></i
      ></b-button>
    </b-button-group>
  </div>
</template>

<script>
export default {
  props: {
    tab: {
      required: true,
      type: Object,
    },
    activeTab: {
      required: false,
      type: String,
      default: "",
    },
  },
  computed: {
    active: function () {
      return this.tab.id === this.activeTab;
    },
    target: function () {
      if (this.active) {
        return "active";
      } else {
        return this.tab.id;
      }
    },
    overrideTitle: function () {
      if (this.active && this.$store.state.loading) {
        return "<i class='fas fa-spinner fa-spin'></i>";
      }
      return null;
    },
  },
  methods: {
    dispose(tabID) {
      // eslint-disable-next-line no-undef
      session.windows[tabID].dispose();
    },
  },
};
</script>

<style scoped lang="scss">
.tab {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
  margin-right: 8px;
  .btn {
    max-width: 200px;
    text-overflow: ellipsis !important;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
  }
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -4px !important;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
