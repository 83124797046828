
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        
        const __nunjucks_template_import__dep_0 = require("../elements/combobox.html");
        
        const __nunjucks_template_import__dep_1 = require("./table.html");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.assets};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/quickedit.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("views/elements/combobox.html", false, "views/content/quickedit.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("combo", t_1);
output += "\n";
env.getTemplate("views/content/table.html", false, "views/content/quickedit.html", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("table", t_4);
output += "\n\n<div class=\"content row  content-flex\" >\n\t<div class=\"row\" style=\"\n\t\t\tmargin-left: 0px;\n\t\t\talign-items: baseline;\n\t\">\n\t<form class=\"form-view grid row\">\n\t\t<!--<input type=\"hidden\" name=\"RentalType\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"formType"), env.opts.autoescape);
output += "\">-->\n\t\t<div class=\"col-xs-12 col-sm-6 col-md-3\">\n\t\t\t<label class=\"form-field\">\n\t\t\t\t<div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Type"), env.opts.autoescape);
output += ":</div>\n\t\t\t\t<div class=\"form-input required-field\">\n\t\t\t\t\t";
output += runtime.suppressValue((lineno = 14, colno = 31, runtime.callWrap(runtime.memberLookup((t_1),"constructCombobox"), "combo[\"constructCombobox\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"combobox")),"type")])), env.opts.autoescape);
output += "\n\t\t\t\t</div>\n\t\t\t</label>\n\t\t</div>\n\n\t\t<div class=\"col-xs-12 col-sm-6 col-md-3\">\n\t\t\t<label class=\"form-field\">\n\t\t\t\t<div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Value"), env.opts.autoescape);
output += ":</div>\n\t\t\t\t<div class=\"form-input required-field\">\n\t\t\t\t\t";
output += runtime.suppressValue((lineno = 23, colno = 31, runtime.callWrap(runtime.memberLookup((t_1),"constructCombobox"), "combo[\"constructCombobox\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"combobox")),"value")])), env.opts.autoescape);
output += "\n\t\t\t\t</div>\n\t\t\t</label>\n\t\t</div>\n\n\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"selectProductionAssistant")) {
output += "\n\t\t\t<div class=\"col-xs-12 col-sm-6 col-md-3\">\n\t\t\t\t<label class=\"form-field\">\n\t\t\t\t\t<div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"ProductionAssistant"), env.opts.autoescape);
output += ":</div>\n\t\t\t\t\t<div class=\"form-input required-field\">\n\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 33, colno = 32, runtime.callWrap(runtime.memberLookup((t_1),"constructCombobox"), "combo[\"constructCombobox\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"combobox")),"productionAssistant")])), env.opts.autoescape);
output += "\n\t\t\t\t\t</div>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t";
;
}
output += "\n\t</form>\n\n\t";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"rows")),"length") > 0) {
output += "\n\t\t<div class=\"table-view unsortable\">\n\t\t\t<form class=\"table-container\">\n\t\t\t\t<div class=\"table-index sticky-div\">\n\t\t\t\t\t<div class=\"table-row\">\n\t\t\t\t\t\t<!-- <div class=\"field checkbox selection-box\"><div class=\"ellipsis\"><input type=\"checkbox\" title=\"Select all\"></div></div> -->\n\t\t\t\t\t\t<label class=\"table-cell checkbox selection-box\">\n\t\t\t\t\t\t\t<div class=\"ellipsis\"><input type=\"checkbox\" title=\"Select all\"></div>\n\t\t\t\t\t\t</label>\n \n\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"useGroupedRows") == true) {
output += "\n\t\t\t\t\t\t\t<label class=\"table-cell checkbox selection-box\">\n\t\t\t\t\t\t\t\t<div class=\"ellipsis\"></div>\n\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t\t\n\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t\t";
frame = frame.push();
var t_9 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"columns");
if(t_9) {t_9 = runtime.fromIterator(t_9);
var t_8 = t_9.length;
for(var t_7=0; t_7 < t_9.length; t_7++) {
var t_10 = t_9[t_7];
frame.set("col", t_10);
frame.set("loop.index", t_7 + 1);
frame.set("loop.index0", t_7);
frame.set("loop.revindex", t_8 - t_7);
frame.set("loop.revindex0", t_8 - t_7 - 1);
frame.set("loop.first", t_7 === 0);
frame.set("loop.last", t_7 === t_8 - 1);
frame.set("loop.length", t_8);
output += "\n\t\t\t\t\t\t\t<div class=\"table-cell ";
output += runtime.suppressValue((runtime.memberLookup((t_10),"Type") == "Money" || runtime.memberLookup((t_10),"Type") == "Decimal" || runtime.memberLookup((t_10),"Type") == "Int"?"number " + runtime.memberLookup((t_10),"Type"):""), env.opts.autoescape);
output += "\" data-column=\"";
output += runtime.suppressValue(runtime.memberLookup((t_10),"Name"), env.opts.autoescape);
output += "\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((t_10),"Title"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t<div class=\"ellipsis\">\n\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((t_10),"Title"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<!-- <div class=\"field\" title=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "i"), env.opts.autoescape);
output += "\"><div class=\"ellipsis\"><input type=\"checkbox\"></div></div> -->";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"table-body bulkedit\">\n\t\t\t\t\t<div class=\"table-rows\">\n\t\t\t\t\t\t";
frame = frame.push();
var t_13 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"rows");
if(t_13) {t_13 = runtime.fromIterator(t_13);
var t_12 = t_13.length;
for(var t_11=0; t_11 < t_13.length; t_11++) {
var t_14 = t_13[t_11];
frame.set("row", t_14);
frame.set("loop.index", t_11 + 1);
frame.set("loop.index0", t_11);
frame.set("loop.revindex", t_12 - t_11);
frame.set("loop.revindex0", t_12 - t_11 - 1);
frame.set("loop.first", t_11 === 0);
frame.set("loop.last", t_11 === t_12 - 1);
frame.set("loop.length", t_12);
output += "<div class=\"table-row ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"useGroupedRows") == true && runtime.memberLookup((t_14),"groupedRow") == false?"hide detail-row":""), env.opts.autoescape);
output += " ";
output += runtime.suppressValue((runtime.memberLookup((t_14),"groupedRow") == true?"grouped-row":""), env.opts.autoescape);
output += "\" data-row-index=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
output += "\" style=\"";
output += runtime.suppressValue((lineno = 71, colno = 219, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"getCssInfo"), "window[\"getCssInfo\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0")])), env.opts.autoescape);
output += "\" data-id=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_14),"used")),"ItemID"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t<label class=\"table-cell checkbox selection-box\"><input type=\"checkbox\" data-commit-checkbox=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
output += "\" title=\"Select\" ";
output += runtime.suppressValue((runtime.memberLookup((t_14),"selected")?"checked":""), env.opts.autoescape);
output += "></label>\n\t\t\t\t\t\t\t\t<!--<div class=\"table-cell delete-row material-icons\" title=\"Verwijderen\"><div class=\"ellipsis\">&#xe5cd;</div></div>-->\n\n\t\t\t\t\t\t\t\t";
if(runtime.memberLookup((t_14),"groupedRow")) {
output += "\n\t\t\t\t\t\t\t\t\t<div class=\"table-cell dropdown-info material-icons\" data-toggle-lower-field>&#xe313;</div>\n\t\t\t\t\t\t\t\t";
;
}
else {
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"useGroupedRows") == true) {
output += "\n\t\t\t\t\t\t\t\t\t<div class=\"table-cell\"></div>\n\t\t\t\t\t\t\t\t";
;
}
;
}
output += "\n\n\t\t\t\t\t\t\t\t";
var t_15;
t_15 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0");
frame.set("index", t_15, true);
if(frame.topLevel) {
context.setVariable("index", t_15);
}
if(frame.topLevel) {
context.addExport("index", t_15);
}
output += "\n\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_18 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"columns");
if(t_18) {t_18 = runtime.fromIterator(t_18);
var t_17 = t_18.length;
for(var t_16=0; t_16 < t_18.length; t_16++) {
var t_19 = t_18[t_16];
frame.set("col", t_19);
frame.set("loop.index", t_16 + 1);
frame.set("loop.index0", t_16);
frame.set("loop.revindex", t_17 - t_16);
frame.set("loop.revindex0", t_17 - t_16 - 1);
frame.set("loop.first", t_16 === 0);
frame.set("loop.last", t_16 === t_17 - 1);
frame.set("loop.length", t_17);
output += runtime.suppressValue((lineno = 83, colno = 31, runtime.callWrap(runtime.memberLookup((t_4),"setColumnCell"), "table[\"setColumnCell\"]", context, [t_19,"",runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"),runtime.memberLookup((runtime.memberLookup((t_14),"used")),runtime.memberLookup((t_19),"Name")),runtime.contextOrFrameLookup(context, frame, "window"),runtime.memberLookup((runtime.memberLookup((t_14),"cells")),runtime.memberLookup((t_19),"Name")),runtime.memberLookup((runtime.memberLookup((t_14),"editable")),runtime.memberLookup((t_19),"Name")),runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Data")),"Metadata")),runtime.contextOrFrameLookup(context, frame, "index"))])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</form>\n\t\t</div>\n\t";
;
}
output += "\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/quickedit.html", ctx);
          }
        
          return nunjucks.render("views/content/quickedit.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/quickedit.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        