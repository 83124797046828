import $ from "jquery";
import formatter from "../model/formatter";
import Hook from "../hook";
import {api} from "../services/api";
import store from "../../state/store";
import ComboboxUI from "../interface/combobox";

import {notify} from "../../src/util/notify";

import swal from "sweetalert";
/** Pick hook */

async function handleScan(barcode, window) {
  let pickListItems = getBulkData(window);

  const result = await api.post(`/v2/process/pick/scan`, pickListItems, {
    headers: {
      Authorization: `Bearer ${store.state.accessToken}`,
    },
    params: {
      barcode,
    },
  });

  if (result.data.Message) {
    if (result.data.Message.Type == "danger") {
      let alertObject = {
        text: result.data.Message.Message,
        icon: "error",
        dangerMode: true,
        buttons: {
          confirm: "OK",
        },
      };

      swal(alertObject);
      return false;
    }

    notify({
      message: result.data.Message.Message,
      type: result.data.Message.Type,
    });
  }

  if (result.data.PickListItem) {
    let index = -1;

    for (let i = 0; i < window.output.Data.Rows.length; i++) {
      if (
        window.output.Data.Rows[i]["PickListItemID"] ==
        result.data.PickListItem["PickListItemID"]
      ) {
        index = i;
        break;
      }
    }

    updateAmountToPick(window, index, result.data.PickListItem);
  }

  return true;
}

/**
 * Update the AmountPicked of the given JQuery element.
 * Triggers 'blur' so the row gets selected and the change is picked up and registered so it can be send to the server correctly.
 */
async function updateAmountToPick(window, rowIndex, data) {
  let $row = $(
    $(window.element)
      .find(".table-row")
      .toArray()
      .filter(function (row) {
        return $(row).attr("data-row-index") == rowIndex;
      }),
  );

  if (
    window.output.Table.Rows[rowIndex].some(function (row) {
      return row.Column.Name == "LocationID";
    })
  ) {
    let $locationID = $row.find(`[name='LocationID']`);
    let combobox = ComboboxUI.getClass($locationID.parent());

    await ComboboxUI.fetch(combobox);
    ComboboxUI.selectByValue(combobox, data.LocationID);
    ComboboxUI.close(combobox);
  }

  if (data.SerialID) {
    let $serialID = $row.find(`[name='SerialID']`);
    if ($serialID.val() != data.SerialID) {
      let combobox = ComboboxUI.getClass($serialID.parent());

      await ComboboxUI.fetch(combobox);
      ComboboxUI.selectByValue(combobox, data.SerialID);
      ComboboxUI.close(combobox);
    }
  }

  let $amountPicked = $row.find(`[name='AmountPicked']`);

  $amountPicked.val(data.AmountPicked);
  $amountPicked.trigger("change");
}

class PickHook extends Hook {
  /**
   * Add Cancel button, which delegates to window.dispose() to close the window.
   * @param {*} window
   */
  async afterProcess(window) {
    if (window.output.Request.Subject == "Rental.virtual_PickListItem") {
      let cancelButton = {
        Event: "dispose",
        Title: window.session.translations.Cancel,
      };

      window.output.Buttons.push(cancelButton);
    }
  }

  async afterRender(window) {
    if (window.output.Request.Subject == "Rental.virtual_PickListItem") {
      $(window.element).find(".dropdown").css("width", "100%");
      /**
       * Check if the entered value is present in the table rows, and if it is and the AmountPicked can be updated, do so.
       * Clears the value of the scanbox afterwards.
       */
      $(window.element).on(
        "change value-change",
        "[name=ScanItem], [name=SerialID]",
        async function (e) {
          e.preventDefault();

          let barcode = $(this).val();

          if (!window.isHandlingPick && barcode) {
            window.isHandlingPick = true;

            if ($(this).attr("name") != "ScanItem") {
              let $amountPicked = $(this)
                .closest(".table-row")
                .find(`[name='AmountPicked']`);

              $amountPicked.val(0);
            }

            let result = await handleScan(barcode, window);

            if ($(this).attr("name") == "ScanItem") {
              $(this).val("");
            } else if (!result) {
              let combobox = ComboboxUI.getClass($(this).parent());

              await ComboboxUI.reset(combobox);
            }

            window.isHandlingPick = false;
          }
        },
      );
    }
  }
}

/**
 * Get bulk edit data
 */
function getBulkData(window) {
  let _this = window;
  let rows = [];

  // get all rows
  let $tableRows = $(_this.element).find(".table-row").toArray();

  // construct data and criteria for each row
  for (let tableRowEl of $tableRows) {
    let rowData = _this.output.Data.Rows[$(tableRowEl).attr("data-row-index")];

    if (!rowData) {
      continue;
    }

    // Get all values, sanitized
    $(tableRowEl)
      .find("[name]")
      .each(function forEachName() {
        let name = $(this).attr("name").split(/\[|\]/g).shift();
        let value = $(this).is("[contenteditable]")
          ? $(this).text()
          : $(this).val();

        let colIndex = $(this).closest(".table-cell").attr("data-field-index");

        if (colIndex === undefined) {
          colIndex = $(this).attr("data-field-index");
        }

        let col =
          _this.output.Table.Rows[$(tableRowEl).attr("data-row-index")][
            colIndex
          ].Column;

        if (!col) {
          return;
        }

        value = formatter.serializeValue(col, value);
        rowData[name] = value;
      });

    rows.push(rowData);
  }

  return rows;
}

/** @ignore */
export default new PickHook();
