<template>
  <v-select
    :options="filter.options"
    :placeholder="`${translations.Search}`"
    v-model="selectedValue"
    label="label"
    id="quickOpen"
    :resetOnOptionsChange="true"
    @search="fetchOptions"
    @option:selected="selectOption"
  >
    <template v-slot:option="option">
      <span>{{ option.label }}</span>
    </template>
    <div slot="no-options">{{ translations.NoRowsFound }}</div>
  </v-select>
</template>

<script>
import vSelect from "vue-select";
import Window from "../../src/model/window";
import {quickSearch} from "../../src/services/quickSearch";
export default {
  components: {
    "v-select": vSelect,
  },
  data: function () {
    return {
      filter: {
        options: [],
      },
      loading: false,
      selectedValue: null,
      requestData: null,
      newFetchData: null,
    };
  },
  computed: {
    translations: function () {
      return this.$store.state.translations;
    },
  },
  methods: {
    fetchOptions: async function (event) {
      if (this.loading) {
        this.newFetchData = event;
        return;
      }
      this.loading = true;
      const requestData = await quickSearch({searchValue: event});

      this.filter.options;

      const newOptions = [];

      for (const searchResult of requestData) {
        newOptions.push({label: searchResult.Text, value: searchResult.Value});
      }

      this.filter.options = newOptions;

      this.loading = false;
      if (this.newFetchData !== null) {
        const newFetchData = this.newFetchData;
        this.newFetchData = null;

        await this.fetchOptions(newFetchData);
      }
    },
    selectOption: async function (event) {
      let window = new Window(global.session);
      this.filter.options = [];

      try {
        //await window.render()
        window.customTitle = "<i class='fas fa-spinner fa-spin'>";
        window.render();
        window.focus();

        let output = await global.session.request(
          "/Admin/WebServices/CoreWebServices.asmx/OpenNodeByValue",
          {
            value: event.value,
            description: event.label,
          },
        );

        await window.process(output);
        window.customTitle = null;
        window.loading = false;
        await window.render();
        this.$store.commit("refreshTabs");
        this.$store.commit("updateWindow");
      } catch (err) {
        console.error(err);
        window.dispose();
      }
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
.v-select {
  .vs__search,
  .vs__search:focus {
    color: #242424 !important;
    line-height: 1.2;
  }

  .vs__dropdown-toggle {
    height: 27px;
    background: white;
    border-radius: 3px;
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.09) !important;
  }
  .vs__dropdown-menu {
    z-index: 2900;
  }
  .vs__dropdown-option--highlight {
    background-color: #e1e1e1 !important;
    color: black;
  }

  .vs__open-indicator {
    color: black;
    fill: black;
    transform: scale(0.65);

    width: 15px !important;
  }
}
</style>
