<template>
  <div class="option-qr">
    <div v-if="translations">
      <div class="title">
        <p>{{ translations.TwoFactorSetupAppQRTitle }}</p>
      </div>

      <div class="qr text-center">
        <p class="text-left mt-2">
          <strong>1.</strong> {{ translations.TwoFactorSetupAppStepOneTitle }}
        </p>
        <qrcode-vue
          v-if="qrCodeValue"
          :value="qrCodeValue"
          :size="300"
        ></qrcode-vue>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import {requestQRCode} from "@/services/twoStep";

export default {
  name: "TwoFactorSetupOptionQR",
  components: {
    QrcodeVue,
  },
  data() {
    return {
      qrData: null,
      username: "",
    };
  },

  computed: {
    translations() {
      return this.$store.state.translations;
    },
    qrCodeValue() {
      if (this.qrData.manualEntrySetupCode) {
        return `otpauth://totp/${this.username}@${window.location.host}?secret=${this.qrData.manualEntrySetupCode}&issuer=RentMagic`;
      }
      return null;
    },
  },
  async created() {
    this.qrData = global.session.twoStepQR;
    this.username = document.querySelector("[name='username']")?.value;

    if (this.qrData === null || this.qrData === false) {
      this.username = this.$store.state.user.me.UserName;
      this.qrData = await requestQRCode({
        username: this.$store.state.user.me.UserName,
      });
    }
  },
};
</script>

<style scoped lang="scss">
.option-qr {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    font-size: 16px;
    font-weight: 600;
  }
}
</style>
