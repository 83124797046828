<template>
  <span refs="modal" class="mt-2">
    <a
      id="availabilityModalButton"
      variant="link"
      class="text-white button button-default"
      style="margin-top: 23px"
      v-b-modal.modal-1
      >{{ buttonText }}</a
    >

    <b-modal size="xl" id="modal-1" :title="modalTitle">
      <div id="availabilityModalBody"></div>
      <div slot="modal-footer" class="w-100">
        <b-button
          class="float-right"
          variant="secondary"
          @click="$bvModal.hide('modal-1')"
          >{{ translations.Close }}</b-button
        >
      </div>
    </b-modal>
  </span>
</template>

<script>
export default {
  data() {
    return {
      translations: window.session.translations,
      modalTitle: "",
      buttonText: "",
      modalBody: "",
    };
  },
  methods: {
    setButtonText(payload) {
      this.buttonText = payload;
    },
    setModalTitle(payload) {
      this.modalTitle = payload;
    },
    setModalBody(payload) {
      this.modalBody = payload;
    },
  },
};
</script>

<style scoped></style>
