import store from "../../state/store";
import Hook from "../hook";
import Vue from "../interface/vue";
import ActionBarHorizontal from "../../views/interface/ActionBarHorizontal.vue";
import Sidebar from "../../views/interface/FormSidebar";

class WindowHook extends Hook {
  async afterRender(window) {
    store.commit("updateWindow");

    const sidebarElements = document.querySelectorAll("[sidebar]");

    if (sidebarElements.length) {
      new Vue({
        el: sidebarElements[0],
        store,
        render: (h) => h(Sidebar, {}),
      });
    }

    if (window.element?.querySelectorAll("[action-bar]").length > 0) {
      new Vue({
        el: window.element.querySelectorAll("[action-bar]")[0],
        store,
        render: (h) => h(ActionBarHorizontal),
      });
    }
    if (window.input !== null) {
      const allWindowInput = global.session.tabs
        .filter((tab) => tab.input !== null)
        .map((tab) => tab.input);
      store.commit("setWindowHistory", allWindowInput);
    }
  }
  async afterDispose(window) {
    if (window.input !== null) {
      const allWindowInput = global.session.tabs
        .filter((tab) => tab.input !== null)
        .map((tab) => tab.input);
      store.commit("setWindowHistory", allWindowInput);
    }
  }
}

export default new WindowHook();
