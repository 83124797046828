import $ from "jquery";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

import vueDateTimeTemplate from "../../views/elements/vueDateTime.vue";

import Sidebar from "../../views/interface/FormSidebar.vue";
import store from "../../state/store";
import Hook from "../hook";
import Vue from "@/interface/vue";

import RichTextEditor from "../../views/elements/RichTextEditor.vue";

import FormField from "@/components/form/FormField.vue";

class FormHook extends Hook {
  async afterRender(window) {
    if (
      window.output.Request.Prefix == "Single" ||
      window.output.Request.Prefix == "New" ||
      window.output.Request.Prefix == "Preview" ||
      window.output.Request.Prefix == "Custom"
    ) {
      //vue-form-field

      const vueFormFields = $(window.element).find("div [vue-form-field]");

      for (const vueFormField of vueFormFields) {
        const vueFormFieldAttrs = vueFormField
          .getAttributeNames()
          .reduce((acc, name) => {
            return {...acc, [name]: vueFormField.getAttribute(name)};
          }, {});

        new Vue({
          el: `#${vueFormField.id}`,
          store,
          render: (h) =>
            h(FormField, {
              props: {
                field: vueFormFieldAttrs,
              },
            }),
        });
      }

      //focusFirstField(window)
      const calendarFields = $(window.element).find(
        "div [data-editor-type='calendar']",
      );
      for (const calendarField of calendarFields) {
        new Vue({
          el: `#${calendarField.id}`,
          store,
          render: (h) =>
            h(vueDateTimeTemplate, {
              props: {
                name: $(calendarField).attr("name"),
                index: $(calendarField).attr("index"),
                value: $(calendarField).attr("value"),
                readonly: $(calendarField).attr("read-only"),
                showTime: $(calendarField).attr("show-time") === "true",
                filterOnOpeningsDay: $(calendarField).attr(
                  "filter-on-openings-day",
                ),
                timePicker: $(calendarField).attr("time-picker"),
                postback: $(calendarField).attr("postback") == "true",
              },
            }),
        });
      }

      const ckeditorFields = $(window.element).find(
        "div [data-editor-type='ckeditor']",
      );
      for (const ckeditorField of ckeditorFields) {
        new Vue({
          el: `#${ckeditorField.id}`,
          render: (h) =>
            h(RichTextEditor, {
              props: {
                name: $(ckeditorField).attr("name"),
                index: $(ckeditorField).attr("index"),
                value: $(ckeditorField).attr("value"),
                readonly: $(ckeditorField).attr("readonly"),
              },
            }),
        });
      }

      if (
        window.output.Request.Prefix == "Single" ||
        window.output.Request.Prefix == "New" ||
        window.output.Request.Prefix == "Multi" ||
        window.output.Request.Prefix == "View"
      ) {
        if (!window.sub && !window.parent) return;
        const sidebarElements = document.querySelectorAll("[sidebar]");
        console.log({sidebarElements, window});
        if (sidebarElements.length) {
          new Vue({
            el: sidebarElements[0],
            store,
            render: (h) => h(Sidebar, {}),
          });
        }
      }
    }
  }
}

/** Table Hook */
class TableHook extends Hook {
  /**
   * Use dates on tables
   * @param {Window} window - Window
   * @returns {Promise} Promise
   */
  async afterProcess(window) {
    /*
      Filters
    */

    if (
      window.output.Request.Prefix == "Multi" ||
      window.output.Request.Subject == "Core.vw_Tasks"
    ) {
      if (window.output.Options && window.output.Filters != null) {
        window.filters = {
          date: [],
          other: [],
          selectedOthers: 0,
        };

        if (
          window.output.Filters.filter(function (filter) {
            return (
              filter.Type == "DateEquals" ||
              filter.Type == "Date" ||
              filter.Type == "DateFrom" ||
              filter.Type == "PeriodStart"
            );
          }).length > 0
        ) {
          window.filters.date.push({
            text: window.session.translations.FilterDateStart,
            suffix: ">=",
            value: this.formatFilterValue(
              window.output.Request.Data.Filters[">="],
            ),
          });
        }

        if (
          window.output.Filters.filter(function (filter) {
            return (
              filter.Type == "Date" ||
              filter.Type == "DateTo" ||
              filter.Type == "PeriodEnd"
            );
          }).length > 0
        ) {
          window.filters.date.push({
            text: window.session.translations.FilterDateEnd,
            suffix: "<=",
            value: this.formatFilterValue(
              window.output.Request.Data.Filters["<="],
            ),
          });
        }
      }
    }
  }

  formatFilterValue(value) {
    if (value != null) {
      value = dayjs(value).format("DD-MM-YYYY");
    }

    return value;
  }
}

/** Date filter 'equals' hook */
class DateEqualsHook extends Hook {
  /**
   * Set correct filters
   * @param {Window} window - Window
   * @returns {Promise} Promise
   */
  async afterProcess(window) {
    if (
      window.output.Options.Filters_UseDate == null ||
      !window.output.Options.Filters_UseDate.endsWith ||
      !window.output.Options.Filters_UseDate.endsWith(" =")
    ) {
      return;
    }

    let filterTarget = window.output.Options.Filters_UseDate.slice(0, -2);
    let filterText = window.session.translations.Date;
    if (
      window.output.Request.Data.Filters.hasOwnProperty(
        window.output.Options.Filters_UseDate,
      )
    ) {
      filterText =
        window.session.translations.Date +
        ": " +
        window.output.Request.Data.Filters[
          window.output.Options.Filters_UseDate
        ];
    }

    window.filters = {
      usedate: filterTarget,
      date: [
        {
          text: filterText,
          suffix: "=",
          value: null,
        },
      ],
      other: [],
      selectedOthers: 0,
    };
  }
}

/** @ignore */
export default [
  new TableHook(),
  new DateEqualsHook(),
  // new VWInvoiceProposalHook(),
  new FormHook(),
];
