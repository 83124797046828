
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/snippet/calendar.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var t_1;
t_1 = 0;
frame.set("daycount", t_1, true);
if(frame.topLevel) {
context.setVariable("daycount", t_1);
}
if(frame.topLevel) {
context.addExport("daycount", t_1);
}
output += "\n";
var t_2;
t_2 = 0;
frame.set("weekcount", t_2, true);
if(frame.topLevel) {
context.setVariable("weekcount", t_2);
}
if(frame.topLevel) {
context.addExport("weekcount", t_2);
}
output += "\n<div class=\"menu-overflow\">\n\t<div class=\"menu calendar calendar-box\" data-month=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "month"), env.opts.autoescape);
output += "\" data-day=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "day"), env.opts.autoescape);
output += "\" data-year=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "year"), env.opts.autoescape);
output += "\" data-hour=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "hour"), env.opts.autoescape);
output += "\" data-minute=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "minute"), env.opts.autoescape);
output += "\">\n\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "options")),"showDates")) {
output += "\n\t\t<div class=\"month\">\n\t\t\t<div class=\"month-name\">\n\t\t\t\t<div class=\"month-action material-icons\" data-previous-month>&#xe314;</div>\n\t\t\t\t<div class=\"right month-action material-icons\" data-next-month>&#xe315;</div>\n\t\t\t\t<span>\n\t\t\t\t\t<span data-time-action-clear><i class=\"fas fa-action fa-calendar-times\"></i></span>&nbsp;&nbsp;\n\t\t\t\t\t";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "monthname"), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "year"), env.opts.autoescape);
output += "&nbsp;&nbsp;\n\t\t\t\t\t<span data-time-action-collapse><i class=\"fas fa-action fa-check\"></i></span>\n\t\t\t\t</span>\n\t\t\t</div>\n\t\t\t<div class=\"day-names\">\n\t\t\t\t<div class=\"day\"><div class=\"day-name\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"Mo"), env.opts.autoescape);
output += "</div></div>\n\t\t\t\t<div class=\"day\"><div class=\"day-name\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"Tu"), env.opts.autoescape);
output += "</div></div>\n\t\t\t\t<div class=\"day\"><div class=\"day-name\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"We"), env.opts.autoescape);
output += "</div></div>\n\t\t\t\t<div class=\"day\"><div class=\"day-name\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"Th"), env.opts.autoescape);
output += "</div></div>\n\t\t\t\t<div class=\"day\"><div class=\"day-name\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"DayFr"), env.opts.autoescape);
output += "</div></div>\n\t\t\t\t<div class=\"day\"><div class=\"day-name\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"Sa"), env.opts.autoescape);
output += "</div></div>\n\t\t\t\t<div class=\"day\"><div class=\"day-name\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"Su"), env.opts.autoescape);
output += "</div></div>\n\t\t\t</div>\n\t\t\t";
frame = frame.push();
var t_5 = (lineno = 24, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,6]));
if(t_5) {t_5 = runtime.fromIterator(t_5);
var t_4 = t_5.length;
for(var t_3=0; t_3 < t_5.length; t_3++) {
var t_6 = t_5[t_3];
frame.set("week", t_6);
frame.set("loop.index", t_3 + 1);
frame.set("loop.index0", t_3);
frame.set("loop.revindex", t_4 - t_3);
frame.set("loop.revindex0", t_4 - t_3 - 1);
frame.set("loop.first", t_3 === 0);
frame.set("loop.last", t_3 === t_4 - 1);
frame.set("loop.length", t_4);
output += "\n\t\t\t<div class=\"week\">\n\t\t\t\t";
frame = frame.push();
var t_9 = (lineno = 26, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [runtime.contextOrFrameLookup(context, frame, "weekcount"),7]));
if(t_9) {t_9 = runtime.fromIterator(t_9);
var t_8 = t_9.length;
for(var t_7=0; t_7 < t_9.length; t_7++) {
var t_10 = t_9[t_7];
frame.set("day", t_10);
frame.set("loop.index", t_7 + 1);
frame.set("loop.index0", t_7);
frame.set("loop.revindex", t_8 - t_7);
frame.set("loop.revindex0", t_8 - t_7 - 1);
frame.set("loop.first", t_7 === 0);
frame.set("loop.last", t_7 === t_8 - 1);
frame.set("loop.length", t_8);
output += "\n\t\t\t\t\t";
output += "\n\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "daycount") < runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "days")),"length")) {
output += "\n\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "days")),runtime.contextOrFrameLookup(context, frame, "daycount"))),"isEnabled") != null && !runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "days")),runtime.contextOrFrameLookup(context, frame, "daycount"))),"isEnabled")) {
output += "\n\t\t\t\t\t\t\t<div class=\"day";
output += runtime.suppressValue((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "days")),runtime.contextOrFrameLookup(context, frame, "daycount"))),"isToday")?" today":""), env.opts.autoescape);
output += runtime.suppressValue((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "days")),runtime.contextOrFrameLookup(context, frame, "daycount"))),"isSelected")?" selected":""), env.opts.autoescape);
output += " disabled\"><div class=\"day-name\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "days")),runtime.contextOrFrameLookup(context, frame, "daycount"))),"day"), env.opts.autoescape);
output += "</div></div>\n\t\t\t\t\t\t";
;
}
else {
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "days")),runtime.contextOrFrameLookup(context, frame, "daycount"))),"isOtherMonth")) {
output += "\n\t\t\t\t\t\t\t<div class=\"day";
output += runtime.suppressValue((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "days")),runtime.contextOrFrameLookup(context, frame, "daycount"))),"isToday")?" today":""), env.opts.autoescape);
output += runtime.suppressValue((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "days")),runtime.contextOrFrameLookup(context, frame, "daycount"))),"isSelected")?" selected":""), env.opts.autoescape);
output += " disabled\"><div class=\"day-name\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "days")),runtime.contextOrFrameLookup(context, frame, "daycount"))),"day"), env.opts.autoescape);
output += "</div></div>\n\t\t\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t\t\t<div class=\"day";
output += runtime.suppressValue((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "days")),runtime.contextOrFrameLookup(context, frame, "daycount"))),"isToday")?" today":""), env.opts.autoescape);
output += runtime.suppressValue((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "days")),runtime.contextOrFrameLookup(context, frame, "daycount"))),"isSelected")?" selected":""), env.opts.autoescape);
output += "\"><div data-day=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "days")),runtime.contextOrFrameLookup(context, frame, "daycount"))),"day"), env.opts.autoescape);
output += "\" class=\"day-name\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "days")),runtime.contextOrFrameLookup(context, frame, "daycount"))),"day"), env.opts.autoescape);
output += "</div></div>\n\t\t\t\t\t\t";
;
}
;
}
output += "\n\t\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t\t<div class=\"day disabled\"><div class=\"day-name\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "daycount") + 1 - runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "days")),"length"), env.opts.autoescape);
output += " </div></div>\n\t\t\t\t\t";
;
}
output += "\n\t\t\t\t";
var t_11;
t_11 = runtime.contextOrFrameLookup(context, frame, "daycount") + 1;
frame.set("daycount", t_11, true);
if(frame.topLevel) {
context.setVariable("daycount", t_11);
}
if(frame.topLevel) {
context.addExport("daycount", t_11);
}
output += "\n\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t</div>\n\t\t\t";
var t_12;
t_12 = 0;
frame.set("weekcount", t_12, true);
if(frame.topLevel) {
context.setVariable("weekcount", t_12);
}
if(frame.topLevel) {
context.addExport("weekcount", t_12);
}
output += "\n\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t</div>\n\t\t";
;
}
output += "\n\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "options")),"showTime")) {
output += "\n\t\t<div class=\"time\">\n\t\t\t<div class=\"time-select\">\n\t\t\t\t<div data-time-action=\"hour:up\" class=\"time-button material-icons\">&#xe316;</div>\n\t\t\t\t<input class=\"hours time-input no-field\" type=\"text\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "hour"), env.opts.autoescape);
output += "\">\n\t\t\t\t<div data-time-action=\"hour:down\" class=\"time-button material-icons\">&#xe313;</div>\n\t\t\t</div>\n\t\t\t<div class=\"time-select\">\n\t\t\t\t<div data-time-action=\"minute:up\" class=\"time-button material-icons\">&#xe316;</div>\n\t\t\t\t<input class=\"minutes time-input no-field\" type=\"text\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "minute"), env.opts.autoescape);
output += "\">\n\t\t\t\t<div data-time-action=\"minute:down\" class=\"time-button material-icons\">&#xe313;</div>\n\t\t\t</div>\n\t\t\t<div class=\"inbetween\">:</div>\n\t\t</div>\n\t\t";
;
}
output += "\n\t</div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/snippet/calendar.html", ctx);
          }
        
          return nunjucks.render("views/snippet/calendar.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/snippet/calendar.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        