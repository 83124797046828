<template>
  <div v-if="showDialog">
    <b-modal
      v-model="showDialog"
      :show="showDialog"
      :size="size"
      :title="title"
      :variant="variant"
      @close="hideDialog"
      @hide="hideDialog"
    >
      <component
        v-bind:is="template"
        v-if="showDialog"
        :dialogData="dialogData"
      >
      </component>
      <template #modal-footer>
        <component
          v-bind:is="footerTemplate"
          v-if="footerTemplate"
          :dialogData="dialogData"
        />

        <dialog-close-button v-else class="w-100" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import DialogCloseButton from "./DialogCloseButton";

export default {
  name: "Dialog",
  components: {DialogCloseButton},
  computed: {
    showDialog() {
      return this.$store.getters["dialog/show"];
    },
    templateName() {
      return this.$store.getters["dialog/getTemplate"] ?? "dialogForm";
    },
    template() {
      if (this.templateName === "") return null;
      return () => import(`./Dialog${this.templateName}.vue`);
    },
    footerTemplate() {
      if (this.templateName === "") return null;
      return () => import(`./Dialog${this.templateName}Footer.vue`) ?? null;
    },
    title() {
      return this.$store.getters["dialog/getTitle"];
    },
    dialogData() {
      return this.$store.getters["dialog/getData"];
    },
    size() {
      return this.dialogData.DialogSize ?? "sm";
    },
    variant() {
      return this.dialogData.DialogVariant ?? "primary";
    },
  },
  methods: {
    hideDialog() {
      this.$store.commit("dialog/hideDialog");
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
