// initial state
// shape: [{ user, properties }]
import {saveTable} from "@/services/datagrid";
import postRequest from "@/services/client-action-requests/postRequest";

const state = () => ({
  title: "",
  template: "",
  data: "",
  show: false,
});

// getters
const getters = {
  getTitle: (state) => state.title,
  getTemplate: (state) => state.template,
  getData: (state) => state.data,
  getFields: (state) => state.data.Fields,
  show: (state) => state.show,
};

// actions
const actions = {
  async postRequest({getters}, {data}) {
    if (data.IncludeFormFields) {
      const formFields = {};

      for (const field in getters.getFields) {
        formFields[field] = getters.getFields[field].Value;
      }

      data.RequestBody = {...data.RequestBody, FormFields: formFields};
    }

    await postRequest(data);
  },
  async save({rootState, rootGetters, state}) {
    const data = [];

    // list of objects
    const fields = {...state.data.Fields};

    Object.keys(fields).forEach((i) => {
      fields[i] = fields[i].Value;
    });

    const Values = fields;

    const rowData = {
      Criteria: state.data.Criteria,
      Values,
    };

    data.push(rowData);

    await saveTable({
      subject: "Core.RailRoadTask",
      data,
      windowId: rootGetters["windows/activeWindowId"],
    });

    rootState.loading = false;
  },
};

// mutations
const mutations = {
  setTitle(state, title) {
    state.title = title;
  },
  setTemplate(state, template) {
    state.template = template;
  },
  setData(state, data) {
    state.data = data;
  },
  hideDialog(state) {
    state.show = false;
  },
  showDialog(state) {
    state.show = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
