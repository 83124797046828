<template>
  <div class="col-md-12 col-lg-2 sidebar-wrapper">
    <b-row class="sidebar">
      <b-col cols="12" v-if="sidebarFields.length">
        <field
          v-for="field in sidebarFields"
          :key="field.Name"
          :field="field"
        />
      </b-col>
      <b-col cols="12">
        <sidebar-checklist v-show="guides.length" :guides="guides" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Field from "../content/fields/Field.vue";
import SidebarChecklist from "./formSidebar/FormSidebarChecklist.vue";
import {getGuides} from "@/services/guide";

export default {
  components: {
    Field,
    SidebarChecklist,
  },
  name: "FormSidebar",
  data() {
    return {
      guides: [],
    };
  },
  computed: {
    window() {
      if (this.$store.getters.subWindow.output) {
        return this.$store.getters.subWindow;
      }
      return this.$store.getters.window;
    },
    columns() {
      return this.window?.output?.FullTable?.Columns;
    },
    prefix() {
      return this.window.output?.Request?.Prefix ?? null;
    },
    metadata() {
      return this.window?.output?.Data.Metadata[0];
    },
    row() {
      return this.window?.output?.FullTable.Rows[0];
    },
    fields() {
      const fields = [];
      if (!this.row) return null;

      for (const index in this.row) {
        fields.push({...this.row[index], ...this.columns[index]});
      }

      return fields.filter((field) => field.IsSideBarColumn === true);
    },
    sidebarFields() {
      let targetWindowID = this.window.id;

      if (global.session.windows[targetWindowID]?.parent) {
        targetWindowID = global.session.windows[targetWindowID].parent.id;
      }

      if (this.columns) {
        const sidebarFields = this.columns?.filter(
          (column) => column.IsSideBarColumn,
        );

        for (const sidebarField of sidebarFields) {
          sidebarField.Value = this.getValueByColumnName(sidebarField.Name);
          sidebarField.Metadata = this.getMetadataByColumnName(
            sidebarField.Name,
          );
          sidebarField.Combobox = this.getComboboxByColumnName(
            sidebarField.Name,
          );
        }

        if (
          sidebarFields.length > 0 &&
          global.session.windows[targetWindowID]
        ) {
          global.session.windows[targetWindowID].sidebarFields = sidebarFields;

          return sidebarFields;
        }
      }

      if (
        global.session.windows[targetWindowID]?.sidebarFields &&
        global.session.windows[targetWindowID].sidebarFields !== []
      ) {
        const fields = global.session.windows[targetWindowID].sidebarFields;
        fields.forEach((sidebarField) => {
          sidebarField.IsReadyOnly = true;
        });
        return fields;
      }

      return [];
    },
  },
  async created() {
    this.guides = await this.updateGuides();
  },

  methods: {
    async updateGuides() {
      const criteria = this.$store.state.window.output.Request?.Criteria ?? [];

      if (this.$store.state.window.output.Request.Prefix === "New") return [];
      if (!criteria[0]) return [];

      const result = await getGuides({
        subject: this.$store.state.window.output.Request.Subject,
        criteria: this.$store.state.window.output.Request.Criteria[0],
      });

      if (!Array.isArray(result)) {
        return [];
      }

      return result;
    },
    getValueByColumnName(name) {
      return (
        this.row?.find((rowColumn) => rowColumn.Column.Name === name).Value ??
        null
      );
    },
    getMetadataByColumnName(name) {
      return this.metadata[name] ?? null;
    },
    getComboboxByColumnName(name) {
      return (
        this.row?.find((rowColumn) => rowColumn.Column.Name === name).Combobox ??
        null
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  border-left: 2px solid #eeeeee;
  position: sticky;
  top: 0px;
  padding-top: 20px;
}
.title {
  font-weight: 600;
  font-size: 14px;
}
</style>
