<template>
  <div class="date-field">
    <b-form-input
      v-model="val"
      :state="validData"
      pattern="[0-9-]+"
      maxlength="10"
      :readonly="readonly == 'true'"
    />
    <slot></slot>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    date: {
      required: true,
      type: String,
    },
    originalValue: {
      required: false,
      type: String,
      default: function () {
        return "";
      },
    },
    showTime: {
      required: false,
      type: Boolean,
      default: false,
    },
    readonly: {
      required: false,
      type: String,
      default: function () {
        return "false";
      },
    },
  },
  data() {
    return {
      val: this.readonly && this.showTime ? this.originalValue : this.date,
      patterns: ["DD-MM-YYYY", "D-MM-YYYY", "DD-M-YYYY", "D-M-YYYY"],
      validData: null,
    };
  },
  watch: {
    date: function (val) {
      this.val = val;
    },
    val: function () {
      if (this.val.match(/[a-zA-Z]/)) this.val = this.val.slice(0, -1);

      if (this.val === "") {
        this.$emit("change", "");
        this.validData = null;
        return;
      }

      for (let pattern of this.patterns) {
        if (dayjs(this.val, pattern).format("YYYY-MM-DD") === "Invalid Date")
          continue;

        this.$emit("change", dayjs(this.val, pattern).format("YYYY-MM-DD"));
        this.validData = null;
        return;
      }
      this.validData = false;
    },
  },
};
</script>
<style lang="scss">
.date-field {
  display: grid;
  grid-template-columns: 1fr 1px;
  .btn {
    margin-left: -35px;
  }
}
</style>
