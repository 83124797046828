<template>
  <span>
    <b-button
      :block="block"
      variant="primary"
      class="mb-2 mt-2"
      v-b-modal.modal-1
      @click="show = true"
    >
      <div v-if="icon" class="float-left" v-html="icon" />
      {{ title }}</b-button
    >
    <two-factor-setup v-if="show" />
  </span>
</template>

<script>
import TwoFactorSetup from "../../twoFactorSetup/TwoFactorSetup";
export default {
  components: {
    TwoFactorSetup,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    block: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      show: false,
    };
  },
};
</script>

<style></style>
