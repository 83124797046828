import Hook from "../hook";

/**
 * This hook updates the window.input.Criteria if window.output.Request.Criteria contains serialized DateTimes, because otherwise on reload the server will throw an error.
 */
class CategoryHook extends Hook {
  async afterProcess(window) {
    if (
      window.output.Request.Criteria &&
      window.output.Request.Criteria.Subject == "Rental.Category"
    ) {
      alert("hi");
      global.session.activeWindow.render();
    }
  }
}

/** @ignore */
export default new CategoryHook();
